import { Injectable } from '@angular/core';
import { EntityType, House, Outing } from '@rhbnb-nx-ws/domain';
import { ElementImagePipeService, FilterImagePipeService } from '@rhbnb-nx-ws/shared-pipes';
import { EvaluationUtilService, MathService } from '@rhbnb-nx-ws/services';

import { SearchMapItem } from './map-item';

@Injectable()
export class MapItemMapperService {
  constructor(
    private elementImagePipeService: ElementImagePipeService,
    private filterImagePipeService: FilterImagePipeService,
    private evaluationUtilService: EvaluationUtilService,
    private mathService: MathService,
  ) {
  }

  fromHouse(house: House): SearchMapItem {
    return {
      title: house?.name,
      picture: this.filterImagePipeService.filter(
        this.elementImagePipeService.getImageLink(
          house?.images,
          false,
        ),
        { w: 150, h: 0}
      ),
      eval: this.mathService.round(
        this.evaluationUtilService.getEvalAvg(house?.eval)
      ),
      evalTotal: house?.eval?.total,
      coordinate: house.location as [number, number],
      // @fixme When date range is selected, this price MUST be according!
      price: house.rangePrice?.lower,
      cupRate: house?.cupRate,
      commission: house?.commission,
      link: ['/', 'houses', house?.id, 'view'],
      ref: house,
      refType: EntityType.HOUSE,
    };
  }

  fromOuting(outing: Outing): SearchMapItem {
    return {
      title: outing?.title,
      picture: this.filterImagePipeService.filter(
        this.elementImagePipeService.getImageLink(
          outing?.images,
          false,
        ),
        { w: 150, h: 0}
      ),
      eval: this.mathService.round(
        this.evaluationUtilService.getEvalAvg(outing?.eval)
      ),
      evalTotal: outing?.eval?.total,
      coordinate: outing.location as [number, number],
      // @fixme When date range is selected, this price MUST be according!
      price: 20,
      cupRate: outing?.cupRate,
      commission: outing?.commission,
      link: ['/', 'outing', outing?.id, 'view'],
      ref: outing,
      refType: EntityType.OUTING,
    };
  }
}
