import { Injectable } from '@angular/core';

@Injectable()
export class CompactNumberService {
  constructor() {}

  getNumber(value: unknown): unknown {
    if (typeof value !== 'number') {
      return value;
    }

    if (value >= 1000000) {
      return (value / 1000000).toFixed(2);
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2);
    } else {
      return value;
    }
  }

  getPostfix(value: unknown): unknown {
    if (typeof value !== 'number') {
      return '';
    }

    if (value >= 1000000) {
      return 'M';
    } else if (value >= 1000) {
      return 'K';
    } else {
      return '';
    }
  }
}
