import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit, Optional, Self,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NgControl,
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { take } from 'rxjs/operators';

export class MatFile {
  constructor(public file?: any, public name?: string) {
  }

  public toString = (): string => {
    return this.name ? this.name : '';
  }
}

// tslint:disable-next-line:max-classes-per-file
@Component({
  selector: 'rhbnb-mat-input-file',
  templateUrl: './mat-input-file.component.html',
  styleUrls: ['./mat-input-file.component.sass'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MatInputFileComponent
    },
  ]
})
export class MatInputFileComponent
  implements MatFormFieldControl<MatFile>, OnDestroy, ControlValueAccessor, OnInit {

  static nextId = 0;

  stateChanges = new Subject<void>();
  @HostBinding() id = `example-tel-input-${MatInputFileComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  _placeholder: string;

  focused = false;
  touched = false;

  get empty() {
    const f = this.form.get('file').value;
    return !f;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  controlType = 'file-type';
  autofilled?: boolean;

  @ViewChild('fileInput') fileInput: ElementRef;
  form: FormGroup;

  @HostBinding('attr.aria-describedby') describedBy = '';

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.form.disable();
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  get errorState(): boolean {
    const touched = !!this.ngControl?.touched;

    if (touched !== this.touched) {
      this.touched=touched;
      this.stateChanges.next();
    }

    return this.form.invalid && this.touched;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };

  @Input() set value(value: MatFile | null) {
    value = value || new MatFile(null, '');

    this.form.setValue({file: value});
    setTimeout(() => this.form.updateValueAndValidity({emitEvent: false}));

    this.stateChanges.next();
  }

  get value(): MatFile {
    return this.form.get('file').value;
  }

  constructor(
    private fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.createForm();
  }

  ngOnInit(): void {
    this.fm.monitor(this.elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.fm
      .monitor(this.elRef.nativeElement)
      .pipe(take(1))
      .subscribe(() => {
        this.onTouched();
      });
  }

  createForm() {
    this.form = this.fb.group({
      file: null
    });
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      const [file] = event.target.files;
      this.form.get('file').setValue(new MatFile(file, file.name));

      this.onChange(this.form.get('file').value);
    }
  }

  openFileDialog() {
    this.fileInput.nativeElement.click();
  }

  ngOnDestroy(): void {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(matFile: MatFile): void {
    if (matFile) {
      this.form.get('file').setValue(matFile);
    }
  }
}
