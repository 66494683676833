import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Currency } from '@rhbnb-nx-ws/domain';
import { JsonLdService } from '@rhbnb-nx-ws/services';
import { EMPTY } from 'rxjs';

import { CurrencyApiService } from '../../shared/services/currency-api.service';
import { StateCurrency } from './reducer';
import { ChatService } from '../../shared/services/chat.service';
import { CoreStoreService } from './core-store.service';

@Injectable()
export class CoreEffects {

  constructor(
    private actions$: Actions,
    private currencyApiService: CurrencyApiService,
    private chatService: ChatService,
    private coreStoreService: CoreStoreService,
    private jsonLdService: JsonLdService,
  ) {
  }

  loadCurrencyTable$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.loadCurrencyTable),
    switchMap(() => this.currencyApiService.getAll()),
    map(res => this.transformResponse(res.data)),
    switchMap(res => [
      fromActions.setCurrencyTable({ value: res })
    ]),
    catchError(() => EMPTY)
  ));

  loginChatUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.loginChatUser),
    tap(() => this.coreStoreService.setChatFail(false)),
    tap(() => this.coreStoreService.setLoginUserLoading(true)),
    switchMap(() => this.chatService.getUserToken()),
    switchMap(res => {
      if (res.success) {
        return [
          fromActions.setChatAuthToken({ token: res?.data?.token }),
          fromActions.setLoginUserLoading({ loading: false }),
        ];
      }
      return [
        fromActions.setChatFail({ fail: true }),
        fromActions.setLoginUserLoading({ loading: false })
      ];
    }),
    catchError(() => EMPTY)
  ));

  loadChatUnreadCount$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.loadChatUnreadCount),
    switchMap(() => this.chatService.getUnreadCount()),
    switchMap(res => [
      fromActions.setChatUnreadCount({count: res?.data})
    ]),
    catchError(() => EMPTY)
  ));

  updateJsonLdScript$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.setJsonLdSchema),
    tap(json => this.jsonLdService.addJsonLdScriptToHead(json.schema)),
    catchError(() => EMPTY)
  ), {dispatch: false});

  transformResponse(currency: Currency[]): StateCurrency {
    let stateCurrency = {} as StateCurrency;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < currency.length; i++) {
      const it = currency[i];
      stateCurrency = { ...stateCurrency, ...{ [it.name]: it } };
    }

    return stateCurrency;
  }
}
