import { BaseModel } from './base.model';
import { Moment } from 'moment';
import { User } from './user.model';

export class DiscountCoupon extends BaseModel {
  public code?: string;
  public start?: Moment;
  public end?: Moment;
  public users?: User[] | string[];
  public data?: DCData;

  constructor(init?: DiscountCoupon) {
    super();
    Object.assign(this, init);
  }

}

export class DCData {
  public clientFee?: number;
  public modality?: DCModality;
  public withMinValue?: boolean;
  public minValue?: number;
}

export enum DCModality {
  PERCENT = 'PERCENT',
  AMOUNT = 'AMOUNT'
}
