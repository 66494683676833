<div class="map-wrapper">
  <div class="loading"
       fxLayout="row wrap"
       fxLayoutAlign="center center"
       *ngIf="isLoading"
       [@in]="true"
       [@out]="true"
  >
    <mat-spinner
      diameter="20">
    </mat-spinner>
  </div>

  <div #map class="map" id="map"></div>

  <div
    class="item-view-wrapper"
    fxLayout="row wrap"
    fxLayoutAlign="center"
  >
    <rhbnb-map-item-view
      *ngIf="selectedItem"
      [item]="selectedItem"
      (closeChange)="onCloseItemView()"
    ></rhbnb-map-item-view>
  </div>
</div>
