<ng-container [formGroup]="form">
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>{{ 'core.discount_coupon' | transloco }}</mat-label>

    <input
      name="code"
      formControlName="code"
      matInput>

    <mat-spinner
      *ngIf="loading"
      matSuffix
      [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>

    <mat-error *ngIf="!loading && codeControl?.hasError('notFound')">
      {{ 'global.coupon_finder.not_found_error' | transloco }}
    </mat-error>

    <mat-error *ngIf="!loading && codeControl?.hasError('used')">
      {{ 'global.coupon_finder.used_error' | transloco }}
    </mat-error>

    <mat-error *ngIf="!loading && codeControl?.hasError('offDate')">
      {{ 'global.coupon_finder.out_off_range_error' | transloco }}
    </mat-error>

    <mat-hint *ngIf="!loading && valid">

      <ng-container *ngIf="discountCoupon?.data?.withMinValue; else noMin">
        {{ 'global.coupon_finder.valid_with_min' | transloco: {min: discountCoupon?.data?.minValue | toDecimal} }}
      </ng-container>

      <ng-template #noMin>
        {{ 'global.coupon_finder.valid' | transloco }}
      </ng-template>

    </mat-hint>

  </mat-form-field>
</ng-container>

