import { Pipe, PipeTransform } from '@angular/core';
import { CommissionService } from '@rhbnb-nx-ws/services';

@Pipe({
  name: 'appCommission'
})
export class AppCommissionPipe implements PipeTransform {

  constructor(
    private commissionService: CommissionService
  ) {
  }

  transform(value: number, ...args: any[]): any {
    const commission = Number(args[0]);

    return  Number(value) + this.commissionService
        .computeCommission(value, commission);
  }

}
