import { animate, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';

export function showing(): AnimationTriggerMetadata {
  return trigger('showing', [
    transition('* <=> *', [
      animate(350, keyframes([
        style({ opacity: 0 }),
        style({ opacity: 1 }),
      ]))
    ])]);
}

export function flowing(): AnimationTriggerMetadata {
  return trigger('flowing', [
    transition(':enter', [
      animate(200, keyframes([
        style({ opacity: 0 }),
        style({ opacity: 1 }),
      ]))
    ]),

    transition(':leave', [
      animate(200, keyframes([
        style({ opacity: 1 }),
        style({ opacity: 0 }),
      ]))
    ])
  ]);
}
