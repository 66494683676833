import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { catchError, take, tap } from 'rxjs/operators';
import { ErrorCodes } from '@rhbnb-nx-ws/domain';
import { NotifyService } from '@rhbnb-nx-ws/services';

import { SecurityService } from '../services/security.service';
import { StorageService } from '../services/storage.service';

const blacklist = [
  '/api/webpush/'
];

export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private notifyService: NotifyService,
    private securityService: SecurityService,
    private router: Router,
    private translocoService: TranslocoService,
    private storageService: StorageService,
    private logger: NGXLogger,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(res => {
          if (this.canHandle(req)) {
            return this.handleError(res);
          }

          return of();
        })
      );
  }

  private handleError(res: HttpErrorResponse): Observable<any> {
    let errorMessage;

    this.logger.debug(`Intercepting error ${JSON.stringify(res, null, 2)}`);

    // Keep previous URL in storage
    // And mark as first Error
    // In error page, OnInit, check if is first error
    // in this case keep this view
    // Otherwise, redirect to previous URL
    this.storageService.setItem('backURL', this.router.url);
    this.storageService.setItem('firstErrorNav', true);

    /*if (!this.isApiError(res?.error?.code)) {
      if (!res || res.status >= 500) {
        this.router.navigate(['/error']);
        return throwError('500 ERROR');
      }
    }*/

    if (res.status == 0) {
      this.translocoService.selectTranslate('core.offline_error')
        .pipe(
          tap(msg => this.notifyService.error(msg)),
          take(1)
        )
        .subscribe();

      return throwError('0 UNKNOWN');
    }

    // Unauthorized, go to login page
    if (res.status === 401) {
      this.securityService.redirectToSSO();
      return throwError('401 ERROR');
    }

    // NotFound!
    if (res.status === 404) {
      this.router.navigate(['/not-found']);
      return throwError('404 ERROR');
    }

    if (res.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `${res.error?.message}`;
    } else {
      if (res.error?.errors) {
        errorMessage = res.error.errors;
      } else if (res.error?.message){
        errorMessage = res.error.errors;
      } else {
        errorMessage = res.statusText;
      }
    }

    errorMessage = typeof errorMessage === 'object' ? JSON.stringify(errorMessage) : errorMessage;

    if (errorMessage) {
      this.notifyService.error(errorMessage);
    }

    return throwError(errorMessage);
  }

  canHandle(req: HttpRequest<any>) {
    const path = (new URL(req.url)).pathname;
    return !blacklist.includes(path);
  }

  isApiError(code: string|number): boolean {
    return Object.values(ErrorCodes).includes(code);
  }
}
