import {
  Directive,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Room, BedType, ReservationRoom } from '@rhbnb-nx-ws/domain';
import { IMAGE_QUALITY, EMPTY_IMAGE_SRC } from '@rhbnb-nx-ws/global-tokens';
import {
  RoomEventsService,
  RoomUtilService,
  RoomApiService,
  HouseUtilService
} from '@rhbnb-nx-ws/services';
import { Moment } from 'moment';

@Directive()
export abstract class HouseRoomsItemBaseComponent implements OnInit {
  @Input() room: Room;
  @Input() commission: number;
  @Input() cupRate: number;
  @Input() interactive = false;
  @Input() selected = false;
  @Input() unavailable = false;
  @Input() showCalendar = false;
  @Input() calendarValue;
  @Input() translateKey: string;

  @Input() currencyTable$;
  @Input() currentCurrency$;

  @Output() selectedChange = new EventEmitter<{ selected: boolean, roomId: string }>();
  @Output() calendarChange = new EventEmitter<any>();

  constructor(
    @Inject(IMAGE_QUALITY) private quality: number,
    @Inject(EMPTY_IMAGE_SRC) public emptyImage: string,
    private roomApiService: RoomApiService,
    private roomUtilService: RoomUtilService,
    private roomEventsService: RoomEventsService,
    public houseUtilService: HouseUtilService
  ) {
  }

  ngOnInit(): void {
  }

  getBedTypeName(type: BedType): string {
    return this.roomUtilService.getBedKindName(type, this.translateKey);
  }

  getBedTypeIcon(type: BedType): string {
    return this.roomUtilService.getBedKindIconName(type);
  }

  onSelectedChange(): void {
    if (this.interactive) {
      this.selected = !this.selected;
      this.selectedChange.emit({ selected: this.selected, roomId: this.room.id });
    }
  }

  getEvents = (ids: string[], from: Moment, to: Moment) =>
    this.roomEventsService.getForRoomsByIds(
      ids,
      from,
      to
    )

  get coverImage() {
    return this.room?.coverImage ? `${this.room?.coverImage?.link}` : this.emptyImage;
  }

  get price(): number {
    const avg = (this.room as ReservationRoom)?.rangeAvg;

    return avg ?
      Math.round(((avg + Number.EPSILON) * 100) / 100) : undefined;
  }

  get privateBathKey() {
    return `${this.translateKey}.with_private_bath`;
  }

  get itemCapacityKey() {
    return `${this.translateKey}.house_room_item_capacity`;
  }
}
