import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '@rhbnb-nx-ws/domain';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService implements OnInit {

  private currentProfileData = new Subject<User>();
  public readonly currentProfileData$ = this.currentProfileData.asObservable();

  constructor(
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    const currentUser = this.storageService.getItem('token') ? this.storageService.getItem('user') as User : {} as any;
    this.currentProfileData = new BehaviorSubject<User>(currentUser);
  }

  changeUserInfo(user: User) {
    // Update user data in storage
    this.storageService.setItem('user', user);
    this.currentProfileData.next(user);
  }
}
