import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, DiscountCoupon } from '@rhbnb-nx-ws/domain';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

import { AbstractDataService } from '../util/abstract-data-service';

export interface TCCStats {
  registers?: number;
  uses?: number;
}

@Injectable({
  providedIn: 'root'
})
export class DiscountCouponApiService extends AbstractDataService<DiscountCoupon> {

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'discount-coupon', apiURL);
  }

  findByCode(code) {
    return this.http
      .get<ApiResponse<any>>(`${this.apiURL}/${this.endpointName}/by-code/${code}`)
      .pipe(catchError(error => observableThrowError(error)));
  }
}
