import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { Image } from '../image';

@Component({
  selector: 'rhbnb-large-gallery',
  templateUrl: './large-gallery.component.html',
  styleUrls: ['./large-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LargeGalleryComponent implements OnInit {

  @Input() images: Image[] = [];
  @Input() seeAllLabel: string;
  @Input() errorImage: string;
  @Output() imageChange = new EventEmitter<{img: Image, index: number}>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(img, index) {
    this.imageChange.emit({img, index});
  }

  trackByFn(index, item: Image) {
    if (!item) {
      return null;
    }

    return item.src;
  }

  getTotal() {
    return this.images.length;
  }

  get isEmpty() {
    return this.getTotal() <= 0;
  }

  get isComplete() {
    return this.images.length >= 5;
  }

  get restImages() {
    return this.images.slice(1, 5);
  }
}
