import { Directive, HostListener, Input, Output, EventEmitter, OnInit, Optional, Inject } from '@angular/core';
import * as moment from 'moment';
import { SnackTime, NotifyService } from '@rhbnb-nx-ws/services';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import { MatDialog } from '@angular/material/dialog';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { DateRangeModel } from './date-range.model';
import { RangeDateModalComponent } from './range-date-modal.component';
import { ModalDataModel } from './modal-data.model';
import { LockableRangeDateModalComponent } from './lockable-range-date-modal.component';
import { Observable, of, Subject } from 'rxjs';
import { Moment } from 'moment';
import { ApiResponse } from '@rhbnb-nx-ws/domain';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[RangeDateModal]',
})
export class RangeDateModalDirective extends WithUnsubscribe()
  implements OnInit {

  @Input() range: DateRangeModel;
  @Input() isSinglePicker: boolean;

  @Input() set disablePast(value: boolean) {
    if (value) {
      this.rangeMinDate = moment();
    } else {
      this.rangeMinDate = undefined;
    }
  }

  @Input() useLockable = false;
  _minRange = 1;
  @Input() set minRange(value) {
    if (value !== null && value !== undefined) {
      this._minRange = value;
    }
  }
  get minRange() {
    return this._minRange;
  }
  @Input() elementIds: Array<any>;
  @Output() dateRangeChange = new EventEmitter<DateRangeModel>();
  rangeMinDate = moment();

  private readonly rangeLimitChange$$ = new Subject<number>();

  @Input()
  getEvents: (ids: string[], from: Moment, to: Moment) => Observable<ApiResponse<Event[]>>;

  @Input()
  getOnlyEndDays: (range: string[]) => any[];

  constructor(
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private notifyService: NotifyService,
    @Optional() @Inject(TRANSLOCO_SCOPE) private scope?
  ) {
    super();
  }

  ngOnInit(): void {
    this.rangeLimitChange$$.asObservable()
      .pipe(
        switchMap(nights => this.translocoService
          .selectTranslate('notify_limit_nights_selection', {nights}, this.scope)),
        tap(msg => this.notifyService.error(msg, SnackTime.NORMAL, true)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe()
    ;
  }

  @HostListener('click') onClick(): void {
    this.openDialog();
  }

  openDialog(): void {
    const cmp = this.useLockable ? LockableRangeDateModalComponent : RangeDateModalComponent;

    this.dialog.open(cmp as any, {
      id: 'date-range',
      data: {
        range: this.range,
        rangeChange: this.dateRangeChange,
        isSingle: this.isSinglePicker,
        rangeLimitChange$$: this.rangeLimitChange$$,
        rangeMinDate: this.rangeMinDate,
        minRange: this.minRange,
        elementIds: this.elementIds,
        getEvents: this.getEvents,
        getOnlyEndDays: this.getOnlyEndDays,
      } as ModalDataModel
    });
  }
}
