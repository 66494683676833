import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ItemWithIcon } from './icon-list-item.component';

@Component({
  selector: 'rhbnb-icon-list-of-items',
  templateUrl: './icon-list-of-items.component.html',
  styleUrls: ['./icon-list-of-items.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconListOfItemsComponent implements OnInit {

  @Input() items: ItemWithIcon[];

  constructor() { }

  ngOnInit(): void {
  }

}
