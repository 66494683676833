import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Currency, StateCurrency } from '@rhbnb-nx-ws/domain';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, combineLatest } from 'rxjs';
import { ChatService } from '@rhbnb-nx-ws/services';

import { ChatService as LibChatService } from '../chat.service';
import * as fromActions from './actions';
import { StoreService } from './store-service';

@Injectable()
export class LibEffects {

  constructor(
    private storeService: StoreService,
    private actions$: Actions,
    private chatService: ChatService,
    private libChatService: LibChatService,
  ) {
  }

  loginChatUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.loginChatUser),
    tap(() => this.storeService.setLoginUserLoading(true)),
    switchMap(() => this.chatService.getUserToken()),
    switchMap(res => {
      if (res.success) {
        return [
          fromActions.setChatAuthToken({ token: res?.data?.token }),
          fromActions.setChatUserId({ userId: res?.data?.userId }),
          fromActions.setLoginUserLoading({ loading: false }),
        ];
      }
      return [
        fromActions.setLoginUserLoading({ loading: false })
      ];
    }),
    catchError(() => EMPTY)
  ));

  loginUserIntoChat$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.loginUserIntoChat),
    tap(() => this.storeService.setChatLoginLoading(true)),
    switchMap(() => combineLatest([
      this.storeService.getChatAuthToken(),
      this.storeService.getChatUserId(),
    ])),
    filter(([token, id]) => (!!token && !!id)),
    tap(([token, id]) => this.libChatService.setTokenAndUserId(token, id)),
    tap(() => this.libChatService.login()),
    switchMap(() => this.libChatService.me()),
    tap(me => this.storeService.setChatUser(me)),
    finalize(() => this.storeService.setChatLoginLoading(false)),
    catchError(() => EMPTY)
  ), {dispatch: false});

  loadChatUnreadCount$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.loadChatUnreadCount),
    switchMap(() => this.chatService.getUnreadCount()),
    switchMap(res => [
      fromActions.incChatUnreadCount({count: res?.data})
    ]),
    catchError(() => EMPTY)
  ));

  transformResponse(currency: Currency[]): StateCurrency {
    let stateCurrency = {} as StateCurrency;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < currency.length; i++) {
      const it = currency[i];
      stateCurrency = {...stateCurrency, ...{[it.name]: it}};
    }

    return stateCurrency;
  }
}
