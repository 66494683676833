import { Event } from './event.model';
import { ICal } from './ical.model';

export class OutingEvent extends Event {
  public data?: OutingEventData;
  public available?: boolean;
  public ical?: ICal;

  constructor(init?: OutingEvent) {
    super();
    init.entity = 'outing';
    Object.assign(this, init);
  }
}

export class OutingEventData {
  public available?: string | boolean;
  public price?: any;
  public singlePrice?: number;
  public totalPrice?: number;
  public notes?: string;
  public reservation?: any;

  constructor(init?: OutingEventData) {
    Object.assign(this, init);
  }
}
