import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { CoreStoreService } from '../../core/store/core-store.service';
import { COUNTRY_SLUG_PARAM } from '@rhbnb-nx-ws/domain';

@Injectable()
export class AddCountryHeaderInterceptor implements HttpInterceptor {
  constructor(private storeService: CoreStoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.headers.get(COUNTRY_SLUG_PARAM)) {
      return next.handle(req);
    }

    return this.storeService.getCountry()
      .pipe(
        take(1),
        map(country => req.clone({
          headers: req.headers.set(COUNTRY_SLUG_PARAM, country),
        })),
        mergeMap(req => next.handle(req)),
      );
  }
}
