import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'rhbnb-method-item',
  template: `
    <mat-card fxFlex="100" matRipple>
      <div class="i-container" fxLayout="row" fxLayoutAlign="center">
        <ng-content></ng-content>
      </div>
    </mat-card>
  `,
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MethodItemComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
