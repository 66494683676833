import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'rhbnb-evaluation-line',
  templateUrl: './evaluation-line.component.html',
  styleUrls: ['./evaluation-line.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EvaluationLineComponent implements OnInit {

  @Input() avg: number;
  @Input() total: number;
  @Input() emptyText: string;

  constructor() { }

  ngOnInit(): void {
  }

}
