import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { EntityType, Image } from '@rhbnb-nx-ws/domain';
import { ApiResponse, PageData, User } from '@rhbnb-nx-ws/domain';
import { removeEmpty } from '@rhbnb-nx-ws/utils';

import { ImagesApiService } from '../services';
import { CacheUtilService } from './cache-util.service';

export const NO_AUTH_HEADER = 'no_auth_header';

export abstract class AbstractDataService<T> extends CacheUtilService {
  constructor(
    protected http: HttpClient,
    protected endpointName: string,
    protected apiURL: string
  ) {
    super();
  }

  /**
   * Makes an Http GET request to fetch the entity(s) of the current user
   */
  getMe(): Observable<ApiResponse<T>> {
    return this.http
      .get<ApiResponse<T>>(`${this.apiURL}/${this.endpointName}/me`)
      .pipe(catchError((error) => observableThrowError(error)));
  }

  getMeList(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string,
    filter?: Record<string, any>,
  ): Observable<ApiResponse<PageData<T>>> {
    let params = {
      _page: page,
      _limit: limit,
      _sort: sort,
      _order: order,
      q,
      ...{ filter },
    } as any;

    params = { ...removeEmpty(params), filter: JSON.stringify(params.filter) }

    return this.http
      .get<ApiResponse<PageData<T>>>(`${this.apiURL}/${this.endpointName}/me`, {
        ...{ params },
        ...{ observe: 'response' },
      })
      .pipe(
        map((res: HttpResponse<ApiResponse<PageData<T>>>) => {
          return {
            data: {
              data: res.body.data,
              total: +res.headers.get('Page-Total'),
            } as any,
            success: res.body.success,
          } as ApiResponse<PageData<T>>;
        }),
        catchError((error) => observableThrowError(error))
      );
  }

  protected buildParams(
    page?: string,
    limit?: string,
    sort?: string,
    order?: string,
    q?: string,
  ) {
    let params = new HttpParams();

    if (page) {
      params = params.set('_page', page);
    }

    if (limit) {
      params = params.set('_limit', limit);
    }

    if (sort) {
      params = params.set('_sort', sort);
    }

    if (order) {
      params = params.set('_order', order);
    }

    if (q) {
      params = params.set('q', q);
    }

    return params;
  }

  /**
   * Makes an Http GET request to fetch all entities from the REST API backend
   */
  getAll(headers = {}): Observable<ApiResponse<T[]>> {
    return this.http
      .get<ApiResponse<T[]>>(`${this.apiURL}/${this.endpointName}`, { headers })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  /**
   * Make an Http GET request to paginate entities from REST API backend
   */
  paginate(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string,
    extraParams?: HttpParams,
    currentUser?: User
  ): Observable<ApiResponse<PageData<T>>> {
    let params: HttpParams = this.buildParams(page, limit, sort, order, q);

    if (extraParams) {
      extraParams
        .keys()
        .forEach((k) => (params = params.append(k, extraParams.get(k))));
    }

    return this.http
      .get<ApiResponse<PageData<T>>>(`${this.apiURL}/${this.endpointName}`, {
        ...{ params },
        ...{ observe: 'response' },
      })
      .pipe(
        map((res: HttpResponse<ApiResponse<PageData<T>>>) => {
          return {
            data: {
              data: res.body.data,
              total: res.headers.get('Page-Total'),
            } as any,
            success: res.body.success,
          } as ApiResponse<PageData<T>>;
        }),
        catchError((error) => observableThrowError(error))
      );
  }

  /**
   * Make an Http GET request to paginate entities from REST API backend
   */
  filterAndPage(
    q?: string,
    page?: string,
    limit?: string
  ): Observable<ApiResponse<T[]>> {
    const params: HttpParams = this.buildParams(
      page,
      limit,
      undefined,
      undefined,
      q
    );

    return this.http
      .get<ApiResponse<T[]>>(`${this.apiURL}/${this.endpointName}`, {
        ...{ params },
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  /**
   * Makes an Http GET request to obtain 1 item
   * @param id Item id
   * @param headers
   */
  getOne(id: string, headers = {}): Observable<ApiResponse<T>> {
    return this.http
      .get<ApiResponse<T>>(`${this.apiURL}/${this.endpointName}/${id}`, {
        headers,
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  /**
   * Makes an Http POST request to persist an entity to the REST API backend
   * @param entity Entity data
   * @param headers Object
   */
  add(entity: T, headers = {}): Observable<ApiResponse<T>> {
    return this.http
      .post<ApiResponse<T>>(`${this.apiURL}/${this.endpointName}`, entity, {
        headers,
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  /**
   * Makes an Http POST request as multipart-formdata to persist an entity to the REST API backend
   * @param entity Entity Data
   * @param file [Optional] File to send in request
   * @param filePropName [Optional] Request property name to send file under
   */
  addAsFormData(entity: T) {
    const formData: FormData = new FormData();
    const props = Object.keys(entity);
    props.forEach((prop) => formData.append(prop, entity[prop]));

    return this.http
      .post<T>(`${this.apiURL}/${this.endpointName}`, formData)
      .pipe(catchError((error) => observableThrowError(error)));
  }

  /**
   * Makes an Http PUT request to update an entity in the REST API backend
   * @param entity Entity data
   */
  update(entity: T): Observable<ApiResponse<T>> {
    return this.http
      .put<ApiResponse<T>>(
        `${this.apiURL}/${this.endpointName}/${(entity as any).id}`,
        entity
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  /**
   * Makes an Http PATCH request to update an entity in the REST API backend
   * @param entity Entity data
   */
  patch(entity: T): Observable<ApiResponse<T>> {
    return this.http
      .patch<ApiResponse<T>>(
        `${this.apiURL}/${this.endpointName}/${(entity as any).id}`,
        entity
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  /**
   * Makes an Http PUT request as multipart-formdata to update an entity on the REST API backend
   * @param entity Entity Data
   * @param file [Optional] File to send in request
   * @param filePropName [Optional] Request property name to send file under
   */
  updateAsFormData(entity: T) {
    const formData: FormData = new FormData();
    const props = Object.keys(entity);
    props.forEach((prop) => formData.append(prop, entity[prop]));

    return this.http
      .put<T>(`${this.apiURL}/${this.endpointName}`, formData)
      .pipe(catchError((error) => observableThrowError(error)));
  }

  /**
   * Makes an Http DELETE request to delete an entity from the REST API backend
   * @param entity Entity to delete
   */
  delete(entity: T): Observable<Response> {
    return this.http
      .delete<any>(`${this.apiURL}/${this.endpointName}/${(entity as any).id}`)
      .pipe(catchError((error) => observableThrowError(error)));
  }

  getImages(
    id: string,
    imageService: ImagesApiService
  ): Observable<ApiResponse<Image[]>> {
    return imageService.getByEntity(EntityType.ROOM, id);
  }
}
