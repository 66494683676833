import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'rhbnb-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarImageComponent implements OnInit, AfterViewInit {

  @Input() imageSrc;
  @Input() name;
  @Input() value = 'RH';
  @Input() size = 100;
  @Input() initials = 2;
  @Input() round = true;
  @Input() isCardAvatar = false;

  loading = true;
  src;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  onImageLoad() {
    this.src = this.imageSrc;
  }

  onLoadError() {
  }
}
