import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

type Constructor<T = {}> = new (...args: any[]) => T;

export function WithUnsubscribe<T extends Constructor<{}>>(Base: T = (class {} as any)) {
  return class extends Base implements OnDestroy {
    protected unsubscribe$ = new Subject<void>();

    ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
  };
}
