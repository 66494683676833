import {
  Component, OnInit, ChangeDetectionStrategy,
  Input, Output, EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';

@Component({
  selector: 'rhbnb-auto-numbered-mat-select',
  templateUrl: './auto-numbered-mat-select.component.html',
  styleUrls: ['./auto-numbered-mat-select.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoNumberedMatSelectComponent extends WithUnsubscribe()
  implements OnInit {

  last = 1;
  initialMax;
  range: Array<number>;

  _value: number;
  @Input()
  set value(v) {
    this._value = v;
    this.initialMax = this.generateInitialMax(v);
  }

  get value() {
    return this._value;
  }

  @Input() form: FormGroup;
  @Input() controlName: string;

  @Input() label: string;
  @Output() valueChange = new EventEmitter<number>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.form?.get(this.controlName)?.value) {
      this.initialMax = this.generateInitialMax(
        this.form?.get(this.controlName)?.value
      );
    }

    if (this.form?.get(this.controlName)) {
      this.form.get(this.controlName)
        .valueChanges
        .pipe(
          takeUntil(this.unsubscribe$),
          tap(v => {
            this.initialMax = this.generateInitialMax(v);
            this.range = this.getNewArrayChunk();
          })
        )
        .subscribe();
    }

    this.range = this.getNewArrayChunk();
  }

  onValueChange(v) {
    this.valueChange.emit(v);
  }

  getNewArrayChunk() {
    let length = 10;

    if (this.initialMax) {
      length = this.initialMax;
      this.initialMax = undefined;
    }

    return Array.from({ length }, (v, k) => k + this.last, this);
  }

  getNextBatch() {
    this.last += 10;
    this.range = [...this.range, ...this.getNewArrayChunk()];
  }

  private generateInitialMax(v) {
    return v + (10 - (v % 10));
  }
}
