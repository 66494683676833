import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Destination } from '@rhbnb-nx-ws/domain';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

import { AbstractEavService } from '../util';

@Injectable({
  providedIn: 'root'
})
export class DestinationApiService extends AbstractEavService<Destination> {

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'destination', apiURL);
  }
}
