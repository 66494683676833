<div
  fxLayout="row wrap" fxLayoutAlign="center"
  fxFlex="100"
  fxLayoutGap.gt-xs="20px grid"
  aria-label="Select an option">

  <rhbnb-method-item
    class="item"
    fxLayout="row wrap" fxLayoutAlign="center"
    fxFlex.xs="100"
    fxFlex.sm="50"
    fxFlex.gt-sm="33.33"
    *ngFor="let method of methods; let i = index">

    <div class="i-container"
         fxFlex="100"
         fxLayout="row wrap" fxLayoutAlign="center"
          (click)="onMethodChoose(method?.id)">

      <img fxFlex="100" src="{{ method.iconSrc }}"
           alt="{{ method.label | transloco }}"/>

      <span fxFlex="100">
        {{ method.label | transloco }}
      </span>

      <span class="my-4" fxFlex="100" *ngIf="selected === method && isLoading">
        <mat-icon>
          <mat-spinner diameter="30"></mat-spinner>
        </mat-icon>
      </span>

    </div>

  </rhbnb-method-item>

</div>
