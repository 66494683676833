import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableDebugTools } from '@angular/platform-browser';

import { AppModule } from './app/core/app.module';
import { environment } from './environments/environment';
import { appInjector } from './app/shared/tokens/app-injector';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => {
  const b = platformBrowserDynamic().bootstrapModule(AppModule);
  b.then((app) => {
    appInjector(app.injector);

    if (!environment.production) {
      enableDebugTools(app.injector.get(ApplicationRef).components[0]);
    }
  });

  return b;
};

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch((err) => console.log(err));
}
