import { NotificationCodes, ROUTE_COUNTRIES } from '@rhbnb-nx-ws/domain';

import { UrlUtilService } from '@rhbnb-nx-ws/services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  constructor(private urlUtilService: UrlUtilService) {}

  paymentCardRedirect(code) {
    return `${this.urlUtilService.getAppsURL()?.admin}?${
      NotificationCodes.QUERY_KEY
    }=${code}`;
  }

  messages() {
    return `${this.urlUtilService.getAppsURL()?.admin}/messages`;
  }

  isLongBannerRoute = (baseURL: string) =>
    [ROUTE_COUNTRIES.cuba, ROUTE_COUNTRIES.dubai]
      .map((r) => `/${r}`)
      .includes(baseURL);

  isNoBannerRoute = (baseURL: string) =>
    [''].map((r) => `/${r}`).includes(baseURL);
}
