<ng-container *ngIf="error; else noError">
  <img
  [src]="errorImage"
  class="lazyload"
  [alt]="alt"
/>
</ng-container>

<ng-template #noError>
  <source
  #img
  *ngFor="let mq of medias; trackBy: trackByItems;"
  [src]="errorImage"
  [attr.data-srcset]="src | filterImage: {w: getWidthByQuery(mq[0]), h: 0}"
  [media]="getQueryByMQ(mq[1])"
/>

<!-- Fallback to first set element -->
<img
  [src]="errorImage"
  [attr.data-srcset]="src | filterImage: {w: getWidthByQuery('SM'), h: 0}"
  [attr.data-sizes]="'auto'"
  class="lazyload"
  [alt]="alt"
  (error)="onError()"
/>
</ng-template>

