import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarGenericLoadingIndicatorComponent } from './bar-generic-loading-indicator/bar-generic-loading-indicator.component';
import { ProfileGenericLoadingIndicatorComponent } from './profile-generic-loading-indicator/profile-generic-loading-indicator.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SimpleCardLoadingIndicatorComponent } from './simple-card-loading-indicator/simple-card-loading-indicator.component';
import { EqualBarGenericLoadingIndicatorComponent } from './equals-bar-generic-loading-indicator/equal-bar-generic-loading-indicator.component';
import { LoadingCalendarComponent } from './loading-calendar';

const COMPONENTS = [
  BarGenericLoadingIndicatorComponent,
  ProfileGenericLoadingIndicatorComponent,
  SimpleCardLoadingIndicatorComponent,
  EqualBarGenericLoadingIndicatorComponent,
  LoadingCalendarComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class GenericLoadersModule { }
