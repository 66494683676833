import { Pipe, PipeTransform } from '@angular/core';
import { CompactNumberService } from './compact-number.service';

@Pipe({
  name: 'compactNumber',
})
export class CompactNumberPipe implements PipeTransform {
  constructor(
    private service: CompactNumberService
  ) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    const param = args[0];

    if (param === 'letter') {
      return this.service.getPostfix(value);
    }

    if (param === 'number') {
      return this.service.getNumber(value);
    }

    return `${this.service.getNumber(value)}${this.service.getPostfix(value)}`;
  }
}
