import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'rhbnb-outing-rules-item-view',
  template: `
      <div fxFlex="100"
           class="rule-item">
            - {{ rule | async}}
      </div>
  `,
  styles: [
  ]
})
export class OutingRulesItemViewComponent implements OnInit {

  @Input() rule: Observable<string>;

  constructor() { }

  ngOnInit(): void {
  }

}
