import { Injectable } from '@angular/core';
import { CurrencyKeyType, StateCurrency } from '@rhbnb-nx-ws/domain';
import { StorageService } from '../services/storage.service';

const BASE_CURRENCY = CurrencyKeyType.EUR;
const STORAGE_KEY = 'current_currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(
    private storageService: StorageService
  ) {}

  private toBase(currency: CurrencyKeyType, amount: number, table: StateCurrency) {
    return amount / (table[currency]?.rate ? table[currency]?.rate : 1);
  }

  private fromBase(currency: CurrencyKeyType, amount: number, table: StateCurrency) {
    return amount * (table[currency]?.rate ? table[currency]?.rate : 1);
  }

  convert(from: CurrencyKeyType, to: CurrencyKeyType,
          amount: number, table: StateCurrency) {

    if (from === to) {
      return amount;
    }

    if (from === BASE_CURRENCY) {
      return this.fromBase(to, amount, table);
    }

    if (to === BASE_CURRENCY) {
      return this.toBase(from, amount, table);
    }

    const baseValue = this.toBase(from, amount, table);
    return this.fromBase(to, baseValue, table);
  }

  storeCurrency(currency: CurrencyKeyType) {
    this.storageService.setItem(STORAGE_KEY, currency);
  }

  loadCurrency() {
    return this.storageService.getItem(STORAGE_KEY);
  }

  mergeConversionTableWithNewCupValue(table, cupRate) {
    let extraTable = {};

    if (cupRate) {
      extraTable = {
        [CurrencyKeyType.CUP]: {
          name: CurrencyKeyType.CUP,
          rate: cupRate
        }
      };
    }

    return { ...table, ...extraTable };
  }
}
