import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { EntityType, House, Outing } from '@rhbnb-nx-ws/domain';

@Component({
  selector: 'rhbnb-map-price-wrapper',
  template: `
    <ng-container [ngSwitch]="type">

      <ng-container *ngSwitchCase="HouseType">
          <rhbnb-house-tiny-price
            [item]="$any(item)"></rhbnb-house-tiny-price>
      </ng-container>

      <ng-container *ngSwitchCase="OutingType">
        <rhbnb-outing-tiny-price
          [item]="$any(item)"></rhbnb-outing-tiny-price>
      </ng-container>

    </ng-container>
  `,
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPriceWrapperComponent implements OnInit {
  _item: (House | Outing);
  @Input() set item(value: (House | Outing)) {
    this._item = value;
    this.cdr.detectChanges();
  }
  get item() {
    return this._item;
  }

  _type: string;
  @Input() set type(value: string) {
    this._type = value;
    this.cdr.detectChanges();
  }
  get type() {
    return this._type;
  }
  HouseType = EntityType.HOUSE;
  OutingType = EntityType.OUTING;

  constructor(
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
  }
}
