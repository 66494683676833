import { Injectable } from '@angular/core';
import { ClientEvaluationStats } from '@rhbnb-nx-ws/domain';

import { MathService } from '../services/math.service';

const TOTAL_ITEMS = 6;

@Injectable({
  providedIn: 'root'
})
export class EvaluationUtilService {

  constructor(private mathService: MathService) {}

  getEvalAvg(stats: ClientEvaluationStats) {
    return (
      this.getNum(stats?.arrivalProcess) +
      this.getNum(stats?.cleaning) +
      this.getNum(stats?.communication) +
      this.getNum(stats?.location) +
      this.getNum(stats?.veracity) +
      this.getNum(stats?.qualityPrice)
    ) / TOTAL_ITEMS;
  }

  getNum(value) {
    if (!value) {
      return 0;
    }

    return this.mathService.round(parseFloat(value), 1);
  }
}
