<div fxLayout="row" fxLayoutAlign="space-around start">
  <div
    [fxFlex]="xs"
    [fxFlex.gt-xs]="gtXs"
    [fxFlex.gt-sm]="gtSm"
    [fxFlex.gt-md]="gtMd"
    [fxFlex.gt-lg]="gtLg">

    <ng-content></ng-content>

  </div>
</div>
