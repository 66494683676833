import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromR from './reducer';

export const selectR = createFeatureSelector<fromR.State>(fromR.moduleKey);

export const selectChatAuthToken = createSelector(
  selectR,
  (state: fromR.State) => state.chatAuthToken
);

export const selectChatUserId = createSelector(
  selectR,
  (state: fromR.State) => state.chatUserId
);

export const selectChatUser = createSelector(
  selectR,
  (state: fromR.State) => state.chatUser
);

export const selectChats = createSelector(
  selectR,
  (state: fromR.State) => state.chats
);

export const selectChatGroupData = (groupId: string) =>
  createSelector(
    selectR,
    (state: fromR.State) => state.chats ? state.chats[groupId] : undefined
  );

export const selectChatGroupMessages = (groupId: string) =>
  createSelector(
    selectR,
    (state: fromR.State) => state.chats ? state.chats[groupId]?.messages: []
  );

export const selectChatGroupHasMore = (groupId: string) =>
  createSelector(
    selectR,
    (state: fromR.State) => state.chats ? state.chats[groupId]?.hasMore : undefined
  );

export const selectChatGroupInitializing = (groupId: string) =>
  createSelector(
    selectR,
    (state: fromR.State) => (
      !state.chats ? true :
        state.chats[groupId]?.initializing)
  );

export const selectChatGroupLoadMore = (groupId: string) =>
  createSelector(
    selectR,
    (state: fromR.State) => state.chats ? state.chats[groupId]?.loadMore : false
  );

export const selectChatGroupFailed = (groupId: string) =>
  createSelector(
    selectR,
    (state: fromR.State) => state.chats ? state.chats[groupId]?.failed : false
  );

export const selectChatTypingUsers = (groupId: string) =>
  createSelector(
    selectR,
    (state: fromR.State) => state.chats ? state.chats[groupId]?.isTyping : []
  );

export const selectChatLoginLoading = createSelector(
  selectR,
  (state: fromR.State) => state.chatLoginLoading
);

export const selectChatLostConnectionTime = createSelector(
  selectR,
  (state: fromR.State) => state.chatLostConnectionTime
);

export const selectChatReconnecting = createSelector(
  selectR,
  (state: fromR.State) => state.chatReconnecting
);

export const selectChatUnreadCount = createSelector(
  selectR,
  (state: fromR.State) => state.chatUnreadCount
);

export const selectLoginUserLoading = createSelector(
  selectR,
  (state: fromR.State) => state.loginUserLoading
);
