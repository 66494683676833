import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedDirectivesModule } from '@rhbnb-nx-ws/shared-directives';
import { SharedPipesModule } from '@rhbnb-nx-ws/shared-pipes';
import { MatDividerModule } from '@angular/material/divider';

import { LockableDaterangePickerModule } from '../lockable-daterange-picker';
import { LazySizesPictureModule } from '../lazy-sizes-picture';
import { HouseRoomsListComponent } from './house-rooms-list/house-rooms-list.component';
import { HouseRoomsItemTinyComponent } from './house-rooms-list/house-rooms-item-tiny.component';
import { HouseRoomsItemComponent } from './house-rooms-list/house-rooms-item.component';

const COMPONENTS = [
  HouseRoomsListComponent,
  HouseRoomsItemTinyComponent,
  HouseRoomsItemComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    TranslocoModule,
    SharedPipesModule,
    SharedDirectivesModule,
    LockableDaterangePickerModule,
    MatDividerModule,
    LazySizesPictureModule,
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class HouseRoomListModule { }
