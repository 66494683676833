import { ApplicationRef, ComponentRef, createComponent, Injectable } from '@angular/core';
import { MapItemComponent } from './map-item.component';
import { SearchMapItem } from './map-item';
import { MapPriceWrapperComponent } from './map-price-wrapper.component';

@Injectable()
export class MapItemBuilderService {
  appRef: ApplicationRef;

  createComponent(data: SearchMapItem): ComponentRef<MapItemComponent> {
    const mapItemComponent = createComponent(MapItemComponent, {
      environmentInjector: this.appRef.injector
    });

    const priceComponent = createComponent(MapPriceWrapperComponent, {
      environmentInjector: this.appRef.injector
    });
    priceComponent.instance.item = data.ref;
    priceComponent.instance.type = data.refType;

    mapItemComponent.instance.item = data;
    mapItemComponent.instance.priceComponentRef = priceComponent;

    this.appRef.attachView(mapItemComponent.hostView);

    return mapItemComponent;
  }
}
