import { InjectionToken } from '@angular/core';

export interface PaymentMethod {
  label: string;
  iconSrc: string;
  id: PaymentMethodId;
}

export type PaymentMethodId = 'CREDIT_CARD' | 'Cuba_APP';

export const methods: PaymentMethod[] = [
  {
    label: 'payment.label_method_credit_card',
    iconSrc: 'assets/icons/payments/credit-card.svg',
    id: 'CREDIT_CARD'
  },
  {
    label: 'payment.label_method_cuba_app',
    iconSrc: 'assets/icons/payments/trans-enzona.svg',
    id: 'Cuba_APP'
  },
];

export let PAYMENT_METHODS = new InjectionToken('payment.methods');
