import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreService } from '../store';
import { ChatHandlerService } from '../chat-handler.service';

@Component({
  selector: 'rhbnb-retry-indicator',
  templateUrl: './retry-indicator.component.html',
  styleUrls: ['./retry-indicator.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetryIndicatorComponent implements OnInit {

  chatReconnecting$: Observable<boolean> = this.chatStoreService.getChatReconnecting();

  constructor(
    private chatStoreService: StoreService,
    public chatHandlerService: ChatHandlerService
  ) { }

  ngOnInit(): void {
  }
}
