import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingGalleryComponent } from './loading-gallery/loading-gallery.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const COMPONENTS = [
  LoadingGalleryComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class LoadingGalleryModule { }
