import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

declare var navigator;

@Injectable()
export class PreloadStrategy implements PreloadingStrategy {
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    // Get NetworkInformation object
    const conn = navigator?.connection;

    if (conn) {
      // Save-Data mode
      if (conn.saveData) {
        return of(null);
      }
      // 'slow-2g', '2g', '3g', or '4g'
      const effectiveType = conn.effectiveType || '';
      // 2G network
      if (effectiveType.includes('2g')) {
        return of(null);
      }
    }

    return fn();
  }
}
