import { Injectable } from '@angular/core';
import { Image, Outing } from '@rhbnb-nx-ws/domain';

@Injectable({
  providedIn: 'root'
})
export class OutingUtilService {
  getDatePrice(outing: Outing) {
    return outing?.events && outing.events.length > 0 ?
      (outing.events[0] && outing.events[0].data && outing.events[0].data.price) || outing?.price :
      outing?.price;
  }

  getDateCUPRate(outing: Outing) {
    return outing?.events && outing.events.length > 0 ?
      (outing.events[0] && outing.events[0].data && outing.events[0].data.cupRate) || outing?.cupRate :
      outing?.cupRate;
  }

  getExtrasAsString(extras) {
    if (extras) {
      return extras
        ?.reduce((acc, value, i, arr) => acc + `${value?.extra?.name}${i === (arr.length - 1) ? '' : ', '}`, '');
    }

    return false;
  }

  getPrincipalImage(outing: Outing): Image {
    if (Array.isArray(outing.images)) {
      // tslint:disable-next-line:triple-equals
      const p = outing.images.find(i => i.number == 0);
      return p ? p : outing.images[0];
    } else if (outing.images) {
      return outing.images;
    }
  }
}
