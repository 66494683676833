import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type SWITCH_STATE = 'map' | 'list';

@Component({
  selector: 'rhbnb-map-switcher',
  template: `
    <button
      mat-raised-button
      (click)="onSwitch()"
    >
        <mat-icon [svgIcon]="icon"></mat-icon>
        <span>{{ text }}</span>
    </button>
  `,
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapSwitcherComponent implements OnInit {

  @Input() state: SWITCH_STATE = 'list';
  @Output() stateChange = new EventEmitter<SWITCH_STATE>();

  constructor() { }

  ngOnInit(): void {
  }

  onSwitch() {
    this.state = this.state === 'list' ? 'map' : 'list';
    this.stateChange.emit(this.state);
  }

  get text() {
    return this.state === 'map'
      ? 'Cambiar a lista'
      : 'Cambiar a mapa';
  }

  get icon() {
    return this.state === 'map'
      ? 'view-list'
      : 'map';
  }
}
