<div *ngIf="show"
     [@fadeInOut]="state"
     class="backdrop">

  <div
    [@state]="state"
    (@state.done)="animationDone($event)"
    class="top-panel">

    <div class="header" fxLayout="row wrap" fxLayoutAlign="end">
      <button
        (click)="show = false"
        mat-icon-button aria-label="Close Button">
        <mat-icon svgIcon="close-circle-outline"></mat-icon>
      </button>
    </div>

    <div ngClass.gt-xs="px-20">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="extra-space" (click)="this.show = false"></div>

</div>
