import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, Client } from '@rhbnb-nx-ws/domain';
import { AbstractDataService, NO_AUTH_HEADER } from '@rhbnb-nx-ws/services';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientApiService extends AbstractDataService<Client> {
  constructor(
    public http: HttpClient
  ) {
    super(http, 'clients', environment.apiURL);
  }

  getAll(): Observable<ApiResponse<Client[]>> {
    return super.getAll({ [NO_AUTH_HEADER]: 'yes' });
  }

  getOne(id: string): Observable<ApiResponse<Client>> {
    return super.getOne(id, { [NO_AUTH_HEADER]: 'yes' });
  }

  add(entity: Client): Observable<ApiResponse<Client>> {
    return super.add(entity, { [NO_AUTH_HEADER]: 'yes' });
  }
}
