import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
} from '@angular/core';

const DISTANCE = 2;
const THROTTLE = 50;

@Component({
  selector: 'rhbnb-infinite-list',
  templateUrl: './infinite-list.component.html',
  styleUrls: ['./infinite-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteListComponent implements OnInit, AfterContentInit {

  @Input() total: number;
  @Input() distance = DISTANCE;
  @Input() throttle = THROTTLE;
  @Input() scrollContainer = '.mat-drawer-content';

  @Output() scrollDownChange = new EventEmitter<void>();
  @Output() scrollTopChange = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onScrollDown() {
    this.scrollDownChange.emit();
  }

  onScrollTop() {
    this.scrollTopChange.emit();
  }

  ngAfterContentInit() {
  }
}
