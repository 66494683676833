<ng-container [ngSwitch]="type">

  <div *ngSwitchCase="'complete'">
    {{ $any($any(item?.price)?.amount |
    appCommission:item?.commission |
    toDecimal |
    currencyConvert: {cupRate: item?.cupRate, currencyTable$: currencyTable$, currentCurrency$: currentCurrency$} | async |
    round |
    number) |
    currencyLabel: {currentCurrency$: currentCurrency$} | async }}
  </div>

  <div *ngSwitchCase="'per_unit'">
    {{ $any($any(item?.price)?.amount |
    appCommission:item?.commission |
    toDecimal |
    currencyConvert: {cupRate: item?.cupRate, currencyTable$: currencyTable$, currentCurrency$: currentCurrency$} | async |
    round |
    number) |
    currencyLabel: {currentCurrency$: currentCurrency$} | async }}
  </div>

  <div *ngSwitchCase="'per_range'">
    {{ $any(getMaxPrice() |
    appCommission:item?.commission |
    toDecimal |
    currencyConvert: {cupRate: item?.cupRate, currencyTable$: currencyTable$, currentCurrency$: currentCurrency$} | async |
    round) + ' - ' + $any(getMinPrice() |
    appCommission:item?.commission |
    toDecimal |
    currencyConvert: {cupRate: item?.cupRate, currencyTable$: currencyTable$, currentCurrency$: currentCurrency$} | async |
    round) | currencyLabel: {currentCurrency$: currentCurrency$} | async   }}
  </div>

</ng-container>
