import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MultipleSelectListComponent } from './multiple-select-list/multiple-select-list.component';
import { SelectItemComponent } from './multiple-select-list/select-item.component';

const COMPONENTS = [
  MultipleSelectListComponent,
  SelectItemComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class MultipleSelectListModule { }
