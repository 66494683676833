<div [formGroup]="parent">
  <mat-form-field class="full-width" appearance="outline">

    <mat-label>{{ label }}</mat-label>

    <mat-select
      (selectionChange)="onCountryChange($event)"
      formControlName="country">

      <mat-option *ngFor="let country of countries" [value]="country.id">
        {{ country.name }}
      </mat-option>
    </mat-select>

  </mat-form-field>
</div>
