<div class="date-wrapper"
     fxFlex
     fxLayout="column"
     fxLayoutAlign="none"
>

  <div fxFlex="1 1 100%"
       *ngFor="let i of size"
       [fxFlexAlign]="alignMessage()"
       [class.first]="false"
  >

    <rhbnb-chat-loading-item>
    </rhbnb-chat-loading-item>

  </div>
</div>
