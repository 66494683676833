import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Message } from '@jjbenitez/glue-rocket-lib';

import * as fromR from './reducer';
import * as Actions from './actions';
import * as Selectors from './selectors';
import { ChatData, Chats } from './reducer';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor(
    private store: Store<fromR.State>
  ) {
  }

  loadUserChatToken(): void {
    this.store.dispatch(Actions.loginChatUser());
  }

  loadChatUnreadCount(): void {
    this.store.dispatch(Actions.loadChatUnreadCount());
  }

  loginUserIntoChat(): void {
    this.store.dispatch(Actions.loginUserIntoChat());
  }

  setChatAuthToken(token: string): void {
    this.store.dispatch(Actions.setChatAuthToken({token}));
  }

  getChatAuthToken(): Observable<string> {
    return this.store.select(Selectors.selectChatAuthToken);
  }

  setChatUserId(userId: string): void {
    this.store.dispatch(Actions.setChatUserId({userId}));
  }

  getChatUserId(): Observable<string> {
    return this.store.select(Selectors.selectChatUserId);
  }

  setChatUser(user: {}): void {
    this.store.dispatch(Actions.setChatUser({user}));
  }

  getChatUser(): Observable<{}> {
    return this.store.select(Selectors.selectChatUser);
  }

  getChats(): Observable<Chats> {
    return this.store.select(Selectors.selectChats);
  }

  getChatGroupData(groupId: string): Observable<ChatData> {
    return this.store.select(Selectors.selectChatGroupData(groupId));
  }

  getChatGroupMessages(groupId: string): Observable<Message[]> {
    return this.store.select(Selectors.selectChatGroupMessages(groupId));
  }

  pushChatGroupMessages(groupId: string, messages: Message[], hasMore?: boolean): void {
    this.store.dispatch(Actions.pushChatGroupMessages({groupId, messages, hasMore}));
  }

  getChatGroupTypingUsers(groupId: string): Observable<string[]> {
    return this.store.select(Selectors.selectChatTypingUsers(groupId));
  }

  addChatGroupTypingUser(groupId: string, user: string): void {
    this.store.dispatch(Actions.addChatUserTyping({groupId, user}));
  }

  deleteChatGroupTypingUser(groupId: string, user: string): void {
    this.store.dispatch(Actions.deleteChatUserTyping({groupId, user}));
  }

  replaceChatGroupMessages(groupId: string, message: Message, id: string): void {
    this.store.dispatch(Actions.replaceChatGroupMessages({groupId, message, id}));
  }

  unshiftChatGroupMessages(groupId: string, messages: Message[], hasMore?: boolean): void {
    this.store.dispatch(Actions.unshiftChatGroupMessages({groupId, messages, hasMore}));
  }

  getChatGroupHasMore(groupId: string): Observable<boolean> {
    return this.store.select(Selectors.selectChatGroupHasMore(groupId));
  }

  setChatGroupHasMore(groupId: string, hasMore: boolean): void {
    this.store.dispatch(Actions.setChatGroupHasMore({groupId, hasMore}));
  }

  getChatGroupInitializing(groupId: string): Observable<boolean> {
    return this.store.select(Selectors.selectChatGroupInitializing(groupId));
  }

  setChatGroupInitializing(groupId: string, initializing: boolean): void {
    this.store.dispatch(Actions.setChatGroupInitializing({groupId, initializing}));
  }

  getChatGroupLoadMore(groupId: string): Observable<boolean> {
    return this.store.select(Selectors.selectChatGroupLoadMore(groupId));
  }

  setChatGroupLoadMore(groupId: string, loadMore: boolean): void {
    this.store.dispatch(Actions.setChatGroupLoadMore({groupId, loadMore}));
  }

  setChatGroupData(groupId: string, data: ChatData): void {
    this.store.dispatch(Actions.setChatGroupData({groupId, data}));
  }

  getChatLostConnectionTime(): Observable<number> {
    return this.store.select(Selectors.selectChatLostConnectionTime);
  }

  setChatLostConnectionTime(time: number): void {
    this.store.dispatch(Actions.setChatLostConnectionTime({time}));
  }

  getChatReconnecting(): Observable<boolean> {
    return this.store.select(Selectors.selectChatReconnecting);
  }

  setChatReconnecting(reconnecting: boolean): void {
    this.store.dispatch(Actions.setChatReconnection({ reconnecting }));
  }

  getChatGroupFailed(groupId: string): Observable<boolean> {
    return this.store.select(Selectors.selectChatGroupFailed(groupId));
  }

  setChatGroupFailed(groupId: string, failed: boolean): void {
    this.store.dispatch(Actions.setChatGroupFailed({groupId, failed}));
  }

  incChatUnreadCount(count: number): void {
    this.store.dispatch(Actions.incChatUnreadCount({count}));
  }

  decChatUnreadCount(count: number): void {
    this.store.dispatch(Actions.decChatUnreadCount({count}));
  }

  setChatUnreadCount(count: number): void {
    this.store.dispatch(Actions.setChatUnreadCount({count}));
  }

  getChatUnreadCount(): Observable<number> {
    return this.store.select(Selectors.selectChatUnreadCount);
  }

  setChatLoginLoading(loading: boolean): void {
    this.store.dispatch(Actions.setChatLoginLoading({loading}));
  }

  getChatLoginLoading(): Observable<boolean> {
    return this.store.select(Selectors.selectChatLoginLoading);
  }

  setLoginUserLoading(loading: boolean): void {
    this.store.dispatch(Actions.setLoginUserLoading({loading}));
  }

  getLoginUserLoading(): Observable<boolean> {
    return this.store.select(Selectors.selectLoginUserLoading);
  }
}
