import {
  BrowserModule,
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  NgModule,
} from '@angular/core';
import { CookieModule } from 'ngx-cookie';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  CompatibilityApiService, PAYMENT_THRESHOLD,
  UrlUtilService
} from '@rhbnb-nx-ws/services';
import {
  API_BASE_URL,
  BASE_ICONS_PATH_TOKEN,
  CURRENCY_TABLE$,
  DEFAULT_CURRENCY$,
  DEFAULT_IMAGE_SRC,
  EMPTY_IMAGE_SRC,
  IMAGE_QUALITY,
  MAPBOX_ACCESS_TOKEN,
  MEDIA_QUERIES,
  MediaQueries,
  OAUTH2_CONFIG,
  URLS,
} from '@rhbnb-nx-ws/global-tokens';
import 'hammerjs';
import { NgxDaterangepickerMd } from '@jjbenitez/ngx-daterangepicker-material';
import { AvatarModule } from '@jjbenitez/ngx-avatar';
import { ChatModule, RC_HTTP_URL, RC_WS_URL } from '@rhbnb-nx-ws/chat';
import * as moment from 'moment';
import {
  APP_API_VERSION, LANGUAGES
} from '@rhbnb-nx-ws/domain';

import { AppComponent } from './app.component';
import { SharedModule } from '../shared/shared.module';
import { environment } from '../../environments/environment';
import * as fromCore from './store/reducer';
import { TranslocoRootModule } from './transloco-root.module';
import { I18nMatPaginatorIntl } from '../shared/classes/i18n-mat-paginator-intl';
import { ErrorHandlerService } from '../shared/services/error-handler.service';
import { CoreEffects } from './store/effects';
import { AppRoutingModule } from './app-routing.module';
import { AppConfig } from '../shared/app.config';
import { LanguageService } from '../shared/services/language.service';
import { SecurityService } from '../shared/services/security.service';
import { CoreStoreService } from './store/core-store.service';
import { HardUpdatePageComponent } from './hard-update-page.component';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

export function buildCurrencyTable(coreStoreService: CoreStoreService) {
  return coreStoreService.getCurrencyTable();
}

export function buildCurrentCurrency(coreStoreService: CoreStoreService) {
  return coreStoreService.getCurrentCurrency();
}

export function createI18nMatPaginatorIntl(transloco: TranslocoService) {
  return new I18nMatPaginatorIntl(transloco);
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  } as any;
}

export function prefetchLang(
  languageService: LanguageService,
  securityService: SecurityService,
  translocoService: TranslocoService,
  coreStoreService: CoreStoreService
) {
  return function () {
    const user = securityService.getProfile();
    const storeLang = languageService.loadLang();

    const lang = storeLang || user?.locale || navigator.language.split('-')[0];

    translocoService.setActiveLang(lang);
    coreStoreService.setCurrentLang(lang as LANGUAGES);

    moment.locale(lang);
    return translocoService.load(lang).toPromise();
  };
}

export function checkCompatibilityVersion(
  compatibilityService: CompatibilityApiService,
  router: Router
) {
  return function () {
    return compatibilityService.getVersion().pipe(
      map((res) => res.data === APP_API_VERSION),
      switchMap((isCompatible) => {
        if (!isCompatible) {
          return router.navigate(['/', '__hard_update__']);
        }

        return Promise.resolve();
      }),
      catchError(() => Promise.resolve())
    );
  };
}

export function getURLSFactory(urlUtilService: UrlUtilService) {
  const { admin, platform } = urlUtilService.getAppsURL();

  return {
    apiURL: environment.apiURL,
    ssoLoginUrl: environment.ssoLoginUrl,
    ssoLogoutUrl: environment.ssoLogoutUrl,
    adminURL: admin,
    platformURL: platform,
    chatUrl: environment.chatUrl,
    imageFilterServiceUrl: environment.imageFilterServiceUrl,
  };
}

@NgModule({
  declarations: [AppComponent, HardUpdatePageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LoggerModule.forRoot({
      level: environment.production
        ? NgxLoggerLevel.ERROR
        : NgxLoggerLevel.DEBUG,
    }),
    StoreModule.forRoot({ [fromCore.coreModuleKey]: fromCore.reducer }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: false,
          features: {
            pause: false,
            lock: true,
            persist: true,
          },
        })
      : [],
    EffectsModule.forRoot([CoreEffects]),
    ChatModule,
    SharedModule.forRoot(),
    CookieModule.forRoot(),
    TranslocoRootModule,
    TranslocoMessageFormatModule.forRoot({
      locales: 'en-US, es-ES',
    }),
    HammerModule,
    AvatarModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    ServiceWorkerModule.register('custom-sw.js', {
      enabled: environment.production,
    }),
  ],
  exports: [TranslocoModule],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiURL,
    },
    /* FIXME: Maybe using a useExisting and register the AppConfig before here? Instead of SharedModule */
    {
      provide: PAYMENT_THRESHOLD,
      useValue: AppConfig.commissionThreshold,
    },
    {
      provide: EMPTY_IMAGE_SRC,
      useValue: AppConfig.emptyHouseImageSrc,
    },
    {
      provide: DEFAULT_IMAGE_SRC,
      useValue: AppConfig.emptyHouseImageSrc,
    },
    {
      provide: IMAGE_QUALITY,
      useValue: 40,
    },
    {
      provide: MEDIA_QUERIES,
      useValue: MediaQueries,
    },
    {
      provide: MAPBOX_ACCESS_TOKEN,
      useValue: AppConfig.mapboxAccessToken,
    },
    {
      provide: OAUTH2_CONFIG,
      useValue: AppConfig.oauth2Config,
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslocoService],
      useFactory: createI18nMatPaginatorIntl,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: RC_WS_URL,
      useValue: environment.wsChatUrl,
    },
    {
      provide: RC_HTTP_URL,
      useValue: `${environment.chatUrl}/api/v1`,
    },
    {
      provide: BASE_ICONS_PATH_TOKEN,
      useValue: `assets/service-icons`,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [
        LanguageService,
        SecurityService,
        TranslocoService,
        CoreStoreService,
      ],
      useFactory: prefetchLang,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [CompatibilityApiService, Router],
      useFactory: checkCompatibilityVersion,
    },
    {
      provide: URLS,
      deps: [UrlUtilService],
      useFactory: getURLSFactory,
    },
    {
      provide: CURRENCY_TABLE$,
      deps: [CoreStoreService],
      useFactory: buildCurrencyTable,
    },
    {
      provide: DEFAULT_CURRENCY$,
      deps: [CoreStoreService],
      useFactory: buildCurrentCurrency,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
