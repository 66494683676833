<div mat-dialog-title class="d-flex flex-column align-items-center">
  <h1 class="mb-4">{{ 'core.hi-traveler' | transloco }}</h1>
  <span class="fw-normal">{{ 'core.login-after-continue' | transloco }}</span>
</div>

<mat-dialog-content>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <button (click)="onLogin()" class="w-100" color="primary" mat-raised-button>{{ 'core.login' | transloco }}</button>

    <div class="divider-section d-flex align-items-center justify-content-between w-100">
      <hr class="me-2" />
      <div class="fs-3">{{ 'core.or' | transloco }}</div>
      <hr class="ms-2" />
    </div>

    <h3 class="fw-normal">{{ 'core.register-after-continue' | transloco }}</h3>
    <button [mat-dialog-close]="true" [routerLink]="['registration']" class="w-100 mb-4" color="accent"
      mat-raised-button>{{ 'core.register' | transloco }}</button>
  </div>
</mat-dialog-content>
