import { Directive } from '@angular/core';
import { FlexDirective } from '@angular/flex-layout';

const selector = `[fxFlex.xxs]`;

const inputs = ['fxFlex.xxs'];

@Directive({ selector, inputs })
export class FlexLayoutFlexXxsDirective extends FlexDirective {
  protected inputs = inputs;
}
