import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'rhbnb-tab-header-item',
  template: `
      <div fxLayout="column"
           fxLayoutAlign="space-evenly center">

          <mat-icon class="big-icon" svgIcon="{{ icon }}"></mat-icon>
          <span class="label-text">{{ label }}</span>
      </div>
  `,
  styles: [
    `
      .big-icon {
          width: 2rem;
          height: 2rem;
          font-size: 2rem;
      }
      
      span {
          margin: 5px 0;
          font-weight: 400;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabHeaderItemComponent implements OnInit {

  @Input() icon: string;
  @Input() label: string;

  constructor() { }

  ngOnInit(): void {
  }

}
