import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangeDateModalComponent } from './range-date-modal/range-date-modal.component';
import { RangeDateModalDirective } from './range-date-modal/range-date-modal.directive';
import { NgxDaterangepickerMd } from '@jjbenitez/ngx-daterangepicker-material';
import { MatDialogModule } from '@angular/material/dialog';
import { LockableRangeDateModalComponent } from './range-date-modal/lockable-range-date-modal.component';
import { LockableDaterangePickerModule } from '../lockable-daterange-picker/lockable-daterange-picker.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';

const COMPONENTS = [
  RangeDateModalComponent,
  RangeDateModalDirective,
  LockableRangeDateModalComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    NgxDaterangepickerMd,
    MatDialogModule,
    LockableDaterangePickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class DaterangeModalModule { }
