import { Pipe, PipeTransform } from '@angular/core';
import { ElementImagePipeService } from './element-image.pipe.service';

@Pipe({
  name: 'elementImage'
})
export class ElementImagePipe implements PipeTransform {

  constructor(
    private elementImagePipeService: ElementImagePipeService,
  ) {
  }

  transform(value: any, ...args: any[]): any {
    return this.elementImagePipeService.getImageLink(value, !args[0])
  }
}
