import { Pipe, PipeTransform } from '@angular/core';
import { Reservation } from '@rhbnb-nx-ws/domain';
import { MomentService } from '@rhbnb-nx-ws/services';

@Pipe({
  name: 'bookingCountNights'
})
export class BookingCountNightsPipe implements PipeTransform {

  constructor(
    private momentService: MomentService
  ) {
  }

  transform(value: Reservation, ...args: any[]): any {
    return this.momentService.countNights(value.checkIn, value.checkOut);
  }

}
