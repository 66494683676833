import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ReservationViewLoadingComponent } from './reservation-view-loading/reservation-view-loading.component';
import { ContainerModule } from '../container';
import { RoomCardLoadingModule } from '../room-card-loading';

const COMPONENTS = [
  ReservationViewLoadingComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    ContainerModule,
    FlexLayoutModule,
    RoomCardLoadingModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class ReservationViewLoadingModule { }
