<div [formGroup]="parent">
  <mat-form-field class="full-width" appearance="outline">

    <mat-label>{{ label }}</mat-label>

    <mat-label *ngIf="code" matPrefix>+{{code}}&#160;</mat-label>

    <input matInput
           [errorStateMatcher]="matcher"
           formControlName="mobile"
           (keyup)="numberChange.next()"
           required>


    <mat-error *ngIf="getField()?.hasError('required')">
      {{ 'user.error_validation_required_mobile' | transloco }}
    </mat-error>

    <mat-error *ngIf="getField()?.hasError('invalid')">
      {{ 'user.error_validation_invalid_mobile' | transloco }}
    </mat-error>

  </mat-form-field>
</div>
