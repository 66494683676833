import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoomCardLoadingComponent } from './room-card-loading/room-card-loading.component';
import { RoomCardItemComponent } from './room-card-loading/room-card-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const COMPONENTS = [
  RoomCardLoadingComponent,
  RoomCardItemComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class RoomCardLoadingModule { }
