import { CurrencyKeyType } from '../models';

export const APP_API_VERSION = 3;

export enum ROLES {
  ROLE_USER = 'ROLE_USER',
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_HOST = 'ROLE_HOST',
  ROLE_HOST_MANAGER = 'ROLE_HOST_MANAGER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export enum LANGUAGES {
  EN = 'en',
  ES = 'es',
}

export interface TargetCard {
  card: string;
  currency: CurrencyKeyType;
}

export enum ROUTE_COUNTRIES {
  cuba = 'cuba',
  dubai = 'dubai'
}

export enum COUNTRIES_SLUGS {
  cuba = 'cuba',
  dubai = 'dubai'
}

export const COUNTRY_SLUG_PARAM = 'country-slug';

export const phoneNumbers = {
  cuba: '+5359396830',
  dubai: '+971526608543',
}
