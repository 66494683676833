import { Injectable } from '@angular/core';
import { Advert, House, Outing, Reservation, ReservationStatus } from '@rhbnb-nx-ws/domain';

@Injectable({
  providedIn: 'root'
})
export class ReservationUtilService {

  getReservationEntities(reservation: Reservation): House[] | Outing[] {
    return (<Advert>reservation.advert)?.entityElements;
  }

  getStatusLangKey(status: ReservationStatus) {
    switch (status) {
      case ReservationStatus.STATUS_CANCELED:
        return 'core.status_canceled_f';

      case ReservationStatus.STATUS_CONFIRMED:
        return 'core.status_confirmed_f';

      case ReservationStatus.STATUS_PENDING:
        return 'core.status_pending_f';
    }
  }

  getStatusClass(status: ReservationStatus) {
    switch (status) {
      case ReservationStatus.STATUS_CANCELED:
        return 'canceled';

      case ReservationStatus.STATUS_CONFIRMED:
        return 'confirmed';

      case ReservationStatus.STATUS_PENDING:
        return 'pending';
    }
  }
}
