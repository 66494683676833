import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';

export class I18nMatPaginatorIntl extends MatPaginatorIntl {

  constructor(
    private translate: TranslocoService
  ) {
    super();
    this.getTranslations();
  }

  getTranslations(): void {

    this.translate.selectTranslateObject('table')
      .pipe(
        take(1)
      )
      .subscribe((trans: any) => {
        this.itemsPerPageLabel = trans.ITEMS_PER_PAGE_LABEL;
        this.nextPageLabel = trans.NEXT_PAGE_LABEL;
        this.previousPageLabel = trans.PREVIOUS_PAGE_LABEL;
        this.firstPageLabel = trans.FIRST_PAGE_LABEL;
        this.lastPageLabel = trans.LAST_PAGE_LABEL;
      });

    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {

    if (length === 0 || pageSize === 0) {
      return this.translate.translate('table.RANGE_PAGE_LABEL_1', {length});
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translate.translate('table.RANGE_PAGE_LABEL_2', {startIndex: startIndex + 1, endIndex, length});
  };
}
