<div class="wrapper" fxLayout="row wrap" fxLayoutAlign="start stretch" (swipe)="onSwipe($event)">

  <div fxFlex="1 0 auto" [@next]="status" (@next.done)="onAnimationEnd($event)">
    <ng-container *ngFor="let image of images; trackBy: trackByItems; let index=index;">
      <ng-container *ngIf="canShow(index)">
        <picture fxFlex="1 0 auto"
          rhbnb-lazy-sizes-picture
          [ngStyle]="getStyle(image.src)"
          [errorImage]="errorImage"
          [src]="image?.src"
          [alt]="image?.label"
          (click)="onClick(images[0], 0)">
        </picture>
      </ng-container>
    </ng-container>
  </div>

  <div class="indicator">
    <span>{{ currentIndex + 1 }}/{{ total }}</span>
  </div>

</div>
