import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockableDaterangeSelectorComponent } from './lockable-daterange-selector';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from '@jjbenitez/ngx-daterangepicker-material';
import { MatInputModule } from '@angular/material/input';

const COMPONENTS = [
  LockableDaterangeSelectorComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd,
    MatInputModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class LockableDaterangePickerModule { }
