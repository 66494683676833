import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Image } from '@rhbnb-nx-ws/ui-share-components';
import { slideOutLeft, slideOutRight } from 'ng-animate';
import { transition, trigger, useAnimation } from '@angular/animations';

type STATUS = 'none' | 'next' | 'prev';

@Component({
  selector: 'rhbnb-tiny-gallery',
  templateUrl: './tiny-gallery.component.html',
  styleUrls: ['./tiny-gallery.component.scss'],
  animations: [
    trigger('next', [
      transition('none => next', useAnimation(slideOutLeft, {
        params: {
          timing: 0.2
        }
      })),
      transition('none => prev', useAnimation(slideOutRight, {
        params: {
          timing: 0.2
        }
      }))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TinyGalleryComponent implements OnInit {

  @Input() images: Image[] = [];
  @Input() errorImage: string;
  @Input() currentIndex = 0;

  higherVisited: number = this.currentIndex;

  status: STATUS = 'none';

  @Output() imageChange = new EventEmitter<{ img: Image, index: number }>();

  constructor() {
  }

  ngOnInit(): void {
  }

  get total() {
    return this.images.length;
  }

  onClick(img, index) {
    this.imageChange.emit({ img, index });
  }

  get currentImage() {
    return this.images[this.currentIndex];
  }

  onSwipe(e) {
    if (this.isToRight(e)) {
      this.prev();
    } else if (this.isToLeft(e)) {
      this.next();
    }
  }

  private isToRight(e) {
    return Math.abs(e.deltaX) > 40 && e.deltaX > 0;
  }

  private isToLeft(e) {
    return Math.abs(e.deltaX) > 40 && e.deltaX < 0;
  }

  getStyle(src: string) {
    return src !== this.currentImage.src
      ? {visibility: 'hidden', width: 0, height: 0}
      : {visibility: 'visible', width: '100vw', height: '100%'};
  }

  onAnimationEnd(e) {
    if (e.toState !== 'none') {

      if (this.status === 'next') {
        this.status = 'none';
        this.nextImage();
      } else {
        this.status = 'none';
        this.prevImage();
      }

    }
  }

  next() {
    if (this.canNext()) {
      this.status = 'next';
    }
  }

  prev() {
    if (this.canPrev()) {
      this.status = 'prev';
    }
  }

  private nextImage() {
    if (this.canNext()) {
      this.currentIndex++;

      this.higherVisited = this.higherVisited < this.currentIndex
        ? this.currentIndex
        : this.higherVisited;
    }
  }

  canShow(index: number) {
    return (this.higherVisited + 3) >= index;
  }

  private prevImage() {
    if (this.canPrev()) {
      this.currentIndex--;
    }
  }

  private canNext() {
    return this.currentIndex + 1 < this.images.length;
  }

  private canPrev() {
    return this.currentIndex - 1 > -1;
  }

  trackByItems(index: number, item: any) {
    return item?.src;
  }
}
