import { NotificationCodes } from '@rhbnb-nx-ws/domain';
import { Inject, Injectable } from '@angular/core';
import { IURLS, URLS } from '@rhbnb-nx-ws/global-tokens';

@Injectable({
  providedIn: 'root'
})
export class Routes {
  constructor(
    @Inject(URLS) private urls: IURLS
  ) {
  }

  reservationPreview(id) {
    return `${this.urls.platformURL}/reservation/view/${id}`;
  }

  houseView(id) {
    return `${this.urls.platformURL}/houses/${id}/view`;
  }

  paymentCardRedirect(code) {
    return `${this.urls.adminURL}?${NotificationCodes.QUERY_KEY}=${code}`;
  }
}

