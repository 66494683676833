import { Directive } from '@angular/core';
import { FlexDirective, LayoutDirective } from '@angular/flex-layout';

const selector = `[fxLayout.xxs]`;
const inputs = ['fxLayout.xxs'];

@Directive({ selector, inputs })
export class FlexLayoutLayoutXxsDirective extends LayoutDirective {
  protected inputs = inputs;
}
