import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconListItemComponent } from './icon-list-of-items/icon-list-item.component';
import { IconListOfItemsComponent } from './icon-list-of-items/icon-list-of-items.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const COMPONENTS = [
  IconListItemComponent,
  IconListOfItemsComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class IconListOfItemsModule { }
