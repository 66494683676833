import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TopFilterService {
  compareDestination(item, selected) {
    if (item?.province === selected || item?.province === selected?.province) {
      return true;
    }

    if (item?.id === selected) {
      return true;
    }

    return false;
  }
}
