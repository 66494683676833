import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PriceVariantChooserComponent } from './price-variant-chooser/price-variant-chooser.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

const COMPONENTS = [
  PriceVariantChooserComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    MatRadioModule,
    FormsModule,
    FlexLayoutModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class PriceVariantChooserModule { }
