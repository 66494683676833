import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_BASE_URL } from "@rhbnb-nx-ws/global-tokens";
import { NO_AUTH_HEADER } from "../util";
import { ApiResponse, User } from "@rhbnb-nx-ws/domain";
import { throwError as observableThrowError } from "rxjs";
import { catchError } from "rxjs/operators";

export type RegisterDto = {
  name: string;
  email: string;
  password: string;
  country: string;
  mobile: string;
  code?: string;
};

@Injectable({
  providedIn: 'root'
})
export class RegisterApiService {
  constructor(
    private http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string,
  ) {}

  register(dto: RegisterDto) {
    let headers = { [NO_AUTH_HEADER]: 'yes' } as any;

    return this.http.post<ApiResponse<any>>(
      `${this?.apiURL}/api-register`,
      dto,
      { headers }
    )
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }

  complete(hash: string) {
    let headers = { [NO_AUTH_HEADER]: 'yes' } as any;

    return this.http.get<ApiResponse<User>>(
      `${this?.apiURL}/api-register/${hash}`,
      { headers }
    )
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }
}
