import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { TranslocoService } from '@ngneat/transloco';
import { CookieService } from 'ngx-cookie';
import { API_BASE_URL, IDENTITY_KEY, IURLS, OAUTH2_CONFIG, OAuth2Config, URLS } from '@rhbnb-nx-ws/global-tokens';

import { NotifyService } from './notify.service';
import { StorageService } from './storage.service';
import { AbstractLoginService } from './abstract-login-service';
import { UserService } from './user.service';
import { ProfileService } from './profile.service';
import { PreviousRouteService } from './previous-route-service';
import { ClientService } from './client.service';
import { NavigationUtilService } from './navigation-util.service';
import { OAuth2Service } from './oauth2.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultLoginService extends AbstractLoginService<any> {

  constructor(
    public http: HttpClient,
    public userService: UserService,
    public storageService: StorageService,
    protected clientService: ClientService,
    protected notifyService: NotifyService,
    public translate: TranslocoService,
    public prs: PreviousRouteService,
    public router: Router,
    public location: Location,
    public profileService: ProfileService,
    @Inject(DOCUMENT) protected document: Document,
    public translocoService: TranslocoService,
    public navigationUtilService: NavigationUtilService,
    public cookieService: CookieService,
    public logger: NGXLogger,
    @Inject(IDENTITY_KEY) public identityKey: string,
    @Inject(API_BASE_URL) public apiURL: string,
    @Inject(URLS) public urls: IURLS,
    public oauth2service: OAuth2Service,
    @Inject(OAUTH2_CONFIG) public oauth2config: OAuth2Config,
  ) {
    super(
      http,
      userService,
      storageService,
      clientService,
      notifyService,
      translate,
      prs,
      router,
      location,
      profileService,
      'login_check',
      document,
      translocoService,
      navigationUtilService,
      cookieService,
      logger,
      identityKey,
      apiURL,
      urls,
      oauth2service,
      oauth2config,
    );
  }
}
