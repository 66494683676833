import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalDataModel } from './modal-data.model';
import { DateRangeModel, SingleDateModel } from './date-range.model';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'rhbnb-range-date-modal',
  templateUrl: './range-date-modal.component.html',
  styleUrls: ['./range-date-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeDateModalComponent implements OnInit {

  rangeSelected: DateRangeModel;
  singlePicker = false;
  minDate: Moment;
  minRange = 0;

  rangeDateLocale = {
    format: 'DD/MM/YYYY',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalDataModel,
    public dialogRef: MatDialogRef<RangeDateModalComponent>
  ) {
  }

  ngOnInit(): void {
    this.rangeSelected = this.data?.range;
    this.minDate = this.data?.rangeMinDate;
    this.minRange = this.data?.minRange;
    this.singlePicker = this.data?.isSingle;
  }

  onDateChange(event): void {
    this.data?.rangeChange.emit(event);
    this.dialogRef.close();
  }

  onSingleDateChange(event) {
    if (this.singlePicker) {
      this.data?.rangeChange.emit(event);
      this.dialogRef.close();
    }
  }

  onMinLimitChange(event): void {
    if (!this.singlePicker) {
      this.data?.rangeLimitChange$$.next(event);
      this.dialogRef.close();
    }
  }

  get isValidDate(): boolean {
    return this.isValidRange();
  }

  isValidRange() {
    return !!this.rangeSelected &&
      !!this.rangeSelected.startDate &&
      !!this.rangeSelected.endDate;
  }
}
