import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addNegativeSign'
})
export class AddNegativeSignPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    return value === 0 ? `${value}` : `-${value}`;
  }

}
