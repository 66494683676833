<a [routerLink]="item.route">
  <div
    class="item d-flex flex-column justify-content-between aling-items-stretch"
  >
    <div
      class="image-container d-flex flex-column justify-content-between aling-items-stretch"
    >
      <img
        rhbnbLazySize
        [ngStyle]="
          item?.description
            ? {}
            : {
                'border-bottom-right-radius': '.5rem',
                'border-bottom-left-radius': '.5rem'
              }
        "
        [alt]="item?.name"
        [errorImage]="defaultImage"
        [imgSrc]="
          item?.imgSrc | elementImage : true | filterImage : { w: 720, h: 0 }
        "
      />

      <div
        class="caption mat-title"
        [ngStyle]="
          item?.description
            ? {}
            : {
                'border-bottom-right-radius': '.5rem',
                'border-bottom-left-radius': '.5rem'
              }
        "
      >
        {{ item?.name }}
      </div>
    </div>

    <div class="content-container" *ngIf="item?.description">
      <p class="mat-body-2">{{ item?.description }}</p>
    </div>
  </div>
</a>
