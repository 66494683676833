import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaderResponse,
  HttpHeaders,
  HttpProgressEvent,
  HttpResponse,
  HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { EntityType, Image } from '@rhbnb-nx-ws/domain';
import { ApiResponse } from '@rhbnb-nx-ws/domain';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

export class Associate {
  public entity: EntityType;
  public element: string;
  public images: Image[];

  constructor(init?: Associate) {
    Object.assign(this, init);
  }
}

export class CreatedImage {
  public path: string;
  public image: Image;

  constructor(init?: CreatedImage) {
    Object.assign(this, init);
  }
}

export class ImagesResponse {
  public path: string;
  public images: Image[];

  constructor(init?: ImagesResponse) {
    Object.assign(this, init);
  }
}

export class ImageResponse {
  public path: string;
  public image: Image[];

  constructor(init?: ImageResponse) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ImagesApiService {

  endpointName = 'images';

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
  }

  add(file: File): Observable<ApiResponse<CreatedImage>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<ApiResponse<CreatedImage>>(`${this?.apiURL}/${this.endpointName}`,
        formData, {reportProgress: true})
      .pipe(catchError(error => observableThrowError(error)));
  }

  addWithEvents(file: File): Observable<ApiResponse<CreatedImage> | HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({ 'ngsw-bypass': ''});

    return this.http
      .post(`${this?.apiURL}/${this.endpointName}`,
        formData, {
          reportProgress: true,
          observe: 'events',
          headers: headers
        })
      .pipe(catchError(error => observableThrowError(error)));
  }

  associate(associate: Associate): Observable<ApiResponse<Image[]>> {
    return this.http
      .post<ApiResponse<Image[]>>(`${this?.apiURL}/${this.endpointName}/associate`, associate)
      .pipe(catchError(error => observableThrowError(error)));
  }

  getByEntity(entity: EntityType, id: string): Observable<ApiResponse<Image[]>> {
    return this.http
      .get<ApiResponse<Image[]>>(`${this?.apiURL}/${this.endpointName}/${entity}/${id}`)
      .pipe(catchError(error => observableThrowError(error)));
  }

  update(image: Image): Observable<ApiResponse<ImageResponse>> {
    return this.http
      .put<ApiResponse<ImageResponse>>(
        `${this?.apiURL}/${this.endpointName}/${image?.id}`,
        image
      )
      .pipe(catchError(error => observableThrowError(error)));
  }

  delete(images: string[]): Observable<ApiResponse<void>> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        images
      },
    };

    return this.http
      .delete<any>(
        `${this?.apiURL}/${this.endpointName}`, options
      )
      .pipe(catchError(error => observableThrowError(error)));
  }
}
