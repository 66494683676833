import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MethodSelectorComponent } from './method-selector';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRadioModule } from '@angular/material/radio';
import { TranslocoModule } from '@ngneat/transloco';
import { MethodItemComponent } from './method-selector/method-item.component';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const COMPONENTS = [
  MethodSelectorComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    MethodItemComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslocoModule,
    MatCardModule,
    MatRippleModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class PaymentsModule { }
