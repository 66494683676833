<rhbnb-large-gallery
  fxHide
  fxShow.gt-xs
  [seeAllLabel]="seeAllLabel"
  [images]="images"
  [errorImage]="errorImage"

  (imageChange)="openFullscreen($event)"></rhbnb-large-gallery>

<div fxHide.gt-xs class="tiny-gallery">
  <rhbnb-tiny-gallery
    [images]="images"
    [errorImage]="errorImage"

    (imageChange)="openFullscreen($event)"></rhbnb-tiny-gallery>
</div>

<ng-image-fullscreen-view
  [images]="fullscreenImages"
  [imageIndex]="selectedImageIndex"
  [show]="showPreview"
  [animationSpeed]="0.3"
  [closeText]="closeText"
  (close)="closeEventHandler()"></ng-image-fullscreen-view>
