import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'rhbnb-destination-slide-loading',
  templateUrl: './destination-slide-loading.component.html',
  styleUrls: ['./destination-slide-loading.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationSlideLoadingComponent implements OnInit {

  count = Array.from({ length: 4 }, (v, k) => k + 1);

  constructor() { }

  ngOnInit(): void {
  }

}
