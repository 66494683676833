import { Component, OnInit, ChangeDetectionStrategy, Inject, Output, EventEmitter, Input } from '@angular/core';

import { PAYMENT_METHODS, PaymentMethod } from '../payment-methods';

@Component({
  selector: 'rhbnb-method-selector',
  templateUrl: './method-selector.component.html',
  styleUrls: ['./method-selector.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MethodSelectorComponent implements OnInit {

  @Input() isLoading = false;
  @Input() selected: PaymentMethod;

  @Output() methodChange = new EventEmitter<PaymentMethod>();

  constructor(
    @Inject(PAYMENT_METHODS) public methods: PaymentMethod[]
  ) {
  }

  ngOnInit(): void {
  }

  onMethodChoose(id) {
    if (!this.isLoading) {
      const method = this.methods.find(m => m.id === id);
      this.methodChange.emit(method);
    }
  }

}
