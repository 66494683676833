import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'rhbnb-simple-card-loading-indicator',
  templateUrl: './simple-card-loading-indicator.component.html',
  styleUrls: ['./simple-card-loading-indicator.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleCardLoadingIndicatorComponent implements OnInit {

  @Input() numberOfCards = 6;
  list = Array.from({ length: this.numberOfCards});

  constructor() { }

  ngOnInit(): void {
  }

}
