import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '../services/security.service';
import { LoginOrRegisterDialogService } from '@rhbnb-nx-ws/ui-share-components';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private securityService: SecurityService,
    private loginOrRegisterDialogService: LoginOrRegisterDialogService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.securityService.isLoggedOut()) {
      this.loginOrRegisterDialogService.open(
        this.securityService.redirectToSSO.bind(this.securityService)
      );
      return false;
    }

    return true;
  }
}
