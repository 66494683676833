import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { InfiniteListComponent } from './infinite-list/infinite-list.component';

const COMPONENTS = [
  InfiniteListComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    InfiniteScrollModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class InfiniteListModule { }
