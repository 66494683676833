import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MathService {

  round(value: number, d = 2): number {
    return Math.round(value * Math.pow(10, d)) / Math.pow(10, d);
  }

  ceil(value: number) {
    return Math.ceil(value);
  }

  toDecimal(value: number) {
    return value / 100;
  }

  toCentime(value: number) {
    return value * 100;
  }

  adjustCentimeForRound(value: number) {
    return this.toCentime(
      this.round(this.toDecimal(value), 0)
    );
  }
}
