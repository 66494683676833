import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

import { OutingRulesViewComponent } from './outing-rules/outing-rules-view.component';
import { OutingRulesItemViewComponent } from './outing-rules/outing-rules-item-view.component';

const COMPONENTS = [
  OutingRulesViewComponent,
  OutingRulesItemViewComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    MatIconModule,
    FlexLayoutModule,
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class OutingRulesViewModule { }
