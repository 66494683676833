import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Pipe({
  name: 'formatPhoneNumber',
})
export class FormatPhoneNumberPipe implements PipeTransform {
  constructor() {}

  transform(value: string, ...args: unknown[]): unknown {
    const phone = parsePhoneNumberFromString(value);
    return phone.formatInternational();
  }
}
