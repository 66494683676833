import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { HouseModalities, ReservationRoom } from '@rhbnb-nx-ws/domain';

@Component({
  selector: 'rhbnb-house-rooms-list',
  templateUrl: './house-rooms-list.component.html',
  styleUrls: ['./house-rooms-list.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HouseRoomsListComponent implements OnInit, AfterViewInit {

  @Input() rooms: ReservationRoom[];
  @Input() modality: HouseModalities;
  @Input() calendarValue: HouseModalities;
  @Input() commission = 0;
  @Input() cupRate = 1;
  @Input() selectable = true;
  @Input() translateKey = 'house';
  @Input() tiny = false;

  @Input() currencyTable$;
  @Input() currentCurrency$;

  @Output() roomsChange = new EventEmitter<ReservationRoom[]>();
  @Output() roomsCalendarChange = new EventEmitter<{room: number|string, range: any}>();

  _gtSmValue = 50;
  _gtXsValue = 50;

  @Input() set gtSmColumns(column: number) {
    this._gtSmValue = 100 / column;
  }

  @Input() set gtXsColumns(column: number) {
    this._gtXsValue = 100 / column;
  }

  constructor() { }

  ngOnInit(): void {
  }

  onSelectedChange({selected, roomId}): void {
    this.rooms.find(r => r?.id === roomId).selected = selected;
    this.roomsChange.emit([...this.rooms]);
  }

  showCalendar() {
    return this.modality ?
      this.modality !== HouseModalities.MODALITY_HOUSE :
      false;
  }

  onRoomsCalendarChange(room, range) {
    this.roomsCalendarChange.emit({ room, range });
  }

  ngAfterViewInit(): void {
  }
}
