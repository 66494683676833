import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'bypassHtml'
})
export class BypassHtmlPipe implements PipeTransform {

  constructor(
    private domSanitizer: DomSanitizer
  ) {}

  transform(value: string, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    }

    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

}
