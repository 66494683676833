import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { SearchMapItem } from './map-item';
import { CURRENCY_TABLE$, DEFAULT_CURRENCY$, DEFAULT_IMAGE_SRC } from '@rhbnb-nx-ws/global-tokens';


@Component({
  selector: 'rhbnb-map-item-view',
  template: `
    <div fxLayout="row wrap">
      <img
        rhbnbLazySize
        alt=""
        [imgSrc]="item?.picture"
        [errorImage]="defaultImage"
        class="image"
      />

      <div
        fxFlex="1 1 150px"
        fxFlex.gt-xs="1 1 auto"
        class="body"
        fxLayout="column"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="space-between start"
          class="title"
        >
          <div>
            <a [routerLink]="item?.link">{{ item?.title }}</a>
          </div>

          <div (click)="onClose()">
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"
                 aria-hidden="true"
                 role="presentation"
                 focusable="false"
                 style="display: block; fill: none; height: 26px; width: 26px; stroke: currentcolor; stroke-width: 1; overflow: visible;">
              <path d="m6 6 20 20"></path>
              <path d="m26 6-20 20"></path>
            </svg>
          </div>
        </div>
        <div>{{ item?.subtitle }}</div>

        <div
          class="mt-30"
          fxLayout="row wrap"
          fxLayoutAlign="space-between center"
        >
          <div>
            <rhbnb-map-price-wrapper
                [item]="item.ref"
                [type]="item.refType"
            ></rhbnb-map-price-wrapper>
          </div>
          <div>
            <rhbnb-evaluation-line
              [total]="item?.evalTotal"
              [avg]="item.eval"
            ></rhbnb-evaluation-line>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host > div {
        position: relative;
        border-radius: 12px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
        background-color: white;
        min-width: 300px;
      }

      img {
        width: 150px;
        border-bottom-left-radius: 12px;
        border-top-left-radius: 12px;
      }

      .body {
        padding: 12px;
        max-width: 300px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapItemViewComponent implements OnInit {
  @Input() item: SearchMapItem;
  @Output() closeChange = new EventEmitter<void>();

  constructor(
    @Inject(DEFAULT_IMAGE_SRC) public defaultImage: string,
    @Inject(CURRENCY_TABLE$) public currencyTable$: Observable<any>,
    @Inject(DEFAULT_CURRENCY$) public currentCurrency$: Observable<any>,
  ) {
  }

  ngOnInit(): void {
  }

  onClose() {
    this.closeChange.emit();
  }
}
