import { Injectable } from '@angular/core';
import * as moment_ from 'moment';
import { Moment } from 'moment';

const moment = moment_;

@Injectable({
  providedIn: 'root'
})
export class MomentService {

  constructor() {
  }

  formatWithoutTzAndEndOfDay(date: Moment) {
    return this.getFormatWithoutOffset(
      this.getEndOfDay(
        this.getMoment(
          this.getStringParsedWithoutOffset(
            this.getFormat(date)
          )
        )
      )
    );
  }

  formatWithoutTzAndStartOfDay(date: Moment) {
    return this.getFormatWithoutOffset(
      this.getStartOfDay(
        this.getMoment(
          this.getStringParsedWithoutOffset(
            this.getFormat(date)
          )
        )
      )
    );
  }

  getFormat(date: Moment | string) {
    return this.getMoment(date).format();
  }

  getFormatWithoutOffset(date: Moment | string) {
    return this.getMoment(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  getTomorrowStartOfDayWithLocalTz(date: string) {
    const d = this.getMomentWithLocalOffset(date);

    const localDate = this.getStartOfDay(d.add(1, 'days'));
    return this.getFormat(localDate);
  }

  getYesterdayFormat(date: Moment | string) {
    const d = this.getMoment(date);
    return d.subtract(1, 'days').format();
  }

  getMoment(date: moment.Moment | string) {
    return (typeof date === 'string') ? moment(date) : date as Moment;
  }

  getMomentWithLocalOffset(date: string) {
    return moment(this.getStringParsedWithoutOffset(date));
  }

  getStringParsedWithoutOffset(date: string) {
    return date.slice(0, -6);
  }

  getStartOfDay(date: Moment) {
    return date.clone().set({
      hour: 0, minute: 0, second: 0
    });
  }

  getEndOfDay(date: Moment) {
    return date.clone().set({
      hour: 23, minute: 59, second: 59
    });
  }

  countNights(start: string | Moment, end: string | Moment) {
    let s = start;
    let e = end;

    if (typeof start === 'string') {
      s = this.getMomentWithLocalOffset(start);
    }

    if (typeof end === 'string') {
      e = this.getMomentWithLocalOffset(end);
    }

    s = this.getMoment(s).startOf('day');
    e = this.getMoment(e).endOf('day');

    return e.diff(s, 'days');
  }

  fromUnix(unix: number) {
    return moment(unix);
  }
}
