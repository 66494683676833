import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileFieldComponent } from './mobile-field/mobile-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

const COMPONENTS = [
  MobileFieldComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslocoModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class MobileFieldModule { }
