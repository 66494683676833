import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  ReservationModificationRequest
} from '@rhbnb-nx-ws/domain';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  AbstractDataService
} from '../util';
import { MomentService } from './moment.service'

@Injectable({
  providedIn: 'root'
})
export class ReservationModificationRequestApiService
  extends AbstractDataService<ReservationModificationRequest> {

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string,
    private momentService: MomentService
  ) {
    super(http, 'reservation-modification-request', apiURL);
  }

  add(entity: ReservationModificationRequest)
    : Observable<ApiResponse<ReservationModificationRequest>> {
    this.subtractOneDayToEndDay(entity);

    return this.http
      .post<ApiResponse<ReservationModificationRequest>>
      (`${this.apiURL}/${this.endpointName}`, entity)
      .pipe(catchError(error => observableThrowError(error)));
  }

  accept(id: string | number): Observable<ApiResponse<{
    modification: ReservationModificationRequest
    payment: boolean
  }>> {
    return this.http
      .post<ApiResponse<{
        modification: ReservationModificationRequest
        payment: boolean
      }>>(`${this.apiURL}/${this.endpointName}/accept`, {id})
      .pipe(catchError(error => observableThrowError(error)));
  }

  reject(id: string | number): Observable<ApiResponse<ReservationModificationRequest>> {
    return this.http
      .post<ApiResponse<ReservationModificationRequest>>(`${this.apiURL}/${this.endpointName}/reject`, {id})
      .pipe(catchError(error => observableThrowError(error)));
  }

  private subtractOneDayToEndDay(r: ReservationModificationRequest) {
    r.checkOut = this.momentService
      .getMoment(r.checkOut)
      .subtract(1, 'day')
      .format('YYYY-MM-DDTHH:mm:ss');
  }
}
