import * as shajs from 'sha.js';

import { SharedReplayRefresh } from "./share-replay-refresh";

export class CacheUtilService {
  protected cacheMap = new Map<string, any>();
  protected _cacheKeys: string[] = [];

  constructor() {
  }

  private buildCache() {
    for (const key of this.cacheKeys) {
      const entry = new SharedReplayRefresh();
      this.cacheMap.set(key, entry);
    }
  }

  set cacheKeys(keys: string[]) {
    this._cacheKeys = keys;
    this.buildCache();
  }

  get cacheKeys() {
    return this._cacheKeys;
  }

  addCacheEntry(key: string) {
    if (!this.cacheKeys.includes(key)) {
      this.cacheKeys.push(key);
      this.cacheMap.set(key, new SharedReplayRefresh());
    }
  }

  getCacheEntry<T>(key: string) {
    if (!this.cacheKeys.includes(key)) {
      this.addCacheEntry(key);
    }

    return this.cacheMap.get(key) as SharedReplayRefresh<T>;
  }

  invalidateCacheEntry(key: string) {
    this.cacheMap.set(key, new SharedReplayRefresh());
  }

  invalidateCacheGroupEntry(partialKey: string) {
    for (const key of this.cacheKeys) {
      if (key.startsWith(partialKey)) {
        this.cacheMap.set(key, new SharedReplayRefresh());
      }
    }
  }

  getCacheKeyWithParam(baseKey: string, params: string) {
    return `${baseKey}.${this.getHash(params)}`;
  }

  getHash(params: string) {
    return shajs('sha256').update(params).digest('hex')
  }
}
