import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

import { CompleteProfileFormComponent } from './complete-profile-dialog/complete-profile-form.component';
import { CountrySelectorModule } from '../country-selector';
import { MobileFieldModule } from '../mobile-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    CompleteProfileFormComponent
  ],
  imports: [
    CommonModule,
    CountrySelectorModule,
    MobileFieldModule,
    TranslocoModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class CompleteProfileDialogModule {}
