import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_BASE_URL } from "@rhbnb-nx-ws/global-tokens";
import { NO_AUTH_HEADER } from "../util";
import { ApiResponse } from "@rhbnb-nx-ws/domain";
import { throwError as observableThrowError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TCCApiService {
  endpointName = 'travel-credit-code';

  constructor(
    private http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string,
  ) {}

  check(code: string) {
    let headers = { [NO_AUTH_HEADER]: 'yes' } as any;

    return this.http.get<ApiResponse<any>>(
      `${this?.apiURL}/${this.endpointName}/check-code/${code}`,
      { headers }
    )
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }
}
