import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, EntityUserChatChannel } from '@rhbnb-nx-ws/domain';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private endpointName = 'chat';

  constructor(
    private http: HttpClient
  ) { }

  getChannel(entity: string, id: string) {
    return this.http.get<ApiResponse<{ channel: {}, ttr: number }>>(
      `${environment?.apiURL}/${this.endpointName}/get-channel-by-entity/${entity}/${id}`
    ).pipe(catchError(error => observableThrowError(error)));
  }

  getUserToken() {
    return this.http.get<ApiResponse<{ token: string }>>(
      `${environment?.apiURL}/${this.endpointName}/me/token`
    ).pipe(catchError(error => observableThrowError(error)));
  }

  getUnreadCount() {
    return this.http.get<ApiResponse<number>>(
      `${environment?.apiURL}/${this.endpointName}/me/unread`
    ).pipe(catchError(error => observableThrowError(error)));
  }

  getMeChatChannelsAsHost() {
    return this.http.get<ApiResponse<EntityUserChatChannel>>(
      `${environment?.apiURL}/${this.endpointName}/me/chat-channels/as-host`
    ).pipe(catchError(error => observableThrowError(error)));
  }

}
