import { createAction, props } from '@ngrx/store';
import { CurrencyKeyType, User, LANGUAGES } from '@rhbnb-nx-ws/domain';
import { StateCurrency } from './reducer';

export const loadCurrencyTable = createAction(
  '[Layout] Load Currency Table'
);

export const setCurrentUser = createAction(
  '[Core] Set Current User',
  props<{user: User}>()
);

export const setCountry = createAction(
  '[Layout] Set Country',
  props<{country: string}>()
);

export const updateUserTCC = createAction(
  '[Core] Update User TCC',
  props<{tcc: number}>()
);

export const setCurrencyTable = createAction(
  '[Core] Set Currency Table',
  props<{value: StateCurrency}>()
);

export const setCurrentCurrency = createAction(
  '[Core] Set Current Currency',
  props<{value: CurrencyKeyType}>()
);

export const setCurrentLang = createAction(
  '[Core] Set Current Lang',
  props<{value: LANGUAGES}>()
);

export const setChatAuthToken = createAction(
  '[Core] Set Chat Auth Token',
  props<{token: string}>()
);

export const loginChatUser = createAction(
  '[Core] Login Chat User'
);

export const loadChatUnreadCount = createAction(
  '[Core] Load Chat Unread Count'
);

export const incChatUnreadCount = createAction(
  '[Core] Inc Chat Unread Count',
  props<{count: number}>()
);

export const decChatUnreadCount = createAction(
  '[Core] Dec Chat Unread Count',
  props<{count: number}>()
);

export const setChatUnreadCount = createAction(
  '[Core] Set Chat Unread Count',
  props<{count: number}>()
);

export const setChatUnreadIndicatorSpace = createAction(
  '[Core] Set Chat Unread Indicator Space',
  props<{space: string}>()
);

export const setChatFail = createAction(
  '[Core] Set Chat Fail',
  props<{fail: boolean}>()
);

export const setChatWindowOpen = createAction(
  '[Core] Set Chat Window Open',
  props<{open: boolean, entity: string, id: string}>()
);

export const setChatWaitingRoom = createAction(
  '[Core] Set Chat Waiting Room',
  props<{waiting: boolean}>()
);

export const setLoginUserLoading = createAction(
  '[Core] Set Login User Loading',
  props<{loading: boolean}>()
);

export const setMetaTitle = createAction(
  '[Core] Set Meta Title',
  props<{title: string}>()
);

export const setMetaUrl = createAction(
  '[Core] Set Meta Url',
  props<{url: string}>()
);

export const setMetaDescription = createAction(
  '[Core] Set Meta Description',
  props<{description: string}>()
);

export const setMetaImage = createAction(
  '[Core] Set Meta Image',
  props<{image: string}>()
);

export const setJsonLdSchema = createAction(
  '[Core] Set Json LD Schema',
  props<{schema: Object}>()
);

export const setUpdateAvailable = createAction(
  '[Core] Set Update Available',
  props<{update: boolean}>()
);
