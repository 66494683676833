import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardWithImgComponent } from './card-with-img/card-with-img.component';
import { SharedDirectivesModule } from '@rhbnb-nx-ws/shared-directives';
import { SharedPipesModule } from '@rhbnb-nx-ws/shared-pipes';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CardWithImgComponent
  ],
  imports: [
    CommonModule,
    SharedDirectivesModule,
    SharedPipesModule,
    RouterModule,
  ],
  exports: [
    CardWithImgComponent
  ]
})
export class CardWithImgModule {}
