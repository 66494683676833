import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Host, Outing } from '@rhbnb-nx-ws/domain';

@Component({
  selector: 'rhbnb-outing-host-info',
  templateUrl: './outing-host-info.component.html',
  styleUrls: ['./outing-host-info.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutingHostInfoComponent implements OnInit {

  @Input() outing: Outing;
  @Input() host: Host;

  constructor() { }

  ngOnInit(): void {
  }

  get avatar(): string {
    return this.host?.user?.image;
  }

  get profileLink() {
    return `/profile/${this.host?.user?.id}`;
  }

}
