import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MEDIA_QUERIES, MQ, SIZE } from '@rhbnb-nx-ws/global-tokens';
import { FilterImagePipeService } from 'libs/shared-pipes/src/lib/pipes';

@Component({
  selector: '[rhbnb-lazy-sizes-picture]',
  templateUrl: './lazy-sizes-picture.component.html',
  styleUrls: ['./lazy-sizes-picture.component.css'],
})
export class LazySizesPictureComponent implements OnInit {
  private _src: string;
  @Input() set src(src: string) {
    this._src = src;
  }
  get src() {
    return this._src;
  }

  @Input() preload = false;

  @Input() lowerW: string = '720';
  @Input() mediumW: string = '960';
  @Input() largeW: string = '1200';

  @Input() alt: string = "";
  @Input() errorImage: string;
  error = false;

  @ViewChild('img', { read: ElementRef })
  imgRef: ElementRef<HTMLElement>;

  constructor(
    @Inject(MEDIA_QUERIES) private mq,
    @Inject(DOCUMENT) private document: Document,
    private filterImagePipeService: FilterImagePipeService,
  ) {}

  ngOnInit(): void {
    if (this.preload) {
      this.preloadImage();
    }
  }

  get medias() {
    return Object.entries(this.mq);
  }

  getWidthByQuery(query: SIZE|string) {
    switch (query) {
      case 'XXS':
      case 'XS':
        return this.lowerW;
      case 'SM':
      case 'MD':
        return this.mediumW;
      case 'LG':
      case 'XL':
        return this.largeW;
    }
  }

  getQueryByMQ(mq: {
    min?: string;
    max?: string;
  }) {
    const twice = mq.min && mq.max;
    return `${mq.min && `(min-width: ${mq.min})`}${twice && ' and '}${mq.max && `(max-width: ${mq.max})`}`;
  }

  preloadImage() {
    this.medias.forEach(m => {
      const link: HTMLLinkElement = this.document.createElement('link');
      const size = m[0] as SIZE;
      const mq = m[1] as MQ;

      link.setAttribute('rel', 'preload');
      link.setAttribute('as', 'image');
      link.setAttribute('media', this.getQueryByMQ(mq));
      link.setAttribute('href', this.filterImagePipeService.filter(
        this.src,
        { w: Number(this.getWidthByQuery(size)), h: 0 }
      ));

      this.document.head.appendChild(link);
    });
  }

  onError() {
    this.error = true;
  }

  trackByItems(index: number, item: [string, unknown]) {
    return item[0];
  }
}
