/* tslint:disable:triple-equals */
import { Inject, Injectable } from '@angular/core';

import { MathService } from './math.service';
import { CurrencyKeyType, Payment } from '@rhbnb-nx-ws/domain';
import { PAYMENT_THRESHOLD } from '../tokens';

@Injectable({
  providedIn: 'root'
})
export class PaymentUtilService {

  constructor(
    private mathService: MathService,
    @Inject(PAYMENT_THRESHOLD) private threshold: number
  ) {
  }

  isCashPayAffected(payment: Payment) {
    return this.paymentDiscounts(payment) > 0 &&
      this.mathService.round(Number(payment?.charge), 2) ===
      this.mathService.round(this.computePaymentCommission(payment), 2);
  }

  paymentDiscounts(payment: Payment) {
    return payment?.usedCredit + payment?.usedCoupon;
  }

  getHouseCashAmount(payment: Payment) {
    if (!payment.partial) {
      return 0;
    }

    const {amount} = this.getPaymentAmountAndChargeInCurrency(
      payment
    );

    const commission = this.computeCommission(amount, payment?.commission);
    const hAmount = amount - (
      commission - (this.paymentDiscounts(payment) * this.getCUPRate(payment)) >= (this.threshold * this.getCUPRate(payment))
        ? 0
        : this.paymentDiscounts(payment) * this.getCUPRate(payment)
    );

    return hAmount >= 0 ? hAmount : 0;
  }

  getCUPRate(payment: Payment) {
    return payment?.currency == CurrencyKeyType.CUP ? payment?.cupRate : 1;
  }

  computeInCurrency(amount: number, cupRate: number, currency: CurrencyKeyType) {
    return currency === CurrencyKeyType.CUP ?
      amount * cupRate :
      amount
    ;
  }

  getTransferPayment(payment: Payment) {
    const {amount} = this.getPaymentAmountAndChargeInCurrency(
      payment
    );

    return amount - this.getHouseCashAmount(payment);
  }

  computePaymentCommission(payment: Payment) {
    return this.computeCommission(payment?.amount, payment?.commission);
  }

  getPaymentAmountWithCommission(payment: Payment) {
    const amount = this.getPaymentAmountWithCommissionInCurrency(
      payment
    );

    return amount;
  }

  getPaymentAmountWithCommissionInCurrency(payment: Payment) {
    return payment?.currency == CurrencyKeyType.CUP ?
      payment?.cupAmountWithCommission :
      payment?.amountWithCommission
    ;
  }

  getPaymentUsedCouponInCurrency(payment: Payment) {
    return payment?.currency == CurrencyKeyType.CUP ?
      payment?.cupUsedCoupon :
      payment?.usedCoupon
    ;
  }

  getPaymentUsedCreditInCurrency(payment: Payment) {
    return payment?.currency == CurrencyKeyType.CUP ?
      payment?.cupUsedCredit :
      payment?.usedCredit
    ;
  }

  computeCommission(amount: number, commission: number) {
    const p = (100 - commission) / 100;
    return (amount / p) - amount;
  }

  getAmountWithCommission(amount: number, commission: number) {
    const p = (100 - commission) / 100;
    return amount / p;
  }

  getAmountWithoutCommission(amount: number, commission: number) {
    const p = (100 - commission) / 100;
    return amount * p;
  }

  /**
   * Return the amount and charge of the given payment
   * in default (USD) currency or in CUP already converted
   *
   * @param payment
   */
  getPaymentAmountAndChargeInCurrency(payment: Payment): {amount: number, charge: number} {
    return payment?.currency == CurrencyKeyType.CUP ?
      {amount: payment?.cupAmount, charge: payment?.cupCharge} :
      {amount: payment?.amount, charge: payment?.charge}
    ;
  }
}
