import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRangeModel, Destination } from '@rhbnb-nx-ws/domain';
import { map } from 'rxjs/operators';

import { TopFilterService } from '../top-filter.service';
import { FilterChangesModel, FilterDataChange } from '../filter-changes.model';

@Component({
  selector: 'rhbnb-house-filter',
  templateUrl: './house-filter.component.html',
  styleUrls: ['./house-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HouseFilterComponent implements OnInit {

  dest$: Observable<any>;

  @Input() destinations$: Observable<Destination[]>;
  @Input() destination$: Observable<number | string>;
  @Input() dateRange$: Observable<DateRangeModel>;
  @Input() room$: Observable<number>;
  @Input() guest$: Observable<number>;
  @Input() alternateColor = false;

  @Output() modelChange = new EventEmitter<FilterDataChange>();

  dateLabel$: Observable<string>;

  constructor(
    public topFilterService: TopFilterService
  ) { }

  ngOnInit(): void {
    this.dateLabel$ =
      this.dateRange$.pipe(
        map(range => range?.chosenLabel ? range?.chosenLabel : '')
      );

    this.dest$ = this.destination$
      .pipe(
        map((d) => {
          return !isNaN(Number(d)) ? d : ({ province: d });
        })
      );
  }

  onDestinationChange(destination: any): void {
    if (!destination?.id) {
      // Users can select the whole province
      // in this case, destination id is undefined
      this.modelChange.emit({
        type: FilterChangesModel.DESTINATION,
        data: destination?.province
      });
    } else {
      this.modelChange.emit({
        type: FilterChangesModel.DESTINATION,
        data: destination?.id
      });
    }
  }

  onDateRangeChange(event): void {
    this.modelChange.emit({
      type: FilterChangesModel.DATE_RANGE,
      data: event
    });
  }

  onRoomChange(event): void {
    this.modelChange.emit({
      type: FilterChangesModel.ROOM,
      data: event
    });
  }

  onGuestChange(event): void {
    this.modelChange.emit({
      type: FilterChangesModel.GUESTS,
      data: event
    });
  }
}
