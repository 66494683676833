import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rhbnb-room-card-item-loading',
  template: `
      <div fxLayout="column"
           fxLayoutAlign="start stretch"
           class="room-item">

          <div class="image-container">
              <div class="img load-animate">
              </div>

              <div class="content-container">
                  <div class="icon mb-5">
                      <div class="load-animate"></div>
                      <div class="load-animate"></div>
                  </div>

                  <div class="title mb-10 load-animate">
                  </div>
              </div>
          </div>
      </div>
  `,
  styles: [
    `
          .room-item {
              box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
              border-radius: 12px !important;
              width: 100% !important;
          }

          .room-item .image-container {
              position: relative;
          }

          .room-item .image-container .img {
              border-top-right-radius: 12px !important;
              border-top-left-radius: 12px !important;
              background-color: #dddddd;
              width: 100%;
              height: 180px;
          }

          .room-item .content-container {
              padding: 24px !important;
          }

          .room-item .content-container .icon > div {
              width: 40px;
              height: 40px;
              background-color: #dddddd;
              display: inline-block;
              margin-right: 5px;
          }

          .room-item .content-container .title {
              width: 150px;
              height: 15px;
              background-color: #dddddd;
          }

          .room-item .title {
              width: 100px;
              height: 20px;
          }

          .room-item div.bed-item:not(:last-child) {
              margin-bottom: 5px;
          }
    `
  ]
})
export class RoomCardItemComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
