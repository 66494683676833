import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rhbnb-room-card-loading',
  templateUrl: './room-card-loading.component.html',
  styleUrls: ['./room-card-loading.component.sass']
})
export class RoomCardLoadingComponent implements OnInit {

  items = Array.from({ length: 3 }, (v, k) => k + 1);
  _gtSmValue = 50;
  _gtXsValue = 50;

  @Input() set gtSmColumns(column: number) {
    this._gtSmValue = 100 / column;
  }

  @Input() set gtXsColumns(column: number) {
    this._gtXsValue = 100 / column;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
