import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'rhbnb-loading-gallery',
  templateUrl: './loading-gallery.component.html',
  styleUrls: ['./loading-gallery.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingGalleryComponent implements OnInit {

  count = [];

  constructor() { }

  ngOnInit(): void {
    this.count.length = 4;
  }

}
