<mat-dialog-content>

  <!--
    The picker was duplicated because if i set the range undefined
    (like the first time) the picker was break
   -->
  <ngx-daterangepicker-material
    *ngIf="isValidDate; else noRange"
    autoApply="true"
    [startDate]="rangeSelected.startDate"
    [endDate]="rangeSelected.endDate"
    [locale]="rangeDateLocale"
    [minDate]="minDate"
    [dateMinLimit]="minRange"
    [singleDatePicker]="singlePicker"
    (choosedDate)="onDateChange($event)"
    (startDateChanged)="onSingleDateChange($event)"
    (minLimitChanged)="onMinLimitChange($event)"
  ></ngx-daterangepicker-material>

  <ng-template #noRange>
    <ngx-daterangepicker-material
      autoApply="true"
      [minDate]="minDate"
      [dateMinLimit]="minRange"
      [locale]="rangeDateLocale"
      [singleDatePicker]="singlePicker"
      (choosedDate)="onDateChange($event)"
      (startDateChanged)="onSingleDateChange($event)"
      (minLimitChanged)="onMinLimitChange($event)"
    ></ngx-daterangepicker-material>
  </ng-template>

</mat-dialog-content>
