<div class="host-info"
     fxLayout="row"
     fxLayoutAlign="space-between start">

  <div fxFlex="70">
    <div class="title mb-5">
      {{ 'outing.host_label_view' | transloco: {name: outing?.host?.user?.name} }}
    </div>

    <div class="extra-info">
      {{ 'outing.extra_info_label' | transloco: {minGuests: outing?.minGuestCapacity, maxGuests: outing?.maxGuestCapacity} }}
    </div>

    <div class="extra-info">
      {{ 'outing.pool_info_label' | transloco: {width: outing?.width, long: outing?.long, deep: outing?.deep} }}
    </div>
  </div>

  <div fxFlex="0 1 auto" class="avatar"
    [routerLink]="profileLink">
    <rhbnb-avatar-image
      [imageSrc]="avatar"
      [name]="outing?.host?.user?.name"></rhbnb-avatar-image>
  </div>

</div>
