import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, Observable, throwError as observableThrowError } from 'rxjs';
import { Moment } from 'moment';
import { OutingEvent } from '@rhbnb-nx-ws/domain';
import { ApiResponse } from '@rhbnb-nx-ws/domain';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

import { MomentService } from './moment.service';
import { AbstractDataService } from '../util';
import { EventsBaseService } from './events-base.service';

@Injectable({
  providedIn: 'root'
})
export class OutingEventsService extends AbstractDataService<OutingEvent> {

  constructor(
    public http: HttpClient,
    public momentService: MomentService,
    private eventsBaseService: EventsBaseService,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'events', apiURL);
  }

  add(entity: OutingEvent): Observable<ApiResponse<OutingEvent>> {
    entity.start = this.momentService.getFormat(entity.start);
    entity.end = this.momentService.getYesterdayFormat(entity.end);

    return super.add(entity);
  }

  get(outingIds: Array<string>, from: Moment, to: Moment,
      joinReservations = false, joinCalendarEvents = false): Observable<OutingEvent[]> {
    if (outingIds.length === 1) {
      return this.getForOuting(outingIds[0], from, to, joinReservations, joinCalendarEvents)
        .pipe(
          map((res: ApiResponse<OutingEvent[]>) => res.data)
        );
    }
    return this.getForOutings(outingIds, from, to, joinReservations);
  }

  private getForOutings(outingIds: Array<string>, from: Moment, to: Moment,
                      joinReservations = false, joinCalendarEvents = false): Observable<OutingEvent[]> {
    const events$ = [];

    outingIds.forEach(roomId => {
      events$.push(this.getForOuting(roomId, from, to, joinReservations, joinCalendarEvents));
    });

    return forkJoin(events$)
      .pipe(
        map((events: ApiResponse<OutingEvent[]>[]) => {
          const mergedApiResponse = [].concat.apply([], events);
          return [].concat.apply([], mergedApiResponse.map(ar => ar.data));
        })
      );
  }

  private getForOuting(outingId: string, from: Moment, to: Moment,
                     joinReservations = false, joinCalendarEvents = false
  ): Observable<ApiResponse<OutingEvent[]>> {
    const params = {
      from: this.momentService.formatWithoutTzAndStartOfDay(from),
      to: this.momentService.formatWithoutTzAndEndOfDay(to)
    };

    if (joinReservations) {
      params['join-reservation'] = true;
    }

    if (joinCalendarEvents) {
      params['join-cal-events'] = true;
    }

    return this.http
      .get<ApiResponse<OutingEvent[]>>(
        `${this.apiURL}/${this.endpointName}/outing/${outingId}`,
        { params })
      .pipe(
        map((res: ApiResponse<OutingEvent[]>) => this.eventsBaseService.toLocalTZ(res)),
        map((res: ApiResponse<OutingEvent[]>) => this.eventsBaseService.addOneDayToEndDate(res)),
        // map((res: ApiResponse<OutingEvent[]>) => this.eventsBaseService.adjustPrice(res)),
        catchError(error => observableThrowError(error))
      );
  }
}
