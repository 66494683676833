import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HouseMapComponent } from './house-map/house-map.component';
import { MapLoaderService } from './house-map/map-loader.service';

const COMPONENTS = [
  HouseMapComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule
  ],
  providers: [
    MapLoaderService
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class HouseMapModule { }
