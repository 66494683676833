import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { APP_API_VERSION } from '@rhbnb-nx-ws/domain';
import { CompatibilityApiService } from '@rhbnb-nx-ws/services';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'rhbnb-hard-update-page',
  template: `
    <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
      <h1 class="p-4">{{ 'core.hard_update_text' | transloco }}</h1>
      <mat-spinner color="warn" diameter="50"></mat-spinner>
    </div>
  `,
  styles: [],
})
export class HardUpdatePageComponent implements OnInit, OnDestroy {
  timeout: NodeJS.Timeout;

  constructor(
    private compatibilityApiService: CompatibilityApiService,
    private router: Router,
    private swUpdate: SwUpdate,
  ) {}

  ngOnInit(): void {
    this.compatibilityApiService.getVersion()
      .pipe(
        take(1),
        tap(res => {
          if (res.data === APP_API_VERSION) {
            this.router.navigate(['/']);
          }
        }),
        catchError(() => this.router.navigate(['/'])),
      )
      .subscribe();

      this.handleUpdate();

      // After 2 mins, force reload to prevent getting stuck
      this.timeout = setTimeout(() => {
        window.location.href = window.location.href
      }, 2 * 60 * 1000);
  }

  handleUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((e) => e.type === 'VERSION_READY'),
          map(() => window.location.href = window.location.href)
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
