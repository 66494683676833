import {
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  AfterContentInit,
  QueryList, Input, AfterContentChecked
} from '@angular/core';

import { TabItemComponent } from '../tab/tab-item.component';
import { Observable } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';

export type Aligns = 'start' | 'center' | 'end' | 'space-around' | 'space-between' | 'space-evenly';

@Component({
  selector: 'rhbnb-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements AfterContentChecked, AfterContentInit {

  @Input() alignItems: Aligns = 'center';
  @Input() showBorder = true;

  @ContentChildren(TabItemComponent)
  tabs: QueryList<TabItemComponent>;

  tabItems$: Observable<TabItemComponent[]>;

  activeTab: TabItemComponent;

  constructor() {}

  ngAfterContentInit(): void {
    this.tabItems$ = this.tabs.changes
      .pipe(startWith(""))
      .pipe(delay(0))
      .pipe(map(() => this.tabs.toArray()));
  }

  ngAfterContentChecked() {
    // choose the default tab
    // we need to wait for a next VM turn,
    // because Tab item content, will not be initialized yet
    if (!this.activeTab) {
      Promise.resolve().then(() => {
        this.activeTab = this.tabs.first;
      });
    }
  }

  selectTab(tabItem: TabItemComponent) {
    if (this.activeTab === tabItem) {
      return;
    }

    if (this.activeTab) {
      this.activeTab.isActive = false;
    }

    this.activeTab = tabItem;
    tabItem.isActive = true;
  }
}
