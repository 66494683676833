// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: true,
  apiURL: 'https://api.docker.vm/v2-api',
  ssoLoginUrl: 'http://api.docker.vm/spa/login',
  ssoLogoutUrl: 'http://api.docker.vm/logout',
  chatUrl: `http://192.168.48.2:3000`,
  wsChatUrl: `ws://192.168.48.2:3000/websocket`,
  auth2server: 'https://api.docker.vm/',
  auth2client: 'platform_web_app',
  imageFilterServiceUrl: `http://localhost:8000`,
  identityKey: 'rhsso_prod_identity',
  webpushKey: 'BJsn8dYP7LhcvUXODbdQMwMimlojyhz0qESmzSKtAqyCzRD6v1a3rQbKw-VMFOgKveesQWmuyy0cisFEr6GYdOg'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
