import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DateRangeModel, Destination, FilterTab } from '@rhbnb-nx-ws/domain';

import * as fromLayout from './reducer';
import { Filter, UIFilter } from './reducer';
import * as actions from './actions';
import * as selectors from './selectors';

@Injectable({
  providedIn: 'root',
})
export class LayoutStoreService {
  constructor(private store: Store<fromLayout.State>) {}

  setDestinationLoading(loading: boolean): void {
    this.store.dispatch(actions.setDestinationsLoading({ loading }));
  }

  setDestinations(destinations: Destination[]): void {
    this.store.dispatch(actions.setDestinations({ destinations }));
  }

  loadDestinations(): void {
    this.store.dispatch(actions.loadDestinations());
  }

  getDestinations(): Observable<Destination[]> {
    return this.store.select(selectors.selectDestinations);
  }

  getDestinationsLoading(): Observable<boolean> {
    return this.store.select(selectors.selectDestinationsLoading);
  }

  getDestinationById(id): Observable<Destination> {
    return this.store.select(selectors.selectDestinations).pipe(
      // tslint:disable-next-line:triple-equals
      map((dd) => dd.find((d) => d.id == id))
    );
  }

  getFilter(): Observable<Filter> {
    return this.store.select(selectors.selectFilter);
  }

  getFilterLoaded(): Observable<boolean> {
    return this.store.select(selectors.selectFilterLoaded);
  }

  getFilterTab(): Observable<FilterTab> {
    return this.store.select(selectors.selectFilterTab);
  }

  getActiveUIFilter(): Observable<UIFilter> {
    return this.store.select(selectors.selectActiveUIFilter);
  }

  getDisabledBarPlaceholder(): Observable<boolean> {
    return this.store.select(selectors.selectDisableBarPlaceholder);
  }

  getFilterDestination(): Observable<string | number> {
    return this.store.select(selectors.selectFilterDestination);
  }

  getFilterSelectedProvince(): Observable<string | number> {
    return this.store.select(selectors.selectFilterSelectedProvince);
  }

  getFilterOutingDestination(): Observable<string | number> {
    return this.store.select(selectors.selectFilterOutingDestination);
  }

  getFilterOutingSelectedProvince(): Observable<string | number> {
    return this.store.select(selectors.selectFilterOutingSelectedProvince);
  }

  getFilterDateRange(): Observable<DateRangeModel> {
    return this.store.select(selectors.selectFilterDateRange);
  }

  getFilterRoomCount(): Observable<number> {
    return this.store.select(selectors.selectFilterRoomCount);
  }

  getFilterGuestCount(): Observable<number> {
    return this.store.select(selectors.selectFilterGuestCount);
  }

  getFilterFacilities(): Observable<(number | string)[]> {
    return this.store.select(selectors.selectFilterFacilities);
  }

  getFilterWithin(): Observable<number[][]> {
    return this.store.select(selectors.selectFilterWithin);
  }

  getFilterLoading(): Observable<boolean> {
    return this.store.select(selectors.selectFilterLoading);
  }

  getLoadingLogout(): Observable<boolean> {
    return this.store.select(selectors.selectLoadingLogout);
  }

  getFilterIsEmpty(): Observable<boolean> {
    return this.store.select(selectors.selectFilterIsEmpty);
  }

  getFilterNights(): Observable<number> {
    return this.store.select(selectors.selectFilterNights);
  }

  getProvinceIdByProvinceName(province: string) {
    return this.store.select(selectors.selectDestinations).pipe(
      map((destinations: any) => {
        // tslint:disable-next-line:triple-equals
        return (destinations.find((d) => d.province == province) as any)
          ?.province_id;
      })
    );
  }

  setFilterLoaded(loaded: boolean): void {
    this.store.dispatch(actions.setFilterLoaded({ loaded }));
  }

  setFilterTab(tab: FilterTab): void {
    this.store.dispatch(actions.setFilterTab({ tab }));
  }

  setActiveUIFilter(filter: UIFilter): void {
    this.store.dispatch(actions.setActiveUIFilter({ filter }));
  }

  setDisableBarPlaceholder(disable: boolean): void {
    this.store.dispatch(actions.setDisableBarPlaceholder({ disable }));
  }

  setFilterDestination(destination: number | string): void {
    this.store.dispatch(actions.setFilterDestination({ destination }));
  }

  setFilterSelectedProvince(province: number | string): void {
    this.store.dispatch(actions.setFilterSelectedProvince({ province }));
  }

  setFilterOutingDestination(destination: number | string): void {
    this.store.dispatch(actions.setFilterOutingDestination({ destination }));
  }

  setFilterOutingSelectedProvince(province: number | string): void {
    this.store.dispatch(actions.setFilterOutingSelectedProvince({ province }));
  }

  setFilter(filter: Filter): void {
    this.store.dispatch(actions.setFilter(filter));
    this.store.dispatch(actions.computeFilterNights());
  }

  setFilterDateRange(range: DateRangeModel): void {
    this.store.dispatch(actions.setFilterDateRange(range));
    this.store.dispatch(actions.computeFilterNights());
  }

  setFilterRoomCount(room: number): void {
    this.store.dispatch(actions.setFilterRoomCount({ number: room }));
  }

  setFilterGuestCount(guest: number): void {
    this.store.dispatch(actions.setFilterGuestCount({ number: guest }));
  }

  setFilterLoading(loading: boolean): void {
    this.store.dispatch(actions.setFilterLoading({ loading }));
  }

  setFilterFacilities(facilities: (string | number)[]): void {
    this.store.dispatch(actions.setFilterFacilities({ facilities }));
  }

  setFilterWithin(within: number[][]): void {
    this.store.dispatch(actions.setFilterWithin({ within }));
  }

  setLoadingLogout(loading: boolean): void {
    this.store.dispatch(actions.setLoadingLogout({ loading }));
  }

  buildQueryParamsFromState(): Observable<any> {
    return this.getFilter().pipe(
      map((filter) => ({
        destination: filter.destination,
        guests: filter.guestCount,
        rooms: filter.roomCount,
        startDate: filter.dateRange.startDate,
        endDate: filter.dateRange.endDate,
        facilities: filter.facilities?.join(','),
      }))
    );
  }
}
