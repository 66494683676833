import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse, User } from '@rhbnb-nx-ws/domain';
import { AbstractDataService, NO_AUTH_HEADER } from '@rhbnb-nx-ws/services';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends AbstractDataService<User> {

  constructor(
    public http: HttpClient
  ) {
    super(http, 'users', environment.apiURL);
  }

  getOne(id: string): Observable<ApiResponse<User>> {
      return super.getOne(id, { [NO_AUTH_HEADER]: 'yes' });
  }

  getAll(): Observable<ApiResponse<User[]>> {
    return super.getAll({ [NO_AUTH_HEADER]: 'yes' });
  }

  uploadImage(image: any): Observable<ApiResponse<string>> {
    const formData = new FormData();
    formData.append('image', image);

    return this.http
      .post<ApiResponse<string>>(`${environment?.apiURL}/${this.endpointName}/image`, formData)
      .pipe(catchError(error => observableThrowError(error)));
  }

  patchMe(entity: User): Observable<ApiResponse<User>> {
    return this.http
      .patch<ApiResponse<User>>(
        `${environment?.apiURL}/${this.endpointName}/me`,
        entity
      )
      .pipe(catchError(error => observableThrowError(error)));
  }

  getTcc(): Observable<number> {
    return this.getMe()
      .pipe(map(res => res?.data?.travelCredit))
    ;
  }
}
