import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  HostBinding,
  EventEmitter,
  Output, ElementRef, AfterContentInit, AfterViewInit, Input
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'rhbnb-select-item',
  template: `
      <ng-template>
          <input [checked]="selected" (change)="onChange($event)" id="{{ id }}" type="checkbox"/>

          <label [for]="id">
              <ng-content></ng-content>
          </label>
      </ng-template>
  `,
  styles: [
      `
          input {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
          }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectItemComponent implements OnInit {

  static nextId = 0;

  @ViewChild(TemplateRef)
  content: TemplateRef<any>;

  @Input() selected = false;

  @Input() data: any;
  @Output() selectedChange = new EventEmitter<any>();

  @HostBinding()
  id = `select-item-${SelectItemComponent.nextId++}`;

  constructor() {
  }

  ngOnInit(): void {
  }

  onChange(e) {
    const { checked } = e?.srcElement;

    if (checked) {
      this.selectedChange.emit(this.data);
    } else {
      this.selectedChange.emit(undefined);
    }

    this.selected = checked;
  }
}
