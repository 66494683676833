import { InjectionToken } from '@angular/core';
import { CurrencyKeyType, TargetCard, ROLES, LANGUAGES } from '@rhbnb-nx-ws/domain';
import { environment } from '../../environments/environment';
import { OAuth2Config } from '@rhbnb-nx-ws/global-tokens';

export let APP_CONFIG = new InjectionToken('app.config');

export interface IAppConfig {
  appName: string;
  title: string;
  image: string;
  roles: any;
  languages: any;
  defaultLanguage: string;
  emptyHouseImageSrc: string;
  emptyUserImageSrc: string;
  lazyImage: string;
  loadingGif: string;
  mapboxAccessToken: string;
  fbClientId: string;
  targetCards: TargetCard[];
  trustInvestingAddress: string;
  commissionThreshold: number;
  chatUrl: string;
  oauth2Config: OAuth2Config
}

export const AppConfig: IAppConfig = {
  appName: 'PLATFORM',
  title: 'RentalHo, renta de casa particulares, experiencias y taxis en toda Cuba',
  image: undefined,
  roles: ROLES,
  languages: LANGUAGES,
  defaultLanguage: 'es',
  emptyHouseImageSrc: 'assets/images/empty-house-01.webp',
  emptyUserImageSrc: 'assets/images/empty-user-image.jpeg',
  lazyImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz\n' +
    'AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAABYSURB\n' +
    'VGiB7c8BCcAwEMDAtv4tvpbVxGBh3ClI9sw86wfO1wFvMVJjpMZIjZEaIzVGaozUGKkxUmOkxkiN\n' +
    'kRojNUZqjNQYqTFSY6TGSI2RGiM1RmqM1BipuZhUA/q8N86WAAAAAElFTkSuQmCC',
  loadingGif: `data:image/png;base64,R0lGODlh+AH4Aff/AE65bzuW0v3+/k24bzuV0FWzfkycxlm0gl21hcPj01Cexkeaxky0cjyVyvz9
/v7+/1CyeDqVzb/c6U25bt7x5czn2cjg7arP4v7//zmV0tvr9WW4ja/axUyzc1qjyDuUyz+Wx3Sy
z/3+/dLq3Uy2cH7CoMfl1c7j7+Lu9v///3C8lcPd6/7//mapy0aZxkGWx0ubxjqW0zyX0/v9/kq4
a+z28fj7/f7+/jSS0TeU0r7b6uDx50i3ajaT0jyW0ku4bL/hz7PU5er18FKzfOTz6Uy1cVizgbHa
xpbE2qzQ4pzQt0a2aU6ydk+5cN7s9rrZ5/n8+vb6/Fa8dlS7dDiV0vv9/Ei2a0O0ZvL59TKR0Mnm
1mmszfD580SYxrnezFOi1cXf6/f7+S+Q0Pr8/YLLnXO+mFml1bfdyu31+XvBnj6Y0lezgFu0hESb
00+6b1K5dEec0vn8/We9iu/2+vX6+DORyfL4++jy997s9EOYzIjNofP5+zeU0U6g1ebx99ru46vb
vkyezzeTze7381C6cVm9eUqe01O7ckCY0EWaz0GZ022v15fG42jChmas11y+ey+QzuLy6SyOz4i+
3Uec1c7q2m/DjXPEkMvi7oS83Nzs9dbs4Pb7+I/C4FG2dT6XzWK9hI3Ppn/KmdDl8b/kzdPm8U+4
cpPE4eXz7Fi7eUGzZZLSqEy6bLLV6p3WsqLM5J3J4zCPyVu7furz+KzR6Lza7cHd7eXw9ub07XjG
lbnZ7Fi9d4a82WDAfuj07jiTyiSJyvT69tjp8129fVO3eKDVtrXfxrziy0OXylq5fXS023m23HSz
1rjY6qXZuHvIlpLOrKPM4MDkz77c7WCp13653bXX68Pe7eLz55/L5nXHkNzr88Hk0LnhycXm0tXo
8VW4eHm005nI543A2n642FS1edDl72y+jp3TtZfRsdrq9CiMzOT06ZnVrsbg7bLew6LYtV66gU+0
dN7t91a3fMro1nbBlzqW0bbgxunz+Lbfx7jixk62cqXVu7zkyQAAACH/C05FVFNDQVBFMi4wAwEA
AAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVT
ek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9
IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAg
ICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIy
LXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1w
PSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFk
b2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFw
LzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9w
IENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyNENENEFDODBFOEMxMUVC
QUYwRjlCQzFCNzVBN0UwMCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyNENENEFDOTBFOEMx
MUVCQUYwRjlCQzFCNzVBN0UwMCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlE
PSJ4bXAuaWlkOjI0Q0Q0QUM2MEU4QzExRUJBRjBGOUJDMUI3NUE3RTAwIiBzdFJlZjpkb2N1bWVu
dElEPSJ4bXAuZGlkOjI0Q0Q0QUM3MEU4QzExRUJBRjBGOUJDMUI3NUE3RTAwIi8+IDwvcmRmOkRl
c2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+
/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bF
xMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2M
i4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRT
UlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsa
GRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkECQQA/wAsAAAAAPgB+AEACP8A/wkcSLCgwYMI
EypcyLChw4cQI0qcSLGixYsYM2rcyLGjx48gQ4ocSbKkyZMoU6pcybKly5cwY8qcSbOmzZs4c+rc
ybOnz59AgwodSrSo0aNIkypdyrSp06dQo0qdSrWq1atYs2rdyrWr169gw4odS7as2bNo06pdy7at
27dw48qdS7eu3bt48+rdy7ev37+AAwseTLiw4cOIEytezLix48eQI0ueTLmy5cuYM2vezLmz58+g
Q4seTbq06dOoU6tezbq169ewY8ueTbu27du4c+vezbu379/AgwsfTry48ePIkytfzry58+fQo0uf
Tr269evYs2vfzr279+/gw4v/H0++vPnz6NOrX8++vfv38OPLn0+/vv37+PPr38+/v///AAYo4IAE
FmjggQgmqOCCDDbo4IMQRijhhBRWaOGFGGao4YYcdujhhyCGKOKIJJZo4okopqjiiiy26OKLMMYo
44w01mjjjTjmqOOOPPbo449ABinkkEQWaeSRSCap5JJMNunkk1BGKeWUVFZp5ZVYZqnlllx26eWX
YIYp5phklmnmmWimqeaabLbp5ptwxinnnHTWaeedeOap55589unnn4AGKuighBZq6KGIJqrooow2
6uijkEYq6aSUVmrppZhmqummnHbq6aeghirqqKSWauqpqKaq6qqsturqq7DG/yrrrLTWauutuOaq
66689urrr8AGK+ywxBZr7LHIJqvsssw26+yz0EYr7bTUVmvttdhmq+223Hbr7bfghivuuNxpUcIB
EJAAAAAkQHBACRXgBEYIBoAQQQABRACCASFYgJO56KrLrrvwhpkAAgKvq/DCABSBQAIySaDAvfhW
bDG+ESgggUwHJ8zwwg5DvGUCB3xsMsMHiMySBAZc7PLLBmzcEskn1wxAyljSbPPOB8SbEssvBw2z
vynpvHPNPVdJQQFHH10ABShpsIDQVF+8gAYoLd30zk9PWQEDWx/NgM8kWfBB1WhX/AHRJH0d9s5j
R7kD2G/DvQNJTjSQ9t4BNP/gBElz1203lEwLzjVJU/O99wIkFW54zQU8qcLjR6sgUguKK96CSJNT
brPlTJrg+dEqd7RC5pnrAJLoo9tcOpKOt26yESAljvreMIAUu+wM067kGby77lEQt6fuEfDBn/x6
kUMkf3LkHXVRvOKMd9S88x9Dj+QA2H88gEcETM83AR5x3/3C3yPJwfkfc8DRBeIrfgFH67O/sPtH
bmD/whtw5EH8fPMAR/S3v3X170hMKOC6IMAREABwby/gSAIVyMAjFUGBAOgARyj2wKo1gCMXVKAG
j4RBAKRPIx1MG/k2UsITFqmEAOBICtPGERgiyYYbmSHaaljC7WHQhRgJnw7/g7ZCjZivgEAcUgcw
OEKN6G2IL/vgRpYoQiRBAIMV1MgLoPiyCG7kihREEgELeECN/I+LFxPgRsa4vzIWqX4FxJ9G4IdG
i81vI3DcnxyNdET2JTGIdcRXETfSx/P9cUhGKCACPAKDQAZAAR5J5P4WmSQvFNAEw3PkCjxiyf1h
UkkIsB8lPaKAOkLyI6Fk3yiTNAL7jQAkJ6jjCUDSSva9kklpOF8JRBKCDEAxBCLJZfd26aRUJm+V
ICmlDk8pEmMGD5lLIgIVeccEIpAEBU/sIAhQQBJpJq+aUWId725JktOlcJYlEafsyAklrY2uayeR
GgBdgLWTuNNz8KRS5x5X/waWYG56W2DJPg3XTywdYXdNG8IRXJIEF6DOBUlwyUEFp1AuAWENWzsA
EGTyhEamzQBPkMlFM7pRMCnhAAzo4wAYwAYl4AQJBviAEAX5AQMgAScnTSn6WOpScvn0p0ANqlCH
StSiGvWoSE2qUpfK1KY69alQjapUp0rVqlr1qljNqla3ytWuevWrYA2rWMdK1rKa9axoTata18rW
trr1rXCNq1znSte62vWueM2rXvfK17769a+ADaxgB0vYwhr2sIhNrGIXy9jGOvaxkI2sZCdL2cpa
9rKYzaxmN8vZznr2s6ANrWhHS9rSmva0qE2talfL2ta69rWwja1sZ0vb2nDa9ra4za1ud8vb3vr2
t8ANrnCHS9ziGve4yE2ucpfL3OY697nQja50p0vd6lr3utjNrna3y93ueve74A2veMdL3vKa97zo
Ta9618ve9rr3vfCNr3znS9/62ve++M2vfvfL3/76978ADrCAB0zgSgUEACH5BAkEAP8ALAAAAAD4
AfgBAAj/AP8JHEiwoMGDCBMqXMiwocOHECNKnEixosWLGDNq3Mixo8ePIEOKHEmypMmTKFOqXMmy
pcuXMGPKnEmzps2bOHPq3Mmzp8+fQIMKHUq0qNGjSJMqXcq0qdOnUKNKnUq1qtWrWLNq3cq1q9ev
YMOKHUu2rNmzaNOqXcu2rdu3cOPKnUu3rt27ePPq3cu3r9+/gAMLHky4sOHDiBMrXsy4sePHkCNL
nky5suXLmDNr3sy5s+fPoEOLHk26tOnTqFOrXs26tevXsGPLnk27tu3buHPr3s27t+/fwIMLH068
uPHjyJMrX868ufPn0KNLn069uvXr2LNr3869u/fv4MOL/x9Pvrz58+jTq1/Pvr379/Djy59Pv779
+/jz69/Pv7///wAGKOCABBZo4IEIJqjgggw26OCDEEYo4YQUVmjhhRhmqOGGHHbo4YcghijiiCSW
aOKJKKao4oostujiizDGKOOMNNZo44045qjjjjz26OOPQAYp5JBEFmnkkUgmqeSSTDbp5JNQRinl
lFRWaeWVWGap5ZZcdunll2CGKeaYZJZp5plopqnmmmy26eabcMYp55x01mnnnXjmqeeefPbp55+A
BirooIQWauihiCaq6KKMNuroo5BGKumklFZq6aWYZqrpppx26umnoIYq6qiklmrqqaimquqqrLbq
6quwxv8q66y01mrrrbjmquuuvPbq66/ABivssMQWa+yxyCar7LLMNuvss9BGK+201FZr7bXYZqvt
ttx26+234IYr7rjjaVHCARCQAAAAJEBwQAkV4ARGCAaAEEEAAUQAggEhWIBTFSysmQAC6q5r8MHr
FoFAAjJJoMC9+EYsMb4RKCCBTIO0mcABCHfs8QEMtySBAROXbLIBF7ckhMYce+xyxwfEm9LIJtd8
sr8pCSHCINiYYE88oDSijTZkFLMDFlAE3CUFBbzsNMIFUICSBgvYbPXEC2iAEhe4GHMOCTS4QQgh
h0hRSC+9CJ3OCFhwWQEDT8dtMAMyk2TBB1fnHfEHOJP/9AcguVxBA8JNiE22FI+gvYoJwWS5A9xy
R87ADiQ50YDemAfQgBMk1VACCUu48bTYh+xyyDDpQIFl05G3XgBJVWeO+QIjcSGKFaK3TroperRd
pQqtBw+ACiK1ILvsLYQUBhlXTCD8wUvoofqUJjwffMgerXD88Tp8VIUeRViPsCnQODAl6+LHbQRI
sW+POQweCaAFA86nv+4EnlRyQ5Rn2B859hsJgvu41xEsWIIH/jMYDXJBhygNIYFxe11HujBA2dFu
I1UAgipyl8AmWEF/UBoABJ82AI8QoIKZIwBHahAPVoxwXW64hO+axIEXPo0DHLkACmV3gY3UwAo2
BAAh/6bAhSdtIIgv2wBHPLDDzHlgI4D4QRCbcAgvPIkJSHQZBDgCgiZi7gUbaVkQp1CGJ4Uvix3r
AEcg5sWrNWAjnsjiIeTwJDR6rIQbaaPeVKiRKWRxCo2oox07xhE96m0jfpziIQLppEESMo+GvBoi
5cjIJonQkevCo0ZOGMma8TEjb6DkkzqAyXWpcSOX66TJ3qiROCLxEOd4EgRKCYAtbuQFqjQZGDUi
RhtKwR5GpKUSN8LEXE7siRqJoiKt6KQalhKHG9GhMSXWQ438MIhDLOKTLjlITW6Ek9P8ZEZqAAop
2jAXjXuSETCJAI/AYJr4UgBHRCANVTRhhE1YwgiU5v8kL2DSBB4RIDxX0JFg5GIJI1wCGcIgJQQM
sp0fUcA05dkRFoyAH+a03w/A8QcMSGkEgxwBSE4wzRN8BArQIEH9xDcBU6BDBFRKAxpLIJIQZECV
IVCeKJqXPiuEYnpUcmgQISoSiUaSoiGx3QBW2roJHEIPDbQSEUj5QiYQgSQoSGUbQYCCzoUCHAiN
XBN4kApoANVK1XuhSEuiPT2atCQ1OMMlrMCKJtzzZTRYwiWAwCWmJTBqU2tfBV2gtZNgARXdsMQb
xOaGuxrsBz84hzEi8SXgpa+MKzFeBbfAkipwIRLFAEUhdiGF0r6BHumgQA1gCqYjoK91QziCS5Lg
gu3/uSAJLknnm4CwhsgdgK8xecI79WaAJ8iEoXFSwgEYwE0ADIABbFACTpBggA+AMwAE+IABkICT
s5Lru+ANr3jHS97ymve86E2vetfL3va6973wja9850vf+tr3vvjNr373y9/++ve/AA6wgAdM4AIb
+MAITrCCF8zgBjv4wRCOsIQnTOEKW/jCGM6whjfM4Q57+MMgDrGIR0ziEpv4xChOsYpXzOIWu/jF
MI6xjGdM4xrb+MY4zrGOd8zjHvv4x0AOspCHTOQiG/nISE6ykpfM5CY7+clQjrKUp0zlKlv5yljO
spa3zOUue/nLYA6zmMdM5jKb+cxoTrOa18zmNrv5Ts1wjrOc50znOtv5znjOs573zOc++/nPgA60
oAdN6EIb+tCITrSiF83oRjv60ZCOtKQnTelKW/rSmM60pjfN6U57+tOgDrWoR03qUlsqIAAh+QQJ
BAD/ACwAAAAA+AH4AQAI/wD/CRxIsKDBgwgTKlzIsKHDhxAjSpxIsaLFixgzatzIsaPHjyBDihxJ
sqTJkyhTqlzJsqXLlzBjypxJs6bNmzhz6tzJs6fPn0CDCh1KtKjRo0iTKl3KtKnTp1CjSp1KtarV
q1izat3KtavXr2DDih1LtqzZs2jTql3Ltq3bt3Djyp1Lt67du3jz6t3Lt6/fv4ADCx5MuLDhw4gT
K17MuLHjx5AjS55MubLly5gza97MubPnz6BDix5NurTp06hTq17NurXr17Bjy55Nu7bt27hz697N
u7fv38CDCx9OvLjx48iTK1/OvLnz59CjS59Ovbr169iza9/Ovbv37+DDi/8fT768+fPo06tfz769
+/fw48ufT7++/fv48+vfz7+///8ABijggAQWaOCBCCao4IIMNujggxBGKOGEFFZo4YUYZqjhhhx2
6OGHIIYo4ogklmjiiSimqOKKLLbo4oswxijjjDTWaOONOOao44489ujjj0AGKeSQRBZp5JFIJqnk
kkw26eSTUEYp5ZRUVmnllVhmqeWWXHbp5ZdghinmmGSWaeaZaKap5ppstunmm3DGKeecdNZp5514
5qnnnnz26eefgAYq6KCEFmrooYgmquiijDbq6KOQRirppJRWaumlmGaq6aacdurpp6CGKuqopJZq
6qmopqrqqqy26uqrsMb/KuustNZq66245qrrrrz26uuvwAYr7LDEFmvsscgmq+yyzDbr7LPQRivt
tNRWa+212Gar7bbcduvtt+CGK+646mlRwgEQkAAAACRAcEAJFeAERggGgBBBAAFEAIIBIVhQagII
qLvuwASvWwQCCcgkgQL34uvww/hGoIAEoCZwQMEYZ3xAwi1JYADEIIdsAMWbWpzxyRrHm5LHIbcs
sr8phfHQDV5SUADKOBdcAAUoabCAy0BDvIAGKHEigAAN3XA0lxUwkPPTAzOgMkkWfBD01Q5/ADNJ
LPwDBURfI43lDk5DbTYDO5DkRANYtx1AA06QJALSWFQxSCTY5L3DIFUk/8RC11febPbgBZD0s9tt
LzASzf8MAoQrlsgzAAADyGMJPMfUkBDjVKow+OcAqCBSC4gj3kJIf0eiTy5vLMGDGwO7wcMSb+Ri
jOYHAS6lCaCDzrFHK5Reug4fdY3LJSTw8EMTGTfxAw805IIFl4L3brYRIB0ufNsweCSACINYYsUP
UP+wxCVcHCQ2lGdY77tHQWw/fEcCDCLKFaBf8cwgB3Hu5BDu+1zhOtIF+SFOcRtJQRiggT/QNUEV
0PhaQdbnpMkF0GwD8AgBDOg2AnRkE4eggfVo8IY/XIkDF/wcBzhyAQ4i7gIbCYYergC73rlBFauQ
2QSftIEUDm4DHPGAC//d5oGN/MEUEwjgBExhwioxwYdmgwBHQDDEtr1gI6RYAvMCuARSWKkIUIRa
BzjSsCoGrQEbkUMSLzgBOVgpjFDL4EbMiDUPaoQYUCTGG+H4NI7QEWsbkQIUpbBHPuLMj38M2kZ2
AcVdWMmChsyYHDWywUS2zI4ZEaQPCVmlDkTyZGPcCNssGTI03jGPVoLAJzMmxY28gJQhu6JG1JjC
Nlqph6ssGBA3IkRYQqyIGsniFt3XRSuhMJcEW+FGWujLh8FQI0dco/V+UEKDUJBJkMzlJDdSyWZi
MiN0CMUVhvm5G65CglUyAjLXhQCPwKCZ+FIAR1LwhynwwHo8mALPCqL/Oyd5YZ0AMAH84BmAFXQE
CuhoIOhUgY6+8VNKCEBmOz+igGbKsyMssJ9CzXYFUUzvoVIaATJHAJITNPMExQvf+MpHA218VEtp
WGUJRBKCDJAyBKjDBRlMwQNWoIwV+RQF/7oUUUNOVCQVTeRFQyKAGrzjEvZkxTCbMAEaTOESXngp
l4jgSTgygQgkQcEozQgCFMjtH7g4RihkQQJCuNUUoFgFN3AHJt7BkaQlCR4dUVqSowUjDLjABjsG
yw5foFNMNvPhznqmPQO6gGgnwcL33uS5AJaBJaQz4BY6dYTqDW4IR3BJElwgPBckwSX9bBMQ1mC2
AwBBJk94J9YM8ASZ/2BATko4AAOyOQAGsEEJOEGCAT7QzQAQ4AMGQAK5lsvc5jr3udCNrnSnS93q
Wve62M2udrfL3e5697vgDa94x0ve8pr3vOhNr3rXy972uve98I2vfOdL3/ra9774za9+98vf/vr3
vwAOsIAHTOACG/jACE6wghfM4AY7+MEQjrCEJ0zhClv4whjOsIY3zOEOe/jDIA6xiEdM4hKb+MQo
TrGKV8ziFrv4xTCOsYxnTOMa2/jGOM6xjnfM4x77+MdADrKQh0zkIhv5yEhOspKXzOQmO/nJUI6y
lKdM5Spb+cpYzrKWt8zlLnv5y2AOs5jHTOYym/nMaE6zmtfM5ja7+TPNcI6znOdM5zrb+c54zrOe
98znPvv5z4AOtKAHTehCG/rQiE60ohfN6EY7+tGQjjSzAgIAIfkECQQA/wAsAAAAAPgB+AEACP8A
/wkcSLCgwYMIEypcyLChw4cQI0qcSLGixYsYM2rcyLGjx48gQ4ocSbKkyZMoU6pcybKly5cwY8qc
SbOmzZs4c+rcybOnz59AgwodSrSo0aNIkypdyrSp06dQo0qdSrWq1atYs2rdyrWr169gw4odS7as
2bNo06pdy7at27dw48qdS7eu3bt48+rdy7ev37+AAwseTLiw4cOIEytezLix48eQI0ueTLmy5cuY
M2vezLmz58+gQ4seTbq06dOoU6tezbq169ewY8ueTbu27du4c+vezbu379/AgwsfTry48ePIkytf
zry58+fQo0ufTr269evYs2vfzr279+/gw4v/H0++vPnz6NOrX8++vfv38OPLn0+/vv37+PPr38+/
v///AAYo4IAEFmjggQgmqOCCDDbo4IMQRijhhBRWaOGFGGao4YYcdujhhyCGKOKIJJZo4okopqji
iiy26OKLMMYo44w01mjjjTjmqOOOPPbo449ABinkkEQWaeSRSCap5JJMNunkk1BGKeWUVFZp5ZVY
Zqnlllx26eWXYIYp5phklmnmmWimqeaabLbp5ptwxinnnHTWaeedeOap55589unnn4AGKuighBZq
6KGIJqrooow26uijkEYq6aSUVmrppZhmqummnHbq6aeghirqqKSWauqpqKaq6qqsturqq7DG/yrr
rLTWauutuOaq66689urrr8AGK+ywxBZr7LHIJqvsssw26+yz0EYr7bTUVmvttdhmq+223Hbr7bfg
hivuuPFpUcIBEJAAAAAkQHBACRXgBEYIBoAQQQABRACCASFYUGoCCKi77sAEr1sEAgnIJIEC9+Lr
8MP4RqCABKAmcEDBGGd8QMItSWAAxCCHbADFm1qc8ckax5uSxyG3LLK/KbHgJgUFoGxzwQVQgJIG
C7jsM8QLaIBSFTKzWQEDNyc9MAMqk2TBBz9H7fAHMJMkcxVs7oC00lwzsANJTjQg9dgBNOAESSII
8M8NaxfE9pg1cy13AST1TPbYC4z0tggC8f9NkN9ve6mC3IQDoIJILdx9dwshsZCCCEUvJIDaXJpQ
eOEce7SC4orr8JHMkTsUeJZxX861ESDZzfnYMHgkQNoCcUGBNP74QwoFwSREOZZnmI65R0Gs3nlH
r6fgSzfPFGLKBBOYUsgz+tSAkN9XDuE74XR31IXwd+e9UQpsbyIHDzTQMMG6E5T/QyORIDQ6lQNc
L/cAHhHAPdkEdCQCBeDwcH7GE7BCKnRmEOpRiQPyIxwHOHKB+93tAhsRACp6sYQm2KwJPJCFEA7y
vihtIIFy2wBHPOBAsnlgI1VoxxWU1gRVtMMBXWICCLkGAY6AoIRje8FGNkEIGljwZk2gwRT/CPg3
KxVhhkrrAEcahsOfNWAjgKgg15qwBEB0CYlKo99Gmii1/GmkEf/jGivkcEUsJo0jXJTaRlJxuVSU
0Yw2Q2Maf7aRR1zuEV2KHxxPpkWN2G+OLfNiRgpxuUJ0qQN7PJkSNyI2QIbsiRpJRhiVNoFkdAkC
icxYDTfyAkeGTIcacYUUWbgEV3Tpg5ksmAg3QkJPQuyEGqnADyZpsx/8YAQG2d2UEJhKgi1wIw10
5cMgqBE6kGGFSruCHqDgJT32so8b+aMwBZkRAfyBBDz44cmaYAUrEJEgKbiSEXq5LgR4BAbCxJcC
OJKCKniDBj+o5Q+8Ec6C6JJKXiAnAEwA/7x0BmAFHbmBCI4BDvNNknk08IQ37imQel4JAb0050cU
IMx1doQFk9tEKB4xAW1O4BGhwCUHtTSCXorUI6OQgStPABK2heEPgFiFTEMBiD9g7SChw1IaMlkC
kYRApYAMgUhyyhCGYgmicJSoSDzggzlaVCQ3IOpIv0QERGKRCUQgCQo+kUYQoMBqbVOIUbdkOSye
dCRgACoOWVqScEr1TDQDYc5QognVcc8FQjuJAds0uOuVgSWJ494WOnWE0sltCEdwSRJcwDkXJAFU
QFgD1w4ABJk8AZ1SM8ATSqWEAzDAmQAYAAPYoAScIMEAH5BmAAjwAQMggVywja1sZ0vb2v/a9ra4
za1ud8vb3vr2t8ANrnCHS9ziGve4yE2ucpfL3OY697nQja50p0vd6lr3utjNrna3y93ueve74A2v
eMdL3vKa97zoTa9618ve9rr3vfCNr3znS9/62ve++M2vfvfL3/76978ADrCAB0zgAhv4wAhOsIIX
zOAGO/jBEI6whCdM4Qpb+MIYzrCGN8zhDnv4wyAOsYhHTOISm/jEKE6xilfM4ha7+MUwjrGMZ0zj
Gtv4xjjOsY53zOMe+/jHQA6ykIdM5CIb+chITrKSl8zkJjv5yVCOspSnTOUqW/nKWM6ylrfM5S57
+ctgDrOYx0zmMpv5zGhOs5rXzOY2u/kOzXCOs5znTOc627lYAQEAIfkECQQA/wAsAAAAAPgB+AEA
CP8A/wkcSLCgwYMIEypcyLChw4cQI0qcSLGixYsYM2rcyLGjx48gQ4ocSbKkyZMoU6pcybKly5cw
Y8qcSbOmzZs4c+rcybOnz59AgwodSrSo0aNIkypdyrSp06dQo0qdSrWq1atYs2rdyrWr169gw4od
S7as2bNo06pdy7at27dw48qdS7eu3bt48+rdy7ev37+AAwseTLiw4cOIEytezLix48eQI0ueTLmy
5cuYM2vezLmz58+gQ4seTbq06dOoU6tezbq169ewY8ueTbu27du4c+vezbu379/AgwsfTry48ePI
kytfzry58+fQo0ufTr269evYs2vfzr279+/gw4v/H0++vPnz6NOrX8++vfv38OPLn0+/vv37+PPr
38+/v///AAYo4IAEFmjggQgmqOCCDDbo4IMQRijhhBRWaOGFGGao4YYcdujhhyCGKOKIJJZo4oko
pqjiiiy26OKLMMYo44w01mjjjTjmqOOOPPbo449ABinkkEQWaeSRSCap5JJMNunkk1BGKeWUVFZp
5ZVYZqnlllx26eWXYIYp5phklmnmmWimqeaabLbp5ptwxinnnHTWaeedeOap55589unnn4AGKuig
hBZq6KGIJqrooow26uijkEYq6aSUVmrppZhmqummnHbq6aeghirqqKSWauqpqKaq6qqsturqq7DG
/yrrrLTWauutuOaq66689urrr8AGK+ywxBZr7LHIJqvsssw26+yz0EYr7bTUVmvttdhmq+223Hbr
7bfghivuuPhpUcIBEJAAAAAkQHBACRXgBEYIBoAQQQABRACCASFYUGoCCKi77sAEr1sEAgnIJIEC
9+Lr8MP4RqCABKAmcEDBGGd8QMItSWAAxCCHbADFm1qc8ckax5uSxyG3LLK/KUHhJgUFoGxzwQVQ
gJIGC7jsM8QLaIASJwLcwGYFDNys9MAMqEySBR/8LLXDH8BMEgv/VMHmDkkv7TUDO5DkRANTlx1A
A06QJIIA/7DNNkFvi1mz13QXQFLPZpe9wEhG//8jgkB/ExR43F2qQPfhAKggUgt5591CSCykIALW
DAkQ+JYmII44xx6t0HjjOnyENeUP9a3l3Jp7bQRIeH9eNgweWf72DYRffhDpV56R+uYeBeE66B1Z
3jbhBLFg+pdD7H643R118Xvee2+UgtG2I1Q9lwMoT/cAHhHwvNkEeHS99V9yoP3hHHB0wfd5X7CR
8FzUA087ztTDBfldbnA+3Rtw5AH7ZvPARljAgkpYwgoTAMAEfmCJTdwOA11iwv68BgGOgACAZXvB
RkRQiQHwIIHrmgAPCFGJg4zPSkWY4NI6wJGGYfBnDdhIDYbBgyYUrAk8GAYWDIK7LKlwadzbyAv/
pxY+jQBiCShbwjvI9MOlcWSIU9vIJX6AMgYysYk3eyIUf7aRQtysEGTKHhZPFkSNeG+LLStiRh5x
s0eQqQNjPBkLN0I2NIYshhppBBVPNoFGkAkCccxYBTfyAjuGTIMagYcVTtaEJTiDTPoLZMH6t5H/
GRJiAtQIKt5QwxuOsAYGOWGVzCdJgqVvI+u75MPcpxEBkGIJexzYD2jgjYMcT0tiLGUZN3JGVaox
IwKoAilSQYMQ/iAVx+ih375khFKuCwEegYEq8aUAjqSABSLYgTNEwU1nRMKEYPKCMwFggt5NMwAr
6MgNMCDKgYggBWFCQCmh+REFqLKaHcGm5Nw0/4JSjgAkJ1DlCT6CAeIVRJlfSkMgSyCSEGTAjiEI
SQoMqiZ5YpGeIrHnFvEp0bU1BKFZIgIcf8gEIpAEBXV8IQhQoLa2LYSimGviP0viuSEOtCRsA6mZ
aLa/nO2sdc9zgdBOwgWYoslwyisDSxj3vC2wRKdnOgLq6DaEI7gkCS74nAuS4JJ2ngkIa/DaAYAg
kydIc2oGeIJMhCAnJRyAAbkEwAAYwAYl4AQJBvhALwNAgA8YAAk4ASW5BkvYwhr2sIhNrGIXy9jG
OvaxkI2sZCdL2cpa9rKYzaxmN8vZznr2s6ANrWhHS9rSmva0qE2talfL2ta69rWwja1sZ0vb2s/a
9ra4za1ud8vb3vr2t8ANrnCHS9ziGve4yE2ucpfL3OY697nQja50p0vd6lr3utjNrna3y93ueve7
4A2veMdL3vKa97zoTa9618ve9rr3vfCNr3znS9/62ve++M2vfvfL3/76978ADrCAB0zgAhv4wAhO
sIIXzOAGO/jBEI6whCdM4Qpb+MIYzrCGN8zhDnv4wyAOsYhHTOISm/jEKE6xilfM4ha7+MUwjrGM
Z0zjGtv4xjjOsY53zOMe+/jHQA6ykIdM5CIb+chITjKhAgIAIfkECQQA/wAsAAAAAPgB+AEACP8A
/wkcSLCgwYMIEypcyLChw4cQI0qcSLGixYsYM2rcyLGjx48gQ4ocSbKkyZMoU6pcybKly5cwY8qc
SbOmzZs4c+rcybOnz59AgwodSrSo0aNIkypdyrSp06dQo0qdSrWq1atYs2rdyrWr169gw4odS7as
2bNo06pdy7at27dw48qdS7eu3bt48+rdy7ev37+AAwseTLiw4cOIEytezLix48eQI0ueTLmy5cuY
M2vezLmz58+gQ4seTbq06dOoU6tezbq169ewY8ueTbu27du4c+vezbu379/AgwsfTry48ePIkytf
zry58+fQo0ufTr269evYs2vfzr279+/gw4v/H0++vPnz6NOrX8++vfv38OPLn0+/vv37+PPr38+/
v///AAYo4IAEFmjggQgmqOCCDDbo4IMQRijhhBRWaOGFGGao4YYcdujhhyCGKOKIJJZo4okopqji
iiy26OKLMMYo44w01mjjjTjmqOOOPPbo449ABinkkEQWaeSRSCap5JJMNunkk1BGKeWUVFZp5ZVY
Zqnlllx26eWXYIYp5phklmnmmWimqeaabLbp5ptwxinnnHTWaeedeOap55589unnn4AGKuighBZq
6KGIJqrooow26uijkEYq6aSUVmrppZhmqummnHbq6aeghirqqKSWauqpqKaq6qqsturqq7DG/yrr
rLTWauutuOaq66689urrr8AGK+ywxBZr7LHIJqvsssw26+yz0EYr7bTUVmvttdhmq+223Hbr7bfg
hivuuP9pUcIBEJAAAAAkQHBACRXgBEYIBoAQQQABRACCASFYUGoCCKi77sAEr1sEAgnIJIEC9+Lr
8MP4RqCABDKF0WYCBxSs8cYHJNySBAZALPLIBlDckgAXZ7zxyhofEG9KII8sM8n+piTEPyywSUEB
LPdccAEUoKTBAjMXDfECGqDEhQgisFkBAz5HPTADL5NkwQdGZ+3wBzWTdMM/ULC5A9RSl83ADiQ5
0YDWbAfQgBMkiYDy3AWhPCbPZeddAElEt//N9gIjff1P04MXRHiYKuStOAAqiNSC3363EBILKYiQ
85omLL64xx6tADnkOnyU8+Vs4q152UaA1PfnbMPgkQByC3SD3QIdLuYZp2/uURCsg94R7P8IQHtB
LAgO5hC5K753R1307jfgG6Xwte0IUc/lAMnnPYBHBDjfNgEtWZ8lB9krzgFHF3jv9wWMblB+3htw
5IH6bXvAKBPvlw0BRyDQz/YLG6kCBbxBAeMdSR3KMMQiStGRIuRPah3gSMP8Z7QGaAQLoZgAACaw
Ck4YxIA9QgEBehCADMhgFBx5oNS2txEKag18GYHHEjQ4gSsAAknKICG+qECNFKowahxxodb/NNIL
DQ7sEUhCBMTa4MMf9iyIQjSaRh5RMCQeiRo5cFgG+sAR7DlxZSzUSPeiKDMYYqQdSxjYElyBJGHk
gAolzMI0ONKBL64sghtZGxlHZsGMYIEMNNggGTyIJF2YgRJfoEUKOAIBO25sfxt5wR5HBkCNQKES
+6hHFZ7kAI64z5EFi99G5jdJiNnPJMMbUic3Qj5QEux8G0lfKR/Gvo1gAE5edGUYNzLGWZoxI4uE
kxFcuS4EeAQGs8SXAjoCQoWIL0teICYATLC7ZAZgBSwRQTC/hABXGvMjCpjlMjvCAm0+00wjcOUI
QHKCWZ7gIxhIJfHKlAZHlkAkIcjAHkMQ/5IUyFNN3XTiN0USziiOs5+xaxMR6qhCJhCBJCjQIwVB
gIK4Bc9NmVPhOkviORe+syQoI92advY+oAltdc5zQdJOwoV/pilxySsDSx7nvC2wRKRsOoLp8jaE
I7gkCS74nAuS4JJzogkIayjbAYAgkycgU2sGeIJMsCAnJRyAAbkEwAAYwAYl4AQJBvhALwNAgA8Y
AAk4wSm51srWtrr1rXCNq1znSte62vWueM2rXvfK17769a+ADaxgB0vYwhr2sIhNrGIXy9jGOvax
kI2sZCdL2cpa9rKYzaxmN8vZznr2s6ANrWhHS9rSmva0qE2talfL2ta69rWwja1sZ0vb2q7a9ra4
za1ud8vb3vr2t8ANrnCHS9ziGve4yE2ucpfL3OY697nQja50p0vd6lr3utjNrna3y93ueve74A2v
eMdL3vKa97zoTa9618ve9rr3vfCNr3znS9/62ve++M2vfvfL3/76978ADrCAB0zgAhv4wAhOsIIX
zOAGO/jBEI6whCdM4Qpb+MIYzrCGN8zhDnv4wyAOsYhHTOISm/jEKE6xilfM4ha7+MUwjnGeAgIA
IfkECQQA/wAsAAAAAPgB+AEACP8A/wkcSLCgwYMIEypcyLChw4cQI0qcSLGixYsYM2rcyLGjx48g
Q4ocSbKkyZMoU6pcybKly5cwY8qcSbOmzZs4c+rcybOnz59AgwodSrSo0aNIkypdyrSp06dQo0qd
SrWq1atYs2rdyrWr169gw4odS7as2bNo06pdy7at27dw48qdS7eu3bt48+rdy7ev37+AAwseTLiw
4cOIEytezLix48eQI0ueTLmy5cuYM2vezLmz58+gQ4seTbq06dOoU6tezbq169ewY8ueTbu27du4
c+vezbu379/AgwsfTry48ePIkytfzry58+fQo0ufTr269evYs2vfzr279+/gw4v/H0++vPnz6NOr
X8++vfv38OPLn0+/vv37+PPr38+/v///AAYo4IAEFmjggQgmqOCCDDbo4IMQRijhhBRWaOGFGGao
4YYcdujhhyCGKOKIJJZo4okopqjiiiy26OKLMMYo44w01mjjjTjmqOOOPPbo449ABinkkEQWaeSR
SCap5JJMNunkk1BGKeWUVFZp5ZVYZqnlllx26eWXYIYp5phklmnmmWimqeaabLbp5ptwxinnnHTW
aeedeOap55589unnn4AGKuighBZq6KGIJqrooow26uijkEYq6aSUVmrppZhmqummnHbq6aeghirq
qKSWauqpqKaq6qqsturqq7DG/yrrrLTWauutuOaq66689urrr8AGK+ywxBZr7LHIJqvsssw26+yz
0EYr7bTUVmvttdhmq+223Hbr7bfghivuuAZqUcIBEJAAAAAkQHBACRXgBEYIBoAQQQABRACCASFY
UGoCCKi77sAEr1sEAgnIJIEC9+Lr8MP4RqCABKAmcEDBGGd8QMItSWAAxCCHbADFm1qc8ckax5uS
xyG3LLK/mFJQAMo0F1wABShpsIDLPEO8gAaXVsBAzUQPzIDKJFnwQc9MO/wBzJPuMHTRVDOwA0lO
NND01gE04ASlM1MtdgEk7cz11gtMqoLYbAOggkgtnH12C3AKAJIJbbfNsUcryP8ttw6Qhp031UaA
ZLbfW8MA5xgenTG43h4Fgfjfjg7xONtkd9TF5GenXSYejFADBxzUMIIHSANcLvYAHhHAOdcEkImB
NQTkkAO+thPQTAp2cMSB6mxzwNEFr599wZg64JAByBngYAvjG20AvNgbcORB8Vx7IOYdBFDRMhU+
oMERE9NTDQFHIGC/9QtiMoIDzz3AwlER5RfdAUcNq99zA2KasbzLGTADR+pXNNZtRH9Ni12Y2sC0
NgyQgETjCAKbJiYG9syBG4FgBA84wZ71738tCyBHUqfBkxlQI67rYMsUCCZG9IBnOJDfRjpQwpPd
byNaU2HI+BemWfiADy3jgxr/xLcRCNQwY+fbyAt0GDL2ickWPQChwzLQg2tATyPSO2LBqreR6zER
YtoTUwqsEYEc/C8DOdBdCoiokd9pkWDC2wjxvviw45FJA534AiUo8YVObAN1b1zXCTeSQjqycFFG
CCQAEOARGNARXwp4k9064gVFmiByjwzACh6FgDcy8iMKoGMkHzWCN44AJCeg4wkilYYjlkAkIZAi
AkMwqU5q8JMiCWUHRykpItCQgEwgAklQkEP9gQAFlcIbAU9Zkr4hcJWWktn0bpazw3HOBUDL1Nou
VwaWxI1zW+jUEQQntiEcwSVJcIHfXJAEUAFhDVQ7ABBk8gRHNs0ATyiVEg7A/wASCpIBbFACTpBg
gA8UMgAE+IABkECuhjr0oRCNqEQnStGKWvSiGM2oRjfK0Y569KMgDalIR0rSkpr0pChNqUpXytKW
uvSlMI2pTGdK05ra9KY4zalOd8rTnvr0p0ANqlCHStSiGvWoSE2qUpfK1KY69alQjapUp0rVqlr1
qljNqla3ytWuevWrYA2rWMdK1rKa9axoTata18rWtrr1rXCNq1znSte62vWueM2rXvfK17769a+A
DaxgB0vYwhr2sIhNrGIXy9jGOvaxkI2sZCdL2cpa9rKYzaxmN8vZznr2s6ANrWhHS9rSmva0qE2t
alfL2ta69rWwja1sZ0vb2i3a9ra4za1ud8vb3vr2t8ANrnCHS9ziGve4yE2ucpfL3OY697nQja50
pwurgAAAIfkECQQA/wAsAAAAAPgB+AEACP8A/wkcSLCgwYMIEypcyLChw4cQI0qcSLGixYsYM2rc
yLGjx48gQ4ocSbKkyZMoU6pcybKly5cwY8qcSbOmzZs4c+rcybOnz59AgwodSrSo0aNIkypdyrSp
06dQo0qdSrWq1atYs2rdyrWr169gw4odS7as2bNo06pdy7at27dw48qdS7eu3bt48+rdy7ev37+A
AwseTLiw4cOIEytezLix48eQI0ueTLmy5cuYM2vezLmz58+gQ4seTbq06dOoU6tezbq169ewY8ue
Tbu27du4c+vezbu379/AgwsfTry48ePIkytfzry58+fQo0ufTr269evYs2vfzr279+/gw4v/H0++
vPnz6NOrX8++vfv38OPLn0+/vv37+PPr38+/v///AAYo4IAEFmjggQgmqOCCDDbo4IMQRijhhBRW
aOGFGGao4YYcdujhhyCGKOKIJJZo4okopqjiiiy26OKLMMYo44w01mjjjTjmqOOOPPbo449ABink
kEQWaeSRSCap5JJMNunkk1BGKeWUVFZp5ZVYZqnlllx26eWXYIYp5phklmnmmWimqeaabLbp5ptw
xinnnHTWaeedeOap55589unnn4AGKuighBZq6KGIJqrooow26uijkEYq6aSUVmrppZhmqummnHbq
6aeghirqqKSWauqpqKaq6qqsturqq7DG/yrrrLTWauutuOaq66689urrr8AGK+ywxBZr7LHIJqvs
ssw26+yz0EYr7bTUVmvttdhmq+223Hbr7bfghivuuA1qUcIBEJAAAAAkQHBACRXgBEYIBoAQQQAB
RACCASFYICcGMiWAgLrrFmzwukUgkIBMEihwL74QR4xvBApIECfALSVwwMEcd3zAwi1JYIDEJJds
gMWbatzxyh7Hm5LIJcdssr+YUlAAyzgfXAAFKGmwgMxAS7yABpdWwEDOSBfMgMskWfBB0FBD/AHN
bqZA0g5HJ601AzuQ5EQDUYcdQANOUHqz1mgXQNLPYoe9AJwOiKQC2nQDoIJILbTddgtw2v8Akgl1
1w2yRyvorbcOYkahAyNmwEEJHGYwokMUI50duNZGgMS24WHDAKYAOjjiAw5UQJxBDz44IoEA/8zh
0RmXC+5REJwf7qUArQjSQwwlx9CDIK2w7tEQsdOtdkdd1N72211ak0UGQWeQhTUfDVA82gN4RIDy
YhPQ5TeQQA91BpB84wdHHFxPNwccXcB92xdsOYYjOYjdgyOUb7SB+mhvwJEH7xObB7a0AgLwLmwx
yAAYOMIE/mkNAhwBQQDD9oItMQIHesMBIzhSBAcmrQMcedgEg9aALQXigGKLQSA44sGkZW8jI4ya
97QEB87BgYUtRBpHYhi1LVGCc5TAYQ7/cbZDHgZtSzU03A03Yr0hruyFGtmeEWM2wyydUG8q5EgH
nLgyEG4EbFMsWQm1dMEMbnAjEOBixyC4kReEsWQV1NI1DJhCNWCCI/tT48H8txEAvlFiA9TSGBaB
wbDhQBmzQJ8eD8a+jbjvjxGL35Y0UAfxRU8QKPBIExcJxY1IEZJV3FIznhc9HEzjI0ZY5LoQ4BEY
QBJfCrhd7nKAwojFIAeCaMYD8scRL6gSACaY3SsDsAIwScARMqAlxG4pA0dcQyQIWCQrP6IASMYy
TFGoRTUCQYluBqIatcjfGEAygkWOACQngOQJ3DQDkaRBjSUQSQgsycMQTCqaQ5ymSKpp/8Rrtslq
IyHCFlvIBCKQBAVgHCEIMkkpwLXwnCUpXAzXaSmb8W9nPduc8lxAtEzNrXhlYEnelLeFTh3Bcmgb
whFckgQXGM4FSQAVENagtQMAQSZPcGXUDPCEUinhAAzYJAAGwAA2KAEnSDDABz4ZAAJ8wABIIJdU
p0rVqlr1qljNqla3ytWuevWrYA2rWMdK1rKa9axoTata18rWtrr1rXCNq1znSte62vWueM2rXvfK
17769a+ADaxgB0vYwhr2sIhNrGIXy9jGOvaxkI2sZCdL2cpa9rKYzaxmN8vZznr2s6ANrWhHS9rS
mva0qE2talfL2ta69rWwja1sZ0vb2oza9ra4za1ud8vb3vr2t8ANrnCHS9ziGve4yE2ucpfL3OY6
97nQja50p0vd6lr3utjNrna3y93ueve74A2veMdL3vKa97zoTa9618ve9rr3vfCNr3znS9/62ve+
+M2vfvfL3/76978ADrCAB0zgAhv4wAhOsIIXzOAGO/jBEI6whCdM4Qpb+MIYTlVAAAAh+QQJBAD/
ACwAAAAA+AH4AQAI/wD/CRxIsKDBgwgTKlzIsKHDhxAjSpxIsaLFixgzatzIsaPHjyBDihxJsqTJ
kyhTqlzJsqXLlzBjypxJs6bNmzhz6tzJs6fPn0CDCh1KtKjRo0iTKl3KtKnTp1CjSp1KtarVq1iz
at3KtavXr2DDih1LtqzZs2jTql3Ltq3bt3Djyp1Lt67du3jz6t3Lt6/fv4ADCx5MuLDhw4gTK17M
uLHjx5AjS55MubLly5gza97MubPnz6BDix5NurTp06hTq17NurXr17Bjy55Nu7bt27hz697Nu7fv
38CDCx9OvLjx48iTK1/OvLnz59CjS59Ovbr169iza9/Ovbv37+DDi/8fT768+fPo06tfz769+/fw
48ufT7++/fv48+vfz7+///8ABijggAQWaOCBCCao4IIMNujggxBGKOGEFFZo4YUYZqjhhhx26OGH
IIYo4ogklmjiiSimqOKKLLbo4oswxijjjDTWaOONOOao44489ujjj0AGKeSQRBZp5JFIJqnkkkw2
6eSTUEYp5ZRUVmnllVhmqeWWXHbp5ZdghinmmGSWaeaZaKap5ppstunmm3DGKeecdNZp55145qnn
nnz26eefgAYq6KCEFmrooYgmquiijDbq6KOQRirppJRWaumlmGaq6aacdurpp6CGKuqopJZq6qmo
pqrqqqy26uqrsMb/KuustNZq66245qrrrrz26uuvwAYr7LDEFmvsscgmq+yyzDbr7LPQRivttNRW
a+212Gar7bbcduvtt+CGK+640EURhVBalHAABCQAAAAJEBxQQgU4gRGCASBEEEAAEYBgQAgWhDnD
UAkg0K67CCfsbhEIJCCTBArou+/EFO8bgQIScDmwUAkcoPDHIB/gcEsSGFDxySgbkPGVDxDsMcgw
f3wAvSmVjPLNKQdMJQZDUVBAzEArXAAFKGmwAM5IV7yABpdWwEDQUCPMAM0kWfBB0lhP/IHOX6Jg
SzYKJEJJH2YwMw0K5560w9NRt83ADiQ50UDWdAfQgBNeosCIGYLg/xBDDAH4IIMiipiRyTV2mPRz
24wXQNLRddO9AJc3RAMHAT3cPLgifSzizgMbg6QC46QDoIJILUQeeQtaOkCLIFQAjrTgirShiwhz
gGRC6aWP7NEKqquuA5YYtAJJBqpn0QzPHy3Oe9tGgAR58HTDgKUOgiCvegaC6OCHR2c837tHQVAv
vJVzfJGD+Xx8kXtHQ4hPuuMddWF+5JNTKcArWdwfQBYXEEBHBiA/xg3AIwTwH90IUCU8qM9/OfgC
JjjCgQKSjgMcuYAC63YBKo2ifwrMwgQ3sgELMm4DHPHABunmASplInMK7EEmOMIEE7YNAhwBwQqz
9gIqIWOHeeBIEf9sGLUOcERiO0RaA6ikiBXGIBEcIWLUDriRJGKNgVNqww7bEEUpQo0jVsQalbS4
Qi5uxItfrGIYkUalRMhOgVDcCAHRCDMqaiSBa0QZFqWUByBypAN0hJkRNzK3PJ5siVN64QZ7wAuO
QCCQIMPhRl5gyJP1cEofVKAPsnACjpQQkgpD4UZUWEmKtXBKKPgCH/zXAzOUgoKgVBgGN6LBUk6s
g1MSwAX8Zr57ZIEWLePIHGNpx43gsZR7nNIezLBK6rVyDx4xQizdhQCPwMCWAVDAlTCBiDfWjQqC
sMAsPOKFaQLABOTD5gqulAJbaK9uGYBEMwT4EQTEspofUUAptYn/JQHUwo10A2crHIAGkIwgliMA
yQlK2cksCeAJzIThzXrQviAIIHEhSQMkSyCSELzTiiHoEgpOYYZ7ZEAGMvCBD/bFh/adAgX/CKZI
7IlGfIpEn2HkZ95skYkv+LQPhjBEOM42EBuQhAiAlCITiEASFBRyhyCAaZuMWpLdSTGhJQFeEhvK
JnqaxGcmHFrRpnc/FzAtU6OTXxlYkrr7baFTR3Ae44ZwBJckwQXBc0ESQAWENbTtAECQyROumTUD
PKFUSjgAA4YJgAEwgA1KwAkSDPCBYwaAAB8wABLIxdnOevazoA2taEdL2tKa9rSoTa1qV8va1rr2
tbCNrWxnS9va8dr2trjNrW53y9ve+va3wA2ucIdL3OIa97jITa5yl8vc5jr3udCNrnSnS93qWve6
2M2udrfL3e5697vgDa94x0ve8pr3vOhNr3rXy972uve98I2vfOdL3/ra9774za9+98vf/vr3vwAO
sIAHTOACG/jACE6wghfM4AY7+MEQjrCEJ0zhClv4whjOsIY3zOEOe/jDIA6xiEdM4hKb+MQoTrGK
V8ziFrv4xTCOsYxnTOMa2/jGOM6xjnfM4x77+MdADrKQh0zkIhv5yEhOspKXzOQmO/nJUI6ylKdM
5Spb+cpYzrKWt8zlLnv5y2AOs5hFFRAAIfkECQQA/wAsAAAAAPgB+AEACP8A/wkcSLCgwYMIEypc
yLChw4cQI0qcSLGixYsYM2rcyLGjx48gQ4ocSbKkyZMoU6pcybKly5cwY8qcSbOmzZs4c+rcybOn
z59AgwodSrSo0aNIkypdyrSp06dQo0qdSrWq1atYs2rdyrWr169gw4odS7as2bNo06pdy7at27dw
48qdS7eu3bt48+rdy7ev37+AAwseTLiw4cOIEytezLix48eQI0ueTLmy5cuYM2vezLmz58+gQ4se
Tbq06dOoU6tezbq169ewY8ueTbu27du4c+vezbu379/AgwsfTry48ePIkytfzry58+fQo0ufTr26
9evYs2vfzr279+/gw4v/H0++vPnz6NOrX8++vfv38OPLn0+/vv37+PPr38+/v///AAYo4IAEFmjg
gQgmqOCCDDbo4IMQRijhhBRWaOGFGGao4YYcdujhhyCGKOKIJJZo4okopqjiiiy26OKLMMYo44w0
1mjjjTjmqOOOPPbo449ABinkkEQWaeSRSCap5JJMNunkk1BGKeWUVFZp5ZVYZqnlllx26eWXYIYp
5phklmnmmWimqeaabLbp5ptwxinnnHTWaeedeOap55589unnn4AGKuighBZq6KGIJqrooow26uij
kEYq6aSUVmrppZhmqummnHbq6aeghirqqKSWauqpqKaq6qqsturqq7DG/yrrrLTWauutuOaq6668
9urrr8AGK+ywxBZr7LHIJqvsssw26+yz0EYr7bTUVmvttdhmq+223Hbr7bfghivuuNc5MANCM5x7
kxYlHAABCQAAQAIEB5RQAU5ghGAACBEEEEAEIBgQggVhCvDQAzbIlAAC8Mbr8MPxFoFAAjJJoEC/
/massb8RKCBBnHu4lMABEJds8gEUtySBARu37LIBH38ZxR0njGNAHogogkgeCoxTSj5RCGSHSiOb
bPTJ96a0sstMv0zwlnZYMMkvPVARw8YxUIHDB+NYEHLIJ1FQwNFkQ1wABShpsEDTbG+8gAZZ2qDD
Kb9kcXXbWfxyigRxoP9hUgUMlC24wwwkTZIFH7SteMYfPF3lDBfkIcbiGouRRxIODD3SDoEP7jkD
O5DkRAOUU96AE1WOQYsgVJSuMRW/0BIHSWN7bnsBJK3t+uILUPkALbHksLvGfEBCi+YgqWD78gCo
IFILw1PewpRBIMJH9Brn8EkQfoBkAvPMp+zRCthTrkOUKOQhfPkZ55AHHil8VDv4nhsBku7ssw0D
lGOcMnn+GRNDJ1DgkTPQL3weCQIAFXe+JrFAHbGQwQL9JYNY4AEDHRnCAZeHu450YYJs612T9rCM
HoDQXzggR9A4MoAN2m4AHiHACZlGACdFoQ4zDIAM6rANjnDAhcvjAEf/LpBDpl2gSScwYQ57gAmO
bACIttsARzxQRJd5oElbuNsMY7AFjjABip6DAEdAUMWWvaBJyCgjMjhSBDAOrgMcwVgZM9aAJiGi
ijHIA0fcODgYbmSOG6shkxRRRkTskY+C4wggN9YkQlZREYdEJNkUuciM2bGQLJTk0fyoERlWUpBL
ygMe9biRDmjSaHDcCOkqWUcmpbGKa9wIBE5pMjFu5AWVDMAZmZTFIsZgeht5Ii0hJsWNULGSV2RS
EovYA1v4cJgQE+JGiFjJIzLphkUUxAo30kJoAoCTG/HkHEG5pCiQAwczxMEk6NARI3gTAAjwCAwW
qQAnYQAPODxhLEaB/0GOeOGdJkjgIlfwJAe84n8LFAMsxvARBEAznh9RwBzrCaVZfKF1AMRBIL4B
khFAcwQgOcEcTyAlCwQiA/nrQR6CIJI00LIEIgkBSnMYgilhoBb3WF/0ikeLB4zEoZKEqEgkOkOK
TskBrYCDTl2XA0TQwm8jIYIp+cgEIpAEBaucIAgIWCUBNCMQWdhdFgIRhDuY5Ht8BGlJyAdCkmLJ
HFNDp+J6EIFJmGMOKBEbFM+WNvyVzwVw05IdMDGJCFjNZTKQASJOMQqoqkR5GywDS6BXvi56yQaz
aIUHEJEzRbShDXDYwjTmEAfkreQI87PdEI7gkiS4YHguSEKbEhYTIP+swXMHAIJMnjDPxRngCWyi
rU2UcAAGdDNeA2AAG5SAEyQY4APiDAABPmAAJJDrutjNrna3y93ueve74A2veMdL3vKa97zoTa96
18ve9rr3vfCNr3znS9/62ve++M2vfvfL3/76978ADrCAB0zgAhv4wAhOsIIXzOAGO/jBEI6whCdM
4Qpb+MIYzrCGN8zhDnv4wyAOsYhHTOISm/jEKE6xilfM4ha7+MUwjrGMZ0zjGtv4xjjOsY53zOMe
+/jHQA6ykIdM5CIb+chITrKSl8zkJjv5yVCOspSnTOUqW/nKWM6ylrfM5S57+ctgDrOYx0zmMpv5
zGhOs5rXzOY2u/lDzXCOs5znTOc62/nOeM6znvfM5z77+c+ADrSgB03oQhv60IhOtKIXzehGO/rR
kI60pCdN6Upb+tKYzrSmN83pTm8qIAAh+QQJBAD/ACwAAAAA+AH4AQAI/wD/CRxIsKDBgwgTKlzI
sKHDhxAjSpxIsaLFixgzatzIsaPHjyBDihxJsqTJkyhTqlzJsqXLlzBjypxJs6bNmzhz6tzJs6fP
n0CDCh1KtKjRo0iTKl3KtKnTp1CjSp1KtarVq1izat3KtavXr2DDih1LtqzZs2jTql3Ltq3bt3Dj
yp1Lt67du3jz6t3Lt6/fv4ADCx5MuLDhw4gTK17MuLHjx5AjS55MubLly5gza97MubPnz6BDix5N
urTp06hTq17NurXr17Bjy55Nu7bt27hz697Nu7fv38CDCx9OvLjx48iTK1/OvLnz59CjS59Ovbr1
69iza9/Ovbv37+DDi/8fT768+fPo06tfz769+/fw48ufT7++/fv48+vfz7+///8ABijggAQWaOCB
CCao4IIMNujggxBGKOGEFFZo4YUYZqjhhhx26OGHIIYo4ogklmjiiSimqOKKLLbo4oswxijjjDTW
aOONOOao44489ujjj0AGKeSQRBZp5JFIJqnkkkw26eSTUEYp5ZRUVmnllVhmqeWWXHbp5Zdghinm
mGSWaeaZaKap5ppstunmm3DGKeecdNZp55145qnnnnz26eefgAYq6KCEFmrooYgmquiijDbq6KOQ
RirppJRWaumlmGaq6aacdurpp6CGKuqopJZq6qmopqrqqqy26uqrsMb/KuustNZq66245qrrrrz2
6uuvwAYr7LDEFmvsscgmq+yyzDbr7LPQRivttNRWa+212Gar7bbcduvtt+CGK+643oERggEgRBBA
ABGAYEAIFuCkRQkHQEACAACQAMEBJVSAkx9zIDSHH2FKoIC66yas8LoRKCCBTAkgcC++FFeMbxEI
JBATBijsIUw0CnTxiRpqfIKMB9GoE8UYMxC8pQQGLCzzzAY83FICB1is884HaMzSA3iUMs4vfATg
w8IxxJABHx+MU0oUD7hsJcwzV01zvCnhvPPWPPuLUgrbjNJJHTlYvXAPdXRizh7/SC2lBguYLXfC
C2iAEgUFcK23xQVQ/4CSH+PUgcPcMuNQBxJR/IPGlBZ8QDjhH2BNUgUM7G05xQx4TRIK2cSSweMy
xxALLGy73aQTDYBOeANOkLRD5ZfHzsAOI6UwyyTAyKA60jIAM0kUKSz+ZNy7z70ASXnHrnwBI83w
Su7Fy9z7KzNA2UL0hLcgkgrKdw+ACiLBEovu2C8sQyzR/GNHkyuUT7gOIJngvfc+d/SAMImU7f7C
OSRyR9RMIt7+rAYDkCRvfrEzwkcwwIvBDVBhMcABLx7ApCA8UG7w68gZEEi/jmDAHLG4oMxicQcM
mK5IXRCh1Y7XkSFwsHvM44gNmKE/Fa4rB+SIw5IIYMOZEcAjA3ih8v8G4JE6HK2H66rDki6AxJld
gCMcEGL3OMCRE9QQiTlQxz9ugSQPNFFmHuDIBqSovA1wJBwx+GIAYhCOf9gASSBQo8JewBEmkDF2
EOAIMuQYgC4oCWF8bABHinDHy3WAI5/g4yeUxMeE/XAjhbwcETeiBj6qgZGNXBdHInk5jlRSjpdM
UiY1CUlO7s2TllQSDxv5SI0E0ZRbm6RGPqnGUCIpdY0U5EY6AMutHXIjiZTjIpP0gkzScSMQ6OXO
8riRFMrRj0nyYiPDuJExKtNiZtwIGtXIRjciiYmNfOJGonjNilFxI5i4Yg+zuMUkrVKNrdzIK8sp
y438Qo2/YBIM+Kj/AI8YoZz4QkBHbBAOKjQxB7wYAwWTZEE5rsAjXgAoAEzgQXPUoYl1yIcJl6QA
NfbzIwgop0A9ggEkiOGIF/SBGE5xg4Uq6QRqPAFIRlDOESwQD4FQ5/5yEIg5YOAOTQrB53oYApGk
QZklEEk06pDGB8agDkn4R8Cc1FEbflQkITXlSEMygwtAb3+9u4ADXNokFODygiBAAUmIwMtIMoEI
tUNDJr6Kvd51wgYpmOqT2idCmZZEfpG0aUnm8Yo6GDR6VKjDKxIH1LcJsHwusNtJ8EbGvv0tGoHA
QVNBh4NARCNxwqPS9cq3BZZw74VlWMkYMEEOsj0uB3UgRynGsD4s/yXBBcVzQVRbcoQDKm8IR2jJ
A8LGC0FkYLNIy4AgeDGKPTyAi1t6wj4JZ4AnyAQIa4jdAYAQEwGgIAq6aAEIfEAykn2iAVvQxcqe
GyYkGOAD7wwAAT5gACTgRAkHYMA8ATAABrBBCf9SyAnJReACG/jACE6wghfM4AY7+MEQjrCEJ0zh
Clv4whjOsIY3zOEOe/jDIA6xiEdM4hKb+MQoTrGKV8ziFrv4xTCOsYxnTOMa2/jGOM6xjnfM4x77
+MdADrKQh0zkIhv5yEhOspKXzOQmO/nJUI6ylKdM5Spb+cpYzrKWt8zlLnv5y2AOs5jHTOYym/nM
aE6zmtfM5ja7+YHNcI6znOdM5zrb+c54zrOe98znPvv5z4AOtKAHTehCG/rQiE60ohfN6EY7+tGQ
jrSkJ03pSlv60pjOtKY3zelOe/rToA61qEdN6lKb+tSoTrWqV83qVrv61bCOtaxnTeta2/rWuM61
rnfN6177+tfADrawh03sYhv72MhOtrIdFRAAIfkECQQA/wAsAAAAAPgB+AEACP8A/wkcSLCgwYMI
EypcyLChw4cQI0qcSLGixYsYM2rcyLGjx48gQ4ocSbKkyZMoU6pcybKly5cwY8qcSbOmzZs4c+rc
ybOnz59AgwodSrSo0aNIkypdyrSp06dQo0qdSrWq1atYs2rdyrWr169gw4odS7as2bNo06pdy7at
27dw48qdS7eu3bt48+rdy7ev37+AAwseTLiw4cOIEytezLix48eQI0ueTLmy5cuYM2vezLmz58+g
Q4seTbq06dOoU6tezbq169ewY8ueTbu27du4c+vezbu379/AgwsfTry48ePIkytfzry58+fQo0uf
Tr269evYs2vfzr279+/gw4v/H0++vPnz6NOrX8++vfv38OPLn0+/vv37+PPr38+/v///AAYo4IAE
FmjggQgmqOCCDDbo4IMQRijhhBRWaOGFGGao4YYcdujhhyCGKOKIJJZo4okopqjiiiy26OKLMMYo
44w01mjjjTjmqOOOPPbo449ABinkkEQWaeSRSCap5JJMNunkk1BGKeWUVFZp5ZVYZqnlllx26eWX
YIYp5phklmnmmWimqeaabLbp5ptwxinnnHTWaeedeOap55589unnn4AGKuighBZq6KGIJqrooow2
6uijkEYq6aSUVmrppZhmqummnHbq6aeghirqqKSWauqpqKaq6qqsturqq7DG/yrrrLTWauutuOaq
66689urrr8AGK+ywxBZr7LHIJqvsssw26+yz0EYr7bTUVmvttdhmq+223Hbr7bfghivuuOWBEYIB
IEQQQAARgGBACBbgpEUJB0BAAgAAkADBASVUgNMDbEqggLrrFmzwuhEoIIFMCSBwL74QR4xvEQgk
IBPAD2CgpgQGHOzxxwYs3FICB0hs8skHWMzSAwI4YMOaHH8sM8jxpkTyyTij7C9KKQgggB1sarDA
zEQfvIAGKFFQQM5MS1wABSjZ8DLANuChyTxYo3BHFHYA7KUFHxQtdsEf1ExSBQw0rTbEDOxMEss2
7LECLacEAgIBMviQhxmwWP+zzRhdOtHA2IQH0IATJO2Q9tqMM7DDSCnYIMAs7mQCBw5ZUHFwDDmo
YcYpwgCu5dCFE74ASUszrnoBI2Ewwy3MKIJDDz7MLEMOMhjCC9BYtlB66S2IpILqxAOggkgC4MEM
JJqP7UMMgiyDxpUr/P67DiCZUHzxKnfE8h3USCLD72pI4sgcVpJuPeEwgJT69owbAZIdi4gxvvUy
QLII7wR5vWQQ67ueR84AP+51BANRYMQ67rc+GazjFTeYUhcCWLrTdWQIBSQe6zjyADzAoQcUXFcP
2nCLKREghIQjgEcGkEHVDaAjaJiE+FAoA0lkIwpRugAKC3cBjnCghcTjAEf/0BCBGOwwABn4xPSg
5IEjjs0DHNkAEFW3AY64Qwy126EPxFCKKIHAiWJ7AUeYMEXGQYAji2jeEamwiCgRDIwzawBHilDG
tXWAI8iAYwDyECU9Ek2FG6nj2l64kTboMRF99OPMOCLItXHEkHBsQyIV+TFGNrJpj9SjJKF0Qkoe
DJAaYeElcUZIjSTikFEanCcNJseNdGCUOLvjRvIAR71F6QWrNJgYNwIBWJ7sjBtJIxjZGKUm5nJd
UNyIFH0psSpuRAdZyCIKfQAJTOTwmOvq4UZ+yMyICXEjd0BEBo6YgTzcQUqdXCUoNyLKbpZSI3OQ
oTQD6ANJvAKHUYLBMRXg/xEjdBNfCPAeCgyBg3n+zgc4gMM5pQTAXK7AI174JwBMcMAxvGIdRgxg
DNYRBP9FSQGr5OdHENDNgH5kDstjYOnyxwx8TukEqzwBSEbQzRF8BANxyEf41PC7Gp7vSiEYpx9D
IJI0+LIEInGABpbBvMJRARLVQB+WQKpHkYqEpJc0aUhwigdeXI52M/NBD3CguyViCQWqdCIIUEAS
IrxSkEwgAuRetgcwVAMOfOhBRgsWgx70wBDVwMQcouBRK1UPjDItifYEaVOT2GAGdrhGNhbxiXQS
4BOLyMYoXhYH0WlJaDt0AdJOorQpPg0lDiCIH76hAU24trVocCmYfEfBLf+wZHgZLANLZrCHNyXB
BdZzQRJccoT3qW4IR3CJAxwg2zU9QZ9jM8ATZAKENTDuAECICcv+4QDPFqSwYUKCAT6QzgAQ4AMG
QAJOlHAABrQTAANgABuUgJPUkuu++M2vfvfL3/76978ADrCAB0zgAhv4wAhOsIIXzOAGO/jBEI6w
hCdM4Qpb+MIYzrCGN8zhDnv4wyAOsYhHTOISm/jEKE6xilfM4ha7+MUwjrGMZ0zjGtv4xjjOsY53
zOMe+/jHQA6ykIdM5CIb+chITrKSl8zkJjv5yVCOspSnTOUqW/nKWM6ylrfM5S57+ctgDrOYx0zm
Mpv5zGhOs5rXzOY2u/lvzXCOs5znTOc62/nOeM6znvfM5z77+c+ADrSgB03oQhv60IhOtKIXzehG
O/rRkI60pCdN6Upb+tKYzrSmN83pTnv606AOtahHTepSm/rUqE61qlfN6la7+tWwjrWsZ03rWtv6
1rjOta53zeteXyogACH5BAkEAP8ALAAAAAD4AfgBAAj/AP8JHEiwoMGDCBMqXMiwocOHECNKnEix
osWLGDNq3Mixo8ePIEOKHEmypMmTKFOqXMmypcuXMGPKnEmzps2bOHPq3Mmzp8+fQIMKHUq0qNGj
SJMqXcq0qdOnUKNKnUq1qtWrWLNq3cq1q9evYMOKHUu2rNmzaNOqXcu2rdu3cOPKnUu3rt27ePPq
3cu3r9+/gAMLHky4sOHDiBMrXsy4sePHkCNLnky5suXLmDNr3sy5s+fPoEOLHk26tOnTqFOrXs26
tevXsGPLnk27tu3buHPr3s27t+/fwIMLH068uPHjyJMrX868ufPn0KNLn069uvXr2LNr3869u/fv
4MOL/x9Pvrz58+jTq1/Pvr379/Djy59Pv779+/jz69/Pv7///wAGKOCABBZo4IEIJqjgggw26OCD
EEYo4YQUVmjhhRhmqOGGHHbo4YcghijiiCSWaOKJKKao4oostujiizDGKOOMNNZo44045qjjjjz2
6OOPQAYp5JBEFmnkkUgmqeSSTDbp5JNQRinllFRWaeWVWGap5ZZcdunll2CGKeaYZJZp5plopqnm
mmy26eabcMYp55x01mnnnXjmqeeefPbp55+ABirooIQWauihiCaq6KKMNuroo5BGKumklFZq6aWY
Zqrpppx26umnoIYq6qiklmrqqaimquqqrLbq6quwxv8q66y01mrrrbjmquuuvPbq66/ABivssMQW
a+yxyCar7LLMNuvss9BGK+201FZr7bXYZqvtttx26+234IYr7rjsgRGCASBEEEAAEYBgQAgW4KRF
CQdAQAIAAJAAwQElVFCqBAqou+7ABK8bgQISyJQAAvfi6/DD+BaBQAKgSmBAwRhnbEDCLSVwAMQg
h3wAxS1h8IAAa1qc8coax5uSxyHHLLK/KQkgQBRsarAAyzwXvIAGKFFQgMxEQ1wABSjFYcNADziA
pgUf9Cz1wB+4TFIFDBSttcMM0EzSyTYIMIOaTjQw9dkBNOAESTtkvfXbDOwwUgphOzD2mjujffYC
JA3//fbfBYyEwQwp/GPDHN/Uongt38wyhpgt6K13CyKp8PflAKggEgYC7FFKJ4sYQkAGGRDQxyKw
4OG0lytILrkOIJmAOeYkd/RACg/gUU0iPeCQA8E54CDDF6eg4WXerp8NA0h+z/62ESA5oIkZYvSQ
gQ8Y+5BBDBkoY/yWQST/ukdnOE97RxjcgMIXYsggtSJUODLLll2IrzffHQ1h/uWBc/QAGo5o39nU
0ANm4CxLBLAf2gjgkQHs728D6IgDWiEGKugtAziwRZYuoEC9XYAjHHjg5TjAEU18QXh6k0EWHHEH
LHmgg2jzAEc2IMK/bYAjFsiC+ySnwhNgCQQwPNsL/zjChBq+DQIcyQQOkucDHEwCSwILYs8awJEi
GHFrHeBIIrAnvkRgSYpTY+BGrri1CG6EEgqEwxfBKDWOkHFrHKEEF5NHiTWykWdufGPROAKHNGIp
gXdcmRg14kA9xsyMGtmi/bx4JbMFMmNU3EgHDBmzLG5EiXNEmwyciKUXPDJjQ9wIBCgZMiRuRAdL
TB4O3OHCT2JMhhuhISkhdsONTC8LmZSaDMTAQoOs7kkcdCXBPriREM7yYSTciAN0kYUMXFAMGjQI
yqIESGEOciOFPCYiNSIANCxCDLlkmQ8kwYylFeQBD5ASDIS5LgV4xAjHxBcCbOeA9QmwZ7s0Qwt9
Of+l8LFzBR7xQjwBYAL0nUwYAczB9bKXASrw8nsFmeaUFCBMd34EAcec50echodMtKEHCiVYBhQK
h0ncAiESldIJhOnDj4zgmCP4CAbigIF/2OFzZkAEIAmgCDOcohTmNEg6rRQCZwYyBCJJAylLsLmh
xgEFK9CF4nRxjVv8UqhZougdLSoSjOpRoyGZ6VUfklIrocCRUgQBCkhChEmSkQlEmJs5x6qQGaBs
qFhqHRhbShLZkTGmJrGBXe+WEAekcwxV4JLOYOgCoJ1EaDU8GkquStd/CACvYIqc/bbAEsvtrwws
mcEe3pQEF7jOBUlwyRGa97chHMElDnBAUNn0hHX/Ts0AT5AJENbwtgMAISYns2xl0YQEA3ygmgEg
wAcMgAScKOEADMgmAAbAADYoASc1JZd2t8vd7nr3u+ANr3jHS97ymve86E2vetfL3va6973wja98
50vf+tr3vvjNr373y9/++ve/AA6wgAdM4AIb+MAITrCCF8zgBjv4wRCOsIQnTOEKW/jCGM6whjfM
4Q57+MMgDrGIR0ziEpv4xChOsYpXzOIWu/jFMI6xjGdM4xrb+MY4zrGOd8zjHvv4x0AOspCHTOQi
G/nISE6ykpfM5CY7+clQjrKUp0zlKlv5yljOspa3zOUue/nLYA6zmMdM5jKb+cxoTrOa18zmNrv5
SM1wjrOc50znOtv5znjOs573zOc++/nPgA60oAdN6EIb+tCITrSiF83oRjv60ZCOtKQnTelKW/rS
mM60pjfN6U57+tOghlRAAAAh+QQJBAD/ACwAAAAA+AH4AQAI/wD/CRxIsKDBgwgTKlzIsKHDhxAj
SpxIsaLFixgzatzIsaPHjyBDihxJsqTJkyhTqlzJsqXLlzBjypxJs6bNmzhz6tzJs6fPn0CDCh1K
tKjRo0iTKl3KtKnTp1CjSp1KtarVq1izat3KtavXr2DDih1LtqzZs2jTql3Ltq3bt3Djyp1Lt67d
u3jz6t3Lt6/fv4ADCx5MuLDhw4gTK17MuLHjx5AjS55MubLly5gza97MubPnz6BDix5NurTp06hT
q17NurXr17Bjy55Nu7bt27hz697Nu7fv38CDCx9OvLjx48iTK1/OvLnz59CjS59Ovbr169iza9/O
vbv37+DDi/8fT768+fPo06tfz769+/fw48ufT7++/fv48+vfz7+///8ABijggAQWaOCBCCao4IIM
NujggxBGKOGEFFZo4YUYZqjhhhx26OGHIIYo4ogklmjiiSimqOKKLLbo4oswxijjjDTWaOONOOao
44489ujjj0AGKeSQRBZp5JFIJqnkkkw26eSTUEYp5ZRUVmnllVhmqeWWXHbp5ZdghinmmGSWaeaZ
aKap5ppstunmm3DGKeecdNZp55145qnnnnz26eefgAYq6KCEFmrooYgmquiijDbq6KOQRirppJRW
aumlmGaq6aacdurpp6CGKuqopJZq6qmopqrqqqy26uqrsMb/KuustNZq66245qrrrrz26uuvwAYr
7LDEFmvsscgmq+yyzDbr7LPQRivttNRWa+212Gar7bbcduvtt+CGK+6484ERggEgRBBAABGAYEAI
FuCkRQkHQEACAACQAMEBJVRQqgQKqLvuwASvG4ECEsiUAAL34uvww/gWgUACoEpgQMEYZ2xAwi0l
cADEIId8AMUtYfCAAGtanPHKGsebkschxyyyvykJIEAUbGqwAMs8F7yABihRUIDMRENcAAUoxWHD
QA84gKYFH/Qs9cAfuExSBQwUrbXDDNBM0sk2CDCDmk40MPXZATTgBEk7ZL312wzsMFIKYTsw9po7
o332AiQN//323wWMhMEMKfzzwAN3C+SAABiI2YLeercgkgp/Vw6ACiJhYPMMDyTk9JcrQA65DiCZ
YLnlJHf0QOGfM4Ryl3mLfjYMIPl9+ttGgKQ5RK9rGYTso3t0xu2od4RBCk47gMI1sIgDCxh3eL5l
F8DrzXdHQxBfeeAcnfyPE530kUEAMfggwxewzILQDVoSUD3aBHg0gPZ/D+CRAKUYIkYP46+bQQ5Z
oMYtDtI6K13gfXq7AEc4QL/KcYAjAkBBG7KwMhng4AvRM0jvquQBBKLNAxzZQAP/tgGOzGEZkpAB
y2QgiUwsrSAFpBIIPHi2F3CECSN8GwQ4YosM9I9lPsSEQf86dyWB0bBnDeBIEXK4tQ5wpBNZUCHP
ZJAFRnzpiFOL30aYuDX7bSQQMZhaDPpwRSxKjSNc3BpHDIE2Q5TRjDxDYxqLtsY2fsl9cFyZFjUy
vznGzIsa6QMeezbGL5ktjxlL4kY64MeYOXEjk8CBD3rmAxx0YohYegEiM2bDjUCgkSHb4UZ0EIAf
riyIBonhlDq4yYKBcCMiBCXESrgRO1RDEpNcmQ9a+EKCbJBKB2wlwRS4EQbK8mEP5AgeDIEDluHA
EBkkiCqpNMhW7nEjfTwmIDVis1H0IQs5KBgVLoiCg7AvSzAQ5roU4BEjHBNfCFAdBhyAh/BRYWAZ
6AMjypn/yi39Tp0r8IgX3gkAExivc2LTRCvEwdBWoOCXAoHoPyTqJAUIk50fQcAx4/mR5DmEohRt
0gmEeQKQjOCYI/gIBuLQOLERkYAvLUhMpxQCU2IxBCJJAyhLkDkiHu6XITVcliwKR4yKRKNz5GhI
VjpN12kJBYc8Igj4KRIiMJKLTCDC3F7Y1IPMAGUzrVLosFjSkpiOiyk1iQ2+mjiEOKBzY6gCl3Tm
QRcA7SRCG+HRUFLAaQogrF16XPW2wBLKaa8MLJnBHt6UBBeIzgVJcMkRbPe3IRzBJQ5wQC/Z9IR0
Ts0AT5AJENbwtgMAISbeE0BXzYQEA3ygmgT4gAGQgBMl/xyAAdkEwAAYwAYl4KRx5AqucIdL3OIa
97jITa5yl8vc5jr3udCNrnSnS93qWve62M2udrfL3e5697vgDa94x0ve8pr3vOhNr3rXy972uve9
8I2vfOdL3/ra9774za9+98vf/vr3vwAOsIAHTOACG/jACE6wghfM4AY7+MEQjrCEJ0zhClv4whjO
sIY3zOEOe/jDIA6xiEdM4hKb+MQoTrGKV8ziFrv4xTCOsYxnTOMa2/jGOM6xjnfM4x77+MdADrKQ
h0zkIhv5yEhOspKXzOQmO/nJUI6ylKdM5Spb+cpYzrKWt8zlLnv5y2AOs5jHTOYym/nMaE6zmtfM
5ja7+SXNcI6znOdM5zrb+c54zrOe98znPvv5z4AOtKAHTehCG/rQiQoIACH5BAkEAP8ALAAAAAD4
AfgBAAj/AP8JHEiwoMGDCBMqXMiwocOHECNKnEixosWLGDNq3Mixo8ePIEOKHEmypMmTKFOqXMmy
pcuXMGPKnEmzps2bOHPq3Mmzp8+fQIMKHUq0qNGjSJMqXcq0qdOnUKNKnUq1qtWrWLNq3cq1q9ev
YMOKHUu2rNmzaNOqXcu2rdu3cOPKnUu3rt27ePPq3cu3r9+/gAMLHky4sOHDiBMrXsy4sePHkCNL
nky5suXLmDNr3sy5s+fPoEOLHk26tOnTqFOrXs26tevXsGPLnk27tu3buHPr3s27t+/fwIMLH068
uPHjyJMrX868ufPn0KNLn069uvXr2LNr3869u/fv4MOL/x9Pvrz58+jTq1/Pvr379/Djy59Pv779
+/jz69/Pv7///wAGKOCABBZo4IEIJqjgggw26OCDEEYo4YQUVmjhhRhmqOGGHHbo4YcghijiiCSW
aOKJKKao4oostujiizDGKOOMNNZo44045qjjjjz26OOPQAYp5JBEFmnkkUgmqeSSTDbp5JNQRinl
lFRWaeWVWGap5ZZcdunll2CGKeaYZJZp5plopqnmmmy26eabcMYp55x01mnnnXjmqeeefPbp55+A
BirooIQWauihiCaq6KKMNuroo5BGKumklFZq6aWYZqrpppx26umnoIYq6qiklmrqqaimquqqrLbq
6quwxv8q66y01mrrrbjmquuuvPbq66/ABivssMQWa+yxyCar7LLMNuvss9BGK+201FZr7bXYZqvt
ttx26+234IYr7rj6gRGCASBEEEAAEYBgQAgW4KRFCQdAQAIAAJAAwQElVFCqBAqou+7ABK8bgQIS
yJQAAvfi6/DD+BaBQAKgSmBAwRhnbEDCLSVwAMQgh3wAxS1h8IAAa1qc8coax5uSxyHHLLK/KQkg
QBRsarAAyzwXvIAGKFFQgMxEQ1wABSjFYcNADziApgUf9Cz1wB+4TFIFDBSttcMM0EzSyTYIMIOa
TjQw9dkBNOAESTtkvfXbDOwwUgphOzD2mjujffYCJA3//fbfBYyEwQwp/PPAA3cL5IAAGIjZgt56
tyCSCn9XDoAKImFg8wwPJOT0lytADrkOIJlgueUkd/RA4Z8zhHKXeYt+Ngwg+X3620aApDlEr2sZ
hOyje3TG7ah3hEEKTmve+uKeb9kF8Hrz3dEQxFceOEcn/5O4Qb2DSQD0aBPg0QDV/z3AR60jlD6Q
jW90Afh6X8ARB+VXzkFH66sPpgfwo+0BRxuo3982wBGUOSEby1jGK1CAkM55CQT9O9sLOMIEAb4N
AhxxgC5kgIMYBAAHbbAaQbq3JYFFsGcN4EgRLLi1DnDkBHzIAcGo0ANhGMSBXTrh1MS3ERZu7Xwb
UUYP/zDWg2UgiQxk4IgOp8YRH26NI31YWR+OKAolLrFnTXRi0aAoRTN974or46FGyKfFmAFRI0Ik
ohHLZDYwZiyFG+lAGWPmwo2MIoYEywEfbFimF7gxYxPcCATmGDIMbkSDARhiAGRoi4OQUEv8+2PB
/reRABISYgTcCMq2IQ5lKEMceGigkG7AkfdJkmDy2wj9Lvmw+3Fke83z3inXJcaNkJGVZ9QI4/JX
EF5uCQazDIACPGIEVuILAarDwO4Q8sh/+HJKv5vlCjziBWMCwATG65wAsjdCZnZJAacc5kcQwEpk
os+ZDmnmlU5wyhOAZASsHMFHMBCHxokNhwZxAD65FP+CDLgxBCJJAyFLkDkHHo6E6uQSOK8oTpGQ
U4vmDAk9n1kmFLTxhCBg4EiIIEcfMoEIc1saOtkUOh26sySm86E8TWKDGYhtIRS1ks765wKgnURo
AjwaStLHy22O6XHQ2wJLKFe9MrBkBnt4UxJcIDoXJMElR7Dd34ZwBJc4wAEibdMTgDk1AzxBJkBY
w9sOAISYZE8AMSUTEgzwgS/S8gMGQAJOlHAABtwSAANgABuUgJP2keuvgA2sYAdL2MIa9rCITaxi
F8vYxjr2sZCNrGQnS9nKWvaymM2sZjfL2c569rOgDa1oR0va0pr2tKhNrWpXy9rWuva1sI2tbGdL
29rJ2va2uM2tbnfL29769rfADa5wh0vc4hr3uMhNrnKXy9zmOve50I2udKdL3epa97rYza52t8vd
7nr3u+ANr3jHS97ymve86E2vetfL3va6973wja9850vf+tr3vvjNr373y9/++ve/AA6wgAdM4AIb
+MAITrCCF8zgBjv4wRCOsIQnTOEKW/jCGM6whjfM4Q57+MMgDrGIR0ziEpv4xChOsYpXzOIWu/jF
MI6xjGdM4xrb+MY4zrGOd8zjHvv4x0AOspCHTOQiByogACH5BAkEAP8ALAAAAAD4AfgBAAj/AP8J
HEiwoMGDCBMqXMiwocOHECNKnEixosWLGDNq3Mixo8ePIEOKHEmypMmTKFOqXMmypcuXMGPKnEmz
ps2bOHPq3Mmzp8+fQIMKHUq0qNGjSJMqXcq0qdOnUKNKnUq1qtWrWLNq3cq1q9evYMOKHUu2rNmz
aNOqXcu2rdu3cOPKnUu3rt27ePPq3cu3r9+/gAMLHky4sOHDiBMrXsy4sePHkCNLnky5suXLmDNr
3sy5s+fPoEOLHk26tOnTqFOrXs26tevXsGPLnk27tu3buHPr3s27t+/fwIMLH068uPHjyJMrX868
ufPn0KNLn069uvXr2LNr3869u/fv4MOL/x9Pvrz58+jTq1/Pvr379/Djy59Pv779+/jz69/Pv7//
/wAGKOCABBZo4IEIJqjgggw26OCDEEYo4YQUVmjhhRhmqOGGHHbo4YcghijiiCSWaOKJKKao4oos
tujiizDGKOOMNNZo44045qjjjjz26OOPQAYp5JBEFmnkkUgmqeSSTDbp5JNQRinllFRWaeWVWGap
5ZZcdunll2CGKeaYZJZp5plopqnmmmy26eabcMYp55x01mnnnXjmqeeefPbp55+ABirooIQWauih
iCaq6KKMNuroo5BGKumklFZq6aWYZqrpppx26umnoIYq6qiklmrqqaimquqqrLbq6quwxv8q66y0
1mrrrbjmquuuvPbq66/ABivssMQWa+yxyCar7LLMNuvss9BGK+201FZr7bXYZqvtttx26+234IYr
7rgBghGCASBEEEAAEYBgQAgW4KRFCQdAQAIAAJAAwQElVFCqBAqou+7ABK8bgQISyJQAAvfi6/DD
+BaBQAKgSmBAwRhnbEDCLSVwAMQgh3wAxS1h8IAAa1qc8coax5uSxyHHLLK/KQkgQBRsarAAyzwX
vIAGKFFQgMxEQ1wABSjFYcNADziApgUf9Cz1wB+4TFIFDBSttcMM0EzSyTYIMIOaTjQw9dkBNOAE
STtkvfXbDOwwUgphOzD2mjujffYCJA3//fbfBYyEwQwp/PPAA3cL5PSYLeitdwsiqfD35ACoIBIG
Ns/wgJorOO64DiCZQDnlJHf0QOGL4+253jCA5PfobxsBEuZO3uBREKt/7tEZsJPeEQYpOI156g6g
TGYXuevNd0dD9D554Byd/E/iBhkfJgHJo02ARwM4//cAH6WOkPhAhsHRBdnrfQFHHHg/OQdoCvCO
JYU0AggUHXmQPtoecLSB+3/bwJlYAIgl/AAAE5jAKqrAERDs72wv4AgTAPg2CJzpGFd42CEKgQ+O
COyBPWsAR4pAwa11YCOasIYmktSIAzqMEIXQA0dAOLXtbaSEWwNfRqwhgwyoYRpIkgLE/9ywC23M
kIZS4wgOt6aRL1AhABn4ApKGMYGHNWEKljgiEnmmxCUWTSN9yEAAqCDFIxUQYj9YBUewt8WV2VAj
3fNizHSIkWZkIAM+AOKRqkAGHlQRgb3AAkfM1saMiXAjHZBjzE6oEQ20QhhKCoMeevGIXpChBh15
QSEzFsGNQECRIbPgovS3yYL1byP/AyXEBOgm/G0EfaUk2Po20j5VPgx+jGJjLN+4kTjako4oIV+X
YBDLdSnAI0awJb4Q8BHaIcR6YMJdMVfgES8oEwAm+N3mBCA9gkBTTAqI5TE/ggBbMjN8/xDmmU4Q
yxOAZAS2HEEz44CBf4htc2oKgRjbGP8CkaQBlCW4HD4P980yhXOL4xRJOb14zpBgIA7qPBMKCAlC
EKCAJERIJA6ZQIS5LS2dbeocDd1ZEtHhUJ4mscEMxJazvGXPBUA7idAAeLRgEiSiZGpc8rbAEsk5
rwwsmcEe3pQEF3jOBUlwyRFe97chHMElDnDAR9v0BGJOzQBPkAkQ1vC2AwAhJtITAE7NhAQDfECX
ASDABwyABJwo4QAM8CUABsAANigBJ/Ukl173yte++vWvgA2sYAdL2MIa9rCITaxiF8vYxjr2sZCN
rGQnS9nKWvaymM2sZjfL2c569rOgDa1oR0va0pr2tKhNrWpXy9rWuva1sI2tbGdL29rD2va2uM2t
bnfL29769rfADa5wh0vc4hr3uMhNrnKXy9zmOve50I2udKdL3epa97rYza52t8vd7nr3u+ANr3jH
S97ymve86E2vetfL3va6973wja9850vf+tr3vvjNr373y9/++ve/AA6wgAdM4AIb+MAITrCCF8zg
Bjv4wRCOsIQnTOEKW/jCGM6whjfM4Q57+MMgDrGIR0ziEpv4xChOsYpXzOIWu/jFMI6xjGdM4xrb
+MY4zrGOd8zjHvv4x0DeU0AAACH5BAkEAP8ALAAAAAD4AfgBAAj/AP8JHEiwoMGDCBMqXMiwocOH
ECNKnEixosWLGDNq3Mixo8ePIEOKHEmypMmTKFOqXMmypcuXMGPKnEmzps2bOHPq3Mmzp8+fQIMK
HUq0qNGjSJMqXcq0qdOnUKNKnUq1qtWrWLNq3cq1q9evYMOKHUu2rNmzaNOqXcu2rdu3cOPKnUu3
rt27ePPq3cu3r9+/gAMLHky4sOHDiBMrXsy4sePHkCNLnky5suXLmDNr3sy5s+fPoEOLHk26tOnT
qFOrXs26tevXsGPLnk27tu3buHPr3s27t+/fwIMLH068uPHjyJMrX868ufPn0KNLn069uvXr2LNr
3869u/fv4MOL/x9Pvrz58+jTq1/Pvr379/Djy59Pv779+/jz69/Pv7///wAGKOCABBZo4IEIJqjg
ggw26OCDEEYo4YQUVmjhhRhmqOGGHHbo4YcghijiiCSWaOKJKKao4oostujiizDGKOOMNNZo4404
5qjjjjz26OOPQAYp5JBEFmnkkUgmqeSSTDbp5JNQRinllFRWaeWVWGap5ZZcdunll2CGKeaYZJZp
5plopqnmmmy26eabcMYp55x01mnnnXjmqeeefPbp55+ABirooIQWauihiCaq6KKMNuroo5BGKumk
lFZq6aWYZqrpppx26umnoIYq6qiklmrqqaimquqqrLbq6quwxv8q66y01mrrrbjmquuuvPbq66/A
BivssMQWa+yxyCar7LLMNuvss9BGK+201FZr7bXYZqvtttx26+234IYr7rgHghGCASBEEEAAEYBg
QAgW4KRFCQdAQAIAAJAAwQElVFCqBAqou+7ABK8bgQISyJQAAvfi6/DD+BaBQAKgSmBAwRhnbEDC
LSVwAMQgh3wAxZtanPHJGsebkschtyyyv5hqsADKNBe8gAYoUVCAyzxDXAAFl1rwQc1ED/yByiRV
wEDPTDvMAMxupkCSEw0UbXUADThB0g5LN+01AztQOvPVVi9A0s5ep13ApC2QTXYLIqmQ9twAqABn
FSCt4LbbOoD/ZALddJPs6Nh7Ww0DSGgD7rURcNLhURCF8+3RGYoHXiYXxogyTCHDkKEPFiB1ETnZ
Znc0ROVzrz0mBcMMQIPDNAwAStgeETD61QR4NADqaQ8wJhFvvA4yDVOgUgNHF9xO9gUcccD73ByE
GQYowofMAyhhcOSB8ld7wNEGz6e9QZjd8NAzD6RwBAL3Vr/AERPhew1BmM9U3zINz3AkMPs1N8BR
EfFrWgfCJIWmSYEj/Cta7jYSwKb5DkyFaFohEJhAonGkgU0joAEpWEGaXRCDPaOf/UKGP47YroMn
W6BGdgfClj3wS8cwH8/Qx5GqoTBj/ttIB1rYsgGCCQqNkKH1/xoBBY684IYZc99GIMDDkM0vTKiY
wgjx9QMp4OIG2kMixry3EfA1EWLjExPrBjABh02gCaDAxj+Ot5HkaZFgzNuI8774sOiNaRDvuEQh
CvGIZ3SDCyA54RtVuBEW0vGFi4LBG9elAI8YgY74QsCbitgRyC1yBR7xAiQBYIJHKeCNjfwIAugo
yUed4I0nAMkI6DiCSIUgAzcMgUjS0MQSTOqTHQylSEYJwlJKCgU25B8IUEASIuywgUwgQqX0lsBU
luRvDWylpWTGPRfg7CQ6C9/PNNW20W2BJXJDXRk6lQQX7M0FSXDJERKXtiEcAVRPUGTRDPAEmQBh
DV47ABBKhf8EA3xAkAEgwAcMgAScKOEADDAkAAbAADYogVwQjahEJ0rRilr0ohjNqEY3ytGOevSj
IA2pSEdK0pKa9KQoTalKV8rSlrr0pTCNqUxnStOa2vSmOM2pTnfK05769KdADapQh0rUohr1qEhN
qlKXytSmOvWpUI2qVKdK1apa9apYzapWt8rVrnr1q2ANq1jHStaymvWsaE2rWtfK1ra69a1wjatc
50rXutr1rnjNq173yte++vWvgA2sYAdL2MIa9rCITaxiF8vYxjr2sZCNrGQnS9nKWvaymM2sZjfL
2c569rOgDa1oR0va0pr2tKhNrWpXy9rWuva1sI2tbGdL29ox2va2uM2tbnfL29769rfADa5wh0vc
4hr3uMhNrnKXy9zmOve50I2udKdL3epa91UBAQAh+QQJBAD/ACwAAAAA+AH4AQAI/wD/CRxIsKDB
gwgTKlzIsKHDhxAjSpxIsaLFixgzatzIsaPHjyBDihxJsqTJkyhTqlzJsqXLlzBjypxJs6bNmzhz
6tzJs6fPn0CDCh1KtKjRo0iTKl3KtKnTp1CjSp1KtarVq1izat3KtavXr2DDih1LtqzZs2jTql3L
tq3bt3Djyp1Lt67du3jz6t3Lt6/fv4ADCx5MuLDhw4gTK17MuLHjx5AjS55MubLly5gza97MubPn
z6BDix5NurTp06hTq17NurXr17Bjy55Nu7bt27hz697Nu7fv38CDCx9OvLjx48iTK1/OvLnz59Cj
S59Ovbr169iza9/Ovbv37+DDi/8fT768+fPo06tfz769+/fw48ufT7++/fv48+vfz7+///8ABijg
gAQWaOCBCCao4IIMNujggxBGKOGEFFZo4YUYZqjhhhx26OGHIIYo4ogklmjiiSimqOKKLLbo4osw
xijjjDTWaOONOOao44489ujjj0AGKeSQRBZp5JFIJqnkkkw26eSTUEYp5ZRUVmnllVhmqeWWXHbp
5ZdghinmmGSWaeaZaKap5ppstunmm3DGKeecdNZp55145qnnnnz26eefgAYq6KCEFmrooYgmquii
jDbq6KOQRirppJRWaumlmGaq6aacdurpp6CGKuqopJZq6qmopqrqqqy26uqrsMb/KuustNZq6624
5qrrrrz26uuvwAYr7LDEFmvsscgmq+yyzDbr7LPQRivttNRWa+212Gar7bbcduvtt+CGK+64ydVA
B0J01IATGCEYAEIEAQQQAQgGhGABTlqUcAAEJAAAAAkQHFBCBWEKAZHBMUmgALzxNuxwvBEoIIFM
CSDQr78YZ+xvEQgkwCXCEoG8kgQGPGzyyQZM3FICB2js8ssHeHylyBSpmxLJJ+eM8r0psfzyzzAT
/CYXKGmwgM5IP7yABihRUADQUGtcAAVw2kySBR8krXXDH/BMUgUMRC02xgwI7SYWJDnRwNZsB9CA
EyTtEPbYdDOwA6VHt832AiQ9/0333wXAWYVILeitdwsiqfD34gCoMCYn3LgihxS7pCKHK9xwIhDN
G61guOE6gGQC44zL7KUI0lzSBA+sYMwKD01cwo0IIeX9OdswgOQ36XQb8aUAxljxwwQvT0ADCcYI
wDlGQdwOukdn8F66l91cQTzUE1zRzUddOK833x0NIf3igXO5yRUDjJ39Jh4R4H3bBHiU/vh0D8Al
FJfQ8DcN2uDC0QXv09sFOMIB+i2OA1vyxvzoNoEBaIEjHghg2zzAkQ0Y8G8b2JIreMA4HriCIyCQ
INtewBEmXJBuENhSMq73twkkgyMME2HSGsCRIpxwbB3YkhR4JwWOyHBr8dvIDf/HZj8t7YJ3u/Dh
D7XGkSGOTYc8VOISkdZEJ0ZNhSxk4As34r4p5iyIGlmgFV1WxCxtsIMf3MjavHgyGm6kA2P8WQ61
ZAIxiq2B9eDIC9h4MhJuBAJxfFkKtQSFXHCQbjzIxeA2EkE+PoyCG7FgIDWWwS1RAH3quwIFllcR
ADrSYQPcSAEnmTEEcukYS8hi8ZZwjI908ZNg3Igd41jGLQFPeKr01wR+YAV83OAjMPhkvBTgESOQ
0l8IABMQLNEE/WHMeE2wBDcEYrWNNE+YK/CIF44JABOEiRPS0IMsKCcFWeghcyNRwCeJ+REEkDKZ
bqomR07wyROAZASkHIGbzhX/khBkgI0hEEkaAlmCSalziuwUiTutCM82oW0kKFijDEGAApIQAY5D
ZAIR4mkSz/3QniUZ3RD12SaincRoEnQB007itAtOzUqLpAgUWFI4722BJYobXxmyxEmG9LQkSXDB
51yQBJccYXd/G8IRPnYwmTwhmFszwBNkAoQ10O0AQAiTPAuyVZkgwQAfeGUACPABAyABJ0o4AAPE
OAAGsEEJ5IqrXOdK17ra9a54zate98rXvvr1r4ANrGAHS9jCGvawiE2sYhfL2MY69rGQjaxkJ0vZ
ylr2spjNrGY3y9nOevazoA2taEdL2tKa9rSoTa1qV8va1rr2tbCNrWxnS9vattr2trjNrW53y9ve
+va3wA2ucIdL3OIa97jITa5yl8vc5jr3udCNrnSnS93qWve62M2udrfL3e5697vgDa94x0ve8pr3
vOhNr3rXy972uve98I2vfOdL3/ra9774za9+98vf/vr3vwAOsIAHTOACG/jACE6wghfM4AY7+MEQ
jrCEJ0zhClv4whjOsIY3zOEOe/jDIA6xiEdM4hKb+MQoTrGKV8ziFrv4xTCOsYxnTOMa2/jGqQoI
ACH5BAkEAP8ALAAAAAD4AfgBAAj/AP8JHEiwoMGDCBMqXMiwocOHECNKnEixosWLGDNq3Mixo8eP
IEOKHEmypMmTKFOqXMmypcuXMGPKnEmzps2bOHPq3Mmzp8+fQIMKHUq0qNGjSJMqXcq0qdOnUKNK
nUq1qtWrWLNq3cq1q9evYMOKHUu2rNmzaNOqXcu2rdu3cOPKnUu3rt27ePPq3cu3r9+/gAMLHky4
sOHDiBMrXsy4sePHkCNLnky5suXLmDNr3sy5s+fPoEOLHk26tOnTqFOrXs26tevXsGPLnk27tu3b
uHPr3s27t+/fwIMLH068uPHjyJMrX868ufPn0KNLn069uvXr2LNr3869u/fv4MOL/x9Pvrz58+jT
q1/Pvr379/Djy59Pv779+/jz69/Pv7///wAGKOCABBZo4IEIJqjgggw26OCDEEYo4YQUVmjhhRhm
qOGGHHbo4YcghijiiCSWaOKJKKao4oostujiizDGKOOMNNZo44045qjjjjz26OOPQAYp5JBEFmnk
kUgmqeSSTDbp5JNQRinllFRWaeWVWGap5ZZcdunll2CGKeaYZJZp5plopqnmmmy26eabcMYp55x0
1mnnnXjmqeeefPbp55+ABirooIQWauihiCaq6KKMNuroo5BGKumklFZq6aWYZqrpppx26umnoIYq
6qiklmrqqaimquqqrLbq6quwxv8q66y01mrrrbjmquuuvPbq66/ABivssMQWa+yxyCar7LLMNuvs
s9BGK+201FZr7bXYZqvtttx26+234IYr7rjP1UAHQnTUgBMYIRgAQgQBBBABCAaEYAFOWpRwAAQk
AAAACRAcUEIFYQoBkcExSaAAvPE27HC8ESgggUwJINCvvxhn7G8RCCTAJcISgbySBAY8bPLJBkzc
UgIHaOzyywd4fKXIFKmbEskn54zyvSmx/PLPMBNMJRcaEX2SBgvorPTDC2iAEgUFAC21xgVQMKXN
GmE9kgUfLO11wx/wTFIFDExtNsYMCP0kFg+FUck+ZNAzhRRT0EPGPpWEIRDbIzn/0cDXgAfQgBMk
7VD22YgzsIOXIlRARipW0OAGxm7QYEUqZFTiQElJBw74AiRFjfjoBTxZRUN0kGEKDRMAPQEN/OjB
yekhteC55y2IpMLovAOgQpM0o0uGFa2bPcESZNARvEYr3H67DiCZ0HvvMl8JhR6q9K6KHlCA1Lnz
gMMAkujTI27EkssblM4S5VuRji8eBQH+8x6dUT71V1IwxQDlDzCF1R3pwvw8B7qODOF+vCtdlcKg
B/bdbwmh6B5HCDDAwBHAI/xDIOIGYKVNvKF45ZvAG0zAkQtU0HMX4AgHNMg7DlRJGg5E4BJIwREP
nDBwHuDIBlg4ug1USQ4gvN8E/+TAERDcEHAv4AgTeIg4CFTJE0wkBkcYdsSlNYAjRWDi2TpQJSkw
cQocqeLXLrgRLZ6Ng1TyIg+lEEYxeo0jZjxblabwxTa6UWlwjOPUqkSMKE7wjjojo0YyqMeXoXFK
QGThEDnyN0Ce7Iob6UAhf8ZFKsGQhUuQBkde4MiTJXEjEJjky5xIJQ8GsXci/EMNO2myHG5kh6LU
mA+pxMAYTg+C8NuICVnpsBRuZIWxzJgLqxQJKfygfD+QQiQ8QkFeBkCQGyFkLA9ZJXRYoX3F+AgM
nBkABXjECMH0FwKUlL6CjAF72oug1jQiP2euwCNeCCcASIilYOiBeGebgBVkF/8SBfDSmx9BQDDH
uaR1JoQOoZACD06ZsQnwQArQOJdBNXICXp4AJCMI5giaxImHaOFxrNPY6zCnBYGcKyQhyIAjQyCS
NIiyBF9ymzPk8AYp2PQNcnBG3k7izzsCVCQC1SNBncQ3jhQ1JChoZBVBgAKSEEGSZmQCEaI00YpU
1SPNE+NFSyI9M25USkbDSFhLgrQbusBpJ4EaD6tmJdpRRIIqsd0At8CS3SGwDFkq50L0SpIkuMB5
LkiCS45AvtEN4QgfO5hMnrDNrxngCTIBwhoQdwAghOmq/8CsS5BggA80M14E+IABkIATJRyAAdIc
AAPYoARyufa1sI2tbGdL29r/2va2uM2tbnfL29769rfADa5wh0vc4hr3uMhNrnKXy9zmOve50I2u
dKdL3epa97rYza52t8vd7nr3u+ANr3jHS97ymve86E2vetfL3va6973wja9850vf+tr3vvjNr373
y9/++ve/AA6wgAdM4AIb+MAITrCCF8zgBjv4wRCOsIQnTOEKW/jCGM6whjfM4Q57+MMgDrGIR0zi
Epv4xChOsYpXzOIWu/jFMI6xjGdM4xrb+MY4zrGOd8zjHvv4x0AOspCHTOQiG/nISE6ykpfM5CY7
+clQjrKUp0zlKlv5yljOspa3zOUue/nLYA6zmMdM5jKb+cxoTrOa18zmNrv5C81wjrOc50znUQUE
ACH5BAUEAP8ALAAAAAD4AfgBAAj/AP8JHEiwoMGDCBMqXMiwocOHECNKnEixosWLGDNq3Mixo8eP
IEOKHEmypMmTKFOqXMmypcuXMGPKnEmzps2bOHPq3Mmzp8+fQIMKHUq0qNGjSJMqXcq0qdOnUKNK
nUq1qtWrWLNq3cq1q9evYMOKHUu2rNmzaNOqXcu2rdu3cOPKnUu3rt27ePPq3cu3r9+/gAMLHky4
sOHDiBMrXsy4sePHkCNLnky5suXLmDNr3sy5s+fPoEOLHk26tOnTqFOrXs26tevXsGPLnk27tu3b
uHPr3s27t+/fwIMLH068uPHjyJMrX868ufPn0KNLn069uvXr2LNr3869u/fv4MOL/x9Pvrz58+jT
q1/Pvr379/Djy59Pv779+/jz69/Pv7///wAGKOCABBZo4IEIJqjgggw26OCDEEYo4YQUVmjhhRhm
qOGGHHbo4YcghijiiCSWaOKJKKao4oostujiizDGKOOMNNZo44045qjjjjz26OOPQAYp5JBEFmnk
kUgmqeSSTDbp5JNQRinllFRWaeWVWGap5ZZcdunll2CGKeaYZJZp5plopqnmmmy26eabcMYp55x0
1mnnnXjmqeeefPbp55+ABirooIQWauihiCaq6KKMNuroo5BGKumklFZq6aWYZqrpppx26umnoIYq
6qiklmrqqaimquqqrLbq6quwxv8q66y01mrrrbjmquuuvPbq66/ABivssMQWa+yxyCar7LLMNuvs
s9BGK+201FZr7bXYZqvtttx26+234IYr7rjV1UAHQnTUgBMYIRgAQgQBBBABCAaEYAFOWpRwAAQk
AAAACRAcUEIFYQoBkcExSaAAvPE27HC8ESgggUwJINCvvxhn7G8RCCTAJcISgbySBAY8bPLJBkzc
UgIHaOzyywd4fKXIFKmbEskn54zyvSmx/PLPMBNMJRcJQUFEJf0gUM4bU7xRDgL9VBIJFAIRfZIG
C+is9cMLaIASBQUALbbGBVAwpc0GQbGJM+XQQMMEGk9AgxX0OLMJ1WiPZMEHW/f/3fAHPJNUAQNj
F44xA0I/icVBKWyyCihXwC32BFeAAs0fKSw+khMN+O15AA04QdIOhBtuOgM7YIkLKEv8YDoANCwB
ii8lZf255wuQFPbrphfwZBUGpbCDLFa4wTsAblghyw7Ah9TC7be3IJIKx7+uQpM0C+SLLDxUjzEP
sviSvUYrQA+9DiCZ4P3rMkc5iByur+/vD3IMApLt5nsOA0i7yz+2EUvKXhUAYQXJyW8CVgAE7ToS
hPydzyNn8J/p2uckVICDBhL0Fw3AgQqPdMGBt8tdR4aQwcL57klhKMYVSuivKwCCahwhAAg/RwCP
DICFYhtAkrJXAwYYj4UTkMcf/zhygRne7gIc4QAOx8YBJN3AIBTo3hJ5YLaNeMCIn/MARzawRLFt
4EkliB8OWVECjoAAi557AUeY0EWgQeBJ8mgjAMrBEYahcWsN4EgR5PiyDjzpDXL0BEfu6LcaboSP
L9Ohk6YgxykMkpB94wgiX/YkRrbRkRuBZCQPOUmNVbKRMdSkzgypkRt20l+KbJInAsmRzonyZHnc
SAdO6S8/OomEbRwCR17wypOpcSMQoCUA3nikJxYkjF0kI0eu2MuHaXEjXKTlF4+UvSh2kQcjIGIz
H4bEjSiRlk100iA8wQocToAY9gvlNgNAyo2YcpKpbFIKV8hCFzZvIzBYZwAU4P8RI5wSAUrKHiqS
gcEM8iAZQ2SgPlfgES+c0gRREoExCihBBOJDBB9RwDb5+REETBKgS8qbQLBwDjGu7wfniARITrDN
E4BkBJPMJpM4YRAhxEOK3uNBPDoYkhBk4JUhEEka+FhGKqXgD48YgAFNN4EBPCISKRiJRjXJUZF4
tIsgdZLmCoIKOQygoIZ7mxxQsdWQoMCVdwQBCkhChFnikAlEiJJIBYILdMgicmOjnCyK4Yu5gqR8
hHRpSdSHQ5lGyWoFCcMIiuGJHywVYxP4QTmKMYIwILYkWMOiC7x2ErBlsGxWuidBoBAJUtjDE0yb
QtM8YQ9STA2GKnkeCLfAEur/ya8MWRpfQ3RrkiS4wHwuSIJLjtC/1w3hCB87mEyekE+/GeAJMgHC
Gkx3ACCEya8DwS5MkGCAD8iwYQT4gAGQgBMlHIAB7wTAABjABiWQ673wja9850vf+tr3vvjNr373
y9/++ve/AA6wgAdM4AIb+MAITrCCF8zgBjv4wRCOsIQnTOEKW/jCGM6whjfM4Q57+MMgDrGIR0zi
Epv4xChOsYpXzOIWu/jFMI6xjGdM4xrb+MY4zrGOd8zjHvv4x0AOspCHTOQiG/nISE6ykpfM5CY7
+clQjrKUp0zlKlv5yljOspa3zOUue/nLYA6zmMdM5jKb+cxoTrOa18zmNrv5ZM1wjrOc50znOtv5
znjOs573zOc++/nPgA60oAdN6EIb+tCITrSiF83oRjv60ZCOtKQnTelKW/rSmM60pjfN6U57+tOg
DrWoR03qUpv61KhOtapXzepWu/rVsI61rGdN61p3KiAAOw==`,
  mapboxAccessToken: 'pk.eyJ1IjoiampiZW5pdGV6IiwiYSI6ImNrMnR4eXA0bjFjODEzbm80cmJ1ZXRqaTYifQ.M9nD-tsxziNlGcavuK1yeg',
  fbClientId: '426872654696136',
  targetCards: [{
    card: '9204 9598 7535 5523',
    currency: CurrencyKeyType.CUP
  }, {
    card: '9225 9598 7006 5882',
    currency: CurrencyKeyType.USD
  }],
  trustInvestingAddress: 'b7749cdc7fc8a48881907a3556fee463b21bb3b4',
  commissionThreshold: 300,
  chatUrl: environment.chatUrl,
  oauth2Config: {
    authorizationEndpoint: '/oauth/authorize',
    clientId: environment.auth2client,
    server: environment.auth2server,
    tokenEndpoint: '/oauth/token',
  }
};
