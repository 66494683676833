import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { NgImageFullscreenViewModule } from '@jjbenitez/ng-image-fullscreen-view';
import { MatIconModule } from '@angular/material/icon';
import { SharedPipesModule } from '@rhbnb-nx-ws/shared-pipes';
import { TranslocoModule } from '@ngneat/transloco';

import { LazySizesPictureModule } from '../lazy-sizes-picture/lazy-sizes-picture.module';
import { GalleryComponent } from './gallery/gallery.component';
import { LargeGalleryComponent } from './large-gallery/large-gallery.component';
import { LoadableImageComponent } from './large-gallery/loadable-image.component';
import { TinyGalleryComponent } from './tiny-gallery/tiny-gallery.component';

const COMPONENTS = [
  GalleryComponent,
  LargeGalleryComponent,
  LoadableImageComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    TinyGalleryComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    NgImageFullscreenViewModule,
    LazySizesPictureModule,
    SharedPipesModule,
    TranslocoModule,
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class GalleryModule { }
