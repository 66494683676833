<rhbnb-container
  xs="100"
  [gtSm]="'750px'"
  [gtMd]="'750px'"
  [gtLg]="'750px'">

  <div class="mb-10 p-25" fxLayout="row wrap" fxLayoutAlign="start center">
    <div class="circle mr-15 load-animate dark"></div>
    <div class="title load-animate dark"></div>
  </div>

  <div class="ml-60">

    <div fxLayout="column"
         fxLayout.gt-xs="row"
         fxLayoutGap="5px"
         fxLayoutAlign="center stretch"
         fxLayoutAlign.gt-xs="start center">

      <div fxFlex
           fxFlex.gt-xs="70"
           fxFlex.gt-sm="50">

        <div class="field load-animate dark"></div>
      </div>

      <div fxFlex
           fxFlex.gt-xs="30"
           fxFlex.gt-sm="30">

        <div class="field load-animate dark"></div>
      </div>

    </div>

    <div class="mt-40">
      <div class="mb-10"></div>

      <rhbnb-room-card-loading
        [gtSmColumns]="3"></rhbnb-room-card-loading>
    </div>

  </div>
</rhbnb-container>
