<ul fxLayout="row wrap"
    fxLayoutAlign="{{alignItems}} center">

  <li
    fxFlex="1 0 5rem"
    (click)="selectTab(item)"
    [class.active]="activeTab === item && showBorder === true"
    *ngFor="let item of tabItems$ | async">

    <div>

      <ng-container *ngIf="item.labelComponent">
        <ng-container *ngTemplateOutlet="item.labelComponent.labelContent">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!item.labelComponent">
        {{ item.label }}
      </ng-container>

    </div>

  </li>

</ul>

<div class="tabs-body">
  <ng-container *ngIf="activeTab && activeTab.bodyComponent">
    <ng-container *ngTemplateOutlet="activeTab.bodyComponent.bodyContent">
    </ng-container>
  </ng-container>
</div>
