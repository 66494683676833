import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Country } from '@rhbnb-nx-ws/domain';

@Component({
  selector: 'rhbnb-complete-profile-form',
  templateUrl: './complete-profile-form.component.html',
  styleUrls: ['./complete-profile-form.component.scss'],
})
export class CompleteProfileFormComponent implements OnInit {
  form!: FormGroup;
  code: number;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, data: any },
    private ref: MatDialogRef<CompleteProfileFormComponent>,
  ) {
    this.createForm();

    this.form.patchValue(data.data);
  }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      country: ['', Validators.required],
      mobile: ['', Validators.required],
    });
  }

  onCountryChange(event: Country) {
    this.code = event?.callingCode;
  }

  onComplete() {
    this.form?.markAllAsTouched();

    if (this.form.valid) {
      this.ref.close(this.form.value);
    }
  }
}
