import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';

export class MobileErrorStateMatcher implements ErrorStateMatcher {

  constructor(
    private value: string
  ) {
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const parse = parsePhoneNumberFromString(this.value);
    const valid = parse && parse.isValid();
    return !!(control && (control.invalid || !valid) && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'rhbnb-mobile-field',
  templateUrl: './mobile-field.component.html',
  styleUrls: ['./mobile-field.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileFieldComponent extends WithUnsubscribe()
  implements OnInit, OnChanges {

  @Input() label: any;
  @Input() parent: FormGroup;
  @Input() code: string | number;

  numberChange = new Subject<void>();

  matcher;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToNumberChange();
  }

  private subscribeToNumberChange(): void {
    this.numberChange
      .pipe(
        debounceTime(50),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.validate();
      });
  }

  getField(): AbstractControl {
    return this.parent.get('mobile');
  }

  validate(): void {
    const phone = `+${this.code}${this.getField().value}`;

    const parse = parsePhoneNumberFromString(phone);
    this.matcher = new MobileErrorStateMatcher(phone);

    if (parse && parse.isValid()) {
      this.getField().setErrors(null);
    } else {
      this.getField().setErrors({invalid: true});
    }

    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.numberChange.next();
  }
}
