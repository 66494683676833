<ng-container [formGroup]="form" *ngIf="asFormControl; else inline">

  <input matInput
         #input
         class="form-control"
         [placeholder]="placeholder"
         required
         ngxDaterangepickerMd
         formControlName="range"
         [minDate]="rangeMinDate"
         [dateMinLimit]="dateMinLimit"
         (minLimitChanged)="minRangeChange.emit($event)"
         [isInvalidDate]="isValidDate.bind(this)"
         [canBeEndDate]="isInvalidStartingDate.bind(this)"
         [isTooltipDate] = "isTooltipDate.bind(this)"
         [singleDatePicker]="singlePicker"
         [locale]="locale"
         [autoApply]="true"
         [opens]="'center'"
         [drops]="'down'"
         [readonly]="true"/>

</ng-container>

<ng-template #inline>

  <ng-container *ngIf="isRangeSet; else noRange">

    <ngx-daterangepicker-material
      [class.flex-adjust]="adjustForFlex"
      [class.single]="showAsSinglePanel"
      [locale]="locale"
      [autoApply]="true"
      [isInvalidDate]="isValidDate.bind(this)"
      [canBeEndDate]="isInvalidStartingDate.bind(this)"
      [isTooltipDate] = "isTooltipDate.bind(this)"
      [startDate]="range?.value?.startDate"
      [endDate]="range?.value?.endDate"
      [dateMinLimit]="dateMinLimit"
      [minDate]="rangeMinDate"
      [singleDatePicker]="singlePicker"

      (choosedDate)="onChooseDate($event)"
      (minLimitChanged)="minRangeChange.emit($event)"
      (startDateChanged)="startDateChanged.emit($event)"></ngx-daterangepicker-material>

  </ng-container>

  <ng-template #noRange>

    <ngx-daterangepicker-material
      [class.flex-adjust]="adjustForFlex"
      [class.single]="showAsSinglePanel"
      [autoApply]="true"
      [locale]="locale"
      [isInvalidDate]="isValidDate.bind(this)"
      [canBeEndDate]="isInvalidStartingDate.bind(this)"
      [isTooltipDate] = "isTooltipDate.bind(this)"
      [dateMinLimit]="dateMinLimit"
      [minDate]="rangeMinDate"
      [singleDatePicker]="singlePicker"
      [isClickable]="clickable"

      (choosedDate)="onChooseDate($event)"
      (minLimitChanged)="minRangeChange.emit($event)"
      (startDateChanged)="startDateChanged.emit($event)"></ngx-daterangepicker-material>

  </ng-template>

</ng-template>
