import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, Location, LocationStrategy } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationUtilService {

  constructor(
    private location: Location,
    private locationStrategy: LocationStrategy,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {}

  removeQueryParamFromUrl(...params) {
    const path = this.location.path().split('?');
    const base = path[0];

    const queries = path.splice(1);
    const searchParams = new URLSearchParams(`?${queries.join()}`);

    params.forEach(param => searchParams.delete(param));
    this.location.replaceState(base, searchParams.toString());
  }

  getQueryParam(key: string) {
    return this.route.snapshot.queryParamMap.get(key);
  }

  getBaseURL() {
    return this.document.location.origin;
  }

}
