<div fxLayout="row wrap" fxLayoutAlign="start center"
     *ngIf="total && total > 0">

  <mat-icon svgIcon="star" color="primary"></mat-icon>

  <div class="line-text">
    <span class="avg">{{ avg | number: '1.1-2' }}</span>
    <span class="total">({{ total }})</span>
  </div>

</div>
