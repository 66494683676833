import { COUNTRIES_SLUGS } from '@rhbnb-nx-ws/domain';

export interface DomainHandler {
  handle(url: URL): Promise<void | boolean>;
}

class VaraderoRentalsDomainHandler implements DomainHandler {
  handle(url: URL): Promise<boolean> {
    return new Promise((resolve) => {
      if (!url.pathname.startsWith(`/${COUNTRIES_SLUGS.cuba}`)) {
        window.location.href = `https://varaderorentals.com/cuba/houses/list?destination=31`;
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }
}

class RentalHoAeDomainHandler implements DomainHandler {
  handle(url: URL): Promise<boolean> {
    return new Promise((resolve) => {
      if (!url.pathname.startsWith(`/${COUNTRIES_SLUGS.dubai}`)) {
        window.location.href = `https://rentalho.ae/dubai`;
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }
}

export const domainHandler = async () => {
  const currentUrl = new URL(window.location.href);

  if (currentUrl.hostname === 'varaderorentals.com') {
    return new VaraderoRentalsDomainHandler().handle(currentUrl);
  }

  if (currentUrl.hostname === 'rentalho.ae') {
    return new RentalHoAeDomainHandler().handle(currentUrl);
  }
};
