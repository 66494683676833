import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

export enum PriceVariant {
  COMPLETE,
  PARTIAL
}

@Component({
  selector: 'rhbnb-price-variant-chooser',
  templateUrl: './price-variant-chooser.component.html',
  styleUrls: ['./price-variant-chooser.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceVariantChooserComponent implements OnInit {

  @Output() optionChange = new EventEmitter<PriceVariant>();

  options = [
    {
      label: 'global.price_variant_chooser.complete_label',
      hint: 'global.price_variant_chooser.complete_hint',
      value: PriceVariant.COMPLETE
    },
    {
      label: 'global.price_variant_chooser.partial_label',
      hint: 'global.price_variant_chooser.partial_hint',
      value: PriceVariant.PARTIAL
    }
  ];

  selected: PriceVariant;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.selected = PriceVariant.PARTIAL;
    this.cdr.detectChanges();
  }

  onSelectedChange(change: MatRadioChange) {
    this.optionChange.emit(change.value);
  }
}
