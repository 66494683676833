import { Injectable, NgZone } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  CurrencyKeyType,
  DateRangeModel,
  House,
  HouseModalities,
  Image,
  ReservationRoom,
  ReservationStatus,
  Room,
  RoomEvent
} from '@rhbnb-nx-ws/domain';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { SnackTime } from '../components/custom-snack';
import { RoomEventsService } from './room-events.service';
import { HouseApiService } from './house-api.service';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root'
})
export class HouseUtilService {

  constructor(
    private notifyService: NotifyService,
    private houseApiService: HouseApiService,
    private roomEventsApiService: RoomEventsService,
    private translocoService: TranslocoService,
    private ngZone: NgZone,
  ) {
  }

  updateRoomsAvailabilityAndPrice(
    house: House,
    selectedDateRange: DateRangeModel,
    currency: CurrencyKeyType
  ): void {
    this.ngZone.runOutsideAngular(() => {
      const updatedRooms: ReservationRoom[] = [];

      house?.rooms?.forEach(room => {
        const status = this.findRoomStatus(room, selectedDateRange, currency, house?.cupRate);

        (room as ReservationRoom).available = status.available;
        (room as ReservationRoom).rangePrice = status.price;
        (room as ReservationRoom).rangeAvg = status.avg;

        if (house.modality === HouseModalities.MODALITY_HOUSE) {
          if ((room as ReservationRoom).available) {
            (room as ReservationRoom).selected = true;
          }
        }

        updatedRooms.push({ ...room });
      });

      house.rooms = updatedRooms;
    });
  }

  /**
   * Find the room total price, price avg
   * and availability in date range
   */
  findRoomStatus(
    room: Room,
    selectedDateRange: DateRangeModel,
    currency: CurrencyKeyType,
    cupRate: number
  ): { available: boolean, price?: number, avg?: number } {

    if (!room) {
      return { available: false };
    }

    const start = selectedDateRange.startDate.clone();
    const end = selectedDateRange.endDate;

    let price = 0;
    let available = true;
    let dayCount = 0;

    // Iterate all date range
    for (start; start.isBefore(end, 'day'); start.add(1, 'days')) {
      dayCount++;

      // Find current day event
      const ev = (room as ReservationRoom)
        .events
        .find(e => {
          return start.isBetween(moment(e.start), moment(e.end).subtract(1, 'day'), 'day', '[]');
        });

      // If event exist and is available, accumulate price else return false
      if (ev) {
        if (!this.isEventAvailable(ev)) {
          available = false;
        }

        let _cupRate = ev?.data?.cupRate ? ev?.data?.cupRate : cupRate;
        _cupRate = currency === CurrencyKeyType.CUP ? _cupRate : 1;

        const currentPrice = data => data?.singlePrice ? data?.singlePrice : data?.price;
        price += parseFloat((currentPrice(ev?.data) ? currentPrice(ev?.data) : room?.price) as any) * _cupRate;
      } else {
        price += parseFloat(room?.price as any) * (currency === CurrencyKeyType.CUP ? cupRate : 1);
      }
    }

    const avg = price / dayCount;
    return { available, price, avg };
  }

  private isEventAvailable(ev) {
    return ev?.available;
  }

  loadRoomEvents(roomId: string, layoutStoreService: any): Observable<RoomEvent[]> {
    return layoutStoreService
      .getFilterDateRange()
      .pipe(
        mergeMap((range: any) =>
          (range?.startDate && range?.endDate) ?
            this.roomEventsApiService.get(
              [roomId],
              range?.startDate,
              range?.endDate?.clone()?.subtract(1, 'days')
            ) :
            of([])
        ),
        take(1)
      );
  }

  loadEvents(houseId: string, layoutStoreService: any): Observable<any> {
    return layoutStoreService
      .getFilterDateRange()
      .pipe(
        take(1),
        mergeMap((range: any) =>
          (range?.startDate && range?.endDate) ?
            this.houseApiService.getRoomEvents(houseId, range?.startDate, range?.endDate) :
            of([])
        ),
        take(1)
      );
  }

  updateRoomsEvents(house: House, dataEvents: any) {
    const rooms = [];

    house?.rooms.forEach((r: ReservationRoom) => {
      r.events = dataEvents[r.id] ? dataEvents[r.id] : [];
      rooms.push({ ...r });
    });

    return { ...house, rooms };
  }

  getPrincipalImage(house: House): Image {
    if (Array.isArray(house.images)) {
      // tslint:disable-next-line:triple-equals
      const p = house.images.find(i => i.number == 0);
      return p ? p : house.images[0];
    } else if (house.images) {
      return house.images;
    }
  }

  clearDateRangeIfLimitCheckFail$(house: House, translocoScope, layoutStoreService: any) {
    return layoutStoreService
      .getFilterNights()
      .pipe(
        take(1),
        map(currentRangeNights =>
          house?.nightsLimit && currentRangeNights >= 1 &&
          currentRangeNights < house?.nightsLimit),
        filter(n => !!n),
        tap(() => {
          layoutStoreService.setFilterDateRange({
            startDate: undefined,
            endDate: undefined,
            chosenLabel: ''
          });
        }),
        switchMap(() => this.translocoService
          .selectTranslate('notify_limit_nights_selection', {
            house: house?.name,
            nights: house?.nightsLimit
          }, translocoScope)),
        tap((msg: any) => {
          this.notifyService.error(msg, SnackTime.NORMAL, true);
        })
      )
      ;
  }

  getOnlyEndDates = (range: string[]) => {
    range.sort((a, b) => moment(a).unix() - moment(b).unix());

    const starting = [];
    let last;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < range.length; i++) {
      const d = moment(range[i]);

      if (starting.length === 0 || d.clone().subtract(1, 'day').format('YYYY-MM-DD') !== last) {
        starting.push(d.format('YYYY-MM-DD'));
      }

      last = d.format('YYYY-MM-DD');
    }

    return starting;
  }

  statusLabel(key: string) {
    switch (key) {
      case ReservationStatus.STATUS_PENDING:
        return this.translocoService.translate('booking.status.pending');
      case ReservationStatus.STATUS_CANCELED:
        return this.translocoService.translate('booking.status.canceled');
      case ReservationStatus.STATUS_CONFIRMED:
        return this.translocoService.translate('booking.status.confirmed');
      case ReservationStatus.STATUS_COMPLETED:
        return this.translocoService.translate('booking.status.completed');
      default:
        return undefined;
    }
  }
}
