import {
  Directive,
  ElementRef,
  NgZone,
  Renderer2,
  ContentChild,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import {
  combineLatest,
  fromEvent,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import { WithUnsubscribe } from '../classes/with-unsubscribe';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  animate,
  AnimationBuilder,
  AnimationMetadata,
  AnimationPlayer,
  keyframes,
  style,
} from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import * as url from 'url';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { LayoutStoreService } from '../../layout/store/layout-store.service';
import { RoutesService } from '../services/routes.service';

type ScrollEvent = 'UP' | 'DOWN' | 'NO';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[dynamicBar]',
})
export class DynamicBar {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[topBarEffect]',
})
export class TopBarEffectDirective
  extends WithUnsubscribe()
  implements AfterViewInit
{
  private readonly shadowSubject$ = new Subject<boolean>();
  private readonly dynamicBarSubject$ = new Subject<boolean>();

  @ContentChild(DynamicBar, { static: false, read: ElementRef })
  dynamicBar: ElementRef;

  private handleDynamicBarSub: Subscription;
  private handleScrollEventSub: Subscription;

  private mediaChange$: Observable<MediaChange>;

  fired = false;

  constructor(
    private el: ElementRef,
    private media: MediaObserver,
    private animationBuilder: AnimationBuilder,
    private ngZone: NgZone,
    private router: Router,
    private renderer: Renderer2,
    private layoutStoreService: LayoutStoreService,
    private routesService: RoutesService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.mediaChange$ = this.media.asObservable().pipe(
      filter((changes: MediaChange[]) => changes.length > 0),
      map((changes: MediaChange[]) => changes[0])
    );

    this.ngZone.runOutsideAngular(() => {
      this.handleShadowModifier();
    });

    this.setupListeners();

    merge(
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)),
      of(true)
    )
      .pipe(
        tap(() =>
          this.ngZone.runOutsideAngular(() => this.handleStyleAfterUnfixed())
        ),
        tap(() => {
          // Reset dynamic bar to true on router changes (To reset scroll event)
          this.dynamicBarSubject$.next(true);
        }),
        tap(() => {
          this.setupListeners();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private setupListeners() {
    const baseURL = url.parse(this.router.url).pathname;

    if (
      this.routesService.isNoBannerRoute(baseURL) &&
      this?.dynamicBar?.nativeElement
    ) {
      this.dynamicBar.nativeElement.style.display = 'none';
      this.layoutStoreService.setDisableBarPlaceholder(true);

      this.stopListening();
    } else {
      this.layoutStoreService.setDisableBarPlaceholder(false);
      this.startListening();
    }
  }

  private startListening() {
    this.ngZone.runOutsideAngular(() => {
      if (
        !this.handleDynamicBarSub ||
        this.handleDynamicBarSub.closed === true
      ) {
        this.handleDynamicBarSub = this.handleDynamicBar();
      }

      if (
        !this.handleScrollEventSub ||
        this.handleScrollEventSub.closed === true
      ) {
        this.handleScrollEventSub = this.handleScrollEvent();
      }
    });
  }

  private stopListening() {
    this.handleDynamicBarSub?.unsubscribe();
    this.handleScrollEventSub?.unsubscribe();
  }

  private handleShadowModifier() {
    this.shadowSubject$
      .asObservable()
      .pipe(
        debounceTime(250),
        tap((isShadow) => {
          if (isShadow) {
            this.addShadow();
          } else {
            this.removeShadow();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  handleDynamicBar() {
    return combineLatest([
      this.dynamicBarSubject$.asObservable().pipe(startWith(true)),
      this.mediaChange$,
    ])
      .pipe(
        debounceTime(250),
        distinctUntilChanged(([show1], [show2]) => {
          return show1 === show2;
        }),
        tap(([show, media]) => {
          const trans = this.computeTransByMediaQuery(show, media);
          if (show) {
            this.showDynamicBar(trans);
          } else {
            this.hideDynamicBar(trans);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private computeTransByMediaQuery(show: boolean, change: MediaChange) {
    if (show) {
      return change.mqAlias === 'sm' ||
        change.mqAlias === 'xs' ||
        change.mqAlias === 'xxs'
        ? '-50px'
        : '-70px';
    } else {
      return change.mqAlias === 'sm' ||
        change.mqAlias === 'xs' ||
        change.mqAlias === 'xxs'
        ? '-50px'
        : '-65px';
    }
  }

  private animateEl(nativeElement, animation, timing, trans, doneCb) {
    const factory = this.animationBuilder.build(animation({ timing, trans }));
    const player = factory.create(nativeElement);

    player.onDone(() => {
      this.destroyPlayer(player);
      doneCb();
    });

    player.play();
  }

  destroyPlayer(player: AnimationPlayer) {
    if (player) {
      player.destroy();
    }
  }

  handleScrollEvent() {
    return fromEvent(window, 'scroll')
      .pipe(
        tap(() => this.onScroll()),
        map(
          () =>
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0
        ),
        pairwise(),
        map(([y1, y2]): ScrollEvent => {
          if (y2 < y1) {
            return 'UP';
          }

          if (y2 > y1) {
            return 'DOWN';
          }

          return 'NO';
        }),
        distinctUntilChanged(),
        tap((v) => this.onScrollMove(v)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  onScroll() {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (verticalOffset > 10) {
      this.fired = true;
      this.shadowSubject$.next(true);
    } else {
      if (this.fired) {
        this.shadowSubject$.next(false);
        this.handleStyleAfterUnfixed();
      }
    }
  }

  onScrollMove(e: ScrollEvent) {
    if (e === 'UP') {
      this.dynamicBarSubject$.next(true);
    } else if (e === 'DOWN') {
      this.dynamicBarSubject$.next(false);
    }
  }

  showDynamicBar(trans: string) {
    this.renderer.setStyle(this.dynamicBar.nativeElement, 'display', 'inline');

    this.animateEl(this.el.nativeElement, this.fadeIn, 270, trans, () => {});
  }

  hideDynamicBar(trans: string) {
    this.animateEl(this.el.nativeElement, this.fadeOut, 230, trans, () => {
      this.renderer.setStyle(this.dynamicBar.nativeElement, 'display', 'none');
    });
  }

  private fadeIn({ timing, trans }): AnimationMetadata[] {
    return [
      animate(
        timing,
        keyframes([
          style({ transform: `translateY(${trans})`, offset: 0 }),
          style({ transform: 'translateY(0)', offset: 1 }),
        ])
      ),
    ];
  }

  private fadeOut({ timing, trans }): AnimationMetadata[] {
    return [
      animate(
        timing,
        keyframes([style({ transform: `translateY(${trans})`, offset: 1 })])
      ),
    ];
  }

  handleStyleAfterUnfixed() {
    const baseURL = url.parse(this.router.url).pathname;

    if (this.routesService.isLongBannerRoute(baseURL)) {
      this.shadowSubject$.next(false);
    } else {
      this.shadowSubject$.next(true);
    }
  }

  private removeShadow() {
    this.renderer.removeClass(this.el.nativeElement, 'header-box-shadow');
  }

  private addShadow() {
    this.renderer.addClass(this.el.nativeElement, 'header-box-shadow');
  }
}
