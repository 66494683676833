import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[rhbnbLazySize]'
})
export class LazySizesDirective implements OnInit {

  _imgSrc: string;
  @Input() set imgSrc(value: string) {
    this._imgSrc = value;
    this.setImgSrc();
  }
  get imgSrc() {
    return this._imgSrc;
  }
  @Input() errorImage: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
  }

  setImgSrc() {
    this.renderer.addClass(this.el.nativeElement, 'lazyload');
    this.renderer.setAttribute(this.el.nativeElement, 'data-src', this.imgSrc);
    this.renderer.setAttribute(this.el.nativeElement, 'data-sizes', 'auto');
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.errorImage);
  }

  @HostListener('error') onError() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.errorImage);
    this.renderer.setAttribute(this.el.nativeElement, 'loading', 'lazy');
  }
}
