<div
  fxFlex="100"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  class="destination-item"
  (click)="onDestinationChange()"
>
  <div
    class="image-container"
    fxFlex="100"
    fxLayout="column"
    fxLayoutAlign="space-between stretch"
  >
    <img
      rhbnbLazySize
      [alt]="destination?.name"
      [errorImage]="defaultImage"
      [imgSrc]="destination?.images | elementImage:true | filterImage:{w:720, h:0}"
    />

    <div class="caption mat-title">
      {{ destination?.name }}
    </div>
  </div>

  <div fxFlex="100" class="content-container">
    <p class="mat-body-2">{{ destination?.description }}</p>
  </div>
</div>
