import { Injectable, ErrorHandler, Renderer2 } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { debounceTime, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  private renderer2: Renderer2;
  private readonly errorBus$$ = new Subject();
  errorBus$ = this.errorBus$$.asObservable();

  constructor(
    private logger: NGXLogger
  ) {
    // this.renderer2 = rendererFactory2.createRenderer(null, null);

    this.errorBus$
      .pipe(
        debounceTime(3000),
        tap(err => this.sendErrorToServer(err)),
      )
      .subscribe();
  }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    this.logger.error(error);
    this.errorBus$$.next(error);
  }

  private sendErrorToServer(error: any) {
  }

  private putErrorIntoPage(error: any) {
    let div = document.getElementById('error-log');

    if (!div) {
      div = this.renderer2.createElement('div');
      this.renderer2.setAttribute(div, 'id', 'error-log');
      this.renderer2.setStyle(div, 'width', '100%');
      this.renderer2.setStyle(div, 'font-size', '120%');
      this.renderer2.setStyle(div, 'position', 'absolute');
      this.renderer2.setStyle(div, 'background', 'black');
      this.renderer2.setStyle(div, 'color', 'red');
      this.renderer2.setStyle(div, 'top', '0');
      this.renderer2.setStyle(div, 'z-index', '999999');

      this.renderer2.appendChild(document.body, div);
    }

    const child = this.renderer2.createElement('div');
    this.renderer2.setStyle(child, 'padding-bottom', '5px');
    const line = `${error}: ${error.stack}`;

    const text = this.renderer2.createText(line);
    this.renderer2.appendChild(div, child);
    this.renderer2.appendChild(child, text);
  }

}
