import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rhbnb-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.sass']
})
export class ContainerComponent implements OnInit {

  @Input() xs = '90';
  @Input() gtXs = '90';
  @Input() gtSm = '90';
  @Input() gtMd = '85';
  @Input() gtLg = '1140px';

  @Input()
  set responsive(value: boolean) {
    if (value) {
      this.gtXs = '';
      this.gtSm = '';
      this.gtMd = '';
      this.gtLg = '';
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
