import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Output,
  EventEmitter
} from '@angular/core';
import { merge, Observable } from 'rxjs';
import { delay, map, mergeMap, startWith, takeUntil, tap } from 'rxjs/operators';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';

import { SelectItemComponent } from './select-item.component';

@Component({
  selector: 'rhbnb-multiple-select-list',
  templateUrl: './multiple-select-list.component.html',
  styleUrls: ['./multiple-select-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleSelectListComponent extends WithUnsubscribe()
  implements OnInit, AfterContentInit {

  @ContentChildren(SelectItemComponent, {descendants: true})
  cmps: QueryList<SelectItemComponent>;

  items$: Observable<SelectItemComponent[]>;

  @Output() itemsChange = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.items$ = this.cmps.changes
      .pipe(startWith(""))
      .pipe(delay(0))
      .pipe(
        map(() => this.cmps.toArray())
      );

    this.items$
      .pipe(
        map(cmps => merge(...cmps.map(c => c.selectedChange))),
        mergeMap(ob => ob),
        mergeMap(() => this.items$),
        map(items => items.filter(i => i.selected).map(i => i.data)),
        tap(ss => this.itemsChange.emit(ss)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe()
    ;
  }
}
