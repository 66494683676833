import { Inject, Injectable } from '@angular/core';
import { OutingFacility } from '@rhbnb-nx-ws/domain';
import { AbstractEavService} from '../util/abstract-eav-service';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';


@Injectable({
  providedIn: 'root'
})
export class OutingFacilityService extends AbstractEavService<OutingFacility> {

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'outingfacility', apiURL);
  }
}
