<div class="list-container">
  <button *ngIf="!(start$ | async)" class="prev"
          (click)="prev()" mat-mini-fab aria-label="Prev">
    <mat-icon svgIcon="chevron-left"></mat-icon>
  </button>

  <div class="list-wrapper"
       #list
       (scroll)="onScroll($event)"
       fxLayout="row"
       fxLayoutAlign="start stretch"
       fxLayoutGap="15px grid">

    <rhbnb-destination-slide-item
      #item
      class="slide-item"
      fxFlex.xs="60"
      fxFlex.gt-xs="60"
      fxFlex.gt-sm="35"
      fxFlex.xxs="90"
      fxLayout="row wrap"
      *ngFor="let destination of destinations; trackBy: trackByItems;"
      [destination]="destination"
      (destinationChange)="onClickAction($event)">
    </rhbnb-destination-slide-item>

  </div>

  <button *ngIf="!(end$ | async)" class="next"
          (click)="next()" mat-mini-fab aria-label="Next">
    <mat-icon svgIcon="chevron-right"></mat-icon>
  </button>
</div>
