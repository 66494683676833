import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRangeModel, Destination } from '@rhbnb-nx-ws/domain';
import { map } from 'rxjs/operators';

import { FilterChangesModel, FilterDataChange } from '../filter-changes.model';

@Component({
  selector: 'rhbnb-outing-filter',
  templateUrl: './outing-filter.component.html',
  styleUrls: ['./outing-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutingFilterComponent implements OnInit {

  date$: Observable<any>;
  provinces$: Observable<any>;

  @Input() destinations$: Observable<Destination[]>;
  @Input() destination$: Observable<number | string>;
  @Input() dateRange$: Observable<DateRangeModel>;
  @Input() guest$: Observable<number>;
  @Input() loading$: Observable<boolean>;
  @Input() alternateColor = false;

  @Output() modelChange = new EventEmitter<FilterDataChange>();

  dateLabel$: Observable<string>;

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.dateLabel$ =
      this.dateRange$.pipe(
        map(sd => (sd?.startDate && sd.startDate.isValid())
          ? sd?.startDate.format('YYYY-MM-DD') : ''),
      )
    ;

    this.provinces$ = this.destinations$
      .pipe(
        map(ds => ds.map(d => (d as any)?.province)),
        map(ds => [...new Set(ds)])
      )
    ;

    this.date$ = this.dateRange$
      .pipe(
        map(range => ({ startDate: range?.startDate }))
      )
    ;
  }

  onDestinationChange(province: string): void {
    this.modelChange.emit({
      type: FilterChangesModel.OUTING_DESTINATION,
      data: province
    });
  }

  onDateChange(event): void {
    this.modelChange.emit({
      type: FilterChangesModel.SINGLE_DATE,
      data: event
    });
  }

  onGuestChange(event): void {
    this.modelChange.emit({
      type: FilterChangesModel.GUESTS,
      data: event
    });
  }
}
