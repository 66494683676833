<div fxLayout="column" fxLayoutAlign="center center">
  <h2>{{ header }}</h2>

  <h4>
    {{ subheader }}
  </h4>

  <h4>
    <ng-container *ngIf="linkText">
      <a href="{{url}}">{{linkText}}</a>
    </ng-container>
  </h4>
  <img src="assets/images/empty-list-01.png">
</div>
