import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { TranslocoService } from '@ngneat/transloco';
import { MAPBOX_ACCESS_TOKEN } from '@rhbnb-nx-ws/global-tokens';
import { DOCUMENT } from '@angular/common';

import { MapLoaderService } from './map-loader.service';

declare let mapboxgl: any;

@Component({
  selector: 'rhbnb-house-map',
  templateUrl: './house-map.component.html',
  styleUrls: ['./house-map.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HouseMapComponent implements OnInit, AfterViewInit {
  @ViewChild('map')
  mapElement: ElementRef;

  map: any;
  marker: any;

  @Input() style = 'mapbox://styles/mapbox/streets-v11';
  @Input() coordinate = [0, 0];
  @Input() zoom = 14;

  constructor(
    @Inject(MAPBOX_ACCESS_TOKEN) private token: string,
    private transloco: TranslocoService,
    private ngZone: NgZone,
    private mapLoaderService: MapLoaderService,
    @Inject(DOCUMENT) private readonly document: any
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.mapLoaderService.lazyLoadMap().subscribe((_) => {
      if (!mapboxgl) {
        mapboxgl = this.document.defaultView.mapboxgl;
      }

      this.initializeMap();
    });
  }

  private initializeMap(): void {
    mapboxgl.accessToken = this.token;
    this.ngZone.runOutsideAngular(() => this.buildMap());
  }

  buildMap(): void {
    const language = this.transloco.getActiveLang();

    this.map = new mapboxgl.Map({
      container: this.mapElement?.nativeElement,
      style: this.style,
      zoom: this.zoom,
      maxZoom: 15,
      center: this.coordinate,
    });

    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());

    // Change lang
    const mapboxLanguage = new MapboxLanguage({
      defaultLanguage: language,
    });

    this.map.addControl(mapboxLanguage);

    // Add listeners
    this.map.on('load', () => {
      // Update map view
      this.map.resize();

      // Add Marker on current location
      this.createMaker(this.coordinate);
    });
  }

  createMaker(coordinates: any): void {
    this.map.loadImage('assets/images/map-marker.png', (error, image) => {
      if (error) {
        throw error;
      }

      this.map.addImage('marker', image);

      this.map.addSource('point', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates,
              },
            },
          ],
        },
      });

      this.map.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'point',
        layout: {
          'icon-image': 'marker',
          'icon-size': 0.25,
        },
      });
    });
  }
}
