import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ApiResponse,
  EntityUserChatChannel,
  PageData,
} from '@rhbnb-nx-ws/domain';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, throwError as observableThrowError } from 'rxjs';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

import { AbstractDataService } from '../util';

const CACHE_TTL = 15 * 60 * 1000; // 15 mins

@Injectable({
  providedIn: 'root',
})
export class ChatService extends AbstractDataService<any> {
  getChannelCacheKey = 'getChannelCache';
  getUserTokenCacheKey = 'getUserTokenCache';
  getUnreadCountCacheKey = 'getUnreadCountCache';
  getMeChatChannelsAsHostCacheKey = 'getMeChatChannelsAsHostCache';
  getMeChatChannelsAsClientCacheKey = 'getMeChatChannelsAsClientCache';

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'chat', apiURL);
  }

  getChannel(houseId: string) {
    const source$ = this.http.get<ApiResponse<{ channel: string }>>(
      `${this.apiURL}/${this.endpointName}/get-channel-by-house/${houseId}`
    );

    return this.getCacheEntry(
      this.getCacheKeyWithParam(this.getChannelCacheKey, `${houseId}`)
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
      .pipe(catchError((error) => observableThrowError(error)));
  }

  getUserToken() {
    const source$ = this.http.get<
      ApiResponse<{ token: string; userId: string }>
    >(`${this.apiURL}/${this.endpointName}/me/token`).pipe(
      catchError(() => EMPTY)
    );

    return this.getCacheEntry<ApiResponse<{ token: string; userId: string }>>(this.getUserTokenCacheKey)
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
      .pipe(catchError((error) => observableThrowError(error)));
  }

  getUnreadCount() {
    const source$ = this.http.get<ApiResponse<number>>(
      `${this.apiURL}/${this.endpointName}/me/unread`
    );

    return this.getCacheEntry<ApiResponse<number>>(this.getUnreadCountCacheKey)
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
      .pipe(catchError((error) => observableThrowError(error)));
  }

  getMeChatChannelsAsHost(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string
  ) {
    const params = this.buildParams(page, limit, sort, order, q);
    const source$ = this.http.get<ApiResponse<PageData<EntityUserChatChannel>>>(
      `${this.apiURL}/${this.endpointName}/me/chat-channels/as-host`,
      { ...{ params }, ...{ observe: 'response' } }
    );

    return this.getCacheEntry(
      this.getCacheKeyWithParam(
        this.getMeChatChannelsAsHostCacheKey,
        params.toString()
      )
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
      .pipe(
        map(
          (res: HttpResponse<ApiResponse<PageData<EntityUserChatChannel>>>) => {
            return {
              data: {
                data: res.body.data,
                total: +res.headers.get('Page-Total'),
              } as any,
              success: res.body.success,
            } as ApiResponse<PageData<EntityUserChatChannel>>;
          }
        ),
        catchError((error) => observableThrowError(error))
      );
  }

  getMeChatChannelsAsClient(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string
  ) {
    const params = this.buildParams(page, limit, sort, order, q);
    const source$ = this.http.get<ApiResponse<PageData<EntityUserChatChannel>>>(
      `${this.apiURL}/${this.endpointName}/me/chat-channels/as-client`,
      { ...{ params }, ...{ observe: 'response' } }
    );

    return this.getCacheEntry(
      this.getCacheKeyWithParam(
        this.getMeChatChannelsAsClientCacheKey,
        params.toString()
      )
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
      .pipe(
        map(
          (res: HttpResponse<ApiResponse<PageData<EntityUserChatChannel>>>) => {
            return {
              data: {
                data: res.body.data,
                total: +res.headers.get('Page-Total'),
              } as any,
              success: res.body.success,
            } as ApiResponse<PageData<EntityUserChatChannel>>;
          }
        ),
        catchError((error) => observableThrowError(error))
      );
  }
}
