import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rhbnb-login-or-register-dialog',
  templateUrl: './login-or-register-dialog.component.html',
  styleUrls: ['./login-or-register-dialog.component.css'],
})
export class LoginOrRegisterDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {}

  onLogin() {
    this.data?.login();
  }
}
