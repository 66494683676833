// THIS FILE IS GENERATED AUTOMATICALLY BY extract-svg-icons.js
// DO NOT EDIT IT MANUALLY - YOU WILL LOSE YOUR CHANGES
export interface IconSet {
 basePath: string;
 icons: Array<string>;
}

export const MDI_SVG_ICONS: IconSet = {
  basePath: 'assets/icons/flaticons/mdi',
  icons: [
    'account-circle',
    'facebook-messenger',
    'email',
    'whatsapp',
    'message-processing',
    'clipboard-text',
    'twitter',
    'emoticon-sad-outline',
    'thumb-up-outline',
    'speedometer-medium',
    'speedometer-slow',
    'speedometer',
    'close-circle-outline',
    'close',
    'chevron-left',
    'chevron-right',
    'alert',
    'share-variant',
    'check-circle',
    'account-convert',
    'exit-run',
    'login',
    'format-quote-close',
    'car-settings',
    'diving-flippers',
    'pool',
    'home-circle-outline',
    'home-city',
    'telegram',
    'instagram',
    'facebook',
    'clipboard-alert-outline',
    'check',
    'pencil',
    'alert-outline',
    'star',
    'image-multiple',
    'check-circle-outline',
    'home-account',
    'briefcase-variant',
    'comment',
    'message-alert',
    'forum',
    'calendar-check',
    'virus',
    'chat',
    'send',
    'comment-alert',
    'view-list',
    'map',
    'eye-off',
    'eye',
    'account-plus'
  ]
};
