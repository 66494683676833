import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whatsAppNumber'
})

export class WhatsAppNumberPipe implements PipeTransform {
  transform(msg: string, ...args: any[]): any {
    const WA_NUMBER = args[0];
    return `https://wa.me/${WA_NUMBER}?${encodeURIComponent(`text=${msg}`)}`;
  }
}
