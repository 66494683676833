import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { BASE_ICONS_PATH_TOKEN } from '@rhbnb-nx-ws/global-tokens';

export interface ItemWithIcon {
  name: string;
  iconClass: string;
}

@Component({
  selector: 'rhbnb-icon-list-item',
  template: `
    <div
      class="service-item d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-sm-center w-100"
    >
      <div class="me-sm-2 text-center text-sm-start">
        <img [src]="getIconPath(item.iconClass)" />
      </div>

      <div class=" text-center text-sm-start">{{ item?.name }}</div>
    </div>
  `,
  styles: [
    `
      .center {
        text-align: center;
      }

      img {
        height: 40px;
        width: auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconListItemComponent implements OnInit {
  @Input() item: ItemWithIcon;

  constructor(@Inject(BASE_ICONS_PATH_TOKEN) public iconPath: string) {}

  ngOnInit(): void {}

  getIconPath(icon: string) {
    return `${this.iconPath}/${icon}.svg`;
  }
}
