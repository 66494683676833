import { Pipe, PipeTransform } from '@angular/core';
import { PriceUtilService } from '@rhbnb-nx-ws/services';
import { Price } from '@rhbnb-nx-ws/domain';

@Pipe({
  name: 'computePrice'
})
export class ComputePricePipe implements PipeTransform {

  constructor(
    private priceUtilService: PriceUtilService
  ) {}

  transform(price: Price, ...args: any[]): number {
    const { guests } = args[0];

    const amount = this.priceUtilService.computePrice(price, guests);
    return amount ? amount : 0;
  }

}
