<div class="header mx-10 my-20 load-animate"></div>

<div class="list-container">
  <div class="list-wrapper"
       fxLayout="row"
       fxLayoutAlign="start stretch"
       fxLayoutGap="15px grid">

    <rhbnb-destination-item-loading
      class="slide-item"
      fxFlex.xs="60"
      fxFlex.gt-xs="60"
      fxFlex.gt-sm="35"
      fxFlex.xxs="90"
      fxLayout="row wrap"
      *ngFor="let c of count">
    </rhbnb-destination-item-loading>

  </div>
</div>
