import { Payment } from './payment.model';
import { BaseModel } from './base.model';
import { Client } from './client.model';
import { Advert } from './advert.model';
import { DiscountCoupon } from './discount-coupon.model';
import { ReservationModificationRequest } from './reservation-modification-request.model';

export enum ReservationStatus {
  STATUS_PENDING = 'PENDIENTE',
  STATUS_CONFIRMED = 'CONFIRMADA',
  STATUS_COMPLETED = 'COMPLETADA',
  STATUS_CANCELED = 'CANCELADA'
}

export class Reservation extends BaseModel {
  public client?: Client | number | string;
  public advert?: Advert | number | string;
  public checkIn?: string;
  public checkOut?: string;
  public data?: any;
  public payment?: Payment | number | string ;
  public discountCoupon?: DiscountCoupon | number | string ;
  public status?: ReservationStatus;
  public modifications?: ReservationModificationRequest[];

  constructor(init?: Reservation) {
    super();
    Object.assign(this, init);
  }
}
