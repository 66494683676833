<div fxLayout="column" class="chat-wrapper">
  <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div
      class="messages-root"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="none"
      #chatList (scroll)="onWindowScroll($event)">

      <div class="opaque" fxFlex *ngIf="loadingMore && !failed">
        {{ 'messages.loading_more' | transloco }}
      </div>

      <ng-container *ngIf="!loading; else loadingMessagesArea">
        <div *ngIf="failed; else messagesArea"
             fxFlex
             fxLayout="row wrap"
             fxLayoutAlign="center center"
        >
          <div class="mb-5" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <mat-icon color="warn" svgIcon="comment-alert"></mat-icon>
          </div>

          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <button color="warn"
                    (click)="failedChange.emit()"
                    mat-raised-button>
              {{ 'messages.reload_channel' | transloco }}
            </button>
          </div>

        </div>

        <ng-template #messagesArea>
          <div
            *ngFor="let group of groupedByDate; let groupIndex = index; let groupFirst = first; trackBy: trackByGroupFn"
            class="date-wrapper"
            fxFlex
            fxLayout="column"
            fxLayoutAlign="end center"
          >

            <div fxFlex="0 1 auto"
                 fxLayout="row"
                 fxLayoutAlign="center center"
                 class="date-holder sticky"
            >
              <span>
                {{ fromUnix(group[0]?.ts?.$date) | amDateFormat: 'DD/MM/YYYY' }}
              </span>
            </div>

            <div *ngFor="let m of group; let index = index; let first = first; trackBy: trackByFn"
                 class="message-wrapper"
                 fxFlex="0 1 auto"
                 [fxFlexAlign]="alignMessage(m)"
                 [class.first]="groupFirst && first"
            >
              <rhbnb-chat-message
                [message]="m"
                [showUser]="m.isFirstOfUser"
                [self]="m?.self"
              ></rhbnb-chat-message>
            </div>
          </div>
        </ng-template>

      </ng-container>

      <ng-template #loadingMessagesArea>
        <rhbnb-chat-loading></rhbnb-chat-loading>
      </ng-template>

      <div class="opaque" *ngIf="typings?.length > 0">
        {{ 'messages.typings' | transloco: {users: typings.join(', ')} }}
      </div>
    </div>

    <div fxFlex="0 1 auto" class="bottom">
      <rhbnb-chat-type-box
        (typingChange)="onTypingChange($event)"
        (focusChange)="onInputFocusChange()"
        (messageChange)="onNewMessage($event)"></rhbnb-chat-type-box>
    </div>
  </div>
</div>
