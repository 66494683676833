import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { House } from '@rhbnb-nx-ws/domain';
import { CURRENCY_TABLE$, DEFAULT_CURRENCY$ } from '@rhbnb-nx-ws/global-tokens';
import { Observable } from 'rxjs';

@Component({
  selector: 'rhbnb-house-tiny-price',
  templateUrl: './house-tiny-price.component.html',
  styleUrls: ['./house-tiny-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HouseTinyPriceComponent implements OnInit {

  @Input() item: House;

  constructor(
    @Inject(CURRENCY_TABLE$) public currencyTable$: Observable<any>,
    @Inject(DEFAULT_CURRENCY$) public currentCurrency$: Observable<any>,
  ) { }

  ngOnInit(): void {
  }
}
