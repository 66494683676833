import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { Outing, Price, PriceType } from '@rhbnb-nx-ws/domain';
import { CURRENCY_TABLE$, DEFAULT_CURRENCY$ } from '@rhbnb-nx-ws/global-tokens';
import { Observable } from 'rxjs';
import { PriceUtilService } from '@rhbnb-nx-ws/services';

@Component({
  selector: 'rhbnb-outing-tiny-price',
  templateUrl: './outing-tiny-price.component.html',
  styleUrls: ['./outing-tiny-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutingTinyPriceComponent implements OnInit {

  @Input() item: Outing;
  type: PriceType;

  constructor(
    @Inject(CURRENCY_TABLE$) public currencyTable$: Observable<any>,
    @Inject(DEFAULT_CURRENCY$) public currentCurrency$: Observable<any>,
    private priceUtilService: PriceUtilService,
  ) { }

  ngOnInit(): void {
    this.type = (this.item?.price as Price)?.type || (this.item?.price as Price)?.discr;
  }

  getMaxPrice() {
    const [, max] = this.priceUtilService.getRangePriceLimits(
      this.item?.price as Price
    );
    return max;
  }

  getMinPrice() {
    const [min] = this.priceUtilService.getRangePriceLimits(
      this.item?.price as Price
    );
    return min;
  }
}
