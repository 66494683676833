import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Client } from '@rhbnb-nx-ws/domain';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

import { AbstractDataService } from '../util';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends AbstractDataService<Client> {
  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string,
  ) {
    super(http, 'clients', apiURL);
  }
}
