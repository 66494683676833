import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContainerComponent } from './container/container.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const COMPONENTS = [
  ContainerComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class ContainerModule { }
