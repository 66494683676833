<div class="reconnect-wrapper" *ngIf="chatReconnecting$ | async">
  {{ 'messages.reconnecting' | transloco }}

  <span *ngIf="(chatHandlerService.reconnectTimer$ | async) as timer">
      {{ 'messages.tray_again_in' | transloco: { secs: timer } }}

    <a href="#" (click)="chatHandlerService.manualReconnect($event)">
        {{ 'messages.tray_now' | transloco }}
      </a>
    </span>
</div>
