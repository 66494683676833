import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NotifyService } from '@rhbnb-nx-ws/services';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'rhbnb-not-found',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.sass']
})
export class NotFoundPage implements OnInit {

  constructor(
    private router: Router,
    private notify: NotifyService,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.translocoService.selectTranslate('core.not_found_msg')
      .pipe(
        take(1),
        tap(msg => this.notify.info(msg)),
        tap(() => this.goHome()),
      )
      .subscribe();
  }

  goHome() {
    this.router.navigate(['/']);
  }
}
