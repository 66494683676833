import { Injectable } from '@angular/core';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  toArray
} from 'rxjs/operators';
import { combineLatest, EMPTY, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { rethrowError } from '@rhbnb-nx-ws/utils';
import { Message, Time } from '@jjbenitez/glue-rocket-lib';

import { StoreService, ChatData, Chats } from './store';
import { ChatService } from './chat.service';
import { BaseEvent } from './events';
import { v4 as uuid } from 'uuid';
import { ChatWindowComponent } from './chat-window';

@Injectable()
export class ChatHandlerService {
  typingSubId: string;
  newMessageSub: Subscription;
  reconnectDelay = 1000;
  reconnectSub: Subscription;
  reconnectTimerSub: Subscription;
  userSub: Subscription;
  keepPingSub: Subscription;
  lostMessages = [];
  loadingOnTop = false;

  reconnectTimer$: Observable<number>;
  private unsubscribe$ = new Subject<void>();

  newMessageBus$$ = new Subject<void>();
  subscriptionUpdate$$ = new Subject<any>();
  restoringCxn$$ = new Subject<void>();
  afterProcessLostMessages$$ = new Subject<void>();

  chatUser$: Observable<any> = this.chatStoreService.getChatUser();
  groupsOpened$: Observable<Chats> = this.chatStoreService.getChats();
  groupMessages$: (id: any) => Observable<any> = groupId => this.chatStoreService.getChatGroupMessages(groupId);
  groupData$: (id: any) => Observable<ChatData> = groupId =>
    this.chatStoreService.getChatGroupData(groupId);

  constructor(
    private chatStoreService: StoreService,
    private chatService: ChatService
  ) {
  }

  initRoom(roomId: string, onScrollFn: () => void) {
    this.groupData$(roomId)
      .pipe(
        take(1),
        filter(g => !g || g?.failed),
        tap(async () => {
          try {
            // Load messages only when select room
            this.chatStoreService.pushChatGroupMessages(
              roomId, []
            );

            this.chatStoreService.setChatGroupInitializing(
              roomId, true
            );

            await this.loadInitialMessageHistory(roomId);
            await this.chatService.readMessages(roomId);

            this.chatStoreService.setChatGroupInitializing(
              roomId,
              false
            );
            this.chatStoreService.setChatGroupFailed(
              roomId,
              false
            );

            this.typingSubId = await this.chatService.subscribeToTypings(
              roomId
            );
          } catch (e) {
            console.error(e);

            this.chatStoreService.setChatGroupInitializing(
              roomId,
              false
            );

            this.chatStoreService.setChatGroupFailed(
              roomId,
              true
            );
          }
        }),
        catchError(() => {
          this.chatStoreService.setChatGroupFailed(
            roomId,
            true
          );

          this.chatStoreService.setChatGroupInitializing(
            roomId,
            false
          );

          return EMPTY;
        }),
        switchMap(() => this.chatStoreService.getChatGroupInitializing(roomId)),
        filter(i => !!i),
        delay(350),
        tap(() => onScrollFn()),
      )
      .subscribe();
  }

  async loadInitialMessageHistory(groupId: string) {
    return rethrowError(async () => {
      const { items, hasMore } = await this.chatService.loadGroupHistory(
        groupId,
        Date.now(),
        null
      );

      this.chatStoreService.pushChatGroupMessages(groupId, items.reverse(), hasMore);
    })();
  }

  async unsubscribeForTypings() {
    if (this.typingSubId) {
      await this.chatService.unsubscribeFromGroupTypings(
        this.typingSubId
      );

      this.typingSubId = undefined;
    }
  }

  subscribeToNewMessages() {
    this.newMessageSub = this.chatService.bus$
      .pipe(
        tap(m => this.handleIncomingMessages(m)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  handleIncomingMessages(e: BaseEvent): void {
    switch (e.type) {
      case 'Messages':
        this.chatStoreService.getChatGroupData(e.payload[0]?.rid)
          .pipe(
            take(1),
            filter(gd => !!gd),
            tap(() => {
              this.chatStoreService.pushChatGroupMessages(
                e.payload[0]?.rid,
                e.payload
              );

              this.newMessageBus$$.next();
            })
          )
          .subscribe();
        break;

      case 'Message':
        this.chatStoreService.getChatGroupData(e.payload[0]?.rid)
          .pipe(
            take(1),
            filter(gd => !!gd),
            tap(() => {
              this.chatStoreService.pushChatGroupMessages(
                e.payload[0]?.rid,
                [e.payload]
              );

              this.newMessageBus$$.next();
            })
          )
          .subscribe();
        break;

      case 'GroupTyping':
        const [isTyping, user, groupId] = e.payload;

        if (isTyping) {
          this.chatStoreService.addChatGroupTypingUser(groupId, user);
        } else {
          this.chatStoreService.deleteChatGroupTypingUser(groupId, user);
        }

        break;

      case 'UserSubscriptionUpdate':
        const [, group] = e.payload;

        // Update total unread after update specific
        // room unread counter
        this.subscriptionUpdate$$.next(group);

        break;

      case 'Closed':
        this.chatService.cleanGroupSubs();

        // Keep the first chat lost connection time
        // To ask missed messages from empty rooms
        this.chatStoreService.getChatLostConnectionTime()
          .pipe(
            take(1),
            tap(lostTime => {
              if (!lostTime) {
                this.chatStoreService.setChatLostConnectionTime(Date.now());
                this.chatStoreService.setChatReconnecting(true);
              }
            })
          )
          .subscribe();

        this.reconnect();
        break;

      case 'Reconnected':
        this.onRestoreConnection();
        break;
    }
  }

  manualReconnect(e) {
    e.preventDefault();

    this.reconnectDelay = 1000;
    this.reconnect();
  }

  reconnect() {
    if (this.reconnectSub) {
      this.reconnectSub.unsubscribe();
    }

    this.initReconnectTimer();

    this.reconnectSub = of(true)
      .pipe(
        delay(this.reconnectDelay),
        tap(() => this.chatService.reconnect()),
        tap(() => this.reconnectDelay = this.reconnectDelay * 2),
        take(1)
      )
      .subscribe();
  }

  initReconnectTimer() {
    if (this.reconnectTimerSub) {
      this.reconnectTimerSub.unsubscribe();
    }

    this.reconnectTimer$ = interval(1000)
      .pipe(
        map(t => (this.reconnectDelay / 1000) - t),
        filter(t => t >= 0),
      )
    ;

    this.reconnectTimerSub = this.reconnectTimer$.subscribe();
  }

  async onRestoreConnection() {
    return rethrowError(async () => {
      this.restoringCxn$$.next();

      this.chatStoreService.loginUserIntoChat();
      this.userSub?.unsubscribe();
      this.keepPingSub?.unsubscribe();

      const getLastMessageTime = ((d: ChatData) => {
        const [last] = d.messages.slice(-1);
        return last?.ts?.$date;
      });

      // Load possible missing messages
      combineLatest([
        this.groupsOpened$,
        this.chatStoreService.getChatLostConnectionTime()
      ])
        .pipe(
          take(1),
          filter(([opened,]) => !!opened),
          mergeMap(([opened, _t]) =>
            Object.keys(opened).map(k => ({ group: k, data: opened[k], last: getLastMessageTime(opened[k]) ?? _t }))),
          switchMap(c => [
            this.loadMissingMessages(c.group, c.last),
            this.chatService.readMessages(c.group)
          ]),
          toArray(),
          take(1)
        )
        .subscribe();

      await this.processLostMessages();

      this.afterProcessLostMessages$$.next();

      this.subscribeUserSubscription();
      this.subscribeToKeepPing();

      this.chatStoreService.setChatLostConnectionTime(undefined);
      this.chatStoreService.setChatReconnecting(false);

      this.reconnectDelay = 0;
      if (this.reconnectTimerSub) {
        this.reconnectTimerSub.unsubscribe();
      }
    })();
  }

  async loadMissingMessages(groupId: string, lastDate: number) {
    return rethrowError(async () => {
      if (lastDate) {
        const messages = await this.chatService.loadMissedMessages(
          groupId,
          lastDate
        );

        this.chatStoreService.pushChatGroupMessages(groupId, messages.reverse());
      }
    })();
  }

  async processLostMessages() {
    return rethrowError(async () => {
      for (const [msg, group, id] of this.lostMessages) {
        await this.sendMessageOrKeep(msg, group, id);
      }
    })();
  }

  async sendMessageOrKeep(message: string, group: string, id: string) {
    try {
      await this.chatService.readMessages(group);
      const remoteMessage = await this.chatService.sendPlainMessage(
        message,
        group,
        id
      );

      // Replace local message from message list using the real message
      this.chatStoreService.replaceChatGroupMessages(
        group,
        remoteMessage?.result,
        id
      );
    } catch (e) {
      this.lostMessages.push(
        [message, group, id]
      );
    }
  }

  async writeMessage(message: string, channel: string) {
    return rethrowError(async () => {
      const id = `${this.chatService.getSessionId()}_${uuid()}`;

      if (message.length > 0) {
        await this.writeLocalMessage(id, message, channel);
        await this.sendMessageOrKeep(message, channel, id);
      }
    })();
  }

  async writeLocalMessage(id: string, msg: string, channel: string) {
    return rethrowError(async () => {
      const u = await this.chatUser$
        .pipe(
          take(1)
        )
        .toPromise();

      const _msg = {
        _id: id,
        msg,
        local: true,
        ts: new Time(Date.now()),
        u: { _id: u?._id, name: u.name, username: u.username }
      } as Message;

      this.chatStoreService.pushChatGroupMessages(channel, [_msg]);
    })();
  }

  subscribeUserSubscription() {
    this.userSub = this.chatStoreService.getChatUser()
      .pipe(
        filter(user => !!user),
        distinctUntilChanged((prev: any, curr: any) => prev._id === curr._id),
        switchMap(u => [
          this.chatService.subscribeToUserSubscription(u._id),
        ]),
        takeUntil(this.unsubscribe$)
      )
      .subscribe()
    ;
  }

  subscribeToKeepPing() {
    this.keepPingSub = this.chatService.keepPing()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  async onWindowTop(room: string, win: ChatWindowComponent) {
    return rethrowError(async () => {
      const { hasMore } = await this.groupData$(room)
        .pipe(
          take(1)
        )
        .toPromise();

      if (hasMore && !this.loadingOnTop) {
        this.loadingOnTop = true;
        const el = win.getCatListNativeElement();

        const curScrollPos = el.scrollTop;
        const oldScroll = el.scrollHeight - el.clientHeight;

        await this.loadMoreMessages(room);

        this.loadingOnTop = false;

        const newScroll = el.scrollHeight - el.clientHeight;
        el.scrollTop = curScrollPos + (newScroll - oldScroll);
      }
    })();
  }

  async loadMoreMessages(groupId: string) {
    return rethrowError(async () => {
      this.chatStoreService.setChatGroupLoadMore(groupId, true);

      const messages = await this.groupMessages$(groupId)
        .pipe(
          take(1)
        )
        .toPromise();

      const lastMessageTime = messages[0]?.ts?.$date ?? null;

      const more = await this.groupData$(groupId)
        .pipe(
          take(1)
        )
        .toPromise();

      if (more) {
        const { items, hasMore } = await this.chatService.loadGroupHistory(
          groupId,
          Date.now(),
          lastMessageTime
        );

        this.chatStoreService.unshiftChatGroupMessages(groupId, items.reverse(), hasMore);
        this.chatStoreService.setChatGroupLoadMore(groupId, false);
      }
    })();
  }

  destroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
