export enum FilterChangesModel {
  DESTINATION,
  OUTING_DESTINATION,
  DATE_RANGE,
  SINGLE_DATE,
  ROOM,
  GUESTS,
  SEARCH
}

export interface FilterDataChange {
  type: FilterChangesModel,
  data?: any
}
