import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromLayout from './reducer';
import * as moment from 'moment';

export const selectLayout = createFeatureSelector<fromLayout.State>(fromLayout.featureModuleKey);

export const selectDestinations = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.destinations
);

export const selectDestinationsLoading = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.loadingDestinations
);

export const selectFilter = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter
);

export const selectFilterLoaded = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.loaded
);

export const selectFilterTab = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.selectedTab
);

export const selectActiveUIFilter = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.activeUIFilter
);

export const selectDisableBarPlaceholder = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.disableBarPlaceholder
);

export const selectFilterDestination = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.destination
);

export const selectFilterSelectedProvince = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.selectedProvince
);

export const selectFilterOutingDestination = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.outingDestination
);

export const selectFilterOutingSelectedProvince = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.outingSelectedProvince
);

export const selectFilterDateRange = createSelector(
  selectLayout,
  (state: fromLayout.State) => ({
    ...state.filter.dateRange,
    startDate: state.filter.dateRange ? moment(state.filter.dateRange.startDate) : undefined,
    endDate: state.filter.dateRange.endDate ? moment(state.filter.dateRange.endDate) : undefined
  })
);

export const selectFilterRoomCount = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.roomCount
);

export const selectFilterGuestCount = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.guestCount
);

export const selectFilterFacilities = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.facilities
);

export const selectFilterWithin = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.within
);

export const selectFilterLoading = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filterLoading
);

export const selectLoadingLogout = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.loadingLogout
);

export const selectFilterIsEmpty = createSelector(
  selectLayout,
  (state: fromLayout.State) => {
    return !state.filter ||
      (!state.filter.destination &&
        Object.values(state.filter.dateRange).every(v => !v) &&
        !state.filter.roomCount && !state.filter.guestCount);
  }
);

export const selectFilterNights = createSelector(
  selectLayout,
  (state: fromLayout.State) => state.filter.nights
);
