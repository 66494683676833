import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, Observable } from 'rxjs';
import { take, takeUntil, tap, map} from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

import { APP_CONFIG, IAppConfig } from '../app.config';
import { WithUnsubscribe } from '../classes/with-unsubscribe';
import { CoreStoreService } from '../../core/store/core-store.service';
import { LayoutStoreService } from '../../layout/store/layout-store.service';

@Injectable({
  providedIn: 'root',
})
export class TagMetadataService extends WithUnsubscribe() {
  tags = {
    title: [
      ['og:title', 'property'],
      ['twitter:title', 'name'],
    ],
    description: [
      ['og:description', 'property'],
      ['twitter:description', 'name'],
      ['description', 'name'],
    ],
    image: [
      ['twitter:image:src', 'name'],
      ['og:image', 'property'],
    ],
    url: [['og:url', 'property']],
    default: [
      ['twitter:card', 'name'],
      ['twitter:site', 'name'],
    ],
  };

  private canonical: HTMLLinkElement;

  constructor(
    private meta: Meta,
    private coreStoreService: CoreStoreService,
    private layoutStoreService: LayoutStoreService,
    private translocoService: TranslocoService,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) private config: IAppConfig
  ) {
    super();
  }

  setDefaultTags() {
    this.translocoService
      .selectTranslate('core.description')
      .pipe(
        take(1),
        tap((t) => {
          this.coreStoreService.setMetaTitle(this.config.title);
          this.coreStoreService.setMetaDescription(t);
          this.coreStoreService.setMetaImage(this.config.image);
          this.coreStoreService.setMetaUrl(this.document.location.href);
          this.addCanonicalUrl(this.document.location.href);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  listenForTagsChanges() {
    combineLatest([
      this.coreStoreService.getMetaTitle(),
      this.coreStoreService.getMetaUrl(),
      this.coreStoreService.getMetaDescription(),
      this.coreStoreService.getMetaImage(),
    ])
      .pipe(
        tap(([title, url, description, image]) => {
          this.setTags({ title, url, description, image });
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  setTags({ title, description, image, url }) {
    if (title) {
      this.tags.title.forEach((item) => {
        this.meta.updateTag({ [item[1]]: item[0], content: title });
      });

      this.titleService.setTitle(`RentalHo | ${title}`);
    }

    if (description) {
      this.tags.description.forEach((item) => {
        this.meta.updateTag({ [item[1]]: item[0], content: description });
      });
    }

    if (image) {
      this.tags.image.forEach((item) => {
        this.meta.updateTag({ [item[1]]: item[0], content: image });
      });
    }

    if (url) {
      this.tags.url.forEach((item) => {
        this.meta.updateTag({ [item[1]]: item[0], content: url });
      });

      this.addCanonicalUrl(url);
    }

    this.tags.default.forEach((item) => {
      this.meta.updateTag({ [item[1]]: item[0] });
    });

    // Set default type
    this.meta.updateTag({ property: 'og:type', content: 'website' });
  }

  getTitleAndDescriptionBySelectedUIFilter$(): Observable<{
    name: string;
    description: string;
    id?: string|number;
  }> {
    return combineLatest([
      this.layoutStoreService.getActiveUIFilter(),
      this.layoutStoreService.getDestinations(),
      this.layoutStoreService.getFilterDestination(),
      this.layoutStoreService.getFilterOutingDestination(),
      this.translocoService.selectTranslate('core.title'),
    ]).pipe(
      map(
        ([
          tab,
          destinations,
          houseDestination,
          outingDestination,
          description,
        ]) => {
          if (tab === 'houses') {
            if (!houseDestination) {
              return { name: this.config.title, description, id: houseDestination };
            }

            if (Number.isNaN(Number(houseDestination))) {
              return { name: houseDestination as string, description, id: houseDestination };
            } else {
              const dd = destinations.find((d) => d.id == houseDestination);
              return {
                name: dd?.name ?? this.config.title,
                description: dd?.description ?? description,
                id: houseDestination
              };
            }
          } else if (tab === 'outing') {
            if (!outingDestination) {
              return { name: this.config.title, description, id: outingDestination };
            }

            return { name: outingDestination as string, description, id: outingDestination };
          } else {
            return { name: this.config.title, description, id: outingDestination };
          }
        }
      )
    );
  }

  addCanonicalUrl(url: string) {
    if (this.canonical) {
      this.document.head.removeChild(this.canonical);
    }

    this.canonical = this.document.createElement('link');
    this.canonical.setAttribute('rel', 'canonical');
    this.document.head.appendChild(this.canonical);
    this.canonical.setAttribute('href', url);
  }
}
