import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { CoreStoreService } from '../../core/store/core-store.service';

@Injectable()
export class AddLangHeaderInterceptor implements HttpInterceptor {
  constructor(private storeService: CoreStoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.storeService.getCurrentLang()
      .pipe(
        take(1),
        map(lang => req.clone({
          headers: req.headers.set('Content-Language', lang),
        })),
        mergeMap(req => next.handle(req)),
      );
  }
}
