import { Pipe, PipeTransform } from '@angular/core';
import { MathService } from '@rhbnb-nx-ws/services';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  constructor(
    private mathService: MathService
  ) { }

  transform(value: any, ...args: any[]): any {
    let [d] = args;
    d = d ? d : 0;

    return this.mathService.round(value, d);
  }

}
