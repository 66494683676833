import { Injectable } from '@angular/core';
import { LANGUAGES } from '@rhbnb-nx-ws/domain';

import { StorageService } from './storage.service';

const STORAGE_KEY = 'current_lang';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private storageService: StorageService
  ) {}

  storeLang(lang: LANGUAGES) {
    this.storageService.setItem(STORAGE_KEY, lang);
  }

  loadLang(): LANGUAGES {
    return this.storageService.getItem(STORAGE_KEY);
  }
}
