<h2 mat-dialog-title>{{ data.title | transloco }}</h2>

<mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <rhbnb-country-selector
        [label]="'core.country' | transloco"
        (countryChange)="onCountryChange($event)"
        [parent]="form"
      >
      </rhbnb-country-selector>
    </div>

    <div class="col-12">
      <!--Mobile group-->

      <rhbnb-mobile-field
        [label]="'core.cell' | transloco"
        [code]="code"
        [parent]="form"
      >
      </rhbnb-mobile-field>
    </div>
    <!--End mobile group-->
  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-end">
  <button mat-flat-button color="primary" (click)="onComplete()">
    {{ 'core.accept' | transloco }}
  </button>
</mat-dialog-actions>
