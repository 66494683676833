import { Pipe, PipeTransform } from '@angular/core';
import { MomentService } from '@rhbnb-nx-ws/services';

@Pipe({
  name: 'toStringDateWithoutOffset'
})
export class ToDateStringWithoutOffsetPipe implements PipeTransform {

  constructor(
    private momentService: MomentService
  ) {
  }

  transform(value: string, ...args: any[]): any {
    return this.momentService.getMomentWithLocalOffset(value);
  }

}
