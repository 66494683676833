import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country, ApiResponse } from '@rhbnb-nx-ws/domain';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import { CountryApiService } from '@rhbnb-nx-ws/services';
import { map, takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';


@Component({
  selector: 'rhbnb-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectorComponent extends WithUnsubscribe()
  implements OnInit {

  @Input() label: any;
  @Input() parent: FormGroup;

  @Output() countryChange = new EventEmitter<Country>();

  countries: Country[];

  constructor(
    private countryApiService: CountryApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCountries();
  }

  getCountries(): void {
    this.countryApiService
      .getAll()
      .pipe(
        map((c: ApiResponse<Country[]>) => c.data),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(countries => {
        this.countries = countries;
        this.onCountryChange({value: this.getFormValue()} as MatSelectChange);
      });
  }

  onCountryChange(event: MatSelectChange): void {
    const country = this.countries.find(c => c.id === event.value);
    this.countryChange.emit(country);
  }

  getFormValue(): Country {
    return this.parent.get('country').value;
  }
}
