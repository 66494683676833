import { Directive } from '@angular/core';
import { LayoutAlignDirective } from '@angular/flex-layout';

const selector = `[fxLayoutAlign.xxs]`;
const inputs = ['fxLayoutAlign.xxs'];

@Directive({ selector, inputs })
export class FlexLayoutAlignXxsDirective extends LayoutAlignDirective {
  protected inputs = inputs;
}
