import { BaseModel } from './base.model';

export class Image extends BaseModel {
  public description?: string;
  public file?: string;
  public id?: string;
  public link?: string;
  public number?: number;
  public name?: string;

  constructor(init?: Image) {
    super();
    Object.assign(this, init);
  }
}
