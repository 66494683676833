import { Injectable } from '@angular/core';
import { CurrencyKeyType, DCModality, DiscountCoupon } from '@rhbnb-nx-ws/domain';
import { PaymentUtilService } from './payment-util.service';

@Injectable({
  providedIn: 'root'
})
export class CouponUtilService {

  constructor(
    private paymentUtilService: PaymentUtilService
  ) {
  }

  canApply(coupon: DiscountCoupon, value) {
    if(!coupon) {
      return false;
    }

    if (coupon?.data?.withMinValue === true) {
      return value >= coupon.data.minValue;
    }

    return true;
  }

  getCouponValue(coupon: DiscountCoupon,
                 value, valueWithCommission,
                 currency: CurrencyKeyType,
                 cupRate: number
  ) {
    if (this.canApply(coupon, value)) {
      if (coupon.data.modality === DCModality.AMOUNT) {
        const discount = +coupon.data.clientFee * 100;

        return this.paymentUtilService.computeInCurrency(discount, cupRate, currency);
      } else {
        return (+coupon.data.clientFee * valueWithCommission) / 100;
      }
    }

    return 0;
  }
}
