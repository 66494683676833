import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ComponentRef,
  EventEmitter,
  Inject,
  OnInit,
  Output, ViewChild, ViewContainerRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { CURRENCY_TABLE$, DEFAULT_CURRENCY$ } from '@rhbnb-nx-ws/global-tokens';

import { SearchMapItem } from './map-item';

@Component({
  selector: 'rhbnb-map-item',
  template: `
    <div
      class="map-item"
      (click)="itemChange.emit(item)"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <ng-container #childComponentContainer></ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      :host:hover {
        z-index: 900;
        cursor: pointer;
      }

      .map-item {
        z-index: 10;
        border: 1px solid rgba(0, 0, 0, 0.08);
        background-color: var(--primary-color);
        border-radius: 28px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
        color: white;
        font-weight: 600;
        height: 28px;
        padding: 0 8px;
        position: relative;
        transform: scale(1);
        transform-origin: 50% 50%;
        transition: background-color cubic-bezier(0, 0, 0.1, 1) 300ms, transform 300ms cubic-bezier(0, 0, 0.1, 1) 0s, box-shadow 300ms cubic-bezier(0, 0, 0.1, 1) 0s;
      }

      .map-item:hover {
        transform: scale(1.08);
      }

      .map-item > div {
        height: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapItemComponent implements OnInit, AfterViewInit {
  item: SearchMapItem;
  priceComponentRef: ComponentRef<any>;

  @ViewChild('childComponentContainer', { read: ViewContainerRef, static: true })
  childComponentContainer: ViewContainerRef;

  @Output() itemChange = new EventEmitter<SearchMapItem>();

  constructor(
    @Inject(CURRENCY_TABLE$) public currencyTable$: Observable<any>,
    @Inject(DEFAULT_CURRENCY$) public currentCurrency$: Observable<any>,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.priceComponentRef) {
      this.childComponentContainer.insert(this.priceComponentRef.hostView);
    }
  }
}
