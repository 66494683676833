export const environment = {
  production: true,
  hmr: false,
  enableChat: true,
  apiURL: 'https://api.rentalho.com/v2-api',
  ssoLoginUrl: 'https://api.rentalho.com/spa/login',
  ssoLogoutUrl: 'https://api.rentalho.com/logout',
  adminProfileUrlPath: `profile`,
  adminPublishHouseUrlPath: `houses/create`,
  adminPublishOutingUrlPath: `outing/create`,
  adminReservationListUrlPath: `dashboard/client`,
  baseDomain: `.rentalho.com`,
  auth2server: 'https://api.rentalho.com/',
  auth2client: 'platform_web_app',
  chatUrl: `https://chat.rentalho.com`,
  wsChatUrl: `wss://chat.rentalho.com/websocket`,
  imageFilterServiceUrl: `https://images.rentalho.com`,
  identityKey: 'rhsso_prod_identity',
  webpushKey: 'BJsn8dYP7LhcvUXODbdQMwMimlojyhz0qESmzSKtAqyCzRD6v1a3rQbKw-VMFOgKveesQWmuyy0cisFEr6GYdOg'
};
