import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { ApiResponse } from '@rhbnb-nx-ws/domain';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { NO_AUTH_HEADER } from '@rhbnb-nx-ws/services';

@Injectable({
  providedIn: 'root'
})
export class GeodataApiService {

  endpointName = 'geodata/currency';

  constructor(
    public http: HttpClient
  ) {
  }

  detectCurrency(): Observable<ApiResponse<string>> {
    return this.http
      .get<ApiResponse<string>>(`${environment?.apiURL}/${this.endpointName}/detect`, {
        headers: { [NO_AUTH_HEADER]: 'yes' }
      })
      .pipe(catchError(error => observableThrowError(error)));
  }
}
