import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { Outing } from '@rhbnb-nx-ws/domain';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';

@Component({
  selector: 'rhbnb-outing-rules-view',
  templateUrl: './outing-rules.component.html',
  styleUrls: ['./outing-rules.component.sass']
})
export class OutingRulesViewComponent implements OnInit {

  @Input() outing: Outing;
  rules = [];

  constructor(
    private translocoService: TranslocoService,
    @Optional() @Inject(TRANSLOCO_SCOPE) private scope
  ) { }

  ngOnInit(): void {
    this.buildRules();
  }

  private buildRules() {
    this.rules.push(
      this.translocoService
        .selectTranslate('rules.check_in', {checkIn: this.outing?.checkIn}, this.scope)
    );

    this.rules.push(
      this.translocoService
        .selectTranslate('rules.check_out', {checkOut: this.outing.checkOut}, this.scope)
    );

    this.outing?.rules
      ?.forEach(hr => (
        typeof hr === 'object' ?
          this.rules.push(of((hr as any).name)) :
          this.rules.push(of(hr)))
      );

    this.outing?.data?.extraRules
      ?.forEach(er => (
          this.rules.push(of(er)))
      );
  }
}
