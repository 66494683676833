import { Injectable } from '@angular/core';
import { ApiResponse, Event } from '@rhbnb-nx-ws/domain';

import { MomentService } from './moment.service';

@Injectable({
  providedIn: 'root'
})
export class EventsBaseService {

  constructor(
    private momentService: MomentService
  ) { }

  toLocalTZ(res: ApiResponse<Event[]>) {
    res.data = res.data.map((roomEvent: Event) => {
      const cloned = { ...roomEvent };

      cloned.start = this.momentService.getFormat(
        this.momentService.getMomentWithLocalOffset(roomEvent.start)
      );

      cloned.end = this.momentService.getFormat(
        this.momentService.getMomentWithLocalOffset(roomEvent.end)
      );

      if (cloned?.data?.reservation?.checkIn && cloned?.data?.reservation?.checkOut) {
        cloned.data.reservation.checkIn = this.momentService.getFormat(
          this.momentService.getMomentWithLocalOffset(cloned.data.reservation.checkIn)
        );

        cloned.data.reservation.checkOut = this.momentService.getFormat(
          this.momentService.getMomentWithLocalOffset(cloned.data.reservation.checkOut)
        );
      }

      return cloned;
    });

    return res;
  }

  addOneDayToEndDate(res: ApiResponse<Event[]>) {
    res.data = res.data.map((roomEvent: Event) => {
      const cloned = { ...roomEvent };

      cloned.end = this.momentService.getTomorrowStartOfDayWithLocalTz(roomEvent.end);

      if (cloned?.data?.reservation?.checkOut) {
        cloned.data.reservation.checkOut =
          this.momentService.getTomorrowStartOfDayWithLocalTz(
            roomEvent.data.reservation.checkOut
          );
      }

      return cloned;
    });

    return res;
  }

  adjustPrice(res: ApiResponse<Event[]>) {
    res.data = res.data.map((roomEvent: Event) => {
      const cloned = { ...roomEvent };

      cloned.data.singlePrice = cloned.data.price;

      if (cloned.data.totalPrice) {
        cloned.data.price = cloned.data.totalPrice;
      }

      return cloned;
    });

    return res;
  }
}
