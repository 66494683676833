import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapItemComponent, MapItemViewComponent, MapSearchComponent } from './map-search';
import { SharedPipesModule } from '@rhbnb-nx-ws/shared-pipes';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedDirectivesModule } from '@rhbnb-nx-ws/shared-directives';
import { RouterModule } from '@angular/router';

import { HouseCardPiecesModule } from '../house-card-pieces';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MapPriceWrapperComponent } from './map-search/map-price-wrapper.component';
import { HousePriceModule } from '../house-price';

const COMPONENTS = [
  MapSearchComponent,
  MapItemViewComponent,
  MapItemComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    MapPriceWrapperComponent,
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
    SharedDirectivesModule,
    FlexLayoutModule,
    RouterModule,
    HouseCardPiecesModule,
    MatProgressSpinnerModule,
    HousePriceModule,
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class MapSearchModule { }
