<rhbnb-top-panel #panel>
  <ng-content select="[normal]"></ng-content>
</rhbnb-top-panel>

<div class="btn-wrapper" fxLayout="row wrap" fxLayoutAlign="center">
  <button style="min-height: 35px;" [class.white]="isButtonWhite" fxShow fxHide.gt-sm mat-stroked-button (click)="showPanel()">

    <ng-container *ngIf="(filterIsEmpty$ | async) else filterWithContent">
      {{ 'filter.mobile_search_btn_label_empty' | transloco}}
    </ng-container>

    <ng-template #filterWithContent>
      {{ 'filter.mobile_search_btn_label_with_data' | transloco}}
    </ng-template>

  </button>

  <div [class.shadow]="!alternateColor" class="top-filter-wrapper" fxHide fxShow.gt-sm>

    <ng-content select="[small]"></ng-content>

  </div>
</div>
