import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { catchError, debounceTime, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import { ApiResponse, DiscountCoupon } from '@rhbnb-nx-ws/domain';
import { DiscountCouponApiService } from '@rhbnb-nx-ws/services';
import { of, throwError } from 'rxjs';

@Component({
  selector: 'rhbnb-coupon-finder-field',
  templateUrl: './coupon-finder-field.component.html',
  styleUrls: ['./coupon-finder-field.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponFinderFieldComponent extends WithUnsubscribe()
  implements OnInit {

  loading = false;
  valid = false;
  discountCoupon: DiscountCoupon;

  @Output() discountCouponChange = new EventEmitter<DiscountCoupon>();

  form: FormGroup;

  constructor(
    private couponApiService: DiscountCouponApiService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.handleCodeLookup();
  }

  createForm() {
    this.form = this.fb.group({
      code: ['']
    });
  }

  handleCodeLookup() {
    this.codeControl.valueChanges
      .pipe(
        tap(() => {
          this.loading = true;
          this.cdr.detectChanges();
        }),
        debounceTime(450),
        switchMap(v => !!v ? this.couponApiService.findByCode(v) : of(undefined)),
        tap((res: ApiResponse<any>) => {
          if (res) {
            this.handleError(res?.data);
          } else {
            this.codeControl.setErrors({});
          }
        }),
        tap(() => {
          this.loading = false;
          this.cdr.markForCheck();
        }),
        catchError(err => {
          this.loading = false;
          this.cdr.detectChanges();

          return throwError(err);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  handleError(response) {
    if (!response) {
      this.discountCouponChange.emit(undefined);
      this.discountCoupon = undefined;
      return;
    }

    if (typeof response === 'object') {
      this.discountCouponChange.emit(response);
      this.discountCoupon = response;
      this.valid = true;
      return;
    }

    switch (response) {
      case '404':
        this.codeControl.setErrors({notFound: true});
        break;
      case 'used':
        this.codeControl.setErrors({used: true});
        break;
      case 'off-date':
        this.codeControl.setErrors({offDate: true});
        break;
    }

    this.discountCoupon = undefined;
    this.discountCouponChange.emit(undefined);
  }

  get codeControl() {
    return this.form.get('code');
  }
}
