import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Event } from '@rhbnb-nx-ws/domain';

export enum EventStatus {
  BLOCK,
  AVAILABLE,
  MIXED
}

@Injectable({
  providedIn: 'root'
})
export class RangeControlService {

  constructor() {
  }

  countDaysByRange(from: Moment, to: Moment) {
    const duration = moment.duration(to.diff(from));
    return duration.as('days');
  }

  countDaysByEvent(event: Event) {
    const from = moment(event.start);
    const to = moment(event.end);

    const duration = moment.duration(to.diff(from));
    return duration.as('days');
  }

  checkEventsOverlap(event1: Event, event2: Event) {
    const lowerBound = moment.max(moment(event1.start), moment(event2.start));
    const upperBound = moment.min(moment(event1.end), moment(event2.end));

    return lowerBound.isSameOrBefore(upperBound);
  }

  detectEventsStatus(events: Event[]) {
    const availableEvents = events.filter(e => this.getEventAvailability(e) === EventStatus.AVAILABLE);
    if (availableEvents.length === events.length) {
      return EventStatus.AVAILABLE;
    }

    const blockEvents = events.filter(e => this.getEventAvailability(e) === EventStatus.BLOCK);
    if (blockEvents.length === events.length) {
      return EventStatus.BLOCK;
    }

    return EventStatus.MIXED;
  }

  sortEventsAsc(events: Event[]) {
    const toSort = [...events];
    return toSort.sort((a, b) => (moment(a.start).isAfter(moment(b.start))) ? 1 : ((moment(b.start).isAfter(moment(a.start))) ? -1 : 0));
  }

  mergeOverlapEvents(events: Event[]) {
    if (events.length === 0) {
      return [];
    }

    const mergedEvents = [events[0]];

    for (let i = 1; i < events.length; i++) {
      const first = mergedEvents.pop();
      const second = events[i];

      if (this.checkEventsOverlap(first, second)) {
        const merged = this.mergeEvents(first, second);
        mergedEvents.push(merged);
      } else {
        mergedEvents.push(first);
        mergedEvents.push(second);
      }
    }

    return mergedEvents;
  }

  /**
   * If given day is in range of events
   * return day data, else return false
   */
  getDayInfoInRange(events: Event[], day: Moment, price?): Event | boolean {
    for (let i = 0; i < events.length; i++) {
      const event = events[i];

      if (!event.data.price && price) {
        event.data.price = price;
      }

      const data = {...event.data};
      data.available = this.getEventAvailability(event);

      if (day.isBetween(moment(event.start), moment(event.end), 'day', '[)')) {
        return data;
      }
    }

    return false;
  }

  public getEventAvailability(event: Event) {
    if (event.available) {
      return EventStatus.AVAILABLE;
    } else {
      return EventStatus.BLOCK;
    }
  }

  private mergeEvents(first: Event, second: Event) {
    const merged = {...second};
    merged.start = first.start;

    if (first.data && first.data.available) {
      merged.data.available = first.data.available !== second.data.available ? '2' : second.data.available;
    }

    return merged;
  }
}
