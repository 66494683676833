import { BaseModel } from './base.model';

export enum CurrencyKeyType {
  EUR = 'EUR',
  USD = 'USD',
  CUP = 'CUP',
  AED = 'AED',
}

export class Currency extends BaseModel {
  public name?: CurrencyKeyType;
  public rate?: number;

  constructor(init?: Currency) {
    super();
    Object.assign(this, init);
  }

}
