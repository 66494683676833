import { Inject, Injectable } from '@angular/core';
import { ApiResponse, Room, BedType } from '@rhbnb-nx-ws/domain';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

import { AbstractDataService } from '../util/abstract-data-service';

@Injectable({
  providedIn: 'root'
})
export class RoomService extends AbstractDataService<Room> {

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'rooms', apiURL);
  }

  checkPriceAndAvailability(rooms: number[], from: string, to: string)
    : Observable<ApiResponse<{
    available: boolean,
    rooms: Array<any>,
    amount: number,
    guests: number
  }>> {

    let params = '';
    rooms.forEach(r => params += `rooms[]=${r}&`);
    params += `from=${from}&`;
    params += `to=${to}`;

    return this.http
      .get<ApiResponse<{
        available: boolean,
        rooms: Array<any>,
        amount: number,
        guests: number
      }>>(`${this.apiURL}/${this.endpointName}/check?${params}`)
      .pipe(catchError(error => throwError(error)));
  }

  countGuests(room: Room) {
    const beds = room.beds;
    let guestsCount = 0;

    beds.forEach(bed => {
      const kind = bed.kind;
      const count = bed.count;

      if (kind && count) {
        guestsCount += this.guestsAmountPerBedType(kind) * count;
      }
    });

    return guestsCount;
  }

  bulkCreate(rooms: Room[], headers = {}) {
    return this.http
      .post<ApiResponse<Room[]>>(`${this.apiURL}/${this.endpointName}/bulk`, rooms, {
        headers
      })
      .pipe(catchError(error => throwError(error)));
  }

  bulkUpdate(rooms: Room[], headers = {}) {
    return this.http
      .patch<ApiResponse<Room[]>>(`${this.apiURL}/${this.endpointName}/bulk`, rooms, {
        headers
      })
      .pipe(catchError(error => throwError(error)));
  }

  guestsAmountPerBedType(bedType: BedType) {
    switch (bedType) {
      case BedType.KING:
        return 2;
      case BedType.QUEEN:
        return 2;
      case BedType.DOUBLE:
        return 2;
      case BedType.BUNK_BED:
        return 2;
      default:
        return 1;
    }
  }
}
