<div fxLayout="row wrap"
     fxLayout.xxs="column"
     fxLayoutAlign="start stretch"
     fxLayoutAlign.xxs="center stretch"
     fxLayoutGap="15px grid">

  <rhbnb-room-card-item-loading
    *ngFor="let i of items"
    fxFlex="100"
    [fxFlex.gt-sm]="_gtSmValue"
    [fxFlex.gt-xs]="_gtXsValue"
    fxLayout="row wrap"></rhbnb-room-card-item-loading>

</div>
