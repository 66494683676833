import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'currencyLabel'
})
export class CurrencyLabelPipe implements PipeTransform {

  constructor(
  ) {
  }

  transform(value: any, ...args: any[]): any {
    const {currentCurrency$} = args[0];

    return currentCurrency$
      .pipe(
        map((currency) => `${value} ${currency}`),
      );
  }

}
