import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HouseTinyPriceComponent } from './house-tiny-price/house-tiny-price.component';
import { SharedPipesModule } from '@rhbnb-nx-ws/shared-pipes';
import { OutingTinyPriceComponent } from './outing-tiny-price/outing-tiny-price.component';

const COMPONENTS = [
  HouseTinyPriceComponent,
  OutingTinyPriceComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class HousePriceModule { }
