import { InjectionToken } from '@angular/core';

export interface MQ {
  min: string;
  max: string;
}

export type SIZE = 'XXS' | 'XS' | 'SM' | 'MD' | 'LG' | 'XL';

export let MEDIA_QUERIES = new InjectionToken('media.queries');
export const MediaQueries: {[K in SIZE]: MQ} = {
  XXS: {
    min: '0px',
    max: '390px'
  },
  XS: {
    min: '391px',
    max: '599px'
  },
  SM: {
    min: '600px',
    max: '959px'
  },
  MD: {
    min: '960px',
    max: '1279px'
  },
  LG: {
    min: '1280px',
    max: '1919px'
  },
  XL: {
    min: '1920px',
    max: '5000px'
  },
}
