import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalDataModel } from './modal-data.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RoomEventsService } from '@rhbnb-nx-ws/services';
import { Moment } from 'moment';
import { LockableDaterangeSelectorComponent } from '../../lockable-daterange-picker/lockable-daterange-selector';
import { Observable } from 'rxjs';
import { ApiResponse, Event } from '@rhbnb-nx-ws/domain';

@Component({
  selector: 'rhbnb-lockable-range-date-modal',
  templateUrl: './lockable-range-date-modal.component.html',
  styleUrls: ['./lockable-range-date-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LockableRangeDateModalComponent implements OnInit {

  form: FormGroup;
  elementIds: Array<any>;
  minDate: Moment;
  minRange = 0;
  singlePicker = false;
  getEvents: (ids: string[], from: Moment, to: Moment) => Observable<ApiResponse<Event[]>>;
  getOnlyEndDays: (range: string[]) => any[];

  @ViewChild(LockableDaterangeSelectorComponent, { static: true })
  calendarSelector: LockableDaterangeSelectorComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalDataModel,
    public dialogRef: MatDialogRef<LockableRangeDateModalComponent>,
    private fb: FormBuilder,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.form.patchValue({ range: this.data?.range });
    this.elementIds = this.data?.elementIds;
    this.minDate = this.data.rangeMinDate;
    this.minRange = this.data.minRange;
    this.singlePicker = this.data?.isSingle;
    this.getEvents = this.data?.getEvents;
    this.getOnlyEndDays = this.data?.getOnlyEndDays;
  }

  createForm() {
    this.form = this.fb.group({
      range: ['']
    });
  }

  onDateChange(event): void {
    if (!this.singlePicker) {
      this.data?.rangeChange.emit(event);
      this.dialogRef.close();
    }
  }

  onSingleDateChange(event) {
    if (this.singlePicker) {
      this.data?.rangeChange.emit(event);
      this.dialogRef.close();
    }
  }

  onMinLimitChange(event): void {
    this.data?.rangeLimitChange$$.next(event);
    this.dialogRef.close();
  }
}
