import { Component, OnInit, ChangeDetectionStrategy, Input, ContentChild } from '@angular/core';
import { TabContentComponent } from './tab-content.component';
import { TabHeaderComponent } from './tab-header.component';

@Component({
  selector: 'rhbnb-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabItemComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  isActive: boolean;

  @ContentChild(TabContentComponent)
  bodyComponent: TabContentComponent;

  @ContentChild(TabHeaderComponent)
  labelComponent: TabHeaderComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
