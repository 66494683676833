import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSnackModule, NotifyService } from '@rhbnb-nx-ws/services';
import { IMAGE_QUALITY, ImageQuality } from '@rhbnb-nx-ws/global-tokens';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NGXLogger } from 'ngx-logger';
import { SharedDirectivesModule, LLD_LOADING_GIF } from '@rhbnb-nx-ws/shared-directives';
import { SwiperModule } from 'swiper/angular';
import { ContainerModule } from '@rhbnb-nx-ws/ui-share-components';
import { SharedPipesModule } from '@rhbnb-nx-ws/shared-pipes';

import { APP_CONFIG, AppConfig } from './app.config';
import { AuthGuard } from './guards/auth.guard';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { SecurityService } from './services/security.service';
import { MaterialRootModule } from './material-root.module';
import { ClickOutsideDirective } from './directives/clickout.directive';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { CustomBreakPointsProvider } from './layout/breakpoints';
import { FlexLayoutFlexXxsDirective } from './directives/flex-layout-flex-xxs.directive';
import { FlexLayoutLayoutXxsDirective } from './directives/flex-layout-layout-xxs.directive';
import { FlexLayoutAlignXxsDirective } from './directives/flex-layout-align-xxs.directive';
import { FlexLayoutShowHideXxsDirective } from './directives/flex-layout-show-hide-xxs.directive';
import { EmptyPageComponent } from './components/empty-page/empty-page.component';
import { DestinationSlideComponent } from './components/destination-slide/destination-slide.component';
import { DestinationSlideItemComponent } from './components/destination-slide/destination-slide-item.component';
import { DestinationSlideLoadingComponent } from './components/destination-slide-loading/destination-slide-loading.component';
import { DestinationItemLoadingComponent } from './components/destination-slide-loading/destination-item-loading.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { LoginGuard } from './guards/login.guard';
import { MatInputFileComponent } from './components/mat-input-file/mat-input-file.component';
import { ScrollFixedDirective } from './directives/scroll-fixed.directive';
import { HouseLoadingItemComponent } from './components/loading-houses/house-loading-item.component';
import { LoadingHousesComponent } from './components/loading-houses/loading-houses.component';
import { InternalErrorPage } from './pages/internal-error/internal-error.page';
import { WebpushDialogComponent } from './components/webpush-dialog/webpush-dialog.component';
import { SocialMediaModalComponent } from './components/social-media-modal/social-media-modal.component';
import { FacebookLinkComponent } from './components/social-media-modal/facebook-link.component';
import { ActionButtonComponent } from './components/social-media-modal/action-button.component';
import { TwitterLinkComponent } from './components/social-media-modal/twitter-link.component';
import { EmailLinkComponent } from './components/social-media-modal/email-link.component';
import { WhatsappLinkComponent } from './components/social-media-modal/whatsapp-link.component';
import { MessengerLinkComponent } from './components/social-media-modal/messenger-link.component';
import { SmsLinkComponent } from './components/social-media-modal/sms-link.component';
import { CopyClipLinkComponent } from './components/social-media-modal/copy-clip-link.component';
import { StorageService } from './services/storage.service';
import { IdentityCheckGuard } from './guards/identity-check.guard';
import { APP_TARGET_CARD } from './tokens/app-target-card';
import { DebugClientService } from './services/debug-client.service';
import { DynamicBar, TopBarEffectDirective } from './directives/top-bar-effect.directive';
import { AddCountryHeaderInterceptor } from './interceptors/add-country-header.interceptor';
import { CoreStoreService } from '../core/store/core-store.service';
import { AnonymousGuard } from './guards/anonymous.guard';
import { AddLangHeaderInterceptor } from './interceptors/add-lang-header.interceptor';
import { DomainRedirectGuard } from './guards/domain-redirect.guard';

const DEPENDENCY_MODULES = [
  FlexLayoutModule,
  MaterialRootModule,
  FormsModule,
  ReactiveFormsModule,
  NgSelectModule,
  NgScrollbarModule,
  SwiperModule,
  SharedDirectivesModule,
  ContainerModule,
  SharedPipesModule,
];

const APP_COMPONENTS = [
  NotFoundPage,
  EmptyPageComponent,
  DestinationSlideComponent,
  DestinationSlideItemComponent,
  DestinationSlideLoadingComponent,
  DestinationItemLoadingComponent,
  MatInputFileComponent,
  LoadingHousesComponent,
  HouseLoadingItemComponent,
];

const APP_PIPES = [
  EnumToArrayPipe,
];

const APP_INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
    deps: [NotifyService, SecurityService, Router, TranslocoService, StorageService, NGXLogger]
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
    deps: [SecurityService]
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AddCountryHeaderInterceptor,
    multi: true,
    deps: [CoreStoreService]
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AddLangHeaderInterceptor,
    multi: true,
    deps: [CoreStoreService]
  },
  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: {duration: 3500}
  }
];

const APP_GUARDS = [
  AuthGuard,
  DomainRedirectGuard,
  AnonymousGuard,
  LoginGuard,
  IdentityCheckGuard
];

const APP_DIRECTIVES = [
  FlexLayoutFlexXxsDirective,
  FlexLayoutLayoutXxsDirective,
  FlexLayoutAlignXxsDirective,
  FlexLayoutShowHideXxsDirective,
  ClickOutsideDirective,
  ScrollFixedDirective,
  TopBarEffectDirective,
  DynamicBar
];

@NgModule({
  declarations: [
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    ...APP_PIPES,
    ConfirmDialogComponent,
    InternalErrorPage,
    WebpushDialogComponent,
    SocialMediaModalComponent,
    FacebookLinkComponent,
    ActionButtonComponent,
    TwitterLinkComponent,
    EmailLinkComponent,
    WhatsappLinkComponent,
    MessengerLinkComponent,
    SmsLinkComponent,
    CopyClipLinkComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    CustomSnackModule,
    ...DEPENDENCY_MODULES,
  ],
  exports: [
    TranslocoModule,
    ...DEPENDENCY_MODULES,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    ...APP_PIPES,
    ActionButtonComponent
  ],
  providers: [
    ...APP_INTERCEPTORS,
    DebugClientService,
    CustomBreakPointsProvider,
  ]
})
export class SharedModule {
  constructor() {}

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: APP_CONFIG, useValue: AppConfig },
        { provide: IMAGE_QUALITY, useValue: ImageQuality },
        {provide: APP_TARGET_CARD, useValue: AppConfig.targetCards},
        { provide: LLD_LOADING_GIF, useValue: AppConfig.loadingGif },
        ...APP_GUARDS
      ]
    };
  }
}
