import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { House, Outing } from '@rhbnb-nx-ws/domain';
import { AppCommissionPipe, ToDecimalPipe, ElementImagePipeService, FilterImagePipeService } from '@rhbnb-nx-ws/shared-pipes';

import { EvaluationUtilService } from './evaluation-util.service';
import { CommissionService } from './commission.service';
import { MathService } from './math.service';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  private scriptRef: any;
  private renderer: Renderer2;
  private appCommissionPipe: AppCommissionPipe;
  private toDecimalPipe: ToDecimalPipe;

  constructor(
    private rendererFactory: RendererFactory2,
    private evaluationUtilService: EvaluationUtilService,
    private elementImagePipeService: ElementImagePipeService,
    private commissionService: CommissionService,
    private mathService: MathService,
    private filterImagePipeService: FilterImagePipeService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.appCommissionPipe = new AppCommissionPipe(commissionService);
    this.toDecimalPipe = new ToDecimalPipe(mathService);
  }

  generateJsonLdFromGeneric(entity: House | Outing) {
    const title = (entity as Outing).title || (entity as House).name;
    const price = (entity as House)?.rangePrice?.lower ?? 0;

    return {
      "@context": "http://schema.org",
      "@type": "LodgingBusiness",
      "name": title,
      "priceRange": `\$${this.mathService.round(
        this.toDecimalPipe.transform(
          this.appCommissionPipe.transform(
              price,
              entity.commission,
            )
          )
        )} por noche`,
      "starRating": this.mathService.round(this.getAvg(entity)),
      "image": this.filterImagePipeService.filter(
        this.elementImagePipeService.getImageLink(entity.images, false), {w:1200, h: 0}),
    };
  }

  getAvg(element) {
    return this.evaluationUtilService?.getEvalAvg(element?.eval);
  }

  addJsonLdScriptToHead(json: Object) {
    if (this.scriptRef) {
      this.removeJsonLdScriptToHead();
    }

    if (isPlatformBrowser(this.platformId)) {
      this.scriptRef = this.renderer.createElement('script');

      this.scriptRef.type = 'application/ld+json';
      this.scriptRef.text = JSON.stringify(json);

      this.renderer.appendChild(document.head, this.scriptRef);
    }
  }

  removeJsonLdScriptToHead() {
    if (isPlatformBrowser(this.platformId) && this.scriptRef) {
      this.renderer.removeChild(document.head, this.scriptRef);
      this.scriptRef = undefined;
    }
  }
}
