import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

import { UpdateBtnComponent } from './update-btn/update-btn.component';
import { UpdateBtnWrapperComponent } from './update-btn-wrapper.component';



@NgModule({
  declarations: [
    UpdateBtnComponent,
    UpdateBtnWrapperComponent
  ],
  exports: [
    UpdateBtnComponent,
    UpdateBtnWrapperComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ]
})
export class UpdateBtnModule { }
