import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[rhbnbSubmitIfValid]'
})
export class SubmitIfValidDirective extends WithUnsubscribe() {

  @Input('rhbnbSubmitIfValid') fromRef: FormGroup;
  @Output() valid = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef
  ) {
    super();
  }

  @HostListener('click')
  onClick() {
    this?.fromRef.markAllAsTouched();
    this.emitIfValid();
  }

  private emitIfValid() {
    this.subscribeToStatusChange();

    if (this.fromRef.valid) {
      this.valid.emit();
    } else {
      this.elementRef.nativeElement.disabled = true;
    }
  }

  private subscribeToStatusChange() {
    this.fromRef.statusChanges
      .pipe(
        distinctUntilChanged(),
        tap(v => (v === 'VALID' ?
          this.elementRef.nativeElement.disabled = false :
          this.elementRef.nativeElement.disabled = true)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}

