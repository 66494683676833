import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { CustomSnackComponent, SnackKind, SnackTime } from '../components/custom-snack';

export interface SnackBarMessage {
  message: string;
  kind: SnackKind;
  time: SnackTime;
  canRepeat: boolean;
  config?: any;
  action?: {
    text: string,
    complete: Subject<unknown>,
  };
}

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  private messagesQueue: SnackBarMessage[] = [];
  private worker = new Subject();
  private working = false;

  private lastMessage = '';
  private lastMessageTime: Date;

  constructor(
    private _snackBar: MatSnackBar
  ) {
    this.worker
      .pipe(
        map(() => this.messagesQueue.shift()),
      )
      .subscribe(current => {
        if (current) {
          this.working = true;

          if (this.isTimeOff() || this.lastMessage !== current?.message || current?.canRepeat) {
            const ref = this._snackBar.openFromComponent(
              CustomSnackComponent,
              {
                data: {
                  ...current,
                },
                duration: current?.time,
                panelClass: `customSnack_${current?.kind}`,
                ...current?.config
              }
            );

            ref.afterDismissed()
              .subscribe(() => this.worker.next());
          } else {
            this.worker.next();
          }

          this.lastMessage = current?.message;
          this.lastMessageTime = new Date();
        } else {
          this.working = false;
        }
      });
  }

  isTimeOff() {
    const diff = Math.abs((new Date().getTime()) - (this.lastMessageTime?.getTime() || (new Date().getTime()))); // Diferencia en milisegundos
    const mins = Math.floor((diff/1000));

    return mins > 5;
  }

  push(msg: SnackBarMessage): void {
    this.messagesQueue.push({
      kind: SnackKind.INFO,
      time: SnackTime.NORMAL,
      canRepeat: false,
      ...msg
    });

    if (!this.working) {
      this.worker.next();
    }
  }

  info(
    message: string,
    time: SnackTime = SnackTime.NORMAL,
    canRepeat = false,
    action?: string,
  ): undefined|Observable<any> {
    let _action;

    if (action) {
      _action = {
        text: action,
        complete: new Subject(),
      }
    }

    this.push({ message, kind: SnackKind.INFO, time, canRepeat, action: _action });

    return _action?.complete;
  }

  error(
    message: string,
    time: SnackTime = SnackTime.NORMAL,
    canRepeat = false,
    action?: string,
  ): undefined|Observable<any>{
    let _action;

    if (action) {
      _action = {
        text: action,
        complete: new Subject(),
      }
    }

    this.push({ message, kind: SnackKind.ERROR, time, canRepeat, action: _action });

    return _action?.complete;
  }
}
