import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CompleteProfileFormComponent } from "./complete-profile-dialog/complete-profile-form.component";

@Injectable({
  providedIn: 'root'
})
export class CompleteProfileDialogService {
  constructor(
    private dialog: MatDialog
  ) {
  }

  open(title: string, data: any) {
    const ref = this.dialog.open(CompleteProfileFormComponent, {
      maxWidth: '400px',
      closeOnNavigation: false,
      disableClose: true,
      data: {
        title,
        data,
      }
    });

    return ref.afterClosed();
  }
}
