import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoginOrRegisterDialogComponent } from "./login-or-register-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class LoginOrRegisterDialogService {
  constructor(
    private dialog: MatDialog
  ) {
  }

  open(onLogin: () => void) {
    const ref = this.dialog.open(LoginOrRegisterDialogComponent, {
      maxWidth: '400px',
      data: {
        login: onLogin
      }
    });

    return ref.afterClosed();
  }
}
