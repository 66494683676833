import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { SecurityService } from '../services/security.service';
import { take, tap } from 'rxjs/operators';

@Injectable()
export class IdentityCheckGuard implements CanActivate {

  constructor(
    private securityService: SecurityService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const code = route.queryParamMap.get('code');

    if (code) {
      this.securityService.sessionEstablished$
        .pipe(
          tap(() => this.securityService.checkIdentity()),
          take(1)
        )
        .subscribe();
    } else {
      this.securityService.checkIdentity();
    }

    return true;
  }

}
