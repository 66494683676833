<div fxLayout="row wrap"
     fxLayout.xxs="column"
     fxLayoutAlign="start stretch"
     fxLayoutAlign.xxs="center stretch"
     fxLayoutGap="25px grid">

  <rhbnb-icon-list-item
    fxFlex="50"
    fxFlex.xxs="100"
    fxLayout="row wrap"
    *ngFor="let item of items"
    [item]="item">
  </rhbnb-icon-list-item>

</div>
