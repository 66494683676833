<mat-dialog-content>

  <ng-container [formGroup]="form">
    <rhbnb-lockable-daterange-selector
      formControlName="range"
      [elementIds]="elementIds"
      [getEvents]="getEvents"
      [getLockStartingRangeDate]="getOnlyEndDays"
      [asFormControl]="false"
      [dateMinLimit]="minRange"
      [singlePicker]="singlePicker"
      [lockPastDates]="true"
      [endLockDatesMessage]="'cmodules.lock_end_date_message' | transloco"

      (chooseDate)="onDateChange($event)"
      (minRangeChange)="onMinLimitChange($event)"
      (startDateChanged)="onSingleDateChange($event)"></rhbnb-lockable-daterange-selector>
  </ng-container>

</mat-dialog-content>
