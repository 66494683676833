import { Pipe, PipeTransform } from '@angular/core';
import { FilterImagePipeService } from './filter-image.pipe.service';

@Pipe({
  name: 'filterImage'
})
export class FilterImagePipe implements PipeTransform {

  constructor(
    private filterImagePipeService: FilterImagePipeService
  ) {
  }

  transform(value: any, ...args: any[]): any {
    const [params] = args;
    const {w, h} = params;

    return this.filterImagePipeService.filter(value, {w, h});
  }
}
