<div
  infiniteScroll
  (scrolled)="onScrollDown()"
  (scrolledUp)="onScrollTop()"
  [infiniteScrollDistance]="distance"
  [infiniteScrollThrottle]="throttle"
  [fromRoot]="true"
  [infiniteScrollContainer]="scrollContainer"
  class="viewport">

  <ng-content></ng-content>

</div>
