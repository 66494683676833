import { Pipe, PipeTransform } from '@angular/core';
import { MathService } from '@rhbnb-nx-ws/services';

@Pipe({ name: 'toDecimal'})
export class ToDecimalPipe implements PipeTransform {

  constructor(
    private mathService: MathService
  ) { }

  transform(value: any, ...args: any[]): any {
    const v = (typeof value === 'string') ? parseInt(value, 10) : value;

    return this.mathService.toDecimal(v);
  }

}
