import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IdentityCheckGuard } from '../shared/guards/identity-check.guard';
import { PreloadStrategy } from './preload-strategy';
import { HardUpdatePageComponent } from './hard-update-page.component';

const routes: Routes = [
  {
    path: '__hard_update__',
    component: HardUpdatePageComponent,
  },
  {
    path: '',
    loadChildren: () => import('../layout/layout.module').then(m => m.LayoutModule),
    canActivate: [IdentityCheckGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: PreloadStrategy,
  })],
  providers: [
    PreloadStrategy
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
