import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  OAuth2Client,
  generateCodeVerifier,
} from '@badgateway/oauth2-client';
import { OAUTH2_CONFIG, OAuth2Config } from '@rhbnb-nx-ws/global-tokens';
import { UrlUtilService } from './url-util.service';

@Injectable({
  providedIn: 'root',
})
export class OAuth2Service {
  public readonly CODE_V_STORAGE_KEY = 'oauth_cv';
  public readonly TOKEN_STORAGE_KEY = 'oauth_token';

  constructor(
    @Inject(OAUTH2_CONFIG) private oauthConfig: OAuth2Config,
    @Inject(DOCUMENT) private document: Document,
    private urlUtilService: UrlUtilService
  ) {}

  private getClient() {
    return new OAuth2Client({
      server: this.oauthConfig.server,
      clientId: this.oauthConfig.clientId,
      tokenEndpoint: this.oauthConfig.tokenEndpoint,
      authorizationEndpoint: this.oauthConfig.authorizationEndpoint,
    });
  }

  public async authorize() {
    const codeVerifier = await this.writeCodeVerifier();

    document.location =
      await this.getClient().authorizationCode.getAuthorizeUri({
        redirectUri: this.urlUtilService.getUrlInCurrentDomain('', true),
        codeVerifier,
        scope: ['SCOPE_NONE'],
        state: '-',
      });
  }

  /**
   * Get oauth2 token after authorization redirect
   * Client credentials flow
   *
   * @return {Promise<OAuth2Token>}
   */
  public async getToken() {
    const codeVerifier = this.popCodeVerifier();

    const token =
      await this.getClient().authorizationCode.getTokenFromCodeRedirect(
        this.document.location as unknown as string | URL,
        {
          redirectUri: this.urlUtilService.getUrlInCurrentDomain('', true),
          codeVerifier,
          state: '-',
        }
      );
    await this.writeToken(token);

    return token;
  }

  public async refreshToken() {
    try {
      const client = this.getClient();
      const token = await client.refreshToken(this.readToken());
      await this.writeToken(token);

      return token;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public async writeToken(token) {
    localStorage.setItem(this.TOKEN_STORAGE_KEY, JSON.stringify(token));
  }

  public readToken() {
    const stringToken = localStorage.getItem(this.TOKEN_STORAGE_KEY);
    return JSON.parse(stringToken);
  }

  public popToken() {
    const stringToken = localStorage.getItem(this.TOKEN_STORAGE_KEY);
    localStorage.removeItem(this.TOKEN_STORAGE_KEY);

    return JSON.parse(stringToken);
  }

  public async writeCodeVerifier() {
    const codeVerifier = await generateCodeVerifier();
    localStorage.setItem(this.CODE_V_STORAGE_KEY, codeVerifier);

    return codeVerifier;
  }

  public popCodeVerifier() {
    const cv = localStorage.getItem(this.CODE_V_STORAGE_KEY);
    localStorage.removeItem(this.CODE_V_STORAGE_KEY);

    return cv;
  }
}
