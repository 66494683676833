import { Inject, Injectable } from '@angular/core';
import { IMAGE_QUALITY, EMPTY_IMAGE_SRC } from '@rhbnb-nx-ws/global-tokens';

@Injectable()
export class ElementImagePipeService {

  constructor(
    @Inject(IMAGE_QUALITY) private quality: number,
    @Inject(EMPTY_IMAGE_SRC) private emptyImageSrc: string,
  ) {
  }

  getImageLink(value: any, withFixedQuality: boolean): any {
    const quality = withFixedQuality
      ? `_${this.quality}`
      : '';

    if (Array.isArray(value)) {
      if (value.length > 0) {
        return `${value[0]?.file}${quality}`;
      } else {
        return this.emptyImageSrc
      }
    } else {
      return `${value?.file}${quality}`;
    }
  }
}
