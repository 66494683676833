/* tslint:disable:no-string-literal */
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AbstractDataService, NO_AUTH_HEADER } from './abstract-data-service';
import { ApiResponse } from '@rhbnb-nx-ws/domain';

export interface Response {
  id: string;
  entity: string;
  attributes: any;
}

export abstract class AbstractEavService<T> extends AbstractDataService<T> {
  constructor(
    protected http: HttpClient,
    protected eavEntity: string,
    protected apiURL: string
  ) {
    super(http, `eav`, apiURL);
  }

  getAll(): Observable<ApiResponse<T[]>> {
    return this.http
      .get<ApiResponse<T[]>>(
        `${this.apiURL}/${this.endpointName}/${this.eavEntity}`,
        {
          headers: { [NO_AUTH_HEADER]: 'yes' },
        }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  add(entity: T): Observable<ApiResponse<T>> {
    const payload = this.modelToApiResponse(entity);

    return super
      .add(payload as any)
      .pipe(
        map((res: ApiResponse<T>) => {
          const model = this.apiResponseToModel(res.data as any);
          return {
            success: true,
            data: model,
          } as ApiResponse<T>;
        })
      );
  }

  update(entity: T): Observable<ApiResponse<T>> {
    const payload = this.modelToApiResponse(entity);

    return super.update(payload as any).pipe(
      map((res: ApiResponse<T>) => {
        const model = this.apiResponseToModel(res.data as any);
        return {
          success: true,
          data: model,
        } as ApiResponse<T>;
      })
    );
  }

  apiResponseToModel(apiResponse: Response): T {
    const attributes = apiResponse.attributes;
    return { ...attributes, ...{ id: apiResponse.id } } as T;
  }

  modelToApiResponse(model: T): Response {
    const apiResponse = {} as Response;

    apiResponse.id = (model as any).id;
    apiResponse.entity = (model as any).entity;

    const clone = Object.assign({}, model);
    delete clone['id'];
    delete clone['entity'];

    apiResponse.attributes = clone;
    return apiResponse;
  }
}
