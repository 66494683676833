import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MomentService } from '@rhbnb-nx-ws/services';
import { Message } from './message.model';


@Component({
  selector: 'rhbnb-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnInit {
  @Input() message: Message;
  @Input() showUser = true;
  @Input() self = true;

  constructor(
    private momentService: MomentService
  ) { }

  ngOnInit(): void {
  }

  fromUnix(time: number) {
    return this.momentService.fromUnix(time);
  }
}
