import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';

@Component({
  selector: 'rhbnb-chat-type-box',
  templateUrl: './chat-type-box.component.html',
  styleUrls: ['./chat-type-box.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTypeBoxComponent extends WithUnsubscribe()
  implements OnInit {

  private isTyping = false;

  @Input() message = "";
  @Output() messageChange = new EventEmitter<string>();
  @Output() typingChange = new EventEmitter<boolean>();
  @Output() focusChange = new EventEmitter<void>();

  typing$$ = new Subject<void>();
  typing$ = this.typing$$.asObservable()
    .pipe(
      tap(() => {
        if (!this.isTyping) {
          this.typingChange.emit(true);
        }

        this.isTyping = true;
      }),
      debounceTime(3000),
      tap(() => {
        this.typingChange.emit(false);
        this.isTyping = false;
      }),
      takeUntil(this.unsubscribe$)
    )
  ;

  @ViewChild('form')
  private form;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.typing$.subscribe();
  }

  sendMessage() {
    this.messageChange.emit(this.message);
    this.message = "";
  }

  onAreaKeyDown(e) {
    this.typing$$.next();

    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();

      if (e.keyCode === 13) {
        this.sendMessage();
      }
    }
  }
}
