<form #form (submit)="sendMessage()">
  <div class="wrapper" fxLayout="row wrap" fxLayoutAlign="space-between center">

    <div class="px-10" fxFlex="1 1 auto">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'messages.message_label' | transloco }}</mat-label>

        <textarea matInput
                  tabindex="1"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="5"
                  name="message"
                  [(ngModel)]="message"
                  (keydown)="onAreaKeyDown($event)"
                  (focusin)="focusChange.emit()"
                  (click)="$event.stopPropagation()"></textarea>
      </mat-form-field>
    </div>

    <button mat-icon-button
            color="primary"
            [disabled]="message?.trim()?.length === 0"
            (click)="$event.stopPropagation()">
      <mat-icon svgIcon="send"></mat-icon>
    </button>
  </div>
</form>
