import { ChangeDetectorRef, Injectable } from "@angular/core";

import { UserService } from "./user.service";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AsyncValidators {
  emailValidator(userService: UserService, cdr: ChangeDetectorRef) {
    return (control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> => {
      return of(control.value).pipe(
        mergeMap((value: string) => {
          console.log(`Executing Async Validator for ${control.value}`);

          return userService.checkEmail(value).pipe(
            map(res => {
              const isNew = !res.data;
              return isNew ? null : { duplicateEmail: true };
            }),
            catchError((err) => {
              return of({ validationApiError: true });
            }),
            tap(() => cdr.detectChanges()),
          );
        })
      );
    }
  }
}
