import { Pipe, PipeTransform } from '@angular/core';
import { UrlUtilService } from '@rhbnb-nx-ws/services';

@Pipe({
  name: 'rhbnbUrlHostname',
})
export class UrlHostnamePipe implements PipeTransform {
  constructor(private urlUtilService: UrlUtilService) {}

  transform(value: string, ...args: unknown[]): unknown {
    const _default = args && args[0];
    return this.urlUtilService.getHostName(value) ?? _default ?? value;
  }
}
