import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ReservationStatus } from '@rhbnb-nx-ws/domain';

@Directive({
  selector: '[rhbnbPanelStatus]'
})
export class PanelStatusDirective implements OnInit {
  constructor(private el: ElementRef) {
  }

  @Input('rhbnbPanelStatus') status: ReservationStatus;

  ngOnInit(): void {
    this.el.nativeElement.style.padding = '5px 10px';
    this.el.nativeElement.style.borderRadius = '5px';
    this.el.nativeElement.classList.add(this.resolveClass(this.status));
  }

  resolveClass(status) {
    switch (status) {
      case ReservationStatus.STATUS_PENDING:
        return 'info-tag';
      case ReservationStatus.STATUS_CONFIRMED:
        return 'success-tag';
      case ReservationStatus.STATUS_CANCELED:
        return 'error-tag';
      case ReservationStatus.STATUS_COMPLETED:
        return 'completed-tag';
    }
  }
}
