<div class="list-wrapper"
     fxLayout="row wrap"
     fxLayout.xxs="column"
     fxLayoutAlign="start stretch"
     fxLayoutAlign.xxs="center stretch"
     fxLayoutGap="15px grid">

  <div fxFlex="100"
       [fxFlex.xs]="100"
       [fxFlex.gt-xs]="50"
       [fxFlex.gt-sm]="33.33"
       fxLayout="row wrap"
       fxLayoutAlign="start stretch"
       *ngFor="let el of list">

    <div class="item load-animate" fxFlex="100">
    </div>

  </div>
</div>
