import { Pipe, PipeTransform } from '@angular/core';
import { Price } from '@rhbnb-nx-ws/domain';
import { PriceUtilService } from '@rhbnb-nx-ws/services';

@Pipe({
  name: 'priceLabel'
})
export class PriceLabelPipe implements PipeTransform {

  constructor(
    private priceUtilService: PriceUtilService
  ) {}
  transform(value: Price|string, ...args: unknown[]): unknown {
    if (typeof value !== 'object') {
      return value || 'X';
    }

    return this.priceUtilService.generatePriceLabel(value);
  }

}
