import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';
import { HttpClient } from '@angular/common/http';
import { Price } from '@rhbnb-nx-ws/domain';

import { AbstractDataService } from '../util';

@Injectable({
  providedIn: 'root'
})
export class PriceApiService extends AbstractDataService<Price> {
  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'price', apiURL);
  }
}
