import { Pipe, PipeTransform } from '@angular/core';
import { Reservation } from '@rhbnb-nx-ws/domain';
import { HouseUtilService } from '@rhbnb-nx-ws/services';

@Pipe({
  name: 'bookingStatusLabel'
})
export class BookingStatusLabelPipe implements PipeTransform {

  constructor(
    private houseUtilService: HouseUtilService
  ) {
  }

  transform(value: Reservation, ...args: any[]): any {
    return value ? this.houseUtilService.statusLabel(value.status) : '';
  }

}
