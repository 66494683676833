import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractDataService, NO_AUTH_HEADER } from '@rhbnb-nx-ws/services';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '@rhbnb-nx-ws/domain';

export interface DebugMessage {
  stack: string;
  message: string;
}

@Injectable()
export class DebugClientService extends AbstractDataService<DebugMessage> {
  constructor(
    public http: HttpClient
  ) {
    super(http, 'debug', environment.apiURL);
  }

  add(entity: DebugMessage): Observable<ApiResponse<DebugMessage>> {
    return super.add(entity, { [NO_AUTH_HEADER]: 'yes' });
  }
}
