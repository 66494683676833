import { Inject, Injectable } from '@angular/core';
import {
  Message,
  RcEngine,
  RoomMessageResponse,
  RoomTypingsResponse,
  RcApi,
  RcBaseResponse,
  ClosedResponse,
  SendPlainMessageMethodCall,
  LoadHistoryMethodCall,
  Time,
  LoadMissedMethodCall,
  ReadMessagesMethodCall,
  UserSubscriptionResponse,
} from '@jjbenitez/glue-rocket-lib';
import { rethrowError } from '@rhbnb-nx-ws/utils';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import { interval, Subject, Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { RC_HTTP_URL, RC_WS_URL } from './tokens';
import { BaseEvent } from './events';
import { ScrollableResponse } from './scrollable.response';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends WithUnsubscribe() {

  private engine: RcEngine;
  private api: RcApi;
  private bus$$ = new Subject<BaseEvent>();
  private sub: Subscription;

  private token: string;
  private userId: string;
  private reconnecting = false;

  public groupSubs: {
    [groupId: string]: {
      subId: string,
    }
  } = {};

  public bus$ = this.bus$$.asObservable();

  constructor(
    @Inject(RC_WS_URL) private wsURL: string,
    @Inject(RC_HTTP_URL) private httpURL: string,
  ) {
    super();

    this.engine = new RcEngine(this.wsURL);
    this.initEngine();

    this.api = new RcApi(this.httpURL);
  }

  initEngine() {
    this.engine.open();

    this.sub = this.engine.bus$
      .pipe(
        map(r => this.transformIncomingMessages(r)),
        filter(e => !!e),
        tap(t => this.bus$$.next(t)),
        takeUntil(this.unsubscribe$),
        filter(r => r.type === 'Closed'),
        take(1)
      )
      .subscribe();
  }

  private transformIncomingMessages(e: RcBaseResponse): BaseEvent {
    if (e.msg === 'connected' && true === this.reconnecting) {
      return { type: 'Reconnected', payload: null } as BaseEvent;
    }

    if (this.engine.isGroupMessage(e)) {
      const args = (e as RoomMessageResponse)?.fields?.args;
      let messages;

      if (args) {
        messages = args?.filter(m => !m._id?.startsWith(this.engine.session));
      }

      return { type: 'Messages', payload: messages } as BaseEvent;
    }

    if (this.engine.isGroupTypingMessage(e)) {
      const fields = (e as RoomTypingsResponse)?.fields;

      const args = fields?.args;
      const [user, isTyping] = args;
      const groupId = fields?.eventName.split('/')[0]

      return { type: 'GroupTyping', payload: [isTyping, user, groupId] } as BaseEvent;
    }

    if (this.engine.isUserRoomSubscription(e)) {
      const fields = (e as UserSubscriptionResponse)?.fields;

      const args = fields?.args;
      const [, group] = args;

      return {
        type: 'UserSubscriptionUpdate',
        payload: [group?.rid, group]
      } as BaseEvent;
    }

    if (e instanceof ClosedResponse) {
      return { type: 'Closed', payload: null } as BaseEvent;
    }
  }

  setTokenAndUserId(token: string, userId: string) {
    this.token = token;
    this.userId = userId;

    this.api.token = token;
    this.api.userId = userId;
  }

  async login() {
    return rethrowError(async () => {
          const response = await this.engine.loginWithToken(this.token);
          return response?.result?.token;
    })();
  }

  async me() {
    return rethrowError(async () => {
          return this.api.me();
    })();
  }

  async ping() {
    return rethrowError(async () => {
          return this.engine.ping();
    })();
  }

  async subscribeToGroup(group: string) {
    return rethrowError(async () => {
          const id = uuid();
          await this.engine.subscribeToGroup(group, id);

          this.groupSubs[group] = { subId: id };
    })();
  }

  async subscribeToUserSubscription(userId: string) {
    return rethrowError(async () => {
      const id = uuid();
      return this.engine.subscribeToUserSubscription(userId, id);
    })();
  }

  async subscribeToTypings(group: string) {
    return rethrowError(async () => {
          const id = uuid();
          await this.engine.subscribeToGroupTypings(group, id);

          return id;
    })();
  }

  async unsubscribeFromGroup(group: string) {
    return rethrowError(async () => {
          const id = uuid();

          const { subId } = this.groupSubs[group];
          delete this.groupSubs[group];

          await this.engine.unsubscribeFromGroup(subId, id);
    })();
  }

  cleanGroupSubs() {
    this.groupSubs = {};
  }

  async unsubscribeFromGroupTypings(subId: string) {
    return rethrowError(async () => {
          await this.engine.unsubscribeFromGroupTypings(subId);
    })();
  }

  async loadGroupHistory(group: string,
                         after: number | null,
                         before: number,
                         size = 50
  ): Promise<ScrollableResponse<Message>> {
    // Get size + 1 to know if exists more elements
    return rethrowError(async () => {
          const r = await this.api.loadGroupHistory(new LoadHistoryMethodCall(
            uuid(),
            [
              group,
              before ? new Time(before): null,
              size + 1,
              after ? new Time(after) : null
            ]
          ));

          const messages = r.result?.messages ?? [];
          let hasMore = false;

          if (messages.length > size) {
            // Remove last messages to keep only [size] items
            messages.pop();
            hasMore = true;
          }

          return { items: messages, hasMore };
    })();
  }

  async loadMissedMessages(group: string,
                           before: number,
  ): Promise<Message[]> {
    return rethrowError(async () => {
          // Get size + 1 to know if exists more elements
          const r = await this.api.loadMissingMessages(
            new LoadMissedMethodCall(
              uuid(),
              [group, before ? new Time(before) : null]
            )
          );
          return r.result ?? [];
    })();
  }

  async sendPlainMessage(message: string, groupId: string, id?: string) {
    return rethrowError(async () => {
          const _id = id ?? uuid();

          return await this.api.sendMessages(
            new SendPlainMessageMethodCall(
              _id,
              [{ _id, rid: groupId, msg: message }]
            )
          );
    })();
  }

  async readMessages(groupId: string, id?: string) {
    return rethrowError(async () => {
      const _id = id ?? uuid();

      return await this.api.readMessages(
        new ReadMessagesMethodCall(
          _id,
          [groupId]
        )
      );
    })();
  }

  async typingChange(groupId: string, name: string, typing: boolean) {
    return rethrowError(async () => {
      return await this.engine.setTypings(groupId, name, typing);
    })();
  }

  keepPing() {
    return interval(30000)
      .pipe(
        switchMap(() => this.ping()),
        filter(r => false === r),
        tap(() => this.reconnect()),
        take(1)
      );
  }

  reconnect() {
    this.reconnecting = true;

    this.engine.close();
    this.sub.unsubscribe();

    this.initEngine();
  }

  getSessionId() {
    return this.engine.session;
  }
}
