import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, Country } from '@rhbnb-nx-ws/domain';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';
import { Observable } from 'rxjs';

import { AbstractDataService, NO_AUTH_HEADER } from '../util/abstract-data-service';

const CACHE_TTL = 1 * 24 * 60 * 60 * 1000; // 1 day

@Injectable({
  providedIn: 'root'
})
export class CountryApiService extends AbstractDataService<Country> {

  getAllCacheKey = 'getAllCache';

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'geodata/countries', apiURL);
    this.cacheKeys = [this.getAllCacheKey];
  }

  getAll(): Observable<ApiResponse<Country[]>> {
    const source$ = super.getAll({ [NO_AUTH_HEADER]: 'yes' });

    return this.getCacheEntry<ApiResponse<Country[]>>(this.getAllCacheKey)
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
  }
}
