import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as humanizeDuration from 'humanize-duration';

import { HumanizeDurationPipe } from './humanize-duration.pipe';
import { HUMANIZE_DURATION } from './humanize-duration.token';

const COMPONENTS = [
  HumanizeDurationPipe
];

export function _humanizeDuration() {
  return humanizeDuration.humanizer({
    units: ["d", "h", "m"],
    round: true
  });
}

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: [
    {
      provide: HUMANIZE_DURATION,
      useFactory: _humanizeDuration
    }
  ]
})
export class HumanTimeModule { }
