import { createAction, props } from '@ngrx/store';
import { Message } from '@jjbenitez/glue-rocket-lib';

import { ChatData } from './reducer';

export const setChatAuthToken = createAction(
  '[Core] Set Chat Auth Token',
  props<{token: string}>()
);

export const setChatUserId = createAction(
  '[Core] Set Chat User Id',
  props<{userId: string}>()
);

export const setChatUser = createAction(
  '[Core] Set Chat User',
  props<{user: {}}>()
);

export const pushChatGroupMessages = createAction(
  '[Core] Push Chat Group Messages',
  props<{groupId: string, messages: Message[], hasMore?: boolean}>()
);

export const unshiftChatGroupMessages = createAction(
  '[Core] Unshift Chat Group Messages',
  props<{groupId: string, messages: Message[], hasMore?: boolean}>()
);

export const replaceChatGroupMessages = createAction(
  '[Core] Replace Chat Group Messages',
  props<{groupId: string, message: Message, id: string}>()
);

export const setChatGroupHasMore = createAction(
  '[Core] Set Chat Group Has More',
  props<{groupId: string, hasMore?: boolean}>()
);

export const loginChatUser = createAction(
  '[Core] Login Chat User'
);

export const loginUserIntoChat = createAction(
  '[Core] Login User Into Chat'
);

export const setChatGroupData = createAction(
  '[Core] Set Chat Group Data',
  props<{groupId: string, data: ChatData}>()
);

export const setChatLoginLoading = createAction(
  '[Core] Set Chat Login Loading',
  props<{loading: boolean}>()
);

export const setChatReconnection = createAction(
  '[Core] Set Chat Reconnecting',
  props<{reconnecting: boolean}>()
);

export const loadChatUnreadCount = createAction(
  '[Core] Load Chat Unread Count'
);

export const setChatLostConnectionTime = createAction(
  '[Core] Set Chat Lost Connection Time',
  props<{time: number}>()
);

export const setChatGroupInitializing = createAction(
  '[Core] Set Chat Group Initializing',
  props<{groupId: string, initializing: boolean}>()
);

export const setChatGroupLoadMore = createAction(
  '[Core] Set Chat Group Load More',
  props<{groupId: string, loadMore: boolean}>()
);

export const addChatUserTyping = createAction(
  '[Core] Add Chat User Typing',
  props<{groupId: string, user: string}>()
);

export const setChatGroupFailed = createAction(
  '[Core] Set Chat Group Failed',
  props<{groupId: string, failed: boolean}>()
);

export const deleteChatUserTyping = createAction(
  '[Core] Delete Chat User Typing',
  props<{groupId: string, user: string}>()
);

export const incChatUnreadCount = createAction(
  '[Core] Inc Chat Unread Count',
  props<{count: number}>()
);

export const decChatUnreadCount = createAction(
  '[Core] Dec Chat Unread Count',
  props<{count: number}>()
);

export const setChatUnreadCount = createAction(
  '[Core] Set Chat Unread Count',
  props<{count: number}>()
);

export const setLoginUserLoading = createAction(
  '[Core] Set Login User Loading',
  props<{loading: boolean}>()
);
