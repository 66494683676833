import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Country } from '@rhbnb-nx-ws/domain';
import {
  AsyncValidators,
  NotifyService,
  TCCApiService,
  UserService,
} from '@rhbnb-nx-ws/services';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import {
  debounceTime,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { fadeIn, fadeOut } from 'ng-animate';
import { transition, trigger, useAnimation } from '@angular/animations';

@Component({
  selector: 'rhbnb-register-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('in', [
      transition(
        ':enter',
        useAnimation(fadeIn, {
          params: {
            timing: 0.1,
          },
        })
      ),
    ]),
    trigger('out', [
      transition(
        ':leave',
        useAnimation(fadeOut, {
          params: {
            timing: 0.1,
          },
        })
      ),
    ]),
  ],
})
export class FormComponent extends WithUnsubscribe() implements OnInit {
  form!: FormGroup;
  code: number;

  hide = true;
  hideConfirm = true;
  tccStatus: 'wrong' | 'success' | 'waiting' | 'unset' = 'unset';

  @Input() creating = false;
  @Output() registrationChange = new EventEmitter();
  @Output() loginChange = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private tccService: TCCApiService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private notify: NotifyService,
    private translocoService: TranslocoService,
    private asyncValidators: AsyncValidators
  ) {
    super();
    this.buildForm(asyncValidators, userService, cdr);
  }

  ngOnInit(): void {
    this.form
      .get('code')
      ?.valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(1000),
        tap(() => (this.tccStatus = 'waiting')),
        tap(() => this.cdr.detectChanges()),
        switchMap((code) => this.tccService.check(code)),
        map((res) => res.data),
        tap((status) => {
          if (status === 'ok') {
            this.tccStatus = 'success';
            return;
          }

          if (status === '404') {
            this.tccStatus = 'wrong';
            this.translocoService
              .selectTranslate('registration.tcc_error_code_not_found')
              .pipe(
                take(1),
                tap((msg) => this.notify.error(msg))
              )
              .subscribe();
            return;
          }

          this.tccStatus = 'wrong';
          this.translocoService
            .selectTranslate('registration.tcc_error_code_not_date')
            .pipe(
              take(1),
              tap((msg) => this.notify.error(msg))
            )
            .subscribe();
        }),
        tap(() => {
          this.cdr.detectChanges();

          setTimeout(() => {
            this.tccStatus = 'unset';
            this.cdr.detectChanges();
          }, 8000);
        })
      )
      .subscribe();
  }

  buildForm(
    asyncValidators: AsyncValidators,
    userService: UserService,
    cdr: ChangeDetectorRef
  ) {
    this.form = this.fb.group(
      {
        name: ['', Validators.required],
        email: [
          '',
          [Validators.required, Validators.email],
          asyncValidators.emailValidator(userService, cdr),
        ],
        country: ['', Validators.required],
        mobile: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
        code: [''],
      },
      {
        validators: this.samePasswords(),
      }
    );
  }

  samePasswords() {
    return (formGroup: FormGroup) => {
      let password = formGroup.get('password').value;
      let confirmPassword = formGroup.get('confirmPassword').value;

      const error = password === confirmPassword ? null : { notSame: true };
      formGroup.get('confirmPassword').setErrors(error);

      return error;
    };
  }

  onCountryChange(event: Country) {
    this.code = event?.callingCode;
  }

  onRegister() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.registrationChange.emit(this.form.value);
    }
  }

  onLoginChange() {
    this.loginChange.emit();
  }
}
