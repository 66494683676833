import {
  AfterViewInit, ApplicationRef,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter, forwardRef,
  Inject, Input, NgZone, OnDestroy,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import { MapLoaderService } from '@rhbnb-nx-ws/services';
import { DOCUMENT } from '@angular/common';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { TranslocoService } from '@ngneat/transloco';
import { MAPBOX_ACCESS_TOKEN } from '@rhbnb-nx-ws/global-tokens';

import { SearchMapItem } from './map-item';
import { MapItemBuilderService } from './map-item-builder.service';
import { tap } from 'rxjs/operators';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';

declare let mapboxgl: any;

@Component({
  selector: 'rhbnb-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MapItemBuilderService,
      useFactory: (appRef: ApplicationRef) => {
        const service = new MapItemBuilderService();
        service.appRef = appRef;
        return service;
      },
      deps: [forwardRef(() => ApplicationRef)],
    }
  ],
  animations: [
    trigger('in', [
      transition(':enter', useAnimation(fadeIn, { params: {
          timing: 0.3
        }}))
    ]),
    trigger('out', [
      transition(':leave', useAnimation(fadeOut, { params: {
          timing: 0.3
        }}))
    ]),
  ],
})
export class MapSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('map', { static: false })
  mapElement: ElementRef;

  @Input() set centerMap(value: [number, number]) {
    if (this.map && value) {
      this.map.jumpTo({
        center: value,
        zoom: this.initialZoom,
      })
    }
  }
  _isLoading = false;
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
  }
  get isLoading() {
    return this._isLoading;
  }

  @Output() rendererChange = new EventEmitter<ElementRef>();
  @Output() boundsChange = new EventEmitter<number[][]>();

  map: any;
  markers: any[];
  selectedItem: SearchMapItem;
  style = 'mapbox://styles/mapbox/streets-v12';
  private readonly initialZoom = 13;
  private firstLoad = false;

  private _items: SearchMapItem[] = [];
  @Input() set items(value: SearchMapItem[]) {
    this._items = value;

    if (this.map) {
      this.removeMarkers();
      this.markers = this.generateMarkers();

      this.ngZone.run(() => {
        // Center map (on first item) on first load
        if (!this.firstLoad) {
          this.firstLoad = true;

          if (this._items.length > 0) {
            this.centerMap = this._items[0].coordinate;
          }
        }

        this.cdr.detectChanges();
      });
    }
  }

  get items() {
    return this._items;
  }

  @Input() coordinate = [0, 0];
  @Input() zoom = 13;

  constructor(
    private mapLoaderService: MapLoaderService,
    private translate: TranslocoService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private mapItemBuilderService: MapItemBuilderService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(MAPBOX_ACCESS_TOKEN) public accessToken: string,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.mapLoaderService.lazyLoadMap()
      .subscribe(_ => {
        if (!mapboxgl) {
          mapboxgl = (this.document.defaultView as any).mapboxgl;
        }

        this.initializeMap();
      });

    this.rendererChange.emit(this.mapElement);
  }

  private initializeMap() {
    mapboxgl.accessToken = this.accessToken;

    this.ngZone.runOutsideAngular(() => {
      this.buildMap();

      this.map.jumpTo({
        center: this.items[0]?.coordinate,
      });

      this.ngZone.run(() => {
        // Starting markers the first time
        this.markers = this.generateMarkers();
        this.cdr.detectChanges();
      });
    });
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      container: this.mapElement.nativeElement,
      style: this.style,
      zoom: this.zoom,
      center: this.coordinate
    });

    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());

    // Change lang
    const language = new MapboxLanguage({
      defaultLanguage: this.translate.getActiveLang()
    });
    this.map.addControl(language);

    // Add listeners
    this.map.on('load', this.resizeMap.bind(this));
    this.map.on('zoomend', this.changeBounds.bind(this));
    this.map.on('dragend', this.changeBounds.bind(this));
  }

  onCloseItemView() {
    this.selectedItem = undefined;
    this.cdr.detectChanges();
  }

  resizeMap() {
    this.map?.resize();
  }

  changeBounds() {
    this.boundsChange.emit(this.mapBoundsToPolygon(this.map.getBounds()));
  }

  onMarkerClick(item: SearchMapItem) {
    this.selectedItem = undefined;
    this.cdr.detectChanges();

    this.selectedItem = this.selectedItem === item
      ? undefined
      : item;

    this.cdr.detectChanges();
  }

  private generateMarkers() {
    return this.items.map(_i => {
      const cmp = this.mapItemBuilderService.createComponent(_i);
      cmp.instance?.itemChange
        .pipe(
          tap(item => this.onMarkerClick(item))
        )
        .subscribe();
      const element = cmp.location.nativeElement;

      return new mapboxgl.Marker({
        element,
      })
        .setLngLat(_i.coordinate)
        .addTo(this.map);
    });
  }

  private removeMarkers() {
    this.markers.forEach(m => {
      if (m) {
        m?.remove();
      }
    })
  }

  private mapBoundsToPolygon({_sw, _ne}: {_sw: {lat: number, lng: number}, _ne: {lat: number, lng: number}}) {
    const p0 = [_sw.lng, _sw.lat];
    const p1 = [_ne.lng, _sw.lat];
    const p2 = [_ne.lng, _ne.lat];
    const p3 = [_sw.lng, _ne.lat];

    return [p0, p1, p2, p3, p0];
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.off('load', this.resizeMap);
      this.map.off('zoomend', this.changeBounds);
      this.map.off('dragend', this.changeBounds);
    }
  }
}
