import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import { MomentService } from '@rhbnb-nx-ws/services';
import { User } from '@jjbenitez/glue-rocket-lib';

import { Message } from './message.model';

@Component({
  selector: 'rhbnb-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWindowComponent extends WithUnsubscribe()
  implements OnInit {

  _messages: Message[] = [];
  groupedByDate: Message[][];

  @Input() set messages(messages: Message[]) {
    if (!!messages) {
      this._messages = messages.map((m, i, arr) => {
        let mapped = this.showUser(i, arr) ?
          ({ ...m, isFirstOfUser: true }) :
          m;

        mapped = {
          ...mapped,
          self: this.isSelf(m)
        }

        return mapped;
      });
      this.groupedByDate = this.groupedMessages();
    }
  }

  get messages() {
    return this._messages;
  }

  _loading = false;
  @Input() set loading(value) {
    this._loading = value;
  }

  get loading() {
    return this._loading;
  }

  @Input() loadingMore = false;
  @Input() reconnecting = false;
  @Input() failed = false;
  @Input() typings: string[] = [];
  @Input() me: User;

  @Output() topChange = new EventEmitter<void>();
  @Output() newMessage = new EventEmitter<string>();
  @Output() typingChange = new EventEmitter<boolean>();
  @Output() failedChange = new EventEmitter<void>();
  @Output() focusChange = new EventEmitter<void>();

  @ViewChild('chatList') private chatListEl: ElementRef;

  constructor(
    private momentService: MomentService
  ) {
    super();
  }

  ngOnInit(): void {
  }

  groupedMessages() {
    return this.messages.reduce((acc: Message[][], v) => {
      if (acc.length === 0) {
        acc.push([v]);
        return acc;
      }

      const lastGroup = acc[acc.length - 1];

      if (lastGroup.length === 0) {
        lastGroup.push(v);
        return acc;
      }

      const lastItem = lastGroup[lastGroup.length - 1];
      if (this.momentService.fromUnix(lastItem?.ts?.$date)
        .isSame(this.momentService.fromUnix(v?.ts?.$date), 'day')) {
        lastGroup.push(v);
      } else {
        acc.push([v]);
      }

      return acc;
    }, []);
  }

  fromUnix(time: number) {
    return new Date(time);
  }

  async onWindowScroll(e: Event) {
    const el = (e.target as HTMLElement);

    if (el.scrollTop < 10) {
      this.topChange.emit();
    }
  }

  async onNewMessage(message: string) {
    this.newMessage.emit(message);
  }

  onTypingChange(typing: boolean) {
    this.typingChange.emit(typing);
  }

  onInputFocusChange() {
    this.focusChange.emit();
  }

  getCatListNativeElement() {
    return this.chatListEl.nativeElement;
  }

  alignMessage(m: Message) {
    return this.me?._id === m?.u?._id ? 'end' : 'start';
  }

  showUser(index: number, arr: Message[]) {
    if (index === 0) {
      return true;
    }

    const prev = arr[index - 1];
    const current = arr[index];

    const timePrev = this.momentService.fromUnix(prev?.ts?.$date);
    const timeCurrent = this.momentService.fromUnix(current?.ts?.$date);

    return prev?.u?._id !== current?.u?._id || !timeCurrent.isSame(timePrev, 'day');
  }

  isSelf(m: Message) {
    return this.me?._id === m?.u?._id;
  }

  scrollToBottom(): void {
    try {
      const el = this.chatListEl.nativeElement;
      el?.scroll({ top: el.scrollHeight });
    } catch (err) {
      console.error(err)
    }
  }

  trackByGroupFn(index, group: Message[]) {
    if (!group) {
      return null;
    }

    return group[0]?._id;
  }

  trackByFn(index, item: Message) {
    if (!item) {
      return null;
    }

    return item._id;
  }

  isBottom() {
    const el = this.getCatListNativeElement();

    if (el) {
      const { scrollHeight, scrollTop, clientHeight } = el;
      const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

      return distanceFromBottom < 5;
    }

    return false;
  }
}
