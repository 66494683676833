import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'rhbnb-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabHeaderComponent implements OnInit {

  @ViewChild(TemplateRef)
  labelContent: TemplateRef<any>;

  @Output()
  clicked = new EventEmitter<void>();

  onClick() {
    this.clicked.emit();
  }

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
