import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'rhbnb-search-button-wrapper',
  templateUrl: './search-button-wrapper.component.html',
  styleUrls: ['./search-button-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchButtonWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
