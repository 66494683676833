import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

@Injectable({
  providedIn: 'root',
})
export class OembedService {
  private scriptRef: any;
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(API_BASE_URL) private apiUrl: string,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  addOembedScriptToHead(type: string, id: any, url: string) {
    if (this.scriptRef) {
      this.removeOembedScriptToHead();
    }

    if (isPlatformBrowser(this.platformId)) {
      this.scriptRef = this.renderer.createElement('link');

      this.scriptRef.type = 'application/json+oembed';
      this.scriptRef.rel = 'alternate';
      this.scriptRef.href = `${this.apiUrl}/oembed/${type}/${id}?url=${url}`;

      this.renderer.appendChild(document.head, this.scriptRef);
    }
  }

  removeOembedScriptToHead() {
    if (isPlatformBrowser(this.platformId) && this.scriptRef) {
      this.renderer.removeChild(document.head, this.scriptRef);
      this.scriptRef = undefined;
    }
  }
}
