import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { EvaluationLineComponent } from './evaluation-line/evaluation-line.component';

const COMPONENTS = [
  EvaluationLineComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class HouseCardPiecesModule { }
