import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabContentComponent } from './tab/tab-content.component';
import { TabsComponent } from './tabs/tabs.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TabHeaderComponent } from './tab/tab-header.component';
import { TabItemComponent } from './tab/tab-item.component';

const COMPONENTS = [
  TabContentComponent,
  TabsComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    TabHeaderComponent,
    TabItemComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule
  ],
  exports: [
    ...COMPONENTS,
    TabHeaderComponent,
    TabItemComponent
  ]
})
export class SimpleTabsModule { }
