import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, Observable, throwError as observableThrowError } from 'rxjs';
import { Moment } from 'moment';
import { RoomEvent } from '@rhbnb-nx-ws/domain';
import { ApiResponse } from '@rhbnb-nx-ws/domain';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

import { MomentService } from './moment.service';
import { AbstractDataService, NO_AUTH_HEADER } from '../util';
import { EventsBaseService } from './events-base.service';

@Injectable({
  providedIn: 'root'
})
export class RoomEventsService extends AbstractDataService<RoomEvent> {

  constructor(
    public http: HttpClient,
    public momentService: MomentService,
    private eventsBaseService: EventsBaseService,
    @Inject(API_BASE_URL) public apiURL: string,
  ) {
    super(http, 'events', apiURL);
  }

  add(entity: RoomEvent): Observable<ApiResponse<RoomEvent>> {
    entity.start = this.momentService.getFormat(entity.start);
    entity.end = this.momentService.getYesterdayFormat(entity.end);

    return super.add(entity);
  }

  get(roomIds: Array<string>, from: Moment, to: Moment,
      joinReservations = false, joinCalendarEvents = false): Observable<RoomEvent[]> {
    if (roomIds.length === 1) {
      return this.getForRoom(roomIds[0], from, to, joinReservations, joinCalendarEvents)
        .pipe(
          map((res: ApiResponse<RoomEvent[]>) => res.data)
        );
    }
    return this.getForRooms(roomIds, from, to, joinReservations);
  }

  private getForRooms(roomIds: Array<string>, from: Moment, to: Moment,
                      joinReservations = false, joinCalendarEvents = false): Observable<RoomEvent[]> {
    const events$ = [];

    roomIds.forEach(roomId => {
      events$.push(this.getForRoom(roomId, from, to, joinReservations, joinCalendarEvents));
    });

    return forkJoin(events$)
      .pipe(
        map((events: ApiResponse<RoomEvent[]>[]) => {
          const mergedApiResponse = [].concat.apply([], events);
          return [].concat.apply([], mergedApiResponse.map(ar => ar.data));
        })
      );
  }

  getForRoomsByIds(ids: string[], from: Moment, to: Moment): Observable<ApiResponse<RoomEvent[]>> {
    const params = {
      from: this.momentService.formatWithoutTzAndStartOfDay(from),
      to: this.momentService.formatWithoutTzAndEndOfDay(to),
      ids: JSON.stringify(ids)
    };

    return this.http
      .get<ApiResponse<RoomEvent[]>>(`${this.apiURL}/${this.endpointName}/for-multiple/room`,
        { params, headers: { [NO_AUTH_HEADER]: 'yes' } })
      .pipe(
        map((res: ApiResponse<RoomEvent[]>) => this.eventsBaseService.toLocalTZ(res)),
        map((res: ApiResponse<RoomEvent[]>) => this.eventsBaseService.addOneDayToEndDate(res)),
        catchError(error => observableThrowError(error))
      );
  }

  private getForRoom(roomId: string, from: Moment, to: Moment,
                     joinReservations = false, joinCalendarEvents = false
  ): Observable<ApiResponse<RoomEvent[]>> {
    const params = {
      from: this.momentService.formatWithoutTzAndStartOfDay(from),
      to: this.momentService.formatWithoutTzAndEndOfDay(to)
    };

    if (joinReservations) {
      params['join-reservation'] = true;
    }

    if (joinCalendarEvents) {
      params['join-cal-events'] = true;
    }

    return this.http
      .get<ApiResponse<RoomEvent[]>>(
        `${this.apiURL}/${this.endpointName}/room/${roomId}`,
        { params, headers: { [NO_AUTH_HEADER]: 'yes' } })
      .pipe(
        map((res: ApiResponse<RoomEvent[]>) => this.eventsBaseService.toLocalTZ(res)),
        map((res: ApiResponse<RoomEvent[]>) => this.eventsBaseService.addOneDayToEndDate(res)),
        map((res: ApiResponse<RoomEvent[]>) => this.eventsBaseService.adjustPrice(res)),
        catchError(error => observableThrowError(error))
      );
  }
}
