import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotifyService, SnackTime } from '@rhbnb-nx-ws/services';
import { take, tap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

const ATTEMPT = 3;

@Directive({
  selector: '[rhbnbCopyClipboard]'
})
export class CopyClipboardDirective {

  @Input() toCopy: string;

  constructor(
    private clipboard: Clipboard,
    private notifyService: NotifyService,
    private translocoService: TranslocoService,
    private el: ElementRef
  ) { }

  @HostListener('click')
  onClick() {
    const text = this.toCopy ? this.toCopy : this.el?.nativeElement?.innerText;

    this.copyAchievements(text)
      .then(() => {
        this.translocoService.selectTranslate('core.copied')
          .pipe(
            tap(msg => this.notifyService.info(msg, SnackTime.NORMAL, true)),
            take(1)
          )
          .subscribe()
        ;
      })
    ;
  }

  copyAchievements(text: string) {
    return new Promise<void>(resolve => {
      const pending = this.clipboard.beginCopy(text);

      let remainingAttempts = ATTEMPT;

      const attempt = () => {
        const result = pending.copy();

        if (!result && --remainingAttempts) {
          setTimeout(attempt);
        } else {
          pending.destroy();
          resolve();
        }
      };

      attempt();
    });
  }
}
