import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';

import { AvatarModule } from '../avatar/avatar.module';
import { OutingHostInfoComponent } from './outing-host-info/outing-host-info.component';

const COMPONENTS = [
  OutingHostInfoComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    AvatarModule,
    TranslocoModule,
    RouterModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class OutingHostInfoModule { }
