import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { HUMANIZE_DURATION } from './humanize-duration.token';

@Pipe({
  name: 'humanizeDuration'
})
export class HumanizeDurationPipe implements PipeTransform {

  constructor(
    @Inject(HUMANIZE_DURATION) private humanizeDuration,
    private translocoService: TranslocoService
  ) {
  }

  transform(time: number, ...args: unknown[]): Observable<string> {
    return this.translocoService.langChanges$.pipe(
      map(lang => this.humanizeDuration(time, { language: lang, round: true }))
    );
  }

}
