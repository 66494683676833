export abstract class Event {
  id?: string;
  entity?: string;
  element?: string;
  guid?: string;
  start?: string;
  end?: string;
  available?: boolean;
  data?: any;
  minNights?: number;
}
