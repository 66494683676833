import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Destination } from '@rhbnb-nx-ws/domain';
import { IMAGE_QUALITY } from '@rhbnb-nx-ws/global-tokens';

import { showing } from '../../animations/global.animation';
import { APP_CONFIG, IAppConfig } from '../../app.config';

@Component({
  selector: 'rhbnb-destination-slide-item',
  templateUrl: './destination-slide-item.component.html',
  styleUrls: ['./destination-slide-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    showing()
  ]
})
export class DestinationSlideItemComponent implements OnInit {

  @Input() destination: Destination;
  @Output() destinationChange = new EventEmitter<Destination>();

  defaultImage = this.config.lazyImage;

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    @Inject(IMAGE_QUALITY) private quality: number
  ) { }

  ngOnInit(): void {
  }

  onDestinationChange() {
    this.destinationChange.emit(this.destination);
  }
}
