import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject, InjectionToken,
  Input,
  NgZone, OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { animate, AnimationBuilder, AnimationMetadata, keyframes, style } from '@angular/animations';

enum LoadStatus {
  success,
  fail
}

export let LLD_LOADING_GIF = new InjectionToken('lld.loading-gif');

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'img[appLazyLoad]'
})
/**
 * @deprecated Use lazy-sizes directive instead
 */
export class LazyLoadDirective implements OnInit, AfterViewInit, OnDestroy {

  @Input() src: string;
  @Input() defaultSrc: string;
  @Input() size = '200px';
  @Input() containerHeight = '100%';
  @Input() errorImage: string | boolean;

  private parentEl: ParentNode;
  private containerEl: HTMLElement;
  private imgEl: HTMLElement;

  private readonly imageIndicatorBus$$ = new Subject<LoadStatus>();
  private readonly destroy$$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private animationBuilder: AnimationBuilder,
    @Inject(LLD_LOADING_GIF) private loadingGif: string
  ) {
  }

  ngOnInit(): void {
    this.getHTMLElement().src = this.defaultSrc;
    this.subscribeToImageChanges();
  }

  subscribeToImageChanges() {
    this.imageIndicatorBus$$.asObservable()
      .pipe(
        takeUntil(this.destroy$$),
        tap(v => {
          if (v === LoadStatus.fail) {
            this.addErrorElement();
          } else {
            // this.removeLoadingIndicator();
            this.getHTMLElement().src = this.src;

            this.getHTMLElement().onload = () => {
              // this.animateImageIn();
            };
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
  }

  @HostListener('error') onError() {
    this.addErrorElement();
  }

  private canLazyLoad() {
    return window && 'IntersectionObserver' in window;
  }

  private lazyLoadImage() {
    this.ngZone.runOutsideAngular(() => {

      const obs = new IntersectionObserver(entries => {
        entries.forEach(({isIntersecting}) => {
          if (isIntersecting) {
            this.loadImage();
            obs.unobserve(this.el.nativeElement);
          }
        });
      });
      obs.observe(this.el.nativeElement);

    });
  }

  private loadImage() {
    this.parentEl = this.getHTMLElement().parentNode;
    this.ngZone.runOutsideAngular(() => this.startBackgroundLoad());

    // this.addLoadingIndicator();
  }

  private startBackgroundLoad() {
    const fakeImage: HTMLImageElement = this.renderer.createElement('img');
    this.renderer.setStyle(fakeImage, 'visibility', 'hidden');
    this.renderer.setStyle(fakeImage, 'width', '0');
    this.renderer.setStyle(fakeImage, 'height', '0');
    this.renderer.appendChild(this.parentEl, fakeImage);

    fakeImage.src = this.src;
    fakeImage.onload = () => {
      this.imageIndicatorBus$$.next(LoadStatus.success);
    };
    fakeImage.onerror = () => {
      this.imageIndicatorBus$$.next(LoadStatus.fail);
    };
  }

  private getHTMLElement(): HTMLImageElement {
    return this.el.nativeElement as HTMLImageElement;
  }

  private addLoadingIndicator() {
    this.getHTMLElement().src = this.defaultSrc;

    this.containerEl = this.renderer.createElement('div');
    this.imgEl = this.renderer.createElement('img');

    this.renderer.setProperty(this.imgEl, 'src', this.loadingGif);
    this.renderer.setStyle(this.imgEl, 'width', this.size);
    this.renderer.setStyle(this.imgEl, 'height', 'auto');

    this.renderer.appendChild(this.containerEl, this.imgEl);

    this.renderer.setStyle(this.containerEl, 'position', 'absolute');
    this.renderer.setStyle(this.containerEl, 'width', '100%');
    this.renderer.setStyle(this.containerEl, 'height', this.containerHeight);
    this.renderer.setStyle(this.containerEl, 'top', '0');
    this.renderer.setStyle(this.containerEl, 'display', 'flex');
    this.renderer.setStyle(this.containerEl, 'place-content', 'center');
    this.renderer.setStyle(this.containerEl, 'align-items', 'center');

    this.renderer.appendChild(this.parentEl, this.containerEl);
  }

  private removeLoadingIndicator() {
    this.renderer.removeChild(this.parentEl, this.containerEl);
  }

  private addErrorElement() {
    if (typeof this.errorImage === 'boolean' && !this.errorImage) {
      this.renderer.removeChild(this.containerEl, this.imgEl);
    } else if (this.errorImage) {
      // this.removeLoadingIndicator();
      this.getHTMLElement().src = this.errorImage as string;
      // this.animateImageIn();
    }
  }

  private animateImageIn() {
    const factory = this.animationBuilder.build(this.fadeIn());
    const player = factory.create(this.getHTMLElement());
    player.play();

    player.onDone(() => {
      if (player) {
        player.destroy();
      }
    });
  }

  private fadeIn(): AnimationMetadata[] {
    return [
      animate(650, keyframes([
        style({ opacity: 0, easing: 'ease', offset: 0 }),
        style({ opacity: 1, easing: 'ease', offset: 1 })
      ]))
    ];
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

}
