import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedPipesModule } from '@rhbnb-nx-ws/shared-pipes';


import { LazySizesPictureComponent } from './lazy-sizes-picture/lazy-sizes-picture.component';

const COMPONENTS = [
  LazySizesPictureComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [CommonModule, SharedPipesModule],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
  ]
})
export class LazySizesPictureModule {}
