import { BaseModel } from './base.model';
import { Client } from './client.model';

export class User extends BaseModel {
  public id?: string;
  public name?: string;
  public mobile?: string;
  public image?: string;
  public email?: string;
  public roles?: Array<string>;
  public password?: string;
  public locale?: string;
  public active?: boolean;
  public country?: any;
  public travelCredit?: number;
  public client?: Client | number | string;

  constructor(init?: User) {
    super();
    Object.assign(this, init);
  }

  hasRole(role: string) {
    if (!this.roles || !Array.isArray(this.roles)) {
      return false;
    }

    return this.roles.includes(role);
  }
}
