<div class="title mb-10">{{ 'global.price_variant_chooser.header' | transloco }}</div>

<mat-radio-group
  [(ngModel)]="selected"
  (change)="onSelectedChange($event)"
  fxLayout="row wrap"
  aria-labelledby="price-variant-label"
  class="radio-group">

  <ng-container *ngFor="let op of options">
    <mat-radio-button class="item"
                      [value]="op.value"
                      fxFlex="100">
      {{ op.label | transloco }}
    </mat-radio-button>

    <div class="hint">{{ op.hint | transloco }}</div>
  </ng-container>

</mat-radio-group>
