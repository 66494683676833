import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapSwitcherComponent } from './map-switcher.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

const COMPONENTS = [
  MapSwitcherComponent,
]

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class MapSwitcherModule { }
