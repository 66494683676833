import { BaseModel } from './base.model';
import { House } from './house.model';
import { Reservation } from './reservation.model';
import { Room } from './room.model';
import { Payment } from './payment.model';

export enum ReservationModificationRequestStatus {
  PENDING = 'PENDIENTE',
  STATUS_WAIT_PAYMENT = 'ESPERANDO_PAGO',
  STATUS_COMPLETED = 'COMPLETADA',
  STATUS_CANCEL = 'CANCELADA',
}

export class ReservationModificationRequest extends BaseModel {
  public house?: string | House;
  public rooms?: string[] | number[] | Room[];
  public checkIn?: string;
  public checkOut?: string;
  public status?: ReservationModificationRequestStatus;
  public guests?: number;
  public price?: number;
  public reservation?: string | Reservation;
  public payment?: string | Payment;

  constructor(init?: ReservationModificationRequest) {
    super();
    Object.assign(this, init);
  }
}
