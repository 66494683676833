import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'rhbnb-bar-generic-loading-indicator',
  templateUrl: './bar-generic-loading-indicator.component.html',
  styleUrls: ['./bar-generic-loading-indicator.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarGenericLoadingIndicatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
