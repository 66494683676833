import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule as NgxAvatarModule } from '@jjbenitez/ngx-avatar';

import { AvatarImageComponent } from './avatar-image/avatar-image.component';

const COMPONENTS = [
  AvatarImageComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    NgxAvatarModule,
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class AvatarModule { }
