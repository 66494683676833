import { animate, keyframes, style, transition, trigger } from '@angular/animations';

export const slideDownAnimation = [
  trigger('slideDown', [
    transition(':enter', [
      animate(350, keyframes([
        style({opacity: 0, transform: 'translateY(-100%)', offset: 0}),
        style({opacity: 1, transform: 'translateY(-5px)', offset: 0.3}),
        style({opacity: 1, transform: 'translateY(0)', offset: 1.0})
      ]))
    ]),
    transition(':leave', [
      animate(600, keyframes([
        style({opacity: 1, transform: 'translateY(-0)', offset: 0}),
        style({opacity: 1, transform: 'translateY(-5px)', offset: 0.7}),
        style({opacity: 0, transform: 'translateY(100%)', offset: 1.0})
      ]))
    ])
  ]),
];
