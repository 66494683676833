import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

type Size = 'short' | 'medium' | 'large';

@Component({
  selector: 'rhbnb-chat-loading-item',
  template: `
    <div fxLayout="column" class="item">
      <div fxFlex="1 1 5px" class="user load-animate" *ngIf="showUser"></div>

      <div fxFlex="1 1 5px" class="message load-animate"></div>
      <div fxFlex="1 1 5px" class="message load-animate"></div>
      <div fxFlex="1 1 5px" class="message load-animate"></div>

      <div fxFlex="1 1 5px" fxFlexAlign="end" class="time load-animate"></div>
    </div>
  `,
  styles: [
    `
      .item {
        border-radius: 7.5px;
        box-shadow: 0 1px .5px rgba(11, 20, 26, .13);
        background-color: white;
        padding: 6px 15px 8px 15px;
        margin-bottom: 2px;
        width: 100%;
      }

      .item > div {
      }

      .message {
        width: 100%;
        margin-bottom: 3px;
      }

      .time {
        text-align: right;
        width: 30%;
      }

      .user {
        width: 40%;
        margin-bottom: 1px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatLoadingItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  get showUser() {
    return Math.random() <= 0.2;
  }
}
