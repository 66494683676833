import { InjectionToken } from '@angular/core';

export interface IURLS {
  apiURL: string,
  ssoLoginUrl: string;
  ssoLogoutUrl: string;
  adminURL: string;
  platformURL: string;
  chatUrl: string;
  imageFilterServiceUrl: string;
  baseDomain: string;
}

export let URLS = new InjectionToken<IURLS>('app.urls');
