import { Component, Inject, Input, OnInit } from '@angular/core';
import { CardWithImg } from './card-with-image.item';
import { DEFAULT_IMAGE_SRC } from '@rhbnb-nx-ws/global-tokens';

@Component({
  selector: 'rhbnb-card-with-img',
  templateUrl: './card-with-img.component.html',
  styleUrls: ['./card-with-img.component.scss'],
})
export class CardWithImgComponent implements OnInit {
  @Input() item!: CardWithImg;

  constructor(
    @Inject(DEFAULT_IMAGE_SRC) public defaultImage: string,
  ) {}

  ngOnInit(): void {}
}
