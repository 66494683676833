import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input, NgZone,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, mergeMap, skip, take, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CountryEntity as Country, Destination } from '@rhbnb-nx-ws/domain';

import { WithUnsubscribe } from '../../classes/with-unsubscribe';
import { LayoutStoreService } from '../../../layout/store/layout-store.service';

@Component({
  selector: 'rhbnb-destination-slide',
  templateUrl: './destination-slide.component.html',
  styleUrls: ['./destination-slide.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationSlideComponent extends WithUnsubscribe()
  implements OnInit {

  @Input() destinations: Destination[];
  @Input() country: Country;

  @ViewChild('list') list: ElementRef;
  @ViewChildren('item', { read: ElementRef }) items: QueryList<ElementRef>;

  private readonly startSubject = new BehaviorSubject<boolean>(true);
  private readonly endSubject = new BehaviorSubject<boolean>(false);
  private readonly scrollSubject = new Subject<void>();

  start$ = this.startSubject.asObservable();
  end$ = this.endSubject.asObservable();

  constructor(
    private layoutStoreService: LayoutStoreService,
    private router: Router,
    private ngZone: NgZone
  ) {
    super();
  }

  ngOnInit(): void {
    this.scrollSubject
      .asObservable()
      .pipe(
        debounceTime(50),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.detectLimits());

    this.scrollSubject.next();
  }

  async onClickAction(destination: Destination): Promise<void> {
    this.layoutStoreService.getFilterDestination()
      .pipe(
        skip(1),
        mergeMap(() => this.layoutStoreService.buildQueryParamsFromState()),
        tap(q => this.router.navigate([this.country, 'houses', 'list'], {
          queryParams: q,
          queryParamsHandling: 'merge'
        })),
        take(1)
      )
      .subscribe();

    this.layoutStoreService.setFilterDestination(destination.id);
  }

  onScroll(e) {
    this.scrollSubject.next();
  }

  next() {
    const width = this.list?.nativeElement?.offsetWidth;
    this.list.nativeElement.scrollLeft = this.list?.nativeElement?.scrollLeft + width;
  }

  prev() {
    const width = this.list?.nativeElement?.offsetWidth;
    this.list.nativeElement.scrollLeft = this.list?.nativeElement?.scrollLeft - width;
  }

  detectLimits() {
    this.ngZone.runOutsideAngular(() => {
      if (this.start) {
        this.startSubject.next(true);
      } else {
        this.startSubject.next(false);
      }

      if (this.end) {
        this.endSubject.next(true);
      } else {
        this.endSubject.next(false);
      }
    });
  }

  get start() {
    return this.list?.nativeElement?.scrollLeft === 0;
  }

  get end() {
    const width = this.list?.nativeElement?.offsetWidth;
    return (this.getFullWidth() < width) || (this.getFullWidth() - (this.list?.nativeElement?.scrollLeft + width) <= 20);
  }

  getFullWidth() {
    if (!this.items) {
      return 0;
    }

    return this.items.toArray().reduce((acc, el) => acc + el.nativeElement.offsetWidth, 0);
  }

  trackByItems(index: number, item: Destination) {
    return item.id;
  }
}
