import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

export enum SnackKind {
  INFO = 'info',
  ERROR = 'error'
}

export enum SnackTime {
  SHORT = 4 * 1000,
  NORMAL = 7 * 1000,
  LONG = 12 * 1000,
  INFINITE = -1
}

export interface SnackData {
  message: string;
  kind: SnackKind;
  time: SnackTime;
  action?: { text: string, complete: Subject<any> };
}

@Component({
  selector: 'rhbnb-custom-snack',
  templateUrl: './custom-snack.component.html',
  styleUrls: ['./custom-snack.component.sass']
})
export class CustomSnackComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackData,
    private snackBarRef: MatSnackBarRef<any>
  ) {
  }

  snackKind = SnackKind;

  ngOnInit() {
  }

  close() {
    this.snackBarRef.dismiss();
  }

  action() {
    if (this.data?.action?.complete) {
      this.data?.action?.complete?.next();
    }

    this.close();
  }

  get closeText() {
    return this.data?.action?.text;
  }
}
