import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserReviewCardComponent } from './user-review-card/user-review-card.component';
import { UserReviewCardSlideComponent } from './user-review-card-slide/user-review-card-slide.component';
import { SwiperModule } from 'swiper/angular';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [UserReviewCardComponent, UserReviewCardSlideComponent],
  imports: [CommonModule, SwiperModule, NgScrollbarModule, TranslocoModule],
  exports: [UserReviewCardComponent, UserReviewCardSlideComponent],
})
export class UserReviewModule {}
