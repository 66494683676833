import { Injectable } from '@angular/core';
import { Price, PriceComplete, PricePerRange, PricePerUnit } from '@rhbnb-nx-ws/domain';

@Injectable({
  providedIn: 'root'
})
export class PriceUtilService {

  generatePriceLabel(price: Price) {
    const type = price?.type || price?.discr;

    switch (type) {
      case 'complete':
        return Number((<PriceComplete>price)?.amount)/100;
      case 'per_unit':
        return Number((<PricePerUnit>price)?.amount)/100;
      case 'per_range':
        const [min, max] = this.getRangePriceLimits(<PricePerRange>price);
        return `[${min/100} - ${max/100}]`;
      default:
        return 'X';
    }
  }

  getRangePriceLimits(price: PricePerRange) {
    const range = price.ranges;

    const a = range[0];
    const b = range[range.length - 1];

    const min = Math.min(Number(a.amount), Number(b.amount));
    const max = Math.max(Number(a.amount), Number(b.amount));

    return [min, max];
  }

  computePrice(price: Price, guests: number) {
    const type = price?.type || price?.discr;

    switch (type) {
      case 'complete':
        return Number((<PriceComplete>price)?.amount);
      case 'per_unit':
        return (Number((<PricePerUnit>price)?.amount)) * guests;
      case 'per_range':
        const ranges = (<PricePerRange>price).ranges;
        const match = ranges.find(r => guests >= r.start && guests <= r.end);

        if (!match) {
          return false;
        }

        return match.amount;
    }
  }
}
