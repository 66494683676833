import { Inject, Injectable } from '@angular/core';
import { OutingExtra } from '@rhbnb-nx-ws/domain';
import { AbstractEavService} from '../util/abstract-eav-service';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';


@Injectable({
  providedIn: 'root'
})
export class OutingExtraService extends AbstractEavService<OutingExtra> {

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'outingextra', apiURL);
  }
}
