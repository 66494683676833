import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qualityFilter'
})
export class QualityFilterPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    let [q] = args;

    if (!q) {
      q = '';
    }

    return `${value}_${q}`;
  }
}

