import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowsUtilsService {

  openInCurrentTab(url: string) {
    window.location.replace(url);
  }

  openInNewTab(url: string) {
    // tslint:disable-next-line:no-unused-expression
    window.open(url, '_blank') || this.openInCurrentTab(url);
  }
}
