import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'rhbnb-loading-btn-body',
  template: `
      <ng-container *ngIf="loading$ | async; else text">
          <mat-icon>
              <mat-spinner
                      diameter="35">
              </mat-spinner>
          </mat-icon>
      </ng-container>

      <ng-template #text>
          {{ 'filter.search_btn_label' | transloco }}
      </ng-template>
  `,
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBtnBodyComponent implements OnInit {

  @Input() loading$: Observable<boolean>;

  constructor() { }

  ngOnInit(): void {
  }

}
