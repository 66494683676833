import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlatformFilterService {

  findDestinationName$(storeService, projected): Observable<string> {
    return storeService
      .getFilter()
      .pipe(
        map(f => f[projected]),
        mergeMap(dd => storeService.getDestinations()
          .pipe(
            map((d: any[]) => {
              if (!isNaN(Number(dd))) {
                // tslint:disable-next-line:triple-equals
                const destination = d.find(it => it?.id == dd);
                return destination?.name ?? 'general';
              } else {
                return dd as string;
              }
            }),
          )
        ),
      );
  }
}
