import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild, RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { COUNTRIES_SLUGS } from '@rhbnb-nx-ws/domain';
import { Observable } from 'rxjs';


@Injectable()
export class DomainRedirectGuard implements CanActivateChild, CanActivate {
  constructor() {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.handle();
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.handle();
    return true;
  }

  handle() {
    const currentUrl = new URL(window.location.href);
    console.log(currentUrl, currentUrl.pathname);

    if (
      currentUrl.hostname === 'varaderorentals.com' &&
      !currentUrl.pathname.startsWith(`/${COUNTRIES_SLUGS.cuba}`)
    ) {
      window.location.href = 'https://varaderorentals.com/cuba';
    }
  }
}
