import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HouseViewLoadingComponent } from './house-view-loading/house-view-loading.component';
import { LoadingGalleryModule } from '../loading-gallery';

const COMPONENTS = [
  HouseViewLoadingComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    LoadingGalleryModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class LoadingHouseModule { }
