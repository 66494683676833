import { createAction, props } from '@ngrx/store';
import { Destination, FilterTab } from '@rhbnb-nx-ws/domain';
import { Moment } from 'moment';
import { Filter, UIFilter } from './reducer';

export const loadDestinations = createAction(
  '[Layout] Load Destinations'
);

export const setDestinationsLoading = createAction(
  '[Layout] Set Destinations Loading',
  props<{loading: boolean}>()
);

export const setDestinations = createAction(
  '[Layout] Set Destinations',
  props<{destinations: Destination[]}>()
);

export const setFilter = createAction(
  '[Layout] Set Filter',
  props<Filter>()
);

export const setFilterLoaded = createAction(
  '[Layout] Set Filter Loaded',
  props<{loaded: boolean}>()
);

export const setFilterTab = createAction(
  '[Layout] Set Filter Tab',
  props<{tab: FilterTab}>()
);

export const setActiveUIFilter = createAction(
  '[Layout] Set Active UI Filter',
  props<{filter: UIFilter}>()
);

export const setDisableBarPlaceholder = createAction(
  '[Layout] Set Disable Bar Placeholder',
  props<{disable: boolean}>()
);

export const setFilterDestination = createAction(
  '[Layout] Set Filter Destination',
  props<{destination: number | string}>()
);

export const setFilterSelectedProvince = createAction(
  '[Layout] Set Filter Selected Province',
  props<{province: number | string}>()
);

export const setFilterOutingDestination = createAction(
  '[Layout] Set Filter Outing Destination',
  props<{destination: number | string}>()
);

export const setFilterOutingSelectedProvince = createAction(
  '[Layout] Set Filter Outing Selected Province',
  props<{province: number | string}>()
);

export const setFilterDateRange = createAction(
  '[Layout] Set Filter Date Range',
  props<{chosenLabel?: string, startDate: Moment, endDate: Moment}>()
);

export const setFilterRoomCount = createAction(
  '[Layout] Set Filter Room Count',
  props<{number}>()
);

export const setFilterGuestCount = createAction(
  '[Layout] Set Filter Guest Count',
  props<{number}>()
);

export const setFilterFacilities = createAction(
  '[Layout] Set Filter Facilities',
  props<{facilities: (number|string)[]}>()
);

export const setFilterWithin = createAction(
  '[Layout] Set Filter Within',
  props<{within: number[][]}>()
);

export const setFilterLoading = createAction(
  '[Layout] Set Filter Loading',
  props<{loading: boolean}>()
);

export const setLoadingLogout = createAction(
  '[Layout] Set Loading Logout',
  props<{loading: boolean}>()
);

export const computeFilterNights = createAction(
  '[Layout] Compute Filter Nights'
);
