import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'rhbnb-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabContentComponent implements OnInit {

  @ViewChild(TemplateRef)
  bodyContent: TemplateRef<any>;

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
