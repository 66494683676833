import { BaseModel } from './base.model';
import { Reservation } from './reservation.model';
import { Advert } from './advert.model';

export enum PaymentStatus {
  STATUS_CREATED = 'CREADO',
  STATUS_PENDING = 'PENDIENTE',
  STATUS_CONFIRMED = 'CONFIRMADO',
  STATUS_REJECT = 'RECHAZADO',
  STATUS_CANCELED = 'CANCELADO'
}

export enum PaymentKind {
  KIND_CREDIT_CARD = 'CREDIT_CARD',
  KIND_CUBA_APP = 'CUBA_APP',
  KIND_TRUST_INVESTING = 'TRUST_INVESTING',
}

export enum PaymentTarget {
  RESERVATION = 'RESERVATION',
  RESERVATION_MODIFICATION = 'MODIFICATION'
}

export class Payment extends BaseModel {
  public reservation?: Reservation | number | string;
  public advert?: Advert | number | string;
  public uid?: string;
  public externalId?: string;
  public amount?: number;
  public charge?: number;
  public cupAmount?: number;
  public cupCharge?: number;
  public amountWithCommission?: number;
  public cupAmountWithCommission?: number;
  public partial?: boolean;
  public description?: string;
  public currency?: string;
  public commission?: number;
  public cupRate?: number;
  public status?: PaymentStatus;
  public kind?: PaymentKind;
  public target?: PaymentTarget;
  public data?: any;
  public usedCredit?: number;
  public usedCoupon?: number;
  public cupUsedCredit?: number;
  public cupUsedCoupon?: number;

  constructor(init?: Payment) {
    super();
    Object.assign(this, init);
  }
}
