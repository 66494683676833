import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { User } from '@rhbnb-nx-ws/domain';
import { StorageService, ProfileService } from '@rhbnb-nx-ws/services';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[hasRole]'
})
export class HasRoleDirective extends WithUnsubscribe()
  implements OnInit {

  private authorities: string[];
  private _op = 'ANY';
  isVisible = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private profileService: ProfileService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.updateView();

    this.profileService.currentProfileData$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.updateView();
      });
  }

  private updateView() {
    const currentUser = this.storageService.getItem('user');

    if (!currentUser || !currentUser.roles || currentUser.roles.length === 0) {
      this.viewContainer.clear();
      return;
    }

    if (this.roleCheck(currentUser)) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainer.clear();
    }
  }

  private roleCheck(currentUser: User) {
    switch (this._op) {
      case 'ANY':
        return this.hasAny(currentUser);
      case 'OneNotTwo':
        return this.hasOneNotTwo(currentUser);
      case 'NOT':
        return this.notHas(currentUser);
    }
  }

  private hasAny(currentUser: User) {
    return !!this.authorities.find(a => currentUser.roles.includes(a));
  }

  private hasOneNotTwo(currentUser: User) {
    if (this.authorities.length !== 2) {
      return false;
    }

    const [one, two] = this.authorities;
    if (!currentUser || !Array.isArray(currentUser.roles)) {
      return false;
    }

    return currentUser.roles.includes(one) && !currentUser.roles.includes(two);
  }

  private notHas(currentUser: User) {
    return !this.authorities.find(a => currentUser.roles.includes(a));
  }

  @Input()
  set hasRole(value: string | string[]) {
    this.authorities = (typeof value === 'string' ? [<string>value] : <string[]>value);
  }

  @Input()
  set hasRoleOp(value) {
    this._op = value;
  }
}
