import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, Currency } from '@rhbnb-nx-ws/domain';
import { AbstractDataService, NO_AUTH_HEADER } from '@rhbnb-nx-ws/services';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyApiService extends AbstractDataService<Currency> {

  constructor(
    public http: HttpClient
  ) {
    super(http, 'currency', environment.apiURL);
  }

  getAll(): Observable<ApiResponse<Currency[]>> {
      return super.getAll({ [NO_AUTH_HEADER]: 'yes' });
  }
}
