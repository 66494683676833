import { Inject, Injectable } from '@angular/core';
import {
  Room,
  BedIcon,
  BedType,
  Image,
  RoomEvent,
  ReservationRoom
} from '@rhbnb-nx-ws/domain';
import { EMPTY_IMAGE_SRC } from '@rhbnb-nx-ws/global-tokens';

@Injectable({
  providedIn: 'root'
})
export class RoomUtilService {

  constructor(
    @Inject(EMPTY_IMAGE_SRC) private emptyImage: string
  ) {
  }

  countGuests(room: Room): number {
    const beds = room?.beds;
    let guestsCount = 0;

    beds.forEach(bed => {
      const kind = bed?.kind;
      const count = bed?.count;

      if (kind && count) {
        guestsCount += this.guestsAmountPerBedType(kind) * count;
      }
    });

    return guestsCount;
  }

  guestsAmountPerBedType(bedType: BedType): number {
    switch (bedType) {
      case BedType.KING:
        return 2;
      case BedType.QUEEN:
        return 2;
      case BedType.DOUBLE:
        return 2;
      case BedType.BUNK_BED:
        return 2;
      default:
        return 1;
    }
  }

  getBedKindName(bedType: BedType, base = 'houses'): string {
    switch (bedType) {
      case BedType.KING:
        return `${base}.bed_type_king`;
      case BedType.QUEEN:
        return `${base}.bed_type_queen`;
      case BedType.DOUBLE:
        return `${base}.bed_type_double`;
      case BedType.BIG_PERSONAL:
        return `${base}.bed_type_big_personal`;
      case BedType.BUNK_BED:
        return `${base}.bed_type_bunk`;
      case BedType.CHILD_BED:
        return `${base}.bed_type_child`;
      case BedType.PERSONAL:
        return `${base}.bed_type_personal`;
      case BedType.SOFA_BED:
        return `${base}.bed_type_sofa_bed`;
      case BedType.FLOOR_MATTRESS:
        return `${base}.bed_type_mattress`;
      default:
        return '';
    }
  }


  getBedKindIconName(bedType: BedType): string {
    switch (bedType) {
      case BedType.KING:
        return BedIcon.KING;
      case BedType.QUEEN:
        return BedIcon.QUEEN;
      case BedType.DOUBLE:
        return BedIcon.DOUBLE;
      case BedType.BIG_PERSONAL:
        return BedIcon.BIG_PERSONAL;
      case BedType.BUNK_BED:
        return BedIcon.BUNK_BED;
      case BedType.CHILD_BED:
        return BedIcon.CHILD_BED;
      case BedType.PERSONAL:
        return BedIcon.PERSONAL;
      case BedType.SOFA_BED:
        return BedIcon.SOFA_BED;
      case BedType.FLOOR_MATTRESS:
        return BedIcon.FLOOR_MATTRESS;
      default:
        return '';
    }
  }

  updateRoomImage(room: Room, image: Image | Image[] | string): void {
    if (room) {
      if (Array.isArray(image) && image.length > 0) {
        room.coverImage = image[0]?.file;
      } else if (typeof image === 'string') {
        room.coverImage = image;
      } else if (image instanceof Image) {
        room.coverImage = image?.file;
      } else {
        room.coverImage = this.emptyImage;
      }
    }
  }

  updateRoomEvents(room: ReservationRoom, events: RoomEvent[]): void {
    room.events = events;
  }

  getRoomsAsString(rooms: Room[] | string[]) {
    if (rooms) {
      return (rooms as any)
        ?.reduce((acc, value: any, i, arr) => {
          value = (typeof value === 'object' && !!value.name) ? value.name : value;
          return acc + `${value}${i === (arr.length - 1) ? '' : ', '}`;
        }, '');
    }

    return '';
  }
}
