<div class="wrapper" fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="10px">

  <ng-container *ngIf="!isEmpty">

    <div class="left-side" fxFlex="0 1 calc(50% - 10px)">
      <picture rhbnb-lazy-sizes-picture [preload]="true" [src]="images[0]?.src" [errorImage]="errorImage" [alt]="images[0]?.label"
        (click)="onClick(images[0], 0)"></picture>
    </div>

    <div class="right-side" fxFlex="0 1 calc(50% - 10px)" fxLayout="row wrap" fxLayoutAlign="start stretch">

      <div fxLayout="row wrap" fxLayoutGap="5px grid" fxLayoutAlign="start stretch">

        <div fxFlex="0 1 calc(50% - 5px)" [ngClass]="'img_'+index"
          *ngFor="let img of restImages; let index = index; trackBy: trackByFn">

          <picture rhbnb-lazy-sizes-picture [src]="img?.src" [errorImage]="errorImage" [alt]="img?.label"
            (click)="onClick(img, index + 1)"></picture>
        </div>

      </div>

    </div>

  </ng-container>

  <ng-container *ngIf="isComplete">
    <div class="more-btn">
      <button mat-flat-button (click)="onClick(images[0], 0)">
        {{ seeAllLabel }}
      </button>
    </div>
  </ng-container>
</div>
