import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MomentModule } from 'ngx-moment';
import { StoreModule } from '@ngrx/store';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';

import { ChatWindowComponent } from './chat-window';
import { ChatTypeBoxComponent } from './chat-window/chat-type-box.component';
import { ChatMessageComponent } from './chat-window/chat-message.component';
import { ChatLoadingComponent } from './chat-window/chat-loading.component';
import { ChatLoadingItemComponent } from './chat-window/chat-loading-item.component';
import { LibEffects } from './store/effects';
import * as fromR from './store/reducer';
import { ChatHandlerService } from './chat-handler.service';
import { RetryIndicatorComponent } from './retry-indicator/retry-indicator.component';

export const COMPONENTS = [
  ChatWindowComponent,
  ChatTypeBoxComponent,
  RetryIndicatorComponent,
  ChatMessageComponent,
  ChatLoadingComponent,
  ChatLoadingItemComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    StoreModule.forFeature(
      fromR.moduleKey,
      fromR.reducer,{
        initialState: fromR.initialState
      }
    ),
    EffectsModule.forFeature([LibEffects]),
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    TextFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MomentModule,
    NgScrollbarModule,
    TranslocoModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: [
    ChatHandlerService
  ]
})
export class ChatModule { }
