<div fxFlex="100"
     fxLayout="column"
     fxLayoutAlign="space-between stretch"
     class="destination-item">

  <div class="image-container load-animate"
       fxFlex="80"
       fxLayout="column"
       fxLayoutAlign="space-between stretch">
  </div>

  <div
    fxFlex="20"
    class="content-container">

    <p class="load-animate"></p>

  </div>
</div>
