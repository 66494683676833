import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HouseRoomsItemBaseComponent } from './house-rooms-item-base.component';

@Component({
  selector: 'rhbnb-house-rooms-item-tiny',
  template: `
    <div
      fxFlex="1 1 100"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      class="room-item"
      [class.selected]="selected"
      [class.unavailable]="unavailable"
      (click)="onSelectedChange()"
    >
      <div fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="stretch center">
        <div class="image-container">
          <picture
            rhbnb-lazy-sizes-picture
            [src]="coverImage"
            [errorImage]="emptyImage"
            [alt]="room?.name"
          ></picture>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxFlex="100"
          class="content-container"
        >
          <div class="title my-10">
            {{ room.name }}
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxFlex="100"
        class="content-container"
      >
        <div class="mt-5">
          {{ itemCapacityKey | transloco : { count: room.guests } }}
        </div>

        <div class="bed-info">
          <span
            class="bed-item"
            *ngFor="let bed of room?.beds; let last = last"
          >
            {{ bed.count }}
            {{ getBedTypeName(bed.kind) | transloco : { count: bed.count } }}
            <ng-container *ngIf="!last">, </ng-container>
          </span>

          <div class="bed-item" *ngIf="room.hasBath">
            {{ privateBathKey | transloco }}
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .room-item {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
        border-radius: 12px !important;
        width: 100% !important;
      }

      .room-item.selected {
        box-shadow: 0 0 3px 3px var(--primary-color);
      }

      .room-item.unavailable {
        box-shadow: 0 0 3px 3px var(--error-color);
      }

      .room-item .image-container {
        position: relative;
        width: 100px;
        height: 100%;
        border-top-left-radius: 12px !important;
      }

      .bg-loading-color {
        background-color: #dddddd;
      }

      .room-item .image-container .price-holder {
        position: absolute;
        background-color: #3498db;
        color: white;
        right: 10px;
        bottom: 15px;
        outline: none;
        z-index: 1;
        padding: 2px 5px;
      }

      .room-item .image-container .img:before {
        content: '';
        float: left;
        padding-top: 65%;
      }

      .room-item .content-container {
        padding: 5px !important;
      }

      .room-item .title {
        font-weight: 500;
        font-size: 20px;
      }

      :host(::ng-deep .room-item .image-container picture > img) {
        border-top-left-radius: 12px !important;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .room-item .image-container .img {
        object-fit: cover;
        width: 100%;
      }
    `,
  ],
  animations: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HouseRoomsItemTinyComponent extends HouseRoomsItemBaseComponent {}
