<div class="snack-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between center">
  <span fxFlex="1 0 min-content">{{ data.message }}</span>

  <button
    (click)="action()"
    fxFlex="0 0 auto"
    color="primary"
    mat-button>
    {{ closeText ? closeText : ('core.snackbar_close_text' | transloco) }}
  </button>
</div>
