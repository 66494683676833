<div fxLayout="column"
     fxLayout.gt-sm="row wrap"
     fxLayoutAlign="center stretch"
     fxLayoutAlign.gt-sm="center center"
     fxLayoutGap.gt-sm="5px grid">

  <div fxFlex="35">
    <ng-select appearance="outline"
               [placeholder]="'filter.province_label' | transloco"
               [items]="provinces$ | async"
               [ngModel]="destination$ | async"
               [clearable]="false"
               (change)="onDestinationChange($event)">
    </ng-select>
  </div>

  <div fxFlex="45">
    <mat-form-field class="full-width">
      <mat-label>{{ 'filter.date_label' | transloco }}</mat-label>
      <input matInput
             [value]="(dateLabel$ | async)"
             [readonly]="true"
             RangeDateModal
             [isSinglePicker]="true"
             [range]="date$ | async"
             (dateRangeChange)="onDateChange($event)"/>
    </mat-form-field>
  </div>

  <div fxFlex="20">
    <rhbnb-auto-numbered-mat-select
      [label]="'filter.guests_label' | transloco"
      [value]="guest$ | async"
      (valueChange)="onGuestChange($event)">
    </rhbnb-auto-numbered-mat-select>
  </div>

</div>
