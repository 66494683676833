<div class="item" [class.opaque]="message?.local" [class.self]="message?.self">
  <div class="user" *ngIf="showUser">{{ message?.u?.name }}</div>
  <div>
    {{ message?.msg }}
    <span class="time">
      <span class="time" style="visibility: hidden">
        {{ fromUnix(message?.ts?.$date) | amDateFormat:'h:mm a' }}
      </span>

      <div>
        <span class="time">
          {{ fromUnix(message?.ts?.$date) | amDateFormat:'h:mm a' }}
        </span>
      </div>
    </span>
  </div>
</div>
