import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {

  constructor(
  ) {
  }

  computeCommission(amount: number, value: number) {
    const p = (100 - value) / 100;
    return (amount / p) - amount;
  }

  addCommission(amount: number, value: number) {
    const p = (100 - value) / 100;
    return (amount / p);
  }
}
