import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FilterImagePipeService } from 'libs/shared-pipes/src/lib/pipes';
import { Image } from '../image';

@Component({
  selector: 'rhbnb-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnInit {

  @Input() images: Image[];
  @Input() seeAllLabel: string;
  @Input() errorImage: string;
  @Input() closeText = 'Close';

  selectedImageIndex = 0;
  showPreview = false;

  constructor(
    private filterImagePipeService: FilterImagePipeService
  ) { }

  ngOnInit(): void {
  }

  get fullscreenImages() {
    return this.images ? this.images.map(img => ({
      image: img?.src
        ? this.filterImagePipeService.filter(img?.src, {w: 720, h: 0})
        : undefined,
      title: img?.label,
      thumbImage: img?.src
      ? this.filterImagePipeService.filter(img?.src, {w: 320, h: 0})
      : undefined,
    })) : [];
  }

  openFullscreen(e) {
    const { index } = e;

    this.showPreview = true;
    this.selectedImageIndex = index;
  }

  closeEventHandler() {
    this.showPreview = false;
  }
}
