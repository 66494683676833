import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  fromEvent,
  Observable,
  of,
  Subject,
  from,
  throwError as observableThrowError,
} from 'rxjs';
import { ApiResponse, User, ROLES } from '@rhbnb-nx-ws/domain';
import {
  catchError,
  map,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { UserApiService } from './user-api.service';
import { ClientApiService } from './client-api.service';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import { LayoutStoreService } from '../../layout/store/layout-store.service';
import { TranslocoService } from '@ngneat/transloco';
import { CookieService } from 'ngx-cookie';
import { CoreStoreService } from '../../core/store/core-store.service';
import { OAuth2Service, UrlUtilService } from '@rhbnb-nx-ws/services';
import { Router } from '@angular/router';
import { APP_CONFIG, IAppConfig } from '../app.config';

const IDENTITY_KEY = environment.identityKey;

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private readonly sessionEstablished$$ = new Subject<void>();
  sessionEstablished$ = this.sessionEstablished$$.asObservable();

  constructor(
    private userApiService: UserApiService,
    private clientApiService: ClientApiService,
    private storageService: StorageService,
    private layoutStoreService: LayoutStoreService,
    private coreStoreService: CoreStoreService,
    private router: Router,
    private translocoService: TranslocoService,
    protected cookieService: CookieService,
    private oauth2service: OAuth2Service,
    private urlUtilService: UrlUtilService,
    @Inject(APP_CONFIG) private config: IAppConfig,
    @Inject(DOCUMENT) private document: Document
  ) {}

  redirectToSSO(target?: string): void {
    this.cleanSession();

    this.storageService.setItem('BACK_URL', this.router.url);
    this.oauth2service.authorize();
  }

  popBackUrl() {
    const url = this.storageService.getItem('BACK_URL');
    this.storageService.removeItem('BACK_URL');

    return url;
  }

  public establishSession(data: any): Observable<ApiResponse<User>> {
    this.setSession(data);

    return this.refreshUserProfile().pipe(
      tap(() => this.setIdentity()),
      tap(() => this.sessionEstablished$$.next())
    );
  }

  sessionCheck(): Observable<boolean> {
    return this.userApiService.getMe().pipe(map((res) => !!res.data?.id));
  }

  refreshUserProfile() {
    return this.userApiService.getMe().pipe(
      // Check if user is not client yet
      mergeMap((me) => {
        // If is client, return the user
        if (
          me?.data?.roles?.includes(ROLES.ROLE_CLIENT) ||
          me?.data?.roles?.includes(ROLES.ROLE_ADMIN)
        ) {
          return of(me);
        }

        // Else create the client and go on
        return this.clientApiService
          .add({ user: me?.data?.id })
          .pipe(mergeMap(() => this.userApiService.getMe()));
      }),
      tap((res) => this.setProfile(res?.data)),
      tap((res) => this.handleLangChange()),
      catchError((error) => observableThrowError(error))
    );
  }

  private setSession(authResult): void {
    if (authResult?.token) {
      this.storageService.setItem('token', { token: authResult?.token });
    }
  }

  refreshToken() {
    return from(this.oauth2service.refreshToken())
      .pipe(
        tap((data) => this.setSession(data)),
        catchError((error) => {
          // Logout on refresh token error
          // to prevent rare behavior
          this.logout();

          return observableThrowError(error);
        })
      );
  }

  handleLangChange() {
    const lang = this.getProfile()?.locale;
    this.translocoService.setActiveLang(lang);
  }

  setProfile(user: User): void {
    this.storageService.setItem('user', user);
  }

  getProfile(): User {
    const s = { ...this.storageService.getItem('user') };
    return new User(s);
  }

  logout() {
    this.layoutStoreService.setLoadingLogout(true);

    this.apiLogout();

    this.cleanSession();
    this.layoutStoreService.setLoadingLogout(false);
  }

  localLogout() {
    this.cleanSession();
    this.coreStoreService.setUser(undefined);
  }

  private apiLogout() {
    const url = `${this.config.oauth2Config.server}logout?redirectTo=${this.urlUtilService.getAppsURL()?.platform}`;
    this.document.location.href = url;
  }

  getToken() {
    return this.storageService.getItem('token')?.token;
  }

  getRefreshToken() {
    return this.storageService.getItem('refreshToken')?.token;
  }

  private cleanSession(): void {
    this.storageService.removeItem('token');
    this.storageService.removeItem('refreshToken');
    this.storageService.removeItem('user');
    this.storageService.removeItem(this.oauth2service.TOKEN_STORAGE_KEY);
  }

  public isLoggedIn(): boolean {
    return (
      this.storageService.getItem('token') &&
      this.storageService.getItem('user')
    );
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  startIdentityCheck(unsubscribe: Subject<void>) {
    fromEvent(window, 'focus')
      .pipe(
        tap(() => this.checkIdentity()),
        takeUntil(unsubscribe)
      )
      .subscribe();
  }

  checkIdentity() {
    const realUserEmail = this.cookieService.get(IDENTITY_KEY);

    if (!realUserEmail) {
      this.localLogout();
    } else if (this.getProfile().email !== realUserEmail) {
      this.redirectToSSO();
    }
  }

  setIdentity() {
    const user = this.getProfile();

    if (user) {
      let expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);

      this.cookieService.put(IDENTITY_KEY, user.email, {
        domain: '.' + this.urlUtilService.getBaseDomain(true),
        expires,
        path: '/',
        secure: false,
        httpOnly: false,
      });
    }
  }
}
