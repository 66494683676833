<form [formGroup]="form" novalidate>
  <div class="row">
    <div class="col-12">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ 'registration.form.name_label' | transloco }}</mat-label>

        <input name="name" formControlName="name" matInput required />

        <mat-error *ngIf="form.get('name')?.hasError('required')">
          {{ 'registration.error_validation_required_name' | transloco }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ 'registration.form.email_label' | transloco }}</mat-label>

        <input
          name="email"
          formControlName="email"
          type="email"
          matInput
          required
        />

        <ng-container matSuffix>
          <mat-icon *ngIf="form.get('email') && form.get('email').pending">
            <mat-spinner color="primary" diameter="20"></mat-spinner>
          </mat-icon>
        </ng-container>

        <mat-error *ngIf="form.get('email')?.hasError('required')">
          {{ 'registration.error_validation_required_email' | transloco }}
        </mat-error>

        <mat-error *ngIf="form.get('email')?.hasError('email')">
          {{ 'registration.error_validation_invalid_email' | transloco }}
        </mat-error>

        <mat-error *ngIf="form.get('email')?.hasError('duplicateEmail')">
          {{ 'registration.error_validation_duplicateEmail_email' | transloco }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <rhbnb-country-selector
        [label]="'registration.form.country_label' | transloco"
        (countryChange)="onCountryChange($event)"
        [parent]="form"
      >
      </rhbnb-country-selector>
    </div>

    <div class="col-12">
      <!--Mobile group-->

      <rhbnb-mobile-field
        [label]="'registration.form.mobile_label' | transloco"
        [code]="code"
        [parent]="form"
      >
      </rhbnb-mobile-field>
    </div>

    <div class="col-12">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{
          'registration.form.password_label' | transloco
        }}</mat-label>

        <input
          name="password"
          formControlName="password"
          [type]="hide ? 'password' : 'text'"
          matInput
          required
        />

        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon [svgIcon]="hide ? 'eye-off' : 'eye'"></mat-icon>
        </button>

        <mat-error *ngIf="form.get('password')?.hasError('required')">
          {{ 'registration.error_validation_required_password' | transloco }}
        </mat-error>

        <mat-error *ngIf="form.get('password')?.hasError('minlength')">
          {{ 'registration.error_validation_min_password' | transloco }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{
          'registration.form.confirmPassword_label' | transloco
        }}</mat-label>

        <input
          name="confirmPassword"
          formControlName="confirmPassword"
          [type]="hideConfirm ? 'password' : 'text'"
          matInput
          required
        />

        <button
          mat-icon-button
          matSuffix
          (click)="hideConfirm = !hideConfirm"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon [svgIcon]="hideConfirm ? 'eye-off' : 'eye'"></mat-icon>
        </button>

        <mat-error *ngIf="form.get('confirmPassword')?.hasError('required')">
          {{
            'registration.error_validation_required_confirmPassword' | transloco
          }}
        </mat-error>

        <mat-error *ngIf="form.get('confirmPassword')?.hasError('notSame')">
          {{ 'registration.error_validation_not_same_password' | transloco }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ 'registration.form.code_label' | transloco }}</mat-label>

        <input name="code" formControlName="code" matInput />

        <ng-container matSuffix>
          <mat-icon [@in] [@out] *ngIf="tccStatus === 'waiting'">
            <mat-spinner color="primary" diameter="20"></mat-spinner>
          </mat-icon>

          <mat-icon
            [@in]
            [@out]
            color="warn"
            *ngIf="tccStatus === 'wrong'"
            svgIcon="alert-outline"
          >
          </mat-icon>

          <mat-icon
            [@in]
            [@out]
            color="accent"
            *ngIf="tccStatus === 'success'"
            svgIcon="check-circle-outline"
          >
          </mat-icon>
        </ng-container>
        <mat-hint>{{ 'registration.tcc_hint' | transloco }}</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-100">
    <button (click)="onLoginChange()" class="me-2" mat-flat-button>
      {{ 'registration.login_btn_label' | transloco }}
    </button>

    <button
      [disabled]="creating"
      (click)="onRegister()"
      class="me-2"
      mat-flat-button
      color="primary"
    >
      <ng-container *ngIf="creating; else txt">
        <mat-icon>
          <mat-spinner color="primary" diameter="20"></mat-spinner>
        </mat-icon>
      </ng-container>

      <ng-template #txt>
        {{ 'registration.register_btn_label' | transloco }}
      </ng-template>
    </button>
  </div>
</form>
