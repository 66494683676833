import { InjectionToken } from '@angular/core';

export interface OAuth2Config {
  server: string;
  clientId: string;
  tokenEndpoint: string;
  authorizationEndpoint: string;
  redirectUri?: string;
}

export const OAUTH2_CONFIG = new InjectionToken<OAuth2Config>('oauth2.config');
