import { Facility } from './facility.model';
import { Service } from './service.model';
import { Room } from './room.model';
import { Destination } from './destination.model';
import { CountryEntity as Country } from './country-entity.model';
import { Image } from './image.model';
import { HouseType } from './house-type.model';
import { HouseRule } from './house-rule.model';
import { User } from './user.model';
import { BaseModel } from './base.model';
import { CancellationPolicy } from './cancellation-policy';
import { ClientEvaluationStats } from './client-evaluation-stats.model';

export enum HouseModalities {
  MODALITY_ROOMS = 'ROOMS',
  MODALITY_HOUSE = 'HOUSE',
  MODALITY_BOTH = 'BOTH'
}

export class HouseData {
  checkIn?: string;
  checkOut?: string;
  extraRules?: Array<string>;
}

// tslint:disable-next-line:max-classes-per-file
export class House extends BaseModel {
  public id?: string;
  public name?: string;
  public modality?: HouseModalities;
  public type?: HouseType | string;
  public basePrice?: number;
  public rangePrice?: {higher: number, lower: number};
  public description?: string;
  public location?: number[];
  public host?: any;
  public hostManager?: any;
  public rooms?: Room[];
  public active?: boolean;
  public review?: boolean;
  public facilities?: Facility[] | string[];
  public services?: Service[] | string[];
  public rules?: HouseRule[] | string[];
  public destination?: Destination | string;
  public country?: Country | string;
  public images?: Image[] | Image;
  public address?: string;
  public baths?: number;
  public data?: HouseData;
  public ranking?: number;
  public timeToRespond?: number;
  public commission?: number;
  public cupRate?: number;
  public automaticCupRate?: boolean;
  public nightsLimit?: number;
  public cancellationPolicy?: CancellationPolicy;
  public user?: User;
  public eval?: ClientEvaluationStats;

  constructor(init?: House) {
    super();
    Object.assign(this, init);
  }
}
