import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Client, Reservation } from '@rhbnb-nx-ws/domain';
import { DEFAULT_IMAGE_SRC, IMAGE_QUALITY } from '@rhbnb-nx-ws/global-tokens';

@Pipe({
  name: 'bookingClientImage'
})
export class BookingClientImagePipe implements PipeTransform {

  constructor(
    @Inject(IMAGE_QUALITY) private quality: number,
    @Inject(DEFAULT_IMAGE_SRC) private imageSrc: string,
  ) {}

  transform(value: Reservation, ...args: any[]): any {
    const notDefaultImage = args[0];
    const image = ((value?.client as Client)?.user as any)?.image;

    return image ?
      `${image}_${this.quality}` :
          notDefaultImage ? image : this.imageSrc;
  }

}
