{{ $any(
  item?.rangePrice?.lower
    | appCommission : item?.commission
    | toDecimal
    | currencyConvert
    : {
      cupRate: item?.cupRate,
      currencyTable$: currencyTable$,
      currentCurrency$: currentCurrency$
    }
    | async
    | round
    | number
)
  | currencyLabel : {currentCurrency$: currentCurrency$}
  | async }}
