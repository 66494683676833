import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as moment from 'moment';
import {
  ApiResponse,
  HostManager,
  PageData,
  Reservation,
  ReservationStatus,
} from '@rhbnb-nx-ws/domain';
import { MomentService } from './moment.service';
import { AbstractDataService } from '../util/abstract-data-service';

import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

const CACHE_TTL = 15 * 60 * 1000; // 15 mins

@Injectable({
  providedIn: 'root',
})
export class BookingService extends AbstractDataService<Reservation> {
  readonly getOneCacheKey = 'getOneCache';
  readonly paginateCacheKey = 'paginateCache';
  readonly inStayCacheKey = 'inStayCache';
  readonly toArriveCacheKey = 'toArriveCache';
  readonly getCompletedReservationsCacheKey = 'getCompletedReservationsCache';
  readonly getPendingReservationsCacheKey = 'getPendingReservationsCache';
  readonly getNextReservationsCacheKey = 'getNextReservationsCache';
  readonly getNearbyReservationsCacheKey = 'getNearbyReservationsCache';

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string,
    private momentService: MomentService
  ) {
    super(http, 'booking', apiURL);
  }

  getOne(id: string): Observable<ApiResponse<Reservation>> {
    const source$ = super.getOne(id);

    return this.getCacheEntry<ApiResponse<Reservation>>(
      this.getCacheKeyWithParam(this.getOneCacheKey, `${id}`)
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
      .pipe(
        map((res) => this.toLocalTZ(res)),
        map((res) => {
          res.data = this.addOneDayToEndDay(res.data);
          return res;
        })
      );
  }

  getNearbyByEntity(
    id?: string,
    entity?: string,
    page?: string,
    limit?: string,
    extraParams?: HttpParams
  ) {
    let params: HttpParams = this.buildParams(page, limit);

    if (extraParams) {
      extraParams
        .keys()
        .forEach((k) => (params = params.append(k, extraParams.get(k))));
    }

    const obs = this.http.get<ApiResponse<Reservation[]>>(
      `${this.apiURL}/${this.endpointName}/nearby/by-entity/${entity}/${id}`,
      { ...{ params: params }, ...{ observe: 'response' } }
    ).pipe(
      map((res) => {
        res.body.data = res.body.data.map(r => this.addOneDayToEndDay(r));
        return res;
      })
    );

    return this.buildResponseForNearby(obs);
  }

  getNearby(page?: string, limit?: string, extraParams?: HttpParams) {
    let params: HttpParams = this.buildParams(page, limit);

    if (extraParams) {
      extraParams
        .keys()
        .forEach((k) => (params = params.append(k, extraParams.get(k))));
    }

    const source$ = this.http.get<ApiResponse<Reservation[]>>(
      `${this.apiURL}/${this.endpointName}/nearby`,
      { ...{ params: params }, ...{ observe: 'response' } }
    ).pipe(
      map((res) => {
        res.body.data = res.body.data.map(r => this.addOneDayToEndDay(r));
        return res;
      })
    );

    return this.buildResponseForNearby(
      this.getCacheEntry<HttpResponse<ApiResponse<Reservation[]>>>(
        this.getCacheKeyWithParam(
          this.getNearbyReservationsCacheKey,
          params.toString()
        )
      )
        .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
    );
  }

  private buildResponseForNearby(
    obs: Observable<HttpResponse<ApiResponse<Reservation[]>>>
  ) {
    return obs.pipe(
      map((res: HttpResponse<ApiResponse<Reservation[]>>) => {
        return {
          data: {
            data: res.body.data,
            total: +res.headers.get('Page-Total'),
          } as any,
          success: res.body.success,
        } as ApiResponse<PageData<Reservation>>;
      })
    );
  }

  getPendingReservations(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string
  ): Observable<ApiResponse<PageData<Reservation>>> {
    let params = this.buildParams(page, limit, sort, order, q);

    // Only pending bookings
    params = params.set('status', ReservationStatus.STATUS_PENDING);
    params = params.set('from', moment().format('YYYY-MM-DD'));

    const source$ = this.paginateReservations(params);

    return this.getCacheEntry<ApiResponse<PageData<Reservation>>>(
      this.getCacheKeyWithParam(
        this.getPendingReservationsCacheKey,
        params.toString()
      )
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
  }

  getNextReservations(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string
  ): Observable<ApiResponse<PageData<Reservation>>> {
    let params = this.buildParams(page, limit, sort, order, q);

    // Only next bookings
    params = params.set('status', ReservationStatus.STATUS_CONFIRMED);
    params = params.set('from', moment().format('YYYY-MM-DD'));

    const source$ = this.paginateReservations(params);

    return this.getCacheEntry<ApiResponse<PageData<Reservation>>>(
      this.getCacheKeyWithParam(
        this.getNextReservationsCacheKey,
        params.toString()
      )
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
  }

  getCompletedReservations(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string
  ): Observable<ApiResponse<PageData<Reservation>>> {
    let params = this.buildParams(page, limit, sort, order, q);

    // Only completed bookings
    params = params.set('status', ReservationStatus.STATUS_COMPLETED);
    params = params.set('to', moment().subtract(1, 'day').format('YYYY-MM-DD'));

    const source$ = this.paginateReservations(params);

    return this.getCacheEntry<ApiResponse<PageData<Reservation>>>(
      this.getCacheKeyWithParam(
        this.getCompletedReservationsCacheKey,
        params.toString()
      )
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL)
  }

  private paginateReservations(params: HttpParams) {
    return this.http
      .get<ApiResponse<Reservation[]>>(`${this.apiURL}/${this.endpointName}`, {
        ...{ params: params },
        ...{ observe: 'response' },
      })
      .pipe(
        map((res: HttpResponse<ApiResponse<Reservation[]>>) => {
          res.body.data = res.body.data.map((r) => this.addOneDayToEndDay(r));

          return {
            data: {
              data: res.body.data,
              total: +res.headers.get('Page-Total'),
            } as any,
            success: res.body.success,
          } as ApiResponse<PageData<Reservation>>;
        })
      );
  }

  paginate(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string,
    extraParams?: HttpParams
  ): Observable<ApiResponse<PageData<Reservation>>> {
    const source$ = super.paginate(q, page, limit, sort, order, extraParams)
      .pipe(
        map((res) => {
          const reservations = res.data.data;

          reservations.forEach((r) => this.addOneDayToEndDay(r));
          return res;
        })
      );
    const paramKey = `${q}_${page}_${limit}_${sort}_${order}_${extraParams.toString()}`;

    return this.getCacheEntry<ApiResponse<PageData<Reservation>>>(
      this.getCacheKeyWithParam(this.paginateCacheKey, paramKey)
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL);
  }

  cancel(id: string | number, gain: number) {
    return this.http
      .post<ApiResponse<HostManager>>(
        `${this.apiURL}/${this.endpointName}/single-cancel`,
        { id, gain }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  toArrive(
    page?: string,
    limit?: string,
    sort?: string,
    order?: string
  ): Observable<ApiResponse<PageData<Reservation>>> {
    const params: HttpParams = this.buildParams(page, limit, sort, order, ``);

    const source$ = this.http.get<ApiResponse<PageData<Reservation>>>(
      `${this.apiURL}/${this.endpointName}/to-arrive`,
      { ...{ params }, ...{ observe: 'response' } }
    ).pipe(
      map((res: HttpResponse<ApiResponse<PageData<Reservation>>>) => {
        return {
          data: {
            data: res.body.data,
            total: res.headers.get('Page-Total'),
          } as any,
          success: res.body.success,
        } as ApiResponse<PageData<Reservation>>;
      }),
      map((res) => {
        const reservations = res.data.data;

        reservations.forEach((r) => this.addOneDayToEndDay(r));
        return res;
      }),
      catchError((error) => throwError(error))
    );

    return this.getCacheEntry<ApiResponse<PageData<Reservation>>>(
      this.getCacheKeyWithParam(this.toArriveCacheKey, params.toString())
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL);
  }

  inStay(
    page?: string,
    limit?: string,
    sort?: string,
    order?: string
  ): Observable<ApiResponse<PageData<Reservation>>> {
    const params: HttpParams = this.buildParams(page, limit, sort, order, ``);

    const source$ = this.http.get<ApiResponse<PageData<Reservation>>>(
      `${this.apiURL}/${this.endpointName}/in-stay`,
      { ...{ params }, ...{ observe: 'response' } }
    ).pipe(
      map((res: HttpResponse<ApiResponse<PageData<Reservation>>>) => {
        return {
          data: {
            data: res.body.data,
            total: res.headers.get('Page-Total'),
          } as any,
          success: res.body.success,
        } as ApiResponse<PageData<Reservation>>;
      }),
      map((res) => {
        const reservations = res.data.data;

        reservations.forEach((r) => this.addOneDayToEndDay(r));
        return res;
      }),
      catchError((error) => throwError(error))
    );

    return this.getCacheEntry<ApiResponse<PageData<Reservation>>>(
      this.getCacheKeyWithParam(this.inStayCacheKey, params.toString())
    )
      .sharedReplayTimerRefresh(source$, 1, CACHE_TTL);
  }

  addOneDayToEndDay(reservation: Reservation) {
    reservation.checkOut = this.momentService
      .getMomentWithLocalOffset(reservation.checkOut)
      .add(1, 'day')
      .format();

    if (reservation.modifications && reservation.modifications.length > 0) {
      for (let i = 0; i < reservation.modifications.length; i++) {
        const mod = reservation.modifications[i];

        mod.checkOut = this.momentService
          .getMomentWithLocalOffset(mod.checkOut)
          .add(1, 'day')
          .format();
      }
    }

    return reservation;
  }

  private toLocalTZ(res: ApiResponse<Reservation>) {
    const cloned = { ...res };

    const checkIn = this.momentService.getFormat(
      this.momentService.getMomentWithLocalOffset(cloned.data.checkIn)
    );

    const checkOut = this.momentService.getFormat(
      this.momentService.getMomentWithLocalOffset(cloned.data.checkOut)
    );

    return {
      ...cloned,
      data: {
        ...cloned.data,
        checkIn,
        checkOut,
      } as Reservation,
    };
  }
}
