import { AfterViewInit, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import {
  animate,
  animateChild,
  AnimationEvent,
  keyframes,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import { fromEvent } from 'rxjs';
import { filter, map, takeUntil} from 'rxjs/operators';

import { PanelState } from './panel-state';

@Component({
  selector: 'rhbnb-top-panel',
  templateUrl: './top-panel.component.html',
  styleUrls: ['./top-panel.component.sass'],
  animations: [
    trigger('state', [
      transition('* => visible', [
        animate(350, keyframes([
          style({ opacity: 0, transform: 'translateY(-100%)', offset: 0 }),
          style({ opacity: 1, transform: 'translateY(-5px)', offset: 0.3 }),
          style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
        ]))
      ]),
      transition('visible => hidden', [
        animate(600, keyframes([
          style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
          style({ opacity: 1, transform: 'translateY(-5px)', offset: 0.7 }),
          style({ opacity: 0, transform: 'translateY(-100%)', offset: 1.0 })
        ]))
      ])
    ]),
    trigger('fadeInOut', [
      transition('* => visible', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
        query('@state', animateChild(), {optional: true}),
      ]),
      transition('visible => hidden', [
        query('@state', animateChild(), {optional: true}),
        style({ opacity: 1 }),
        animate(250, style({ opacity: 0 })),
      ]),
    ])
  ]
})

export class TopPanelComponent extends WithUnsubscribe()
  implements OnInit, AfterViewInit {

  state: PanelState;

  private _show: boolean;

  get show(): boolean {
    return this._show;
  }

  @Input()
  set show(value: boolean) {
    if (value) {
      this._show = value;
      this.state = 'visible';
    } else {
      this.state = 'hidden';
    }
  }

  constructor(
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
  }

  animationDone(event: AnimationEvent): void {
    if (event?.fromState === 'visible' && event?.toState === 'hidden') {
      this._show = false;
    }
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'keydown')
        .pipe(
          map((evt: KeyboardEvent) => evt.key === 'Escape'),
          filter(res => res),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(() => {
          this.show = false;
          this.cdr.detectChanges();
        });
    });
  }
}
