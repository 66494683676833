<div fxLayout="column"
     fxLayout.gt-sm="row wrap"
     fxLayoutAlign="center stretch"
     fxLayoutAlign.gt-sm="center center"
     fxLayoutGap.gt-sm="5px grid">

  <div fxFlex="30">
    <ng-select appearance="outline"
               [placeholder]="'filter.destiny_label' | transloco"
               [items]="destinations$ | async"
               [ngModel]="dest$ | async"
               [compareWith]="topFilterService.compareDestination"
               [clearable]="false"
               (change)="onDestinationChange($event)"
               [selectableGroup]="true"
               bindValue="id"
               bindLabel="name"
               groupBy="province">
    </ng-select>
  </div>

  <div fxFlex="40">
    <mat-form-field class="full-width">
      <mat-label>{{ 'filter.date_range_label' | transloco }}</mat-label>
      <input matInput
             [value]="(dateLabel$ | async)"
             [readonly]="true"
             RangeDateModal
             [range]="dateRange$ | async"
             (dateRangeChange)="onDateRangeChange($event)"/>
    </mat-form-field>
  </div>

  <div fxFlex="15">
    <rhbnb-auto-numbered-mat-select
      [label]="'filter.rooms_label' | transloco"
      [value]="room$ | async"
      (valueChange)="onRoomChange($event)">
    </rhbnb-auto-numbered-mat-select>
  </div>

  <div fxFlex="15">
    <rhbnb-auto-numbered-mat-select
      [label]="'filter.guests_label' | transloco"
      [value]="guest$ | async"
      (valueChange)="onGuestChange($event)">
    </rhbnb-auto-numbered-mat-select>
  </div>

</div>
