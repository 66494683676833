import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';

import { TopPanelComponent } from '../../top-panel/top-panel';

@Component({
  selector: 'rhbnb-top-filter-panel',
  templateUrl: './top-filter-panel.component.html',
  styleUrls: ['./top-filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopFilterPanelComponent extends WithUnsubscribe()
  implements OnInit {

  @Input() alternateColor: boolean;
  @Input() invertButtonColor: boolean;

  @Input() filterIsEmpty$: Observable<boolean>;

  @ViewChild('panel')
  private panel: TopPanelComponent;

  constructor(
    private mediaObserver: MediaObserver,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToViewChanges();
  }

  private subscribeToViewChanges(): void {
    // Hide panel if screen is gd-sm
    this.mediaObserver.asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes?.length > 0),
        map((changes: MediaChange[]) => changes[0]),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((change: MediaChange) => {
        if (change?.mqAlias !== 'sm') {
          this.hidePanel();
        }
      });
  }

  showPanel(): void {
    this.panel.show = true;
    this.cdr.detectChanges();
  }

  hidePanel(): void {
    this.panel.show = false;
    this.cdr.detectChanges();
  }

  get isButtonWhite() {
    return this.invertButtonColor ?
      !this.alternateColor :
      this.alternateColor
    ;
  }
}
