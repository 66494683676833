import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopDefaultFilterService {

  private readonly searchSubject = new Subject<void>();
  searchChange$ = this.searchSubject.asObservable();

  searchChange(): void {
    this.searchSubject.next();
  }
}
