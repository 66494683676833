import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'rhbnb-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.sass']
})
export class EmptyPageComponent implements OnInit {

  @Input() header: string;
  @Input() subheader: string;
  @Input() linkText: string;
  @Input() url: Array<any>;

  constructor(
    private translate: TranslocoService
  ) { }

  ngOnInit(): void {
    this.header = this.header ? this.header : this.translate.translate('core.empty_header_default');
    this.subheader = this.subheader ? this.subheader : this.translate.translate('core.empty_subheader_default');
  }

}
