import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiResponse, PageData, User } from '@rhbnb-nx-ws/domain';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

import { AbstractDataService } from '../util';

const CACHE_TTL = 15 * 60 * 1000; // 15 mins

@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractDataService<User> {
  paginateCacheKey = 'paginateCache';

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'users', apiURL);
  }

  add(entity: User): Observable<ApiResponse<User>> {
    return super
      .add(entity)
      .pipe(tap(() => this.invalidateCacheGroupEntry(this.paginateCacheKey)));
  }

  paginate(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string,
    extraParams?: HttpParams,
    currentUser?: User
  ): Observable<ApiResponse<PageData<User>>> {
    const source$ = super.paginate(
      q,
      page,
      limit,
      sort,
      order,
      extraParams,
      currentUser
    );

    return this.getCacheEntry<ApiResponse<PageData<User>>>(
      this.getCacheKeyWithParam(
        this.paginateCacheKey,
        `${q}_${page}_${limit}_${sort}_${order}_${extraParams?.toString()}_${currentUser}`
      )
    ).sharedReplayTimerRefresh(source$, 1, CACHE_TTL);
  }

  uploadImage(image: any): Observable<ApiResponse<string>> {
    const formData = new FormData();
    formData.append('image', image);

    return this.http
      .post<ApiResponse<string>>(
        `${this.apiURL}/${this.endpointName}/image`,
        formData
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  patchMe(entity: User): Observable<ApiResponse<User>> {
    return this.http
      .patch<ApiResponse<User>>(
        `${this?.apiURL}/${this.endpointName}/me`,
        entity
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  checkEmail(email: string): Observable<ApiResponse<boolean>> {
    return this.http
      .get<ApiResponse<boolean>>(
        `${this?.apiURL}/${this.endpointName}/check-by-email/${email}`,
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }
}
