import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Message } from './message.model';

@Component({
  selector: 'rhbnb-chat-loading',
  templateUrl: './chat-loading.component.html',
  styleUrls: ['./chat-loading.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatLoadingComponent implements OnInit {

  size = [...Array(20).keys()];

  constructor() { }

  ngOnInit(): void {
  }

  alignMessage() {
    return Math.random() > 0.5 ? 'start' : 'end';
  }
}
