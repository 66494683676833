<ng-container *ngIf="form" [formGroup]="form">
  <mat-form-field class="full-width" appearance="outline">

    <mat-label>{{ label }}</mat-label>

    <mat-select
      [formControlName]="controlName"
      (infiniteScroll)="getNextBatch()" [threshold]="'10%'" msInfiniteScroll>
      <mat-option *ngFor="let num of range" [value]="num">
        {{ num }}
      </mat-option>

    </mat-select>

    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>

  </mat-form-field>

</ng-container>

<ng-container *ngIf="!form">

  <mat-form-field class="full-width" appearance="outline">

    <mat-label>{{ label }}</mat-label>

    <mat-select [value]="value"
                (valueChange)="onValueChange($event)"
                (infiniteScroll)="getNextBatch()" [threshold]="'10%'" msInfiniteScroll>
      <mat-option *ngFor="let num of range" [value]="num">
        {{ num }}
      </mat-option>

    </mat-select>

    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>

  </mat-form-field>

</ng-container>
