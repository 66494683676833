<div fxLayout="row wrap"
     fxLayout.xxs="column"
     fxLayoutAlign="start stretch"
     fxLayoutAlign.xxs="center stretch"
     fxLayoutGap="15px grid">

  <rhbnb-outing-rules-item-view
    fxFlex="50"
    fxFlex.xxs="100"
    fxLayout="row wrap"
    *ngFor="let r of rules"

    [rule]="r"></rhbnb-outing-rules-item-view>

</div>
