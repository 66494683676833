<div
  fxHide
  fxShow.gt-xs

  fxLayout="row wrap"
  fxLayoutAlign="start stretch"
  fxLayoutGap="10px">

  <div class="left-side load-animate" fxFlex="0 1 calc(50% - 10px)">
  </div>

  <div class="right-side" fxFlex="0 1 calc(50% - 10px)">

    <div fxLayout="row wrap"
         fxLayoutGap="5px grid"
         fxLayoutAlign="start stretch">

      <div fxFlex="0 1 calc(50% - 5px)"
           [ngClass]="'img_'+index"
           *ngFor="let img of count; let index = index">

        <div class="load-animate item"></div>

      </div>

    </div>

  </div>
</div>

<div fxHide.gt-xs class="tiny load-animate">
</div>
