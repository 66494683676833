import { Action, createReducer, on } from '@ngrx/store';
import { Message } from '@jjbenitez/glue-rocket-lib';

import * as Actions from './actions';

export const moduleKey = 'libMessages';

export interface ChatData {
  messages: Message[],
  hasMore: boolean,
  initializing: boolean,
  failed: boolean,
  loadMore: boolean,
  isTyping: string[],
}

export interface Chats {
  [roomId: string]: ChatData
}

export interface State {
  chatLoginLoading: boolean;
  chatAuthToken: string;
  chatUserId: string;
  chatUser: {};
  chats: Chats;
  chatLostConnectionTime: number;
  chatReconnecting: boolean;
  chatUnreadCount: number;
  loginUserLoading: boolean;
}

const _getChatMessages = (messages) => {
  return messages ?? [];
}

export const initialState: State = {
  chatAuthToken: undefined,
  chatLoginLoading: false,
  chatUserId: undefined,
  chatUser: undefined,
  chats: undefined,
  chatReconnecting: false,
  chatLostConnectionTime: undefined,
  chatUnreadCount: 0,
  loginUserLoading: false,
}

const _reducer = createReducer(
  initialState,

  on(Actions.setChatAuthToken, (state, payload) => ({
    ...state,
    chatAuthToken: payload.token
  })),

  on(Actions.setChatUserId, (state, payload) => ({
    ...state,
    chatUserId: payload.userId
  })),

  on(Actions.setChatUser, (state, payload) => ({
    ...state,
    chatUser: payload.user
  })),

  on(Actions.pushChatGroupMessages, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: {
          ...(state.chats ? state.chats[payload.groupId]: []),
          messages: [
            ...(state.chats ? _getChatMessages(state.chats[payload.groupId]?.messages): []),
            ...payload.messages
          ],
          initializing: state.chats ? state.chats[payload.groupId]?.initializing: false,
          loadMore: state.chats ? state.chats[payload.groupId]?.loadMore: false,
          hasMore: undefined === payload.hasMore ?
            (state.chats ? state.chats[payload.groupId]?.hasMore : true) :
            payload.hasMore,
          isTyping: state.chats ? state.chats[payload.groupId]?.isTyping: [],
          failed: state.chats ? state.chats[payload.groupId]?.failed: false,
        }
      }
    }
  }),

  on(Actions.unshiftChatGroupMessages, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: {
          ...(state.chats ? state.chats[payload.groupId]: []),
          messages: [
            ...payload.messages,
            ...(state.chats ? _getChatMessages(state.chats[payload.groupId]?.messages): []),
          ],
          initializing: state.chats ? state.chats[payload.groupId]?.initializing: false,
          loadMore: state.chats ? state.chats[payload.groupId]?.loadMore: false,
          hasMore: undefined === payload.hasMore ?
            (state.chats ? state.chats[payload.groupId]?.hasMore : true) :
            payload.hasMore,
          isTyping: state.chats ? state.chats[payload.groupId]?.isTyping: [],
          failed: state.chats ? state.chats[payload.groupId]?.failed: false,
        }
      }
    }
  }),

  on(Actions.replaceChatGroupMessages, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: {
          ...(state.chats ? state.chats[payload.groupId]: []),
          messages: (state.chats ? _getChatMessages(state.chats[payload.groupId]?.messages): [])
            .map(m => (m._id === payload.id && true === m.local ? payload.message : m)),
          initializing: state.chats ? state.chats[payload.groupId]?.initializing: false,
          loadMore: state.chats ? state.chats[payload.groupId]?.loadMore : false,
          hasMore: state.chats ? state.chats[payload.groupId]?.hasMore : true,
          isTyping: state.chats ? state.chats[payload.groupId]?.isTyping: [],
          failed: state.chats ? state.chats[payload.groupId]?.failed: false,
        }
      }
    }
  }),

  on(Actions.setChatGroupHasMore, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: {
          ...(state.chats ? state.chats[payload.groupId]: []),
          messages: [
            ..._getChatMessages(state.chats[payload.groupId]?.messages),
          ],
          initializing: state.chats ? state.chats[payload.groupId]?.initializing : false,
          loadMore: state.chats ? state.chats[payload.groupId]?.loadMore : false,
          hasMore: payload.hasMore,
          isTyping: state.chats ? state.chats[payload.groupId]?.isTyping: [],
          failed: state.chats ? state.chats[payload.groupId]?.failed: false,
        }
      }
    }
  }),

  on(Actions.setChatGroupInitializing, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: {
          ...(state.chats ? state.chats[payload.groupId]: []),
          messages: [
            ..._getChatMessages(state.chats[payload.groupId]?.messages),
          ],
          initializing: payload.initializing,
          loadMore: state.chats ? state.chats[payload.groupId]?.loadMore : false,
          hasMore: state.chats ? state.chats[payload.groupId]?.hasMore : true,
          isTyping: state.chats ? state.chats[payload.groupId]?.isTyping: [],
          failed: state.chats ? state.chats[payload.groupId]?.failed: false,
        }
      }
    }
  }),

  on(Actions.setChatGroupData, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: payload.data
      }
    }
  }),

  on(Actions.setChatGroupLoadMore, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: {
          ...(state.chats ? state.chats[payload.groupId]: []),
          messages: [
            ..._getChatMessages(state.chats[payload.groupId]?.messages),
          ],
          loadMore: payload.loadMore,
          initializing: state.chats ? state.chats[payload.groupId]?.initializing : false,
          hasMore: state.chats ? state.chats[payload.groupId]?.hasMore : true,
          isTyping: state.chats ? state.chats[payload.groupId]?.isTyping: [],
          failed: state.chats ? state.chats[payload.groupId]?.failed: false,
        }
      }
    }
  }),

  on(Actions.addChatUserTyping, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: {
          ...(state.chats ? state.chats[payload.groupId]: []),
          messages: [
            ..._getChatMessages(state.chats[payload.groupId]?.messages),
          ],
          loadMore: state.chats ? state.chats[payload.groupId]?.loadMore : false,
          initializing: state.chats ? state.chats[payload.groupId]?.initializing : false,
          hasMore: state.chats ? state.chats[payload.groupId]?.hasMore : true,
          isTyping: state.chats ?
            [
              ...state.chats[payload.groupId]?.isTyping ?? [],
              payload.user
            ] :
            [],
          failed: state.chats ? state.chats[payload.groupId]?.failed: false,
        }
      }
    }
  }),

  on(Actions.setChatGroupFailed, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: {
          ...(state.chats ? state.chats[payload.groupId]: []),
          messages: [
            ..._getChatMessages(state.chats[payload.groupId]?.messages),
          ],
          loadMore: state.chats ? state.chats[payload.groupId]?.loadMore : false,
          initializing: state.chats ? state.chats[payload.groupId]?.initializing : false,
          hasMore: state.chats ? state.chats[payload.groupId]?.hasMore : true,
          isTyping: state.chats ? state.chats[payload.groupId]?.isTyping: [],
          failed: payload.failed,
        }
      }
    }
  }),

  on(Actions.deleteChatUserTyping, (state, payload) => {
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.groupId]: {
          ...(state.chats ? state.chats[payload.groupId]: []),
          messages: [
            ..._getChatMessages(state.chats[payload.groupId]?.messages),
          ],
          loadMore: state.chats ? state.chats[payload.groupId]?.loadMore : false,
          initializing: state.chats ? state.chats[payload.groupId]?.initializing : false,
          hasMore: state.chats ? state.chats[payload.groupId]?.hasMore : true,
          isTyping: state.chats ? state.chats[payload.groupId]?.isTyping.filter(t => t !== payload?.user) : [],
          failed: state.chats ? state.chats[payload.groupId]?.failed: false,
        }
      }
    }
  }),

  on(Actions.setChatLostConnectionTime, (state, payload) => ({
    ...state,
    chatLostConnectionTime: payload.time
  })),

  on(Actions.setChatReconnection, (state, payload) => ({
    ...state,
    chatReconnecting: payload.reconnecting
  })),

  on(Actions.incChatUnreadCount, (state, payload) => ({
    ...state,
    chatUnreadCount: state.chatUnreadCount + payload.count
  })),

  on(Actions.decChatUnreadCount, (state, payload) => ({
    ...state,
    chatUnreadCount: state.chatUnreadCount - payload.count
  })),

  on(Actions.setChatUnreadCount, (state, payload) => ({
    ...state,
    chatUnreadCount: payload.count
  })),

  on(Actions.setLoginUserLoading, (state, payload) => ({
    ...state,
    loginUserLoading: payload.loading
  })),
);

export function reducer(state: State | undefined, action: Action): any {
  return _reducer(state, action);
}
