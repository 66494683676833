import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  Renderer2,
  EventEmitter,
  Output,
  ElementRef,
  HostBinding,
  Optional,
  Self,
  OnDestroy,
  forwardRef,
  Inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NgControl,
} from '@angular/forms';
import { Moment } from 'moment';
import {
  DaterangepickerComponent,
  DaterangepickerDirective,
} from '@jjbenitez/ngx-daterangepicker-material';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ApiResponse, Event } from '@rhbnb-nx-ws/domain';
import * as moment from 'moment';

import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';

export interface DateRange {
  start: Moment;
  end: Moment;
}

export interface Range {
  startDate: any;
  endDate: any;
}

// tslint:disable-next-line:max-classes-per-file
@Component({
  selector: 'rhbnb-lockable-daterange-selector',
  templateUrl: './lockable-daterange-selector.component.html',
  styleUrls: ['./lockable-daterange-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      multi: true,
      useExisting: forwardRef(() => LockableDaterangeSelectorComponent),
    },
  ],
})
export class LockableDaterangeSelectorComponent
  extends WithUnsubscribe()
  implements
    OnInit,
    AfterViewInit,
    OnDestroy,
    MatFormFieldControl<Range>,
    ControlValueAccessor
{
  static nextId = 0;
  stateChanges = new Subject<void>();

  @Input()
  set value(value: Range) {
    this.form.patchValue(value);
    if (this.pickerEl) {
      setTimeout(() => this.pickerEl.updateView());
    }

    this.stateChanges.next();
  }

  get value() {
    return this.form.value;
  }

  form: FormGroup;
  touched = false;
  events: Event[];

  @ViewChild('input', { read: ElementRef, static: false })
  input: ElementRef;

  /**
   * Range of dates to ignore even if
   * are locked
   * Useful for editing cases
   */
  @Input() ignoreRange: { start: Moment; end: Moment };
  @Input() elementIds: any[];
  @Input() lockPastDates = true;
  @Input() dateMinLimit = 1;
  @Input() endLockDatesMessage;
  @Input() adjustForFlex = false;
  @Input() singlePicker = false;
  @Input() showAsSinglePanel = false;
  @Input() clickable = true;

  /**
   * Use as form control (true by default)
   * otherwise, show a inline range calendar to pick dates
   */
  @Input() asFormControl = true;

  get rangeMinDate() {
    return this.lockPastDates ? moment() : undefined;
  }

  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective: DaterangepickerDirective;

  @ViewChild(DaterangepickerComponent, { static: false })
  pickerCmp: DaterangepickerComponent;

  get pickerEl() {
    return this.pickerDirective ? this.pickerDirective.picker : this.pickerCmp;
  }

  locale = {
    format: 'DD/MM/YYYY',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
  };

  // Reference to calendar loading indicator
  loadingContainer: any;

  invalidDates = [];

  /**
   * Store a invalid date range end dates here
   * These dates can be used as the end of a range but not as the start
   */
  invalidStartingDates = [];
  tooltips = [];

  @Output() invalidDatesChange = new EventEmitter<string[]>();
  @Output() chooseDate = new EventEmitter<Range>();
  @Output() minRangeChange = new EventEmitter<number>();
  @Output() startDateChanged = new EventEmitter<any>();

  // Keep updated the range of events
  // that have been queried to the backend
  _searchRange: DateRange = {
    start: undefined,
    end: undefined,
  };

  _tmpRange: DateRange = {
    start: undefined,
    end: undefined,
  };

  private readonly calendarsChange$$ = new Subject<void>();
  private readonly findNewDateRange$$ = new Subject<DateRange>();

  @HostBinding()
  id = `custom-form-field-id-${LockableDaterangeSelectorComponent.nextId++}`;

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get placeholder() {
    return this._placeholder;
  }

  private _placeholder: string;

  focused: boolean;

  get empty(): boolean {
    return !this.value;
  }

  @HostBinding('class.floated')
  get shouldLabelFloat(): boolean {
    return true;
  }

  @Input()
  required: boolean;

  @Input()
  disabled: boolean;

  controlType?: 'house-scroll-selector';

  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input() getEvents: (
    ids: string[],
    from: Moment,
    to: Moment
  ) => Observable<ApiResponse<Event[]>> = () =>
    of({ success: true, data: [], errors: undefined });

  @Input() getLockStartingRangeDate: (range: string[]) => any[] = () => [];

  onChange = (range) => {};
  onTouched = () => {};

  constructor(
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private focusMonitor: FocusMonitor,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super();

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.createForm();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.form.disable();
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  createForm() {
    this.form = this.fb.group({
      range: [
        {
          startDate: null,
          endDate: null,
        },
      ],
    });
  }

  ngOnInit(): void {
    this.subscribeToCalendarChanges();
    this.subscribeToNewDateRangeChanges();

    setTimeout(() => {
      if (this.input) {
        this.focusMonitor.monitor(this.input).subscribe((focused) => {
          this.focused = !!focused;
          this.stateChanges.next();
        });

        this.focusMonitor
          .monitor(this.input)
          .pipe(take(1))
          .subscribe(() => {
            this.onTouched();
          });
      }
    });

    this.form.valueChanges
      .pipe(
        tap((v) => this.onChange(v.range)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  onContainerClick(): void {
    this.focusMonitor.focusVia(this.input, 'program');
  }

  triggerCalendarChange() {
    this.calendarsChange$$.next();
  }

  private subscribeToNewDateRangeChanges() {
    this.findNewDateRange$$
      .pipe(
        mergeMap((r) =>
          forkJoin([this.getEvents(this.elementIds, r.start, r.end), of(r)])
        ),
        tap(([res, range]) => {
          this.preProcessEvents(res.data);
          this.updateSearchRange(range);

          this.hideCalendarLoader();
          this.cdr.detectChanges();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private subscribeToCalendarChanges() {
    this.calendarsChange$$
      .pipe(
        debounceTime(150),
        tap(() => this.findNewRanges()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  writeValue(range: Range) {
    this.form.patchValue({ range });
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  preProcessEvents(events: Event[]) {
    this.events = events;

    let locks = events
      .filter((e) => !e.available)
      .map((e) => e.start.split('T')[0])
      .filter((item, pos, self) => self.indexOf(item) === pos);

    if (this.ignoreRange) {
      locks = locks.filter(
        (e) =>
          !moment(e).isBetween(
            this.ignoreRange.start,
            this.ignoreRange.end,
            'day',
            '[]'
          )
      );
    }

    this.invalidDates = [...new Set([...this.invalidDates, ...locks])];
    this.invalidDatesChange.emit(this.invalidDates);

    this.invalidStartingDates = this.getLockStartingRangeDate(
      this.invalidDates
    );

    if (this.endLockDatesMessage) {
      this.tooltips = this.invalidStartingDates.map((d) => ({
        date: d,
        text: this.endLockDatesMessage,
      }));
    }

    this.pickerEl.updateCalendars();
    this.cdr.detectChanges();
  }

  isValidDate(date: Moment) {
    this.waitForRange(date);
    return this.invalidDates.includes(date.format('YYYY-MM-DD'));
  }

  isInvalidStartingDate(date: Moment) {
    return this.invalidStartingDates.includes(date.format('YYYY-MM-DD'));
  }

  isTooltipDate(date: Moment) {
    const tooltip = this.tooltips.find(
      (tt) => tt.date === date.format('YYYY-MM-DD')
    );
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  }

  get range() {
    return this.form.get('range');
  }

  get isRangeSet() {
    return this.range?.value?.startDate && this.range?.value?.endDate;
  }

  get errorState(): boolean {
    return this.form.invalid && this.touched;
  }

  /**
   * Wait for calendar selection range changes
   */
  private waitForRange(date: Moment) {
    if (!this._searchRange.start) {
      this._tmpRange.start = date;
      this._searchRange.start = date;
    } else if (
      date.isBefore(this._searchRange.start) &&
      date.isBefore(this._tmpRange.start)
    ) {
      this._tmpRange.start = date;
    }

    if (!this._searchRange.end) {
      this._tmpRange.end = date;
      this._searchRange.end = date;
    } else if (
      date.isAfter(this._searchRange.end) &&
      date.isAfter(this._tmpRange.end)
    ) {
      this._tmpRange.end = date;
    }

    this.calendarsChange$$.next();
  }

  /**
   * Find new event ranges
   * finding the differences between
   * searchRange and tmpRange
   */
  private findNewRanges() {
    if (this._tmpRange.start.isBefore(this._searchRange.start)) {
      this.showCalendarLoader();

      this.findNewDateRange$$.next({
        start: this._tmpRange.start,
        end: this._searchRange.start,
      });
    }

    if (this._tmpRange.end.isAfter(this._searchRange.end)) {
      this.showCalendarLoader();

      this.findNewDateRange$$.next({
        start: this._searchRange.end,
        end: this._tmpRange.end,
      });
    }
  }

  /**
   * Trigger by component date picker
   * when range date is selected
   */
  onChooseDate(event) {
    let min = 1;

    // The minNights property in the events says the minimum
    // number of nights to reserve. By iterating all the
    // events I can find the maximum value in the range to apply to the rule
    for (const e of this.events) {
      if (
        moment(e.start).isBetween(event.startDate, event.endDate, 'day', '[)')
      ) {
        min = e.minNights && e.minNights > min ? e.minNights : min;
      }
    }

    if (
      event.endDate
        .clone()
        .startOf('day')
        .diff(event.startDate.clone().startOf('day'), 'days') < min
    ) {
      this.minRangeChange.next(min);
    } else {
      this.chooseDate.emit(event);
      setTimeout(() => this.pickerEl.updateView());
    }
  }

  private updateSearchRange(searchedRange: DateRange) {
    if (searchedRange.start.isSameOrBefore(this._searchRange.start)) {
      this._searchRange.start = searchedRange.start;
    }

    if (searchedRange.end.isSameOrAfter(this._searchRange.end)) {
      this._searchRange.end = searchedRange.end;
    }
  }

  ngAfterViewInit(): void {
    this.appendLoaderToCalendar();
  }

  private appendLoaderToCalendar() {
    const parent = this.pickerEl.pickerContainer.nativeElement;
    const [calendarTable1, calendarTable2] =
      parent.querySelectorAll('.calendar-table');

    this.renderer.setStyle(calendarTable1, 'position', 'relative');

    this.loadingContainer = this.renderer.createElement('div');
    const loader = this.renderer.createElement('div');

    this.renderer.setStyle(this.loadingContainer, 'position', 'absolute');
    this.renderer.setStyle(this.loadingContainer, 'bottom', '0');
    this.renderer.setStyle(this.loadingContainer, 'top', '0');
    this.renderer.setStyle(this.loadingContainer, 'right', '0');
    this.renderer.setStyle(this.loadingContainer, 'left', '0');
    this.renderer.setStyle(this.loadingContainer, 'display', 'none');
    this.renderer.setStyle(this.loadingContainer, 'align-items', 'center');
    this.renderer.setStyle(this.loadingContainer, 'justify-content', 'center');

    this.renderer.addClass(loader, 'loader');

    this.renderer.appendChild(this.loadingContainer, loader);

    if (calendarTable1) {
      this.renderer.appendChild(calendarTable1, this.loadingContainer);
    }

    if (calendarTable2) {
      this.renderer.appendChild(calendarTable2, this.loadingContainer);
    }
  }

  showCalendarLoader() {
    this.renderer.setStyle(this.loadingContainer, 'display', 'flex');
  }

  hideCalendarLoader() {
    this.renderer.setStyle(this.loadingContainer, 'display', 'none');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.focusMonitor.stopMonitoring(this.input);
    this.stateChanges.complete();
  }
}
